import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import {Grid, Icon} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import connect from 'react-redux/es/connect/connect'
import autobind from 'autobind-decorator'
import {updateLocal, updateLocalAtIndex, deleteLocalAtIndex, addMaterial} from '../redux/actions'
import styles, {DetailTextField, ButtonFilled} from './CreateRecipe.styles.js'
import DeleteButton from './DeleteButton'
import InputInteger from '../../common/InputInteger'

import dragHandle from '../../../assets/icons/ellipsis-v.svg'

class RecipeMaterials extends React.Component {
  onChange(index, patch) {
    this.props.updateLocalAtIndex('recipe', 'materials', index, patch)
  }

  onChangeSelect(fieldName, value) {
    this.props.updateLocalAtIndex({[fieldName]: value}, 'recipe')
  }

  @autobind
  deleteMaterial(index) {
    this.props.deleteLocalAtIndex('recipe', 'materials', index)
  }

  @autobind
  addMaterial(e) {
    e.preventDefault()
    this.props.addMaterial()
  }

  onDragStart(e, index) {
    this.draggedIndex = index
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', e.target.parentNode.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode.parentNode, 20, 20)
  }

  onDragOver(index) {
    if (typeof this.draggedIndex === 'undefined') {
      return
    }
    const {recipe} = this.props
    if (this.draggedIndex === index) {
      return
    }
    const materials = recipe.materials.filter((material, index) => index !== this.draggedIndex)
    materials.splice(index, 0, recipe.materials[this.draggedIndex])
    this.props.updateLocal({materials}, 'recipe')
    this.draggedIndex = index
  }

  onDragEnd() {
    this.draggedIndex = undefined
  }

  render() {
    const {recipe, onBlur, classes} = this.props
    return (
      <Grid item xs={12} md={6}>
        <Grid item container spacing={3}>
          <Grid item md={8} className={classes.ingredientMaterialTitleDesktop}>
            Material
          </Grid>
          <Grid item md={4} className={classes.ingredientMaterialTitleDesktop}>
            Quantity
          </Grid>
          <Grid item xs={12} className={classes.materialMobileTitle}>
            Add Material
          </Grid>
          {recipe.materials.map((material, index) => {
            return (
              <Grid
                key={index}
                className={classes.materials}
                item
                xs={12}
                onDragOver={() => this.onDragOver(index)}
              >
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={6} md={8}>
                    <Grid item xs={12} md={8} className={classes.ingredientMaterialTitleMobile}>
                      Material
                    </Grid>
                    <img
                      src={dragHandle}
                      alt="drag handle"
                      draggable
                      onDragStart={e => this.onDragStart(e, index)}
                      onDragEnd={e => {
                        this.onDragEnd(e)
                        onBlur()
                      }}
                      className={classes.dragImage}
                    />
                    <DetailTextField
                      value={material.material.title}
                      fullWidth
                      inputProps={{maxLength: 100}}
                      name="title"
                      onChange={e => this.onChange(index, {material: {title: e.target.value}})}
                      onBlur={onBlur}
                      className={classes.draggableInput}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4} md={3}>
                    <Grid item xs={12} md={3} className={classes.ingredientMaterialTitleMobile}>
                      Quantity
                    </Grid>
                    <DetailTextField
                      className={classes.quantityInput}
                      value={material.quantity}
                      fullWidth
                      decimalSeparator={false}
                      name="quantity"
                      onChange={e => this.onChange(index, {quantity: e.target.value})}
                      onBlur={onBlur}
                      component={InputInteger}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <DeleteButton
                      className={classes.deleteButtonIngredients}
                      onClick={() => {
                        this.deleteMaterial(index)
                        setTimeout(onBlur, 50)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          })}

          <Grid item xs={12} className={classes.addButton}>
            <ButtonFilled fullWidth onClick={this.addMaterial} variant="outlined">
              <Icon color="action">add_circle_outline</Icon>
            </ButtonFilled>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

RecipeMaterials.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {updateLocalAtIndex, deleteLocalAtIndex, updateLocal, addMaterial},
    dispatch,
  )
}

function mapStateToProps(state) {
  return {...state.recipe}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(RecipeMaterials)

import React from 'react'
import {
  Grid,
  ListItemText,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  Button,
  withStyles,
} from '@material-ui/core'
import autobind from 'autobind-decorator'
import _ from 'lodash'
import {compose, bindActionCreators} from 'redux'
import connect from 'react-redux/es/connect/connect'

import MyKitchenApi from '../../myKitchen/api'
import {createNotificationFromError, createSuccess} from '../../common/redux/actions.notifications'
import {createCategorySuccess} from '../../myKitchen/redux/actions'
import CreateCategoryMobile from '../../myKitchen/CreateCategoryMobile'

const styles = theme => ({
  title: {
    fontFamily: 'Lato',
    fontSize: '24px',
    lineHeight: '29px',
    fontWeight: '300',
    paddingBottom: '10px',
    borderBottom: '1.5px solid #589a43',
    paddingLeft: '10px',
  },
  contentContainer: {
    padding: '30px 30px 0 30px',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 8px 0 8px',
    },
  },
  categoriesContainer: {
    padding: '0',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  categoryName: {
    fontFamily: 'Lato',
    fontSize: '18px',
    lineHeight: '29px',
    fontWeight: '300',
    paddingBottom: '10px',
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 20,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
    },
  },

  categoryNameModal: {
    fontFamily: 'Lato',
    fontSize: '18px',
    lineHeight: '29px',
    fontWeight: '300',
    paddingBottom: '10px',
  },
  select: {
    height: '51px',
    width: '96%',
    border: '1.5px solid #D8D8D8',
    borderRadius: '3px',
    padding: '3px',
    paddingLeft: 10,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  saveBtn: {
    height: '30px',
    width: '132px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    padding: 0,
    color: '#589a43',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '21px',
    [theme.breakpoints.down('xs')]: {
      width: '140px',
    },
    textTransform: 'none',
  },
  cancelBtn: {
    height: '33px',
    width: '107px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '14px',
    lineHeight: '16px',
    padding: 0,
    textTransform: 'none',
  },
  checkBox: {
    color: '#589a43 !important',
  },
  actionsContainer: {
    width: '100%',
    padding: '18px 30px 0 30px',
    [theme.breakpoints.down('xs')]: {
      padding: '5px 5px 0 5px',
    },
  },
  createCategoryBtn: {
    height: 48,
    width: '100%',
    border: '1.15px solid #D8D8D8',
    lineHeight: '18px',
    fontSize: 13,
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
      width: '100%',
    },
  },
  createCategoryInput: {
    width: '100%',
    border: '1.5px solid #D8D8D8',
    borderRadius: '3px',
    height: '51px',
    marginTop: 0,
    backgroundColor: '#FFFFFF',
    color: '#000',
    padding: '9px 11px',
  },
  saveLongBtn: {
    height: '30px',
    width: '280px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    padding: 0,
    color: '#589a43',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '21px',
    [theme.breakpoints.down('xs')]: {
      width: '200px',
    },
    textTransform: 'none',
  },
  cancelLongBtn: {
    height: '23px',
    width: '176px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '14px',
    lineHeight: '16px',
    padding: 0,
    textTransform: 'none',
  },
  categoryNameText: {
    width: '474px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  saveCancelMobile: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  createMobile: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  selectCategoryDialog: {
    margin: 0,
  },
  cancelSaveMobile: {
    display: 'inline-block',
    marginLeft: '15px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      display: 'block',
      marginTop: 10,
    },
  },
  createCategoryContainer: {
    padding: 20,
    backgroundColor: 'white',
    position: 'absolute',
    width: '90%',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
})

class Categories extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      categories: [],
      selectedCategories: [{name: 'All', id: ''}],
      createCategoryDialogOpen: false,
    }
  }

  @autobind
  async onCategorySaved(category) {
    this.setState(state => {
      const selCats = [...state.selectedCategories, category.data]
      const categories = [...state.categories, category.data]
      return {
        selectedCategories: selCats,
        currentComponent: 'selectCategories',
        categoryName: '',
        categories,
      }
    })
    this.renderSelectedCategories(this.state.selectedCategories)
    this.props.onCategoryAdded(this.state.selectedCategories)
  }

  componentDidMount() {
    this.fetchCategories()
  }

  @autobind
  createCategoryToggle() {
    this.setState(state => {
      return {createCategoryDialogOpen: !state.createCategoryDialogOpen}
    })
  }

  async fetchCategories() {
    const {profile, categories} = this.props
    const selectedCategories = [{name: 'All', id: ''}]
    try {
      const cats = await MyKitchenApi.getCategories(profile.id)
      categories.forEach(selCat => {
        cats.results.forEach(cat => {
          if (selCat === cat.id) selectedCategories.push(cat)
        })
      })
      this.setState({categories: cats.results, selectedCategories})
      this.renderSelectedCategories(selectedCategories)
    } catch (e) {
      console.error(e)
    }
  }

  @autobind
  isCategorySelected(category) {
    const {selectedCategories} = this.state

    const index = _.findIndex(selectedCategories, ['id', category.id])
    return index !== -1
  }

  @autobind
  handleChange(event) {
    this.setState({selectedCategories: event.target.value})
    this.props.onCategoryAdded(event.target.value)
  }

  @autobind
  renderSelectedCategories(selectedCategories) {
    const selected = selectedCategories.map(cat => cat.name)
    return selected.join(', ')
  }

  render() {
    const {classes} = this.props
    const {categories, selectedCategories, createCategoryDialogOpen} = this.state

    return (
      <React.Fragment>
        <Typography variant="body2" className={classes.categoryName}>
          Category Name
        </Typography>
        <Grid container className={classes.categoriesContainer}>
          <Grid item xs={12} sm={12} md={10}>
            <Select
              multiple
              disableUnderline
              className={classes.select}
              onChange={this.handleChange}
              renderValue={this.renderSelectedCategories}
              value={selectedCategories}
            >
              <MenuItem disabled value="all-id">
                <Checkbox classes={{checked: classes.checkBox}} checked={true} />
                <ListItemText primary="All" />
              </MenuItem>
              {categories.map(cat => (
                <MenuItem key={cat.id} value={cat}>
                  <Checkbox
                    classes={{checked: classes.checkBox}}
                    checked={this.isCategorySelected(cat)}
                  />
                  <ListItemText primary={cat.name} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} md={2} sm={12}>
            <Button
              id="createCategory"
              className={classes.createCategoryBtn}
              onClick={this.createCategoryToggle}
            >
              Create Category
            </Button>
          </Grid>
        </Grid>
        <CreateCategoryMobile
          openModal={createCategoryDialogOpen}
          useSmall={true}
          onCancel={this.createCategoryToggle}
          onSaved={this.onCategorySaved}
        />
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {profile: state.profile.profile}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {createSuccess, createNotificationFromError, createCategorySuccess},
    dispatch,
  )
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(Categories)

import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {
  IconButton,
  Icon,
  Popover,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Button,
} from '@material-ui/core'
import MuiCheckbox from '@material-ui/core/Checkbox'
import {withStyles} from '@material-ui/core/styles'
import autobind from 'autobind-decorator'
import {fraction, number, round} from 'mathjs'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import pluralize from 'pluralize'
import {DetailTextField, SelectFilled} from '../recipe/CreateRecipeFlow/CreateRecipe.styles.js'
import {getUnitLabel, UNITS} from '../constants/Units'
import {deleteShoppingItem, updateShoppingItem, updateShoppingItemByTitle} from './redux/actions'
import {isNumeric} from '../recipe/utils'
import {ROUND_DIGITS} from '../utils/constants'

const Checkbox = props => {
  return (
    <MuiCheckbox
      checkedIcon={<Icon className="fa fa-check-circle-o" style={{color: '#D8D8D8'}} />}
      icon={<Icon className="fa fa-circle-o" style={{color: '#D8D8D8'}} />}
      {...props}
    />
  )
}

class ShoppingItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      editMode: false,
      ingredient: '',
      quantity: '',
      unit: '',
      otherUnit: '',
      error: null,
    }
  }

  @autobind
  delete(e) {
    e.preventDefault()
    this.props.deleteShoppingItem(this.props.item.id)
  }

  @autobind
  toggleDone(e, checked) {
    e.preventDefault()
    const {item} = this.props
    this.props.updateShoppingItem(item.id, {done: checked})
  }

  @autobind
  handleOpen() {
    this.setState({open: true})
  }

  @autobind
  handleClose() {
    this.setState({open: false})
  }

  @autobind
  editMode() {
    const {item} = this.props
    this.setState({
      editMode: true,
      open: false,
      ingredient: item.ingredient.title,
      quantity: fraction(number(item.quantity)),
      unit: item.unit,
      otherUnit: getUnitLabel(item.unit) === 'Other' ? item.unit : '',
    })
  }

  @autobind
  onBlur() {
    const {quantity} = this.state
    try {
      fraction(quantity)
      this.setState({error: null})
    } catch (error) {
      this.setState({error: 'Please enter a valid number'})
    }
  }

  @autobind
  onCloseEditDialog() {
    this.setState({editMode: false, error: null})
  }

  @autobind
  handleChangeInput(e) {
    const {
      target: {name, value},
    } = e
    this.setState({[name]: value})
  }

  @autobind
  handleChangeInputQuantity(e) {
    const {
      target: {value},
    } = e
    this.setState({quantity: value})
    try {
      fraction(value)
      this.setState({error: null})
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  @autobind
  handleSave() {
    const {item} = this.props
    this.props.updateShoppingItemByTitle(
      item.id,
      {
        ingredient: this.state.ingredient,
        quantity: round(number(fraction(this.state.quantity)), ROUND_DIGITS),
        unit:
          getUnitLabel(this.state.unit) === 'Other'
            ? pluralize.singular(this.state.otherUnit.toLowerCase())
            : this.state.unit,
      },
      null,
      success => {
        if (success) {
          this.onCloseEditDialog()
        }
      },
    )
  }

  render() {
    const {classes, item} = this.props
    const {open, editMode, error} = this.state
    return (
      <div className={classes.root}>
        <Checkbox color="default" onChange={this.toggleDone} checked={item.done} />
        <span className={classes.title}>{item.ingredient.title}</span>
        <span className={classes.quantity}>
          {fraction(number(item.quantity)).toFraction(true)}{' '}
          {this.capitalizeFirstLetter(pluralize(item.unit?.toLowerCase(), number(item.quantity)))}
        </span>
        <span>
          <IconButton
            onClick={this.handleOpen}
            ref={node => {
              this.anchorEl = node
            }}
          >
            <Icon>more_vert</Icon>
          </IconButton>
          <Popover
            anchorEl={this.anchorEl}
            open={open}
            onClose={this.handleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            className={classes.popover}
          >
            <MenuList>
              <MenuItem button onClick={this.editMode} className={classes.edit}>
                <ListItemIcon>
                  <Icon>edit</Icon>
                </ListItemIcon>
                <ListItemText inset primary="Edit" />
              </MenuItem>
              <MenuItem button onClick={this.delete}>
                <ListItemIcon>
                  <Icon>delete</Icon>
                </ListItemIcon>
                <ListItemText inset primary="Delete" />
              </MenuItem>
            </MenuList>
          </Popover>
        </span>

        {editMode && (
          <Dialog open={editMode} onClose={this.onCloseEditDialog}>
            <DialogTitle>Edit Ingredient in Shopping List</DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <DetailTextField
                    value={this.state.ingredient}
                    fullWidth
                    name="ingredient"
                    onChange={this.handleChangeInput}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <FormControl error={!!error}>
                    <DetailTextField
                      aria-describedby="quantity-error-text"
                      value={
                        isNumeric(this.state.quantity)
                          ? fraction(this.state.quantity).toFraction(true)
                          : this.state.quantity
                      }
                      fullWidth
                      onChange={this.handleChangeInputQuantity}
                      onBlur={this.onBlur}
                    />
                    {!!error && <FormHelperText id="quantity-error-text">{error}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <SelectFilled
                    fullWidth
                    value={this.state.unit}
                    onChange={this.handleChangeInput}
                    name="unit"
                  >
                    {UNITS.map(unit => {
                      return (
                        <option value={unit.value} key={unit.value}>
                          {unit.label}
                        </option>
                      )
                    })}
                  </SelectFilled>
                </Grid>
                {getUnitLabel(this.state.unit) === 'Other' && (
                  <>
                    <Grid
                      item
                      sm={6}
                      xs={12}
                      alignItems="center"
                      justifyContent="flex-end"
                      style={{display: 'flex'}}
                    >
                      <Typography variant="body1">Custom Unit</Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <DetailTextField
                        value={this.state.otherUnit}
                        fullWidth
                        name="otherUnit"
                        onChange={this.handleChangeInput}
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.onCloseEditDialog} color="primary">
                Cancel
              </Button>
              <Button disabled={!!this.state.error} color="primary" onClick={this.handleSave}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    )
  }
}

ShoppingItem.displayName = 'ShoppingItem'

const styles = {
  //   edit: {
  //     [theme.breakpoints.down('sm')]: {
  //       display: 'none',
  //     },
  //   },
  root: {
    display: 'flex',
    border: '1px solid #D8D8D8',
    borderRadius: 3,
    marginBottom: 18,
  },
  title: {
    flexGrow: 1,
    padding: '14px 0 12px 0',
    fontSize: 16,
  },
  quantity: {
    padding: '14px 0 12px 12px',
    fontSize: 16,
    whiteSpace: 'nowrap',
  },
  popover: {},
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {deleteShoppingItem, updateShoppingItem, updateShoppingItemByTitle},
    dispatch,
  )
}

export default compose(connect(null, mapDispatchToProps), withStyles(styles))(ShoppingItem)

import React from 'react'
import PropTypes from 'prop-types'
import autobind from 'autobind-decorator'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {FacebookShareButton, TwitterShareButton} from 'react-share'

import {Typography, Grid, Dialog, DialogContent, withStyles} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTwitter, faFacebookSquare} from '@fortawesome/free-brands-svg-icons'
import defaultFood from '../../../assets/defaultFood.png'
import {DetailTextField} from '../CreateRecipeFlow/CreateRecipe.styles'
import ButtonWithProgress from '../../common/ButtonWithProgress'
import {StyledButton} from './RecipeView.styles'
import RecipeApi from '../api'
import {createNotificationFromError, createSuccess} from '../../common/redux/actions.notifications'

const styles = theme => ({
  shareDialog: {
    width: '1109px',
  },
  shareDialogContent: {
    padding: '0 !important',
  },
  shareImage: {
    height: '400px',
    width: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      width: '100%',
    },
  },
  title: {
    fontFamily: 'Literata',
    fontSize: '32px',
    fontWeight: '400',

    lineHeight: '36px',
    marginBottom: '10px',
  },
  author: {
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '21px',
  },
  titleContainer: {
    padding: '20px',
  },
  shareRight: {
    padding: '20px',
  },
  shareThis: {
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '24px',
    lineHeight: '29px',
    paddingBottom: '10px',
    borderBottom: '2px solid #589a43',
    color: '#000',
    width: '100%',
  },
  sendTo: {
    color: '#000',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '18px',
    lineHeight: '21px',
    margin: '10px 0',
  },
  shareEmailText: {
    width: '395px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  shareBtnContainer: {
    marginTop: '150px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 35,
    },
  },
  shareBtn: {
    height: '30px',
    width: '233px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '18px',
    lineHeight: '21px',
    color: '#589a43',
    textTransform: 'none',
  },
  cancelBtn: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 'normal',
  },
  containerCancel: {
    marginTop: '20px',
    textAlign: 'center',
  },
  containerSocial: {
    padding: '20px',
    borderTop: '2px solid #589a43',
    marginTop: '20px',
    textAlign: 'center',
  },
  socialIcon: {
    width: '28px !important',
    height: '28px !important',
    marginRight: '38px',
    cursor: 'pointer',
  },
  shareDialogContainer: {
    [theme.breakpoints.down('xs')]: {
      margin: -38,
    },
  },
})

class ShareDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      sharing: false,
    }
  }

  @autobind
  onSocialSharing() {
    this.setState({sharing: true})
  }

  @autobind
  onDoneSocialSharing() {
    this.setState({sharing: false})
  }

  @autobind
  handleSetEmail(e) {
    this.setState({email: e.target.value})
  }

  @autobind
  async shareEmail() {
    const {email} = this.state
    const {recipe, createSuccess, createNotificationFromError} = this.props

    try {
      this.setState({sharing: true})
      await RecipeApi.shareRecipe(recipe.id, email)
      this.setState({email: ''})
      createSuccess('Recipe shared successfully.')
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      this.setState({sharing: false})
    }
  }

  @autobind
  handleClose() {
    const {onClose} = this.props
    const {sharing} = this.state

    if (!sharing) {
      onClose()
    }
  }

  render() {
    const {open, recipe, classes, handleCancel} = this.props

    const {email, sharing} = this.state

    const shareDisabled = email === '' || sharing

    return (
      <Dialog
        maxWidth="xl"
        open={open}
        className={classes.shareDialogContainer}
        onClose={this.handleClose}
      >
        <DialogContent id="share-dialog" className={classes.shareDialogContent}>
          <Grid container item>
            <Grid item xs={12} md={6}>
              {recipe.image === null ? (
                <div id="share-dialog-default-image">
                  <img className={classes.shareImage} src={defaultFood} alt={recipe.title} />
                </div>
              ) : (
                <div id="share-dialog-image">
                  <img className={classes.shareImage} src={recipe.image} alt={recipe.title} />
                </div>
              )}
              <div className={classes.titleContainer}>
                <Typography variant="body2" className={classes.title} id="share-dialog-title">
                  {recipe.title}
                </Typography>
                <Typography variant="body2" className={classes.author} id="share-dialog-author">
                  by: {recipe.user.firstName} {recipe.user.lastName}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.shareRight}>
                <Typography variant="body2" className={classes.shareThis}>
                  Share this
                </Typography>
                <Typography variant="body2" className={classes.sendTo}>
                  Send to:
                </Typography>
                <DetailTextField
                  value={email}
                  placeholder="Email"
                  className={classes.shareEmailText}
                  onChange={this.handleSetEmail}
                  inputProps={{id: 'share-dialog-share-email'}}
                />
                <div className={classes.shareBtnContainer}>
                  <ButtonWithProgress
                    disabled={shareDisabled}
                    id="share-dialog-share-btn"
                    className={classes.shareBtn}
                    onClick={this.shareEmail}
                    isLoading={sharing}
                  >
                    Share
                  </ButtonWithProgress>
                  <div className={classes.containerCancel}>
                    <StyledButton
                      id="share-dialog-cancel"
                      className={classes.cancelBtn}
                      onClick={handleCancel}
                      disabled={sharing}
                    >
                      Cancel
                    </StyledButton>
                  </div>
                </div>
                <div className={classes.containerSocial}>
                  <FacebookShareButton
                    style={{display: 'inline-block', outline: 'none'}}
                    url={recipe.shareUrl}
                    quote={recipe.title}
                    disabled={sharing}
                    onShareWindowClose={this.onDoneSocialSharing}
                    id="share-dialog-facebook"
                  >
                    <FontAwesomeIcon
                      icon={faFacebookSquare}
                      color="#000"
                      className={classes.socialIcon}
                      onClick={this.onSocialSharing}
                      disabled={sharing}
                      id="share-dialog-facebook-icon"
                    />
                  </FacebookShareButton>
                  <TwitterShareButton
                    style={{display: 'inline-block', outline: 'none'}}
                    url={recipe.shareUrl}
                    title={recipe.title}
                    disabled={sharing}
                    onShareWindowClose={this.onDoneSocialSharing}
                    id="share-dialog-twitter"
                  >
                    <FontAwesomeIcon
                      icon={faTwitter}
                      color="#000"
                      className={classes.socialIcon}
                      onClick={this.onSocialSharing}
                      disabled={sharing}
                      id="share-dialog-twitter-icon"
                    />
                  </TwitterShareButton>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }
}

ShareDialog.propTypes = {
  open: PropTypes.bool,
  handleCancel: PropTypes.func,
  recipe: PropTypes.object,
  onClose: PropTypes.func,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({createSuccess, createNotificationFromError}, dispatch)
}

export default compose(connect(null, mapDispatchToProps), withStyles(styles))(ShareDialog)

import React from 'react'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import {Grid, Paper} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import Slider from 'react-slick'
import autobind from 'autobind-decorator'

import Skeleton from 'react-loading-skeleton'
import cx from 'classnames'
import Dots from '../common/Dots.jsx'
import * as NotificationActions from '../common/redux/actions.notifications.js'
import RecipeApi from '../recipe/api'
import defaultImage from '../../assets/temp_icon_banner.png'

const styles = theme => ({
  noResult: {
    padding: theme.spacing(3),
  },
  slide: {
    width: `109.67%`,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      width: '100%',
    },
  },
  noHeight: {
    height: 0,
  },
  fitHeight: {
    height: `fit-content`,
    marginTop: `-76px`,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: 10,
      marginBottom: '-60px',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      justifyContent: 'center',
      marginBottom: '-60px',
      marginTop: 0,
    },
  },
  fitHeightOne: {
    height: `fit-content`,
    marginTop: `-76px`,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      display: 'none',
    },
  },
  carouselContainer: {
    position: 'relative',
    height: 750,
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      height: 646,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      height: 561,
    },
  },
  carouselImageContainer: {
    position: 'absolute',
    width: '100%',
    height: 600,
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      height: 260,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      height: 240,
    },
  },
  carouselImage: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: 260,
      objectFit: 'cover',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      height: 240,
      objectFit: 'cover',
    },
  },
  carouselInfo: {
    position: 'absolute',
    width: 418,
    color: 'black',
    height: 378,
    left: 75,
    top: 300,
    padding: '25px 35px 35px 35px',
    '& >p': {
      fontFamily: 'Lato',
      fontSize: '16px',
      fontWeight: '300',
      lineHeight: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '92%',
      paddingTop: 14,
      paddingLeft: 16,
      top: 380,
      boxShadow: '0 1px 10px 0 rgba(0,0,0,0.19)',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      left: '50%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '75%',
      paddingTop: 14,
      paddingLeft: 16,
      top: 300,
    },
  },
  carouselInfoTitle: {
    fontFamily: 'Literata',
    fontWeight: `600`,
    fontSize: '52px',
    lineHeight: '62px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 32,
      lineHeight: '40px',
    },
  },
  carouselInfoBlurb: {},
  carouselInfoBy: {
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: `34px`,
    position: `absolute`,
    bottom: 0,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  carouselInfoDescription: {
    fontFamily: `Lato`,
    fontSize: `16px`,
    fontWeight: 300,
    lineHeight: `24px`,
  },
  paddingSkeleton: {
    margin: '10px 0 ',
  },
  paddingBannerSkeleton: {
    marginLeft: `-64px`,
    marginRight: `-64px`,
  },
})

export const CustomDots = withStyles(theme => ({
  dots: {
    position: 'relative',
    padding: '20px 0 28px',
    [theme.breakpoints.down('xs')]: {
      bottom: 0,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      bottom: 0,
    },
  },
  dotOuter: {
    width: 19,
    height: 19,
    padding: 8.5,
    float: 'right',
    position: 'absolute',
    [theme.breakpoints.down('xs')]: {
      width: 14,
      height: 14,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      width: 14,
      height: 14,
    },
  },
  dot: {
    width: 19,
    height: 19,
    background: '#589A43',
    transition: 'all 400ms cubic-bezier(0.4, 0.0, 0.2, 1)',
    borderRadius: 8.5,
    [theme.breakpoints.down('xs')]: {
      width: 14,
      height: 14,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      width: 14,
      height: 14,
    },
  },
}))(Dots)

class FeaturedRecipes extends React.Component {
  constructor(props) {
    super(props)
    this.slider = React.createRef()

    this.state = {
      features: [],
      slideIndex: 0,
    }
  }

  @autobind
  componentDidMount() {
    this.setFeaturedRecipes()
  }

  @autobind
  handleChangeDot(e, slider) {
    slider.slickGoTo(e)
    this.setState({slideIndex: e})
  }

  async setFeaturedRecipes() {
    try {
      const response = await RecipeApi.getFeaturedRecipes()
      this.setState({
        features: response.results,
      })
    } catch (error) {
      NotificationActions.createError('Failed to fetch featured recipes.')
    }
  }

  render() {
    const {classes} = this.props
    const {features} = this.state
    //     const c = 0
    const settings = {
      beforeChange: (current, next) => this.setState({slideIndex: next}),
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: true,
    }
    if (features.length === 0) {
      return (
        <Grid container justifyContent="center">
          <div className={cx(classes.paddingBannerSkeleton, classes.slide)}>
            <Grid item container>
              <Grid item xs={12}>
                <div className={classes.carouselContainer}>
                  <div className={classes.carouselImageContainer}>
                    <img className={classes.carouselImage} src={defaultImage} />
                  </div>
                  <Paper className={classes.carouselInfo}>
                    <Skeleton variant="rect" width="100%" height={120} />
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={150}
                      className={classes.paddingSkeleton}
                    />
                    <Skeleton variant="rect" width="100%" height={30} />
                  </Paper>
                </div>
              </Grid>
            </Grid>
          </div>
          <Grid container className={classes.fitHeight} item xs={6} />
          <Grid
            container
            className={classes.fitHeight}
            justifyContent="flex-end"
            alignItems="flex-end"
            item
            xs={6}
          >
            <CustomDots index={0} count={3} />
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container justifyContent="center">
        <Slider
          ref={slider => {
            this.slider = slider
          }}
          {...settings}
          className={classes.slide}
        >
          {features.map(feature => (
            <Grid item container key={feature.id}>
              <Grid item xs={12}>
                <div className={classes.carouselContainer}>
                  <a href={feature.url}>
                    <div className={classes.carouselImageContainer}>
                      <img
                        alt={feature.title}
                        className={classes.carouselImage}
                        src={feature.image.retinaSize}
                      />
                    </div>
                  </a>
                  <a href={feature.url}>
                    <Paper className={classes.carouselInfo}>
                      <div className={classes.carouselInfoTitle}>{feature.title}</div>
                      <p className={classes.carouselInfoDescription}>
                        &ldquo;
                        {feature.description}
                        &rdquo;
                      </p>
                      <div className={classes.carouselInfoBy}>by: {feature.source}</div>
                    </Paper>
                  </a>
                </div>
              </Grid>
            </Grid>
          ))}
        </Slider>
        <Grid container className={classes.fitHeightOne} item xs={6} />

        <Grid container className={classes.fitHeight} item xs={12}>
          <CustomDots
            index={this.state.slideIndex}
            count={features.length}
            onDotClick={e => this.handleChangeDot(e, this.slider)}
          />
        </Grid>
      </Grid>
    )
  }
}

FeaturedRecipes.displayName = 'FeaturedRecipes'

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

export default compose(connect(mapDispatchToProps), withStyles(styles))(FeaturedRecipes)

import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Icon,
  Typography,
} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import autobind from 'autobind-decorator'
import {fraction, number, round} from 'mathjs'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import {DetailTextField, SelectFilled} from '../recipe/CreateRecipeFlow/CreateRecipe.styles.js'
import {UNITS} from '../constants/Units'
import Loading from '../common/Loading'
import {addShoppingItemByTitle, getShoppingItems, deleteAllShoppingItems} from './redux/actions'
import ShoppingItem from './ShoppingItem'
import {isNumeric} from '../recipe/utils'
import {ROUND_DIGITS} from '../utils/constants'

class ShoppingListWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addDialogOpen: false,
      deleteDialogOpen: false,
      ingredient: '',
      quantity: 1,
      unit: 'ounce',
      error: null,
    }
  }

  componentDidMount() {
    this.props.getShoppingItems({expand: 'ingredient,category', done: false})
  }

  render() {
    const {classes, isLoadingItems, items} = this.props
    const allCat = items.map(item => item.ingredient.category)
    const categories = allCat
      .map(item => (item === null ? null : item.id))
      .map((item, i, final) => final.indexOf(item) === i && i)
      .filter(e => allCat[e])
      .map(e => allCat[e])
    //added for undefined elements, remove once it is not needed and data is added correctly
    const byCategory = _.groupBy(items, item =>
      item.ingredient.category ? item.ingredient.category.id : null,
    )
    if (byCategory.null) categories.push(null)
    return (
      <div className={classes.root}>
        {isLoadingItems && <Loading />}
        <div className="baseHolder">
          {categories.length === 0 ? <p>Start building your shopping list now!</p> : ''}
          {categories.map((category, index) => {
            return (
              <div key={index}>
                {category?.title ? (
                  <Typography variant="subtitle1" gutterBottom>
                    {category.title}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" gutterBottom>
                    Other
                  </Typography>
                )}

                {byCategory[category ? category.id : null].map(item => (
                  <ShoppingItem key={item.id} item={item} />
                ))}
              </div>
            )
          })}
        </div>
        <Button
          onClick={this.openAddDialog}
          variant="outlined"
          fullWidth
          className={classes.addButton}
        >
          <Icon color="action" className="fa fa-plus-circle" style={{marginRight: 8}} />
          <Typography component="span" style={{textTransform: 'none'}}>
            Add Ingredient
          </Typography>
        </Button>
        <Button
          onClick={this.openDeleteDialog}
          variant="outlined"
          fullWidth
          className={classes.addButton}
          style={{marginTop: 8}}
          disabled={items.length === 0}
        >
          <Icon
            color="action"
            className="fa fa-trash"
            style={{color: items.length === 0 ? 'inherit' : 'red', marginRight: 8}}
          />
          <Typography
            component="span"
            color={items.length === 0 ? 'inherit' : 'error'}
            style={{textTransform: 'none'}}
          >
            Clean list
          </Typography>
        </Button>
        {this.state.addDialogOpen && this.renderAddDialog()}
        {this.state.deleteDialogOpen && this.renderDeleteDialog()}
      </div>
    )
  }

  @autobind
  openAddDialog(e) {
    e.preventDefault()
    this.setState({addDialogOpen: true})
  }

  @autobind
  openDeleteDialog(e) {
    e.preventDefault()
    this.setState({deleteDialogOpen: true})
  }

  @autobind
  closeAddDialog() {
    this.setState({
      addDialogOpen: false,
      ingredient: '',
      quantity: 1,
      unit: 'ounce',
      error: null,
    })
  }

  @autobind
  closeDeleteDialog() {
    this.setState({deleteDialogOpen: false})
  }

  @autobind
  handleDelete() {
    this.props.deleteAllShoppingItems()
    this.closeDeleteDialog()
  }

  @autobind
  handleChangeInput(e) {
    const {
      target: {name, value},
    } = e
    this.setState({[name]: value})
  }

  @autobind
  handleChangeInputQuantity(e) {
    const {
      target: {value},
    } = e
    this.setState({quantity: value})
    try {
      fraction(value)
      this.setState({error: null})
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  @autobind
  handleAdd(e) {
    e.preventDefault()
    const {ingredient, quantity, unit} = this.state
    this.props.addShoppingItemByTitle(
      {
        ingredient,
        quantity: round(number(fraction(quantity)), ROUND_DIGITS),
        unit,
      },
      {},
      success => {
        if (success) {
          this.closeAddDialog()
        }
      },
    )
  }

  @autobind
  onBlur() {
    const {quantity} = this.state
    try {
      fraction(quantity)
      this.setState({error: null})
    } catch (error) {
      this.setState({error: 'Please enter a valid number'})
    }
  }

  @autobind
  renderDeleteDialog() {
    const {error} = this.state
    return (
      <Dialog
        open={this.state.deleteDialogOpen}
        onClose={this.closeDeleteDialog}
        aria-labelledby="form-dialog-title"
        style={{margin: '-40'}}
      >
        <DialogTitle id="form-dialog-title">Clean Shopping List</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" gutterBottom>
            Are you sure you want to clean your shopping list?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDeleteDialog} color="primary" style={{color: 'gray'}}>
            Cancel
          </Button>
          <Button onClick={this.handleDelete} color="primary" style={{color: '#589a43'}}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  @autobind
  renderAddDialog() {
    const {error} = this.state
    return (
      <Dialog
        open={this.state.addDialogOpen}
        onClose={this.closeAddDialog}
        aria-labelledby="form-dialog-title"
        style={{margin: '-40'}}
      >
        <DialogTitle id="form-dialog-title">Add Ingredient to Shopping List</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DetailTextField
                value={this.state.ingredient}
                fullWidth
                name="ingredient"
                onChange={this.handleChangeInput}
                inputProps={{
                  maxLength: 50,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl error={!!error}>
                <DetailTextField
                  aria-describedby="quantity-error-text"
                  value={
                    isNumeric(this.state.quantity)
                      ? fraction(this.state.quantity).toFraction(true)
                      : this.state.quantity
                  }
                  fullWidth
                  onChange={this.handleChangeInputQuantity}
                  onBlur={this.onBlur}
                />
                {!!error && <FormHelperText id="quantity-error-text">{error}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <SelectFilled
                fullWidth
                value={this.state.unit}
                onChange={this.handleChangeInput}
                name="unit"
              >
                {UNITS.map(unit => {
                  return (
                    <option value={unit.value} key={unit.value}>
                      {unit.label}
                    </option>
                  )
                })}
              </SelectFilled>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeAddDialog} style={{color: 'gray'}}>
            Cancel
          </Button>
          <Button
            disabled={!!error}
            color="primary"
            onClick={this.handleAdd}
            style={{color: '#589a43'}}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const styles = theme => ({
  baseHolder: {
    height: 575,
    overflowY: 'scroll',
    webkitOverflowScrolling: 'touch',
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      height: 370,
    },
  },
  root: {
    padding: '20px',
    '& p': {
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.2)',
      fontWeight: 'bold',
      paddingTop: 15,
    },
  },
  addButton: {
    paddingTop: 11,
    paddingBottom: 11,
  },
})

ShoppingListWidget.displayName = 'ShoppingListWidget'

function mapStateToProps(state) {
  return {
    ...state.shoppingList,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {addShoppingItemByTitle, getShoppingItems, deleteAllShoppingItems},
    dispatch,
  )
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ShoppingListWidget)

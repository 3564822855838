import * as Types from './actions.forgotPw.types'

export function initForgotPw() {
  return {type: Types.FORGOT_PW_INIT}
}

export function requestForgotPw(email) {
  return {type: Types.FORGOT_PW_REQUEST, email}
}

export function requestForgotPwSuccess(profile) {
  return {type: Types.FORGOT_PW_REQUEST_SUCCESS, payload: profile}
}

export function requestForgotPwFailure(error) {
  return {type: Types.FORGOT_PW_REQUEST_FAILURE, error}
}

export function resetForgotPw(token, newPassword) {
  return {type: Types.FORGOT_PW_RESET, token, newPassword}
}

export function resetForgotPwSuccess(profile) {
  return {type: Types.FORGOT_PW_RESET_SUCCESS, payload: profile}
}

export function resetForgotPwFailure(error) {
  return {type: Types.FORGOT_PW_RESET_FAILURE, error}
}

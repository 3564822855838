import _ from 'lodash'
import * as Types from './actions.types'
import {arrayRemoveAt} from '../../utils/ReduxUtils'

function createInitialState() {
  return {
    isLoadingItems: false,
    isAdding: false,
    items: [],
    isDeleting: false,
  }
}
export default function calendar(state = createInitialState(), action) {
  switch (action.type) {
    case Types.ADD_RECIPE_TO_CALENDAR:
      return {
        ...state,
        isAdding: true,
      }
    case Types.ADD_RECIPE_TO_CALENDAR_SUCCESS:
    case Types.ADD_RECIPE_TO_CALENDAR_FAILURE:
      return {
        ...state,
        isAdding: false,
      }

    case Types.GET_CALENDAR_ITEMS:
      return {
        ...state,
        isLoadingItems: true,
        items: [],
      }
    case Types.GET_CALENDAR_ITEMS_SUCCESS:
      return {
        ...state,
        isLoadingItems: false,
        items: action.response,
      }
    case Types.GET_CALENDAR_ITEMS_FAILURE:
      return {
        ...state,
        isLoadingItems: false,
      }

    case Types.DELETE_CALENDAR_ITEM:
      return {
        ...state,
        isDeleting: true,
      }

    case Types.DELETE_CALENDAR_ITEM_SUCCESS: {
      state.items[action.dayIndex] = arrayRemoveAt(state.items[action.dayIndex], action.itemIndex)
      return {
        ...state,
        isDeleting: false,
        items: {...state.items},
      }
    }
    case Types.DELETE_CALENDAR_ITEM_FAILURE:
      return {
        ...state,
        isDeleting: false,
      }
    case Types.ADD_CALENDAR_ITEM_SUCCESS:
      return {
        ...state,
        items: addCalendarItems(state, action.calItem),
      }
    case Types.CALENDAR_ITEM_UPDATE_ORDER_LOCAL:
      return {
        ...state,
        items: updateCalendarItemsOrder(state, action.items),
      }
    case Types.CALENDAR_ITEM_MOVE_LOCAL:
      return {
        ...state,
        items: moveCalendarItem(state, action.calItem, action.fromDate, action.toDate),
      }

    default:
      return state
  }
}

function addCalendarItems(state, calItem) {
  const items = {...state.items}
  const date = calItem.date.replace(/-/g, '')
  const calItems = items[date]
  if (calItems) {
    calItems.push(calItem)
  } else {
    items[date] = [calItem]
  }
  return items
}

function updateCalendarItemsOrder(state, calItems) {
  const items = {...state.items}
  const date = calItems[0].date.replace(/-/g, '')
  const dayItems = items[date]
  if (dayItems) {
    const newDayItems = calItems.map((item, index) => {
      return {...item, order: index}
    })
    items[date] = newDayItems
  }
  return items
}

function moveCalendarItem(state, calItem, fromDate, toDate) {
  const items = {...state.items}
  const fD = fromDate.replace(/-/g, '')
  const tD = toDate.replace(/-/g, '')
  const fromItems = items[fD]
  const toItems = items[tD]
  if (fromItems) {
    const newFromItems = _.filter(fromItems, i => i.id !== calItem.id)
    items[fD] = newFromItems
  }
  if (toItems) {
    const newToItems = [...toItems, calItem]
    items[tD] = newToItems
  } else {
    items[tD] = [calItem]
  }
  return items
}

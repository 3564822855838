import React from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import _ from 'lodash'
import Helmet from 'react-helmet'
import NotFoundView from '../common/NotFound.Component.jsx'
import Loading from '../common/Loading.jsx'
import * as Actions from './redux/actions'
import UserUtils from '../utils/UserUtils'

const styles = theme => ({
  container: {
    background: theme.palette.grey['100'],
    marginTop: theme.spacing(3),
    marginRight: 'auto',
    marginBottom: theme.spacing(3),
    marginLeft: 'auto',
    maxWidth: '62.5rem',
  },
})

class ProfileSettings extends React.Component {
  componentDidMount() {
    if ((this.props.params && this.props.params.id) || this.props.profile.profile !== null) {
      this.props.getPublicProfile(
        this.props.params.id ? this.props.params.id : this.props.profile.profile.id,
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    const hasLocationChanged =
      _.get(nextProps, 'location.pathname') !== _.get(this.props, 'location.pathname')
    const hasUserLoggedOut =
      this.props.profile.profile !== null && _.get(nextProps, 'profile.profile') === null
    const hasUserLoggedIn =
      this.props.profile.profile === null && _.get(nextProps, 'profile.profile') !== null
    if (hasUserLoggedOut) {
      this.props.router.push('/login')
    } else if (hasUserLoggedIn) {
      this.props.getProfile()
    } else if (hasLocationChanged) {
      this.props.getPublicProfile(
        nextProps.params.id ? nextProps.params.id : this.props.profile.profile.id,
      )
    }
  }

  componentDidUpdate() {
    if (
      this.props.profile.profile &&
      this.props.profile.profile.id &&
      !this.props.profile.publicProfile &&
      !this.props.profile.isFetchingPublic
    ) {
      this.props.getPublicProfile(this.props.profile.profile.id)
    }
  }

  render() {
    const {publicProfile, publicProfileNotFound, isFetchingPublic, isFetching} = this.props.profile
    if (publicProfileNotFound) {
      return <NotFoundView />
    }
    if (isFetchingPublic || isFetching) {
      return (
        <React.Fragment>
          <div className="row">
            <Loading />
          </div>
        </React.Fragment>
      )
    }
    if (!publicProfile) {
      return null
    }

    return (
      <React.Fragment>
        <Helmet
          title="Profile"
          meta={[
            {
              property: 'og:image',
              content: UserUtils.getSmallImageUrl(publicProfile),
            },
          ]}
        />
        {this.props.children}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

ProfileSettings.displayName = 'Profile'
ProfileSettings.contextTypes = {
  router: PropTypes.object.isRequired,
}
ProfileSettings.props = {
  id: PropTypes.number,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ProfileSettings)

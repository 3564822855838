import humps from 'humps'
import qs from 'qs'
import _ from 'lodash'
import settings from '../../../config/settings'
import axios from '../api/axios'

export default class UserApi {
  static getUser(id) {
    return axios.get(`${settings.apiBaseURL}/users/${id}`)
  }

  static getUsers(query) {
    const q = humps.decamelizeKeys(_.omitBy(query, p => !p))

    return axios.get(`${settings.apiBaseURL}/users?${qs.stringify(q)}`)
  }
}

export const AUTO_SAVE_RECIPE = 'AUTO_SAVE_RECIPE'
export const AUTO_SAVE_RECIPE_SUCCESS = 'AUTO_SAVE_RECIPE_SUCCESS'
export const AUTO_SAVE_RECIPE_FAILURE = 'AUTO_SAVE_RECIPE_FAILURE'

export const CREATE_RECIPE = 'CREATE_RECIPE'
export const CREATE_RECIPE_SUCCESS = 'CREATE_RECIPE_SUCCESS'
export const CREATE_RECIPE_FAILURE = 'CREATE_RECIPE_FAILURE'

export const DELETE_RECIPE = 'DELETE_RECIPE'
export const DELETE_RECIPE_SUCCESS = 'DELETE_RECIPE_SUCCESS'
export const DELETE_RECIPE_FAILURE = 'DELETE_RECIPE_FAILURE'

export const UPDATE_LOCAL = 'UPDATE_LOCAL'
export const UPDATE_LOCAL_AT_INDEX = 'UPDATE_LOCAL_AT_INDEX'
export const UPDATE_INGREDIENT = 'UPDATE_INGREDIENT'
export const DELETE_LOCAL_AT_INDEX = 'DELETE_LOCAL_AT_INDEX'
export const DELETE_INGREDIENT = 'DELETE_INGREDIENT'
export const DELETE_INGREDIENT_LIST = 'DELETE_INGREDIENT_LIST'

export const ADD_INGREDIENT_LIST = 'ADD_INGREDIENT_LIST'
export const ADD_INGREDIENT = 'ADD_INGREDIENT'
export const ADD_MATERIAL = 'ADD_MATERIAL'
export const ADD_DIRECTION = 'ADD_DIRECTION'
export const ADD_TAG = 'ADD_TAG'

export const GET_TAG_SUGGESTIONS = 'GET_TAG_SUGGESTIONS'
export const GET_TAG_SUGGESTIONS_SUCCESS = 'GET_TAG_SUGGESTIONS_SUCCESS'
export const GET_TAG_SUGGESTIONS_FAILURE = 'GET_TAG_SUGGESTIONS_FAILURE'

export const RECIPE_CLEAR = 'RECIPE_CLEAR'

export const RECIPE_PUBLISH_FAILURE = 'RECIPE_PUBLISH_FAILURE'

export const GET_RECIPE_FROM_URL = 'GET_RECIPE_FROM_URL'
export const GET_RECIPE_FROM_URL_SUCCESS = 'GET_RECIPE_FROM_URL_SUCCESS'
export const GET_RECIPE_FROM_URL_FAILURE = 'GET_RECIPE_FROM_URL_FAILURE'

export const GET_RECIPE = 'GET_RECIPE'
export const GET_RECIPE_SUCCESS = 'GET_RECIPE_SUCCESS'
export const GET_RECIPE_FAILURE = 'GET_RECIPE_FAILURE'

import {Grid, withStyles} from '@material-ui/core'

export const GridContainerPage = withStyles(theme => ({
  container: {
    width: '1440px',
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    margin: '0 auto',
  },
}))(Grid)

export const GridItemPage = withStyles(() => ({
  item: {
    padding: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: 0,
    maxWidth: '100%',
  },
}))(Grid)

export const GridPageContent = withStyles(theme => ({
  item: {
    paddingTop: 16,
    paddingRight: 64,
    paddingBottom: 20,
    minHeight: `80vh`,
    background: 'white',
    [theme.breakpoints.down('xl')]: {
      paddingLeft: 64,
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 64,
      paddingBottom: 20,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 41,
    },
  },
}))(Grid)

export const isMobile = {
  Android() {
    return !!navigator.userAgent.match(/Android/i)
  },
  BlackBerry() {
    return !!navigator.userAgent.match(/BlackBerry/i)
  },
  iOS() {
    return !!(
      navigator.userAgent.match(/iPhone|iPad|iPod/i) ||
      navigator.platform.match(/iPhone|iPad|iPod/i)
    )
  },
  iPhone() {
    return !!navigator.userAgent.match(/iPhone/i)
  },
  Opera() {
    return !!navigator.userAgent.match(/Opera Mini/i)
  },
  Windows() {
    return !!navigator.userAgent.match(/IEMobile/i)
  },
  resolution() {
    return window.innerWidth <= 600
  },
  any() {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows() ||
      isMobile.resolution()
    )
  },
}

import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Grid, Typography, Paper} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import autobind from 'autobind-decorator'
import cx from 'classnames'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'

import {Link} from 'react-router'
import _ from 'lodash'
import Loading from '../common/Loading'
import styles, {CreateCategoryActionArea} from './MyKitchen.styles.js'
import categoryDefault from '../../assets/categoryDefault.png'
import MyKitchenApi from './api'
import {createNotificationFromError} from '../common/redux/actions.notifications.js'
import PlusCircle from '../../assets/icons/plusCircle.svg'
import {setCategoryToAddRecipe} from './redux/actions'
import MyKitchenSidebar from './MyKitchenSidebar'

function CreateCategoryCard(props) {
  const {classes, handleClick} = props

  return (
    <Grid item xs={6} md={4} className={classes.paddingGridSubPaper}>
      <Card className={classes.createCategoryCard}>
        <CreateCategoryActionArea
          onClick={handleClick}
          data-test-name="create-category-action-area"
        >
          <CardContent>
            <span className={classes.addIcon}>
              <img src={PlusCircle} alt="Add Category" />
            </span>
          </CardContent>
        </CreateCategoryActionArea>
      </Card>
    </Grid>
  )
}

class MyKitchenCategory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      recipes: [],
      isLoadingR: false,
      isLoadingC: false,
      category: {},
    }
  }

  componentDidMount() {
    if (this.props.params.categoryId === 'all') {
      this.setState({category: {name: 'All Recipes'}})
      this.loadAllRecipes(this.props.profile.id)
    } else {
      this.loadRecipes(this.props.params.categoryId)
      this.loadCategory(this.props.params.categoryId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.params.categoryId !== nextProps.params.categoryId) {
      if (this.props.params.categoryId === 'all') {
        this.setState({category: {name: 'All Recipes'}})
        this.loadAllRecipes(this.props.profile.id)
      } else {
        this.loadRecipes(this.props.params.categoryId)
        this.loadCategory(this.props.params.categoryId)
      }
    }
  }

  @autobind
  loadAllRecipes(userId) {
    const {createNotificationFromError} = this.props
    this.setState({isLoadingR: true}, async () => {
      try {
        const response = await MyKitchenApi.getAllRecipesOfUser(userId)
        this.setState({recipes: response, isLoadingR: false})
      } catch (e) {
        createNotificationFromError(e)
      }
    })
  }

  @autobind
  loadRecipes(categoryId) {
    const {createNotificationFromError} = this.props
    this.setState({isLoadingR: true}, async () => {
      try {
        const response = await MyKitchenApi.getRecipesOfCategory(categoryId)
        this.setState({recipes: response, isLoadingR: false})
      } catch (e) {
        createNotificationFromError(e)
      }
    })
  }

  @autobind
  loadCategory(categoryId) {
    const {createNotificationFromError} = this.props
    this.setState({isLoadingC: true}, async () => {
      try {
        const response = await MyKitchenApi.getCategory(categoryId)
        this.setState({category: response, isLoadingC: false})
      } catch (e) {
        createNotificationFromError(e)
      }
    })
  }

  @autobind
  handleCreateRecipe() {
    const {setCategoryToAddRecipe} = this.props
    setCategoryToAddRecipe(this.state.category)
    this.context.router.push('/create-recipe')
  }

  render() {
    const {classes} = this.props
    const {recipes, isLoadingR, isLoadingC, category} = this.state

    if (!recipes || isLoadingR || isLoadingC) {
      return <Loading />
    }
    return (
      <MyKitchenSidebar>
        <Paper className={cx(classes.root, classes.categoriesPaper)} elevation={1}>
          <Typography variant="h5" component="h3" className={classes.titleCategories}>
            My Recipe Box - {category.name}
          </Typography>
          <Grid container className={classes.containerSubPaper}>
            <CreateCategoryCard classes={classes} handleClick={this.handleCreateRecipe} />
            {recipes.map((data, id) => {
              return (
                <Grid item xs={4} key={id} className={classes.paddingGridSubPaper}>
                  <Card>
                    <CardActionArea
                      className={classes.card}
                      component={Link}
                      to={`/recipe/${data.id}/${data.slug}`}
                    >
                      {data.image !== null ? (
                        <CardMedia
                          component="img"
                          className={classes.media}
                          height="140"
                          image={data.image}
                        />
                      ) : (
                        <CardMedia
                          component="img"
                          className={classes.media}
                          height="140"
                          src={categoryDefault}
                        />
                      )}
                      <CardContent className={classes.cardContent}>
                        <Typography gutterBottom className={classes.cardTitle}>
                          {data.title}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              )
            })}
          </Grid>
        </Paper>
      </MyKitchenSidebar>
    )
  }
}

MyKitchenCategory.displayName = 'MyKitchenCategory'

function mapStateToProps(state) {
  return {
    ...state.myKitchen,
    profile: _.get(state.profile, 'profile', null),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({setCategoryToAddRecipe, createNotificationFromError}, dispatch)
}

MyKitchenCategory.propTypes = {
  classes: PropTypes.object.isRequired,
}

MyKitchenCategory.contextTypes = {
  router: PropTypes.object.isRequired,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(MyKitchenCategory)

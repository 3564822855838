import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Grid} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import autobind from 'autobind-decorator'
import Helmet from 'react-helmet'

import Loading from '../../common/Loading'
import RecipeApi from '../api'
import {updateLocal, recipePublishFailure, getRecipe} from '../redux/actions'
import {clearCategoryToAddRecipe} from '../../myKitchen/redux/actions'
import {createSuccess, createError} from '../../common/redux/actions.notifications'

import {styles} from './RecipeView.styles'
import Overview from './Overview'
import IngredientsMaterials from './IngredientsMaterials'
import DirectionsNotes from './DirectionsNotes'
import {getAbsoluteAssetUrl} from '../../utils/PathUtils'

class RecipeView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isPublishing: false,
      isAddingToMyRecipes: false,
      alreadyAdded: false,
      checkingAlreadyAdded: false,
    }
  }

  componentWillMount() {
    const {id} = this.props.params
    const {getRecipe, recipe} = this.props
    getRecipe(id, this.getParams())
    this.checkAlreadyAdded()
  }

  componentDidUpdate(prevProps, prevState) {
    const {slug} = this.props.params
    const {recipe} = this.props
    if ((!slug || recipe.slug !== slug) && recipe?.id && recipe?.slug) {
      this.props.router.replace(getAbsoluteAssetUrl(`/recipe/${recipe?.id}/${recipe?.slug}`))
    }
  }

  componentDidMount() {
    if (window) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const {alreadyAdded, checkingAlreadyAdded, isPublishing, isAddingToMyRecipes} = this.state
    const {classes, recipe, myKitchen} = this.props

    if (recipe.isFetching) {
      return <Loading />
    }

    if (!recipe.id) {
      return <div>Recipe not found</div>
    }

    return (
      <Grid container className={classes.recipeViewContainer} xs={12}>
        <Helmet title={recipe.title}>
          <link
            rel="canonical"
            href={getAbsoluteAssetUrl(`/recipe/${recipe?.id}/${recipe?.slug}`)}
          />
        </Helmet>
        <Overview
          recipe={recipe}
          classes={classes}
          onPublish={this.publish}
          isPublishing={isPublishing}
          isAddingToMyRecipes={isAddingToMyRecipes}
          onAddToMyRecipes={this.addToMyRecipes}
          alreadyAdded={alreadyAdded}
          checkingAlreadyAdded={checkingAlreadyAdded}
          categoryToAddRecipe={myKitchen.categoryToAddRecipe}
        />
        <Grid container xs={12}>
          <IngredientsMaterials recipe={recipe} />
          <DirectionsNotes classes={classes} recipe={recipe} />
        </Grid>
      </Grid>
    )
  }

  @autobind
  getParams() {
    return {
      expand: 'user,creator,ingredient_lists,materials,directions,tags',
    }
  }

  @autobind
  publish(selectedCategories) {
    const {id} = this.props.params
    this.setState({isPublishing: true}, async () => {
      try {
        const recipe = await RecipeApi.publishRecipe(id, this.getParams(), {
          categories: selectedCategories,
        })
        this.props.updateLocal(recipe, 'recipe')
        this.props.clearCategoryToAddRecipe()
        this.props.createSuccess('Recipe published successfully')
      } catch (e) {
        this.props.recipePublishFailure(e)
      } finally {
        this.setState({isPublishing: false})
      }
    })
  }

  @autobind
  addToMyRecipes(selectedCategories) {
    const {id} = this.props.params
    this.setState({isAddingToMyRecipes: true}, async () => {
      try {
        await RecipeApi.addToMyRecipes({
          recipe: id,
          categories: selectedCategories,
        })
        this.setState({alreadyAdded: true})
        this.props.createSuccess('Recipe added successfully.')
      } catch (e) {
        this.props.createError('Failed to add recipe.')
      } finally {
        this.setState({isAddingToMyRecipes: false})
      }
    })
  }

  @autobind
  async checkAlreadyAdded() {
    const {profile, auth} = this.props
    const {id} = this.props.params

    if (!!profile && !!auth?.token) {
      try {
        const resp = await RecipeApi.alreadyAdded(id)
        this.setState({alreadyAdded: resp.added})
      } catch (e) {
        console.log(e)
      }
    }
  }
}

function mapStateToProps(state) {
  return {...state.recipe, myKitchen: state.myKitchen}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateLocal,
      createSuccess,
      createError,
      clearCategoryToAddRecipe,
      recipePublishFailure,
      getRecipe,
    },
    dispatch,
  )
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(RecipeView)

import {call, put, take} from 'redux-saga/effects'
import {push} from 'react-router-redux'
import ProfileApi from '../../profile/api'
import * as Actions from '../../profile/redux/actions'
import * as Types from '../../profile/redux/actions.types'
import * as AuthActions from './actions'
import * as NotificationActions from '../../common/redux/actions.notifications'

export default function* createProfile() {
  while (true) {
    const {payload} = yield take(Types.PROFILE_CREATE)
    try {
      const response = yield call(ProfileApi.createProfile, payload)
      yield put(Actions.createProfileSuccess(response))
      yield put(AuthActions.login(payload.email, payload.password))
      yield put(push('/'))
      yield put(NotificationActions.createSuccess('You have successfully created an account'))
    } catch (error) {
      yield put(Actions.createProfileFailure(error))
    }
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import urlParse from 'url-parse'
import _ from 'lodash'
import {Button, Icon} from '@material-ui/core'
import SocialAuthEmailPopup from './SocialAuthEmailPopup.jsx'

export class FacebookAuthButton extends React.Component {
  constructor() {
    super()

    this.code = null
  }

  render() {
    const {isNoEmailProvidedError} = this.props
    return (
      <span>
        {isNoEmailProvidedError ? (
          <SocialAuthEmailPopup onSendAuthEmail={this.sendEmail.bind(this)} />
        ) : null}
        <Button
          variant="contained"
          color="primary"
          aria-label="Log in with Facebook"
          onClick={this.onFacebookLogin.bind(this)}
        >
          <Icon className="fa fa-facebook-official" aria-hidden="true" />
        </Button>
      </span>
    )
  }

  onFacebookLogin() {
    this.openFbAuthPopup()
  }

  openFbAuthPopup() {
    const {fbAppId, onSendAuthRequest} = this.props
    const redirectUri = getRedirectUrl()
    const url = `https://www.facebook.com/dialog/oauth?client_id=${fbAppId}&redirect_uri=${redirectUri}&display=popup&scope=email`
    this.popup = openSocialLoginPopup(url)

    this.listenForFbCredentials(this.popup).then(query => {
      this.code = query.code
      onSendAuthRequest('facebook', this.code, redirectUri)
    })
  }

  listenForFbCredentials(popup, resolve, reject) {
    if (!resolve) {
      return new Promise((resolve, reject) => {
        this.listenForFbCredentials(popup, resolve, reject)
      })
    }

    let query = null
    try {
      query = urlParse(popup.location, true).query
    } catch (error) {
      // Ignore Exception: Blocked a frame with origin from accessing a cross-origin frame.
      // A hack to get around same-origin security policy errors
    }
    if (query && query.code) {
      popup.close()
      resolve(query)
    } else if (popup.closed) {
      reject({errors: 'Authentication was cancelled.'})
    } else {
      setTimeout(() => {
        this.listenForFbCredentials(popup, resolve, reject)
      }, 500)
    }
  }

  sendEmail(email) {
    this.props.onSendAuthRequest('facebook', this.code, getRedirectUrl(), email)
  }
}

FacebookAuthButton.propTypes = {
  fbAppId: PropTypes.string.isRequired,
  onSendAuthRequest: PropTypes.func.isRequired,
  isNoEmailProvidedError: PropTypes.bool,
}

export class TwitterAuthButton extends React.Component {
  constructor() {
    super()

    this.oauthToken = null
    this.oauthVerifier = null
  }

  componentDidUpdate(prevProps) {
    const hasReceivedOAuth1Token =
      this.props.oauthToken && prevProps.oauthToken !== this.props.oauthToken
    if (hasReceivedOAuth1Token) {
      this.openTwitterAuthPopup(this.props.oauthToken)
    }
  }

  render() {
    const {isNoEmailProvidedError} = this.props
    return (
      <span>
        {isNoEmailProvidedError ? (
          <SocialAuthEmailPopup onSendAuthEmail={this.sendEmail.bind(this)} />
        ) : null}
        <Button
          variant="contained"
          color="primary"
          aria-label="Log in with Twitter"
          onClick={this.onTwitterLogin.bind(this)}
        >
          <Icon className="fa fa-twitter" aria-hidden="true" />
        </Button>
      </span>
    )
  }

  onTwitterLogin() {
    const redirectUri = getRedirectUrl()
    this.props.onRetrieveAuthRequestToken('twitter', redirectUri)
  }

  openTwitterAuthPopup(oauthRequestToken) {
    const url = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauthRequestToken}`
    this.popup = openSocialLoginPopup(url)

    this.listenForTwitterCredentials(this.popup).then(query => {
      const payload = {
        oauthToken: query.oauth_token,
        oauthTokenSecret: this.props.oauthTokenSecret,
        oauthVerifier: query.oauth_verifier,
        provider: 'twitter',
      }

      this.oauthToken = payload.oauthToken
      this.oauthVerifier = payload.oauthVerifier

      this.props.onSendAuthRequest(payload)
    })
  }

  listenForTwitterCredentials(popup, resolve, reject) {
    if (!resolve) {
      return new Promise((resolve, reject) => {
        this.listenForTwitterCredentials(popup, resolve, reject)
      })
    }

    let query = null
    try {
      query = urlParse(popup.location, true).query
    } catch (error) {
      // Ignore Exception: Blocked a frame with origin from accessing a cross-origin frame.
      // A hack to get around same-origin security policy errors
    }
    if (query && query.oauth_token && query.oauth_verifier) {
      popup.close()
      resolve(query)
    } else if (popup.closed) {
      reject({errors: 'Authentication was cancelled.'})
    } else {
      setTimeout(() => {
        this.listenForTwitterCredentials(popup, resolve, reject)
      }, 500)
    }
  }

  sendEmail(email) {
    const payload = {
      oauthToken: this.oauthToken,
      oauthTokenSecret: this.props.oauthTokenSecret,
      oauthVerifier: this.oauthVerifier,
      provider: 'twitter',
      email,
    }
    this.props.onSendAuthRequest(payload)
  }
}

TwitterAuthButton.propTypes = {
  onRetrieveAuthRequestToken: PropTypes.func.isRequired,
  oauthToken: PropTypes.string,
  oauthTokenSecret: PropTypes.string,
  onSendAuthRequest: PropTypes.func.isRequired,
  isNoEmailProvidedError: PropTypes.bool,
}

export class GoogleplusAuthButton extends React.Component {
  constructor() {
    super()

    this.code = null
  }

  render() {
    const {isNoEmailProvidedError} = this.props
    return (
      <span>
        {isNoEmailProvidedError ? (
          <SocialAuthEmailPopup onSendAuthEmail={this.sendEmail.bind(this)} />
        ) : null}
        <Button
          variant="contained"
          color="primary"
          aria-label="Log in with Google Plus"
          onClick={this.onGoogleplusLogin.bind(this)}
        >
          <Icon className="fa fa-google-plus" aria-hidden="true" />
        </Button>
      </span>
    )
  }

  onGoogleplusLogin() {
    alert('connect Googleplus to app')
  }

  sendEmail() {
    alert('connect Googleplus to app')
  }
}

GoogleplusAuthButton.propTypes = {
  isNoEmailProvidedError: PropTypes.bool,
}

export class LinkedinAuthButton extends React.Component {
  constructor() {
    super()

    this.code = null
  }

  render() {
    const {isNoEmailProvidedError} = this.props
    return (
      <span>
        {isNoEmailProvidedError ? (
          <SocialAuthEmailPopup onSendAuthEmail={this.sendEmail.bind(this)} />
        ) : null}
        <Button
          variant="contained"
          color="primary"
          aria-label="Log in with LinkedIn"
          onClick={this.onLinkedinLogin.bind(this)}
        >
          <Icon className="fa fa-linkedin" aria-hidden="true" />
        </Button>
      </span>
    )
  }

  onLinkedinLogin() {
    alert('connect Linkedin to app')
  }

  sendEmail() {
    alert('connect Linkedin to app')
  }
}

LinkedinAuthButton.propTypes = {
  isNoEmailProvidedError: PropTypes.bool,
}

function openSocialLoginPopup(url) {
  const popup = window.open(
    url,
    'sharewin',
    'left=20,top=20,width=500,height=400,toolbar=1,resizable=0',
  )
  if (popup && popup.focus) {
    popup.focus()
  }
  return popup
}

function getRedirectUrl() {
  const url = urlParse(_.get(document, 'location.href'), false)
  url.set('query', null)
  return url.toString()
}

import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import {connect} from 'react-redux'
import _ from 'lodash'
import {Grid} from '@material-ui/core'
import Header from './common/Header.jsx'
import Footer from './common/Footer.jsx'
import NotificationContainer from './common/NotificationContainer.jsx'
import {getProfile} from './profile/redux/actions'
import UserSession from './auth/UserSession'
import {GridContainerPage, GridItemPage, GridPageContent} from './App.styles.js'

const headerlessLocations = ['confirm-email']
const footerlessLocations = ['confirm-email']

class App extends React.Component {
  constructor() {
    super()

    this.state = {
      showHeader: true,
      showFooter: true,
    }
  }

  componentWillMount() {
    if (UserSession.getToken() && !this.props.profile) {
      this.props.dispatch(getProfile())
    }
  }

  componentDidMount() {
    this.setHeaderFooterState(this.props.location)

    // Remove the server-side injected CSS.
    const jssStyles = document.getElementById('jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }

  componentDidUpdate(prevProps) {
    if (_.get(this.props, 'location.pathname') !== _.get(prevProps, 'location.pathname')) {
      this.setHeaderFooterState(this.props.location)
    }
  }

  setHeaderFooterState(location) {
    const showHeader = headerlessLocations.indexOf(location.pathname.split('/')[1]) < 0
    const showFooter = footerlessLocations.indexOf(location.pathname.split('/')[1]) < 0

    if (showHeader !== this.state.showHeader || showFooter !== this.state.showFooter) {
      this.setState({showHeader, showFooter})
    }
  }

  render() {
    const {auth, profile} = this.props
    const {showHeader, showFooter} = this.state

    return (
      <GridContainerPage
        id="appContain"
        className="ba-site"
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
      >
        <CssBaseline />
        <GridItemPage justifyContent="center" spacing={0} item container xs={12}>
          {showHeader && (
            <Grid item xs={12}>
              <Header auth={auth} profile={profile} onHomeClick={() => {}} />
            </Grid>
          )}
          <GridPageContent container item xs={12}>
            {this.props.children}
          </GridPageContent>
        </GridItemPage>
        {showFooter ? <Footer /> : null}
        <NotificationContainer />
      </GridContainerPage>
    )
  }
}

App.displayName = 'App'

function mapStateToProps(state) {
  return {
    auth: state.auth,
    profile: _.get(state.profile, 'profile', null),
    recipe: state.recipe.recipe,
  }
}

// Wrap the component to inject dispatch and state into it
export default connect(mapStateToProps)(App)

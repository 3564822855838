import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Link} from 'react-router'
import {
  Button,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  Icon,
  InputAdornment,
  MenuItem,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Checkbox,
} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import moment from 'moment'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft, faChevronRight, faTrash} from '@fortawesome/free-solid-svg-icons'
import cx from 'classnames'
import autobind from 'autobind-decorator'
import _ from 'lodash'
import SearchIcon from '@material-ui/icons/Search'
import {getCalendarItems, deleteCalendarItem} from './redux/actions'
import {createNotificationFromError, createSuccess} from '../common/redux/actions.notifications'
import {DetailTextField, Label} from '../recipe/CreateRecipeFlow/CreateRecipe.styles'
import RCESelect from '../common/styles/RCESelect'
import MyKitchenApi from '../myKitchen/api'
import RecipeApi from '../recipe/api'
import CalendarItemApi from './api'

const styles = theme => ({
  root: {
    padding: '28px',
  },
  addButton: {
    paddingTop: 11,
    paddingBottom: 11,
  },
  navButton: {
    verticalAlign: 'middle',
    fontSize: 18,
    padding: '10px',
    [theme.breakpoints.down('lg')]: {
      padding: '5px',
    },
  },
  checkBox: {
    color: '#589a43 !important',
  },
  currWeekText: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '19px',
    textAlign: 'center',
    display: 'inline',
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  sidebarSearchInput: {
    paddingRight: 1,
    marginBottom: 10,
  },
  recipesContainer: {
    paddingLeft: 10,
    paddingRight: 10,
    maxHeight: 500,
    overflow: 'auto',
  },

  dayHeading: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '19px',
    marginBottom: '12px',
  },
  day: {
    marginBottom: '17px',
  },
  meal: {
    borderRadius: '2px',
    backgroundColor: '#efefef',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '6px',
  },
  mealText: {
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '18px',
    lineHeight: '21px',
    padding: '5px',
    [theme.breakpoints.down('xs')]: {
      wordBreak: 'break-word',
    },
  },
  editCal: {
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  dateSel: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      marginTop: 10,
    },
  },
  editCalBtn: {
    height: '30px',
    width: '186px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    color: '#589a43',
    fontFamily: 'Lato',
    fontSize: '12px',
    fontWeight: '600',
    lineHeight: '21px',
    padding: 0,
    textTransform: 'none',
    [theme.breakpoints.down('lg')]: {
      width: '150px',
    },
    [theme.breakpoints.down('md')]: {
      width: '126',
    },
  },
  noRecipes: {
    textAlign: 'center',
    padding: '10px',
  },
  trashIcon: {},
  titleTextField: {
    marginBottom: 10,
  },
  optionsModal: {
    top: `50%`,
    left: '50%',
    transform: `translate(-50%, -50%)`,
  },
  optionsBottom: {
    paddingBottom: `31.5px`,
  },
  optionsTitle: {
    fontFamily: 'Literata',
    fontSize: `24px`,
    lineHeight: `32px`,
    fontWeight: '400',

    textAlign: `center`,
    paddingTop: '30.5px',
    padding: `0 20px`,
  },
  saveBtn: {
    height: '30px',
    width: '280px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    color: '#589a43',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '21px',
    textAlign: 'center',
    textTransform: 'none',
    marginTop: '50px',
    padding: 0,
  },
  cancelBtn: {
    height: '23px',
    width: '176px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    color: '#000000',
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    marginTop: '26px',
    textTransform: 'none',
    padding: 0,
  },
  calendarLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
})

function CalDay(props) {
  const {classes, heading, dayIndex, calItems, openAddDialog, onDelete, date} = props

  return (
    <div className={classes.day}>
      <Typography id="cal-day-heading" className={classes.dayHeading}>
        {heading}
      </Typography>
      {calItems.map((item, index) => {
        return (
          <Grid item container key={item.id} className={classes.meal}>
            <Grid item xs={10}>
              <Link to={`/recipe/${item.recipe.id}`} className={classes.calendarLink}>
                <Typography id="cal-day-recipe" variant="body2" className={classes.mealText}>
                  {item.recipe.title + ' meal'}
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={2}>
              <IconButton className={classes.trashIcon} onClick={deleteElement}>
                <FontAwesomeIcon icon={faTrash} color="#C2C2C2" size="xs" />
              </IconButton>
            </Grid>
          </Grid>
        )
        function deleteElement() {
          onDelete(item.id, dayIndex, index)
        }
      })}
      <Button onClick={openDialog} variant="outlined" fullWidth className={classes.addButton}>
        <Icon color="action" className="fa fa-plus-circle" />
      </Button>
    </div>
  )

  function openDialog(e) {
    e.preventDefault()
    openAddDialog(date)
  }
}

class CalendarWidget extends React.Component {
  constructor(props) {
    super(props)

    const startOfWeek = moment().day(0)
    const endOfWeek = moment().day(6)

    this.state = {
      startOfWeek,
      endOfWeek,
      category: 'rce-all',
      openDialog: false,
      dialogDate: startOfWeek,
      categories: [],
      recipes: [],
      loading: false,
    }
  }

  @autobind
  async getCategories() {
    const {profile} = this.props
    if (profile && profile.profile) {
      try {
        this.setState({loading: true})
        const response = await MyKitchenApi.getCategories(profile.profile.id)
        this.setState({categories: response.results})
      } catch (e) {
        console.log(e)
      } finally {
        this.setState({loading: false})
      }
    }
  }

  componentDidMount() {
    this.getCalendarItems()
    this.getRecipes()
    this.getCategories()
  }

  @autobind
  async getRecipes(params = {}) {
    const {profile} = this.props
    if (profile && profile.profile) {
      const _params = {...params, user: profile.profile.id, status: 'published'}
      try {
        this.setState({loading: true})
        const response = await RecipeApi.getRecipes(_params)
        this.setState({recipes: response})
      } catch (e) {
        console.log(e)
      } finally {
        this.setState({loading: false})
      }
    }
  }

  @autobind
  getCalendarItems() {
    const {getCalendarItems} = this.props
    const {startOfWeek, endOfWeek} = this.state

    getCalendarItems({
      date_start: startOfWeek.format('YYYY-MM-DD'),
      date_end: endOfWeek.format('YYYY-MM-DD'),
      expand: 'recipe',
    })
  }

  @autobind
  handlePrevWeek(e) {
    e.preventDefault()
    this.setState(
      state => ({
        startOfWeek: state.startOfWeek.day(-7),
        endOfWeek: state.endOfWeek.day(-1),
      }),
      () => {
        this.getCalendarItems()
      },
    )
  }

  @autobind
  handleNextWeek(e) {
    e.preventDefault()
    this.setState(
      state => ({
        startOfWeek: state.startOfWeek.day(7),
        endOfWeek: state.endOfWeek.day(13),
      }),
      () => {
        this.getCalendarItems()
      },
    )
  }

  getCalDays() {
    const calDays = []
    for (let x = 0; x <= 6; x++) {
      calDays.push(moment().day(x))
    }
    return calDays
  }

  renderDayHeading(date) {
    return moment(date).format('dddd - MMMM D')
  }

  render() {
    const {classes, isLoadingItems} = this.props
    const self = this
    const {openDialog, loading, categories, recipes} = this.state

    return (
      <div className={classes.root}>
        <Grid item container>
          <Grid item xs={12} sm={6} className={classes.editCal}>
            <Button
              disabled={isLoadingItems}
              component={Link}
              to="/my-kitchen/calendar"
              className={classes.editCalBtn}
            >
              View/Edit Calendar
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.dateSel}>
            <IconButton
              onClick={this.handlePrevWeek}
              className={cx(classes.navButton, classes.navButtonLeft)}
              id="prevWeek"
              disabled={isLoadingItems}
            >
              <FontAwesomeIcon icon={faChevronLeft} color="#589a43" />
            </IconButton>
            {this.renderCurrWeekText()}
            <IconButton
              onClick={this.handleNextWeek}
              className={cx(classes.navButton, classes.navButtonRight)}
              id="nextWeek"
              disabled={isLoadingItems}
            >
              <FontAwesomeIcon icon={faChevronRight} color="#589a43" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            {this.renderCalDays()}
          </Grid>
        </Grid>
        <div>
          <Dialog
            open={openDialog}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            style={{margin: '-40'}}
          >
            <DialogTitle id="form-dialog-title">Add Recipe to Calendar</DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Label>All my recipes</Label>
                  <DetailTextField
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={this.handleOnSearch} id="recipeSearchSubmit">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    className={classes.sidebarSearchInput}
                    placeholder="Search"
                    onChange={this.handleChange.bind(this, 'searchParams')}
                    onKeyPress={this.handleOnKeyPress}
                    disabled={loading}
                    id="recipeSearch"
                  />
                  <RCESelect
                    disabled={loading}
                    fullWidth
                    onChange={this.handleCategoryChange}
                    id="recipeCategories"
                    disableUnderline
                    value={this.state.category}
                  >
                    <MenuItem value="rce-all">All Categories</MenuItem>
                    {categories.map(cat => {
                      return (
                        <MenuItem key={cat.id} value={cat.id}>
                          <Typography variant="inherit" noWrap>
                            {cat.name}
                          </Typography>
                        </MenuItem>
                      )
                    })}
                  </RCESelect>
                </Grid>
                <Grid container spacing={2} className={classes.recipesContainer}>
                  <Grid item xs={12}>
                    {recipes.map(recipe => {
                      return (
                        <Grid
                          item
                          container
                          key={recipe.id}
                          className={classes.meal}
                          onClick={addCalendar}
                        >
                          <Grid item xs={10}>
                            <Typography
                              id="cal-day-recipe"
                              variant="body2"
                              className={classes.mealText}
                            >
                              {recipe.title}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Checkbox classes={{checked: classes.checkBox}} />
                          </Grid>
                        </Grid>
                      )
                      function addCalendar() {
                        self.addCalendarItem(recipe.id)
                      }
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} style={{color: '#589a43'}}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    )
  }

  @autobind
  handleOnKeyPress(evt) {
    if (evt.key === 'Enter') {
      evt.preventDefault()
      this.doSearch()
    }
  }

  @autobind
  handleChange(name, event) {
    this.setState({[name]: event.target.value})
  }

  @autobind
  handleCategoryChange(evt) {
    this.setState({category: evt.target.value}, () => {
      this.doSearch()
    })
  }

  @autobind
  doSearch() {
    const {searchParams, category} = this.state
    const params = {q: searchParams}
    if (category !== 'rce-all') {
      params.categories = category
    }
    this.getRecipes(params)
  }

  @autobind
  renderCalDays() {
    const {classes, items, isLoadingItems} = this.props
    if (isLoadingItems) {
      return (
        <div className={classes.root} style={{textAlign: 'center'}}>
          <CircularProgress />
        </div>
      )
    }

    return (
      <div className="days">
        {[0, 1, 2, 3, 4, 5, 6].map(dayIndex => {
          const date = moment(this.state.startOfWeek).day(dayIndex)
          const day = date.format('YYYYMMDD')
          return (
            <CalDay
              key={dayIndex}
              classes={classes}
              dayIndex={day}
              date={date}
              openAddDialog={this.openAddDialog}
              heading={this.renderDayHeading(date)}
              calItems={this.getDayItems(items, date)}
              onDelete={this.props.deleteCalendarItem}
            />
          )
        })}
      </div>
    )
  }

  @autobind
  openAddDialog(date) {
    this.setState({dialogDate: date, openDialog: true})
  }

  @autobind
  handleClose(e) {
    e.preventDefault()
    this.setState(
      {
        openDialog: false,
        category: 'rce-all',
      },
      () => {
        this.getRecipes()
      },
    )
  }

  @autobind
  async addCalendarItem(id) {
    const {createSuccess, createNotificationFromError} = this.props
    const {recipes, dialogDate} = this.state
    try {
      const payload = {recipe: id, date: dialogDate.format('YYYY-MM-DD')}
      const item = await CalendarItemApi.addCalendarItem(payload)
      recipes.forEach((recipe, index) => {
        if (recipe.id === item.recipe.id) {
          recipes.splice(index, 1)
        }
      })
      this.setState({recipes})
      createSuccess('Recipe added successfully.')
      this.getCalendarItems()
    } catch (e) {
      createNotificationFromError(e)
    }
  }

  getDayItems(items, date) {
    return _.get(items, date.format('YYYYMMDD'), [])
  }

  renderCurrWeekText() {
    const {startOfWeek, endOfWeek} = this.state
    const {classes} = this.props
    return (
      <Typography variant="body2" className={classes.currWeekText} id="current-week-text">
        {`${startOfWeek.format('M/D')} - ${endOfWeek.format('M/D')}`}
      </Typography>
    )
  }
}

CalendarWidget.displayName = 'CalendarWidget'

function mapStateToProps(state) {
  return {
    ...state.calendar,
    recipe: state.recipe,
    profile: state.profile,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createNotificationFromError,
      createSuccess,
      getCalendarItems,
      deleteCalendarItem,
    },
    dispatch,
  )
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(CalendarWidget)

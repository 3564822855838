import React from 'react'
import {Typography, CircularProgress} from '@material-ui/core'
import autobind from 'autobind-decorator'
import {DropTarget} from 'react-dnd'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import CalendarItem from './CalendarItem'
import ItemTypes from '../constants/DnD'
import withDragDropContext from '../DnDContext'
import CalendarItemApi from './api'
import {addCalendarItemSuccess, updateOrderCalendarItems} from './redux/actions'

const dropTarget = {
  drop(props, monitor, component) {
    const item = monitor.getItem()
    const type = monitor.getItemType()
    if (type === ItemTypes.CALENDAR_RECIPE) {
      component.createCalItem(item.id, props.date.format('YYYY-MM-DD'))
    }
    if (type === ItemTypes.CALENDAR_ITEM) {
      props.moveCalItem(
        item.calItem.id,
        item.calItem.date,
        props.date.format('YYYY-MM-DD'),
        item.dayIndex,
        props.dayIndex,
      )
    }
  },

  canDrop(props, monitor) {
    const type = monitor.getItemType()
    if (type === ItemTypes.CALENDAR_RECIPE) {
      return true
    }
    if (type === ItemTypes.CALENDAR_ITEM) {
      const item = monitor.getItem()
      if (item.calItem.date !== props.date.format('YYYY-MM-DD')) {
        return true
      }
    }
    return false
  },
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }
}

class CalendarDay extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      creatingCalItem: false,
      updatingOrder: false,
    }
  }

  @autobind
  reorderCalItems(oldIndex, newIndex) {
    const {items, updateOrderCalendarItems} = this.props
    const oldIndexItem = items[oldIndex]
    const newIndexItem = items[newIndex]
    const newItems = [...items]
    newItems[oldIndex] = newIndexItem
    newItems[newIndex] = oldIndexItem
    updateOrderCalendarItems(newItems)
    this.updateCalendarItemsOrder(newItems)
  }

  @autobind
  async updateCalendarItemsOrder(items) {
    const itemIds = items.map(i => i.id)
    try {
      this.setState({updatingOrder: true})
      await CalendarItemApi.updateOrder({items: itemIds})
    } catch (e) {
      console.log(e)
    } finally {
      this.setState({updatingOrder: false})
    }
  }

  async createCalItem(recipeId, date) {
    const {addCalendarItemSuccess} = this.props

    try {
      this.setState({creatingCalItem: true})
      const payload = {recipe: recipeId, date}
      const item = await CalendarItemApi.addCalendarItem(payload)
      addCalendarItemSuccess(item)
    } catch (e) {
      console.log(e)
    } finally {
      this.setState({creatingCalItem: false})
    }
  }

  render() {
    const {classes, dayIndex, date, connectDropTarget, isDraggingItem, isOver} = this.props

    let borderStyles = {}
    let calListBorderStyles = {}
    let calDay = {}

    if (isDraggingItem) {
      borderStyles = {borderBottom: 'none'}
      calListBorderStyles = {
        border: '2px dashed #efefef',
        borderRadius: '3px',
        backgroundColor: '#fff',
        boxShadow: '0 1px 10px 0 rgba(0,0,0,0.19)',
      }
      calDay = {
        borderRight: 'none',
      }
    }

    if (isOver) {
      calListBorderStyles.border = '2px dashed #589a43'
    }

    return connectDropTarget(
      <div className={classes.calendarDay} key={dayIndex} style={calDay}>
        <Typography
          variant="subtitle"
          component="h4"
          className={classes.calendarDayTitle}
          style={borderStyles}
        >
          {date.format('dddd')}
        </Typography>
        <div className={classes.calendarDayList} style={calListBorderStyles}>
          {this.renderCalItems()}
        </div>
      </div>,
    )
  }

  renderCalItems() {
    const {isLoadingItems, items, dayIndex, hasCalItemMoving} = this.props
    const {creatingCalItem} = this.state

    const loading = isLoadingItems || creatingCalItem || hasCalItemMoving

    if (loading) {
      return <CircularProgress />
    }

    return (
      <React.Fragment>
        {items.map((calendarItem, index) => {
          return (
            <CalendarItem
              calendarItem={calendarItem}
              key={calendarItem.id}
              index={index}
              reorderCalItems={this.reorderCalItems}
              updatingOrder={this.state.updatingOrder}
              dayIndex={dayIndex}
            />
          )
        })}
      </React.Fragment>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({addCalendarItemSuccess, updateOrderCalendarItems}, dispatch)
}

export default compose(
  connect(null, mapDispatchToProps),
  withDragDropContext,
  DropTarget([ItemTypes.CALENDAR_RECIPE, ItemTypes.CALENDAR_ITEM], dropTarget, collect),
)(CalendarDay)

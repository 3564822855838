import React from 'react'
import {Link} from 'react-router'
import {Grid, withStyles} from '@material-ui/core'
import autobind from 'autobind-decorator'
import {compose} from 'redux'
import {connect} from 'react-redux'

import InviteRecimeeFriendsDialog from './InviteRecimeeFriendsDialog'
import ContactUsDialog from './ContactUsDialog'

const styles = {
  footer: {
    backgroundColor: '#B9DCAD',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '88px',
    textAlign: 'center',

    '& a': {
      color: '#000000',
      fontFamily: 'Lato',
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '24px',
      margin: '0 10px',
    },
    '& a:hover': {
      cursor: `pointer`,
    },
  },
}

class Footer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openInviteDialog: false,
      openContactUsDialog: false,
    }
  }

  backToTop(e) {
    e.preventDefault()

    const cosParameter = window.scrollY / 2

    let scrollCount = 0

    let oldTimestamp = performance.now()

    function step(newTimestamp) {
      scrollCount += Math.PI / (1000 / (newTimestamp - oldTimestamp))
      if (scrollCount >= Math.PI) window.scrollTo(0, 0)
      if (window.scrollY === 0) return
      window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)))
      oldTimestamp = newTimestamp
      window.requestAnimationFrame(step)
    }

    window.requestAnimationFrame(step)
  }

  @autobind
  handleInviteOpen(e) {
    e.preventDefault()
    this.setState({openInviteDialog: true})
  }

  @autobind
  handleInviteClose() {
    this.setState({openInviteDialog: false})
  }

  @autobind
  handleContactUsOpen(e) {
    e.preventDefault()
    this.setState({openContactUsDialog: true})
  }

  @autobind
  handleContactUsClose() {
    this.setState({openContactUsDialog: false})
  }

  @autobind
  renderInviteFriendsLink() {
    const {profile} = this.props

    if (profile) {
      return (
        <React.Fragment>
          |{' '}
          <a id="invite-btn" href="#" onClick={this.handleInviteOpen}>
            Invite Friends to Recimee
          </a>
        </React.Fragment>
      )
    }

    return null
  }

  render() {
    const {classes} = this.props

    return (
      <footer>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.footer}>
              <Link to="/about-us">About Us</Link> |
              <a id="contact-us-btn" onClick={this.handleContactUsOpen}>
                Contact Us
              </a>
              {this.renderInviteFriendsLink()}
            </div>
          </Grid>
        </Grid>
        <InviteRecimeeFriendsDialog
          open={this.state.openInviteDialog}
          onClose={this.handleInviteClose}
        />
        <ContactUsDialog
          open={this.state.openContactUsDialog}
          onClose={this.handleContactUsClose}
        />
      </footer>
    )
  }
}

Footer.displayName = 'Footer'

const mapStateToProps = state => {
  return {profile: state.profile.profile}
}

export default compose(connect(mapStateToProps), withStyles(styles))(Footer)

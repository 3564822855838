import reduceReducers from 'reduce-reducers'
import _ from 'lodash'
import * as Types from './actions.types'
import * as ChangeEmailTypes from './actions.changeEmail.types'
import {LOGOUT} from '../../auth/redux/actions.types'
import * as ErrorUtils from '../../utils/ErrorUtils'
import {arrayRemoveAt} from '../../utils/ReduxUtils'

function profile(state = getInitialState(null), action) {
  switch (action.type) {
    case LOGOUT:
      return getInitialState(null)
    default:
      return state
    case Types.PUBLICPROFILE_GET:
      return {
        ...state,
        isFetchingPublic: true,
      }
    case Types.PUBLICPROFILE_GET_SUCCESS:
      return {
        ...state,
        isFetchingPublic: false,
        publicProfile: action.payload,
        publicProfileNotFound: false,
      }
    case Types.PUBLICPROFILE_GET_FAILURE:
      return {
        ...state,
        isFetchingPublic: false,
        publicProfile: null,
        publicProfileNotFound: true,
      }
    case Types.PROFILE_GET:
      return {
        ...state,
        isFetching: true,
      }
    case Types.PROFILE_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        profile: action.payload,
      }
    case Types.PROFILE_GET_FAILURE:
      return {
        ...state,
        isFetching: false,
        profile: null,
      }
    case Types.RECIMEE_GET:
      return {
        ...state,
        isFetching: true,
      }
    case Types.RECIMEE_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        recimeeId: action.payload,
      }
    case Types.RECIMEE_GET_FAILURE:
      return {
        ...state,
        isFetching: false,
        recimeeId: null,
      }
    case Types.PROFILE_EDIT_INIT:
      return {
        ...state,
        isEditMode: false,
        profileEdit: null,
      }
    case Types.PROFILE_TOGGLE_EDIT: {
      const isEditMode = !state.isEditMode
      return {
        ...state,
        isEditMode,
        profileEdit: isEditMode ? _.cloneDeep(state.profile) : null,
      }
    }
    case Types.PROFILE_FOLLOW: {
      return {
        ...state,
        isChangingFollowing: true,
      }
    }
    case Types.PROFILE_FOLLOW_SUCCESS: {
      return {
        ...state,
        publicProfile: {
          ...state.publicProfile,
          userIsFollowing: true,
        },
        isChangingFollowing: false,
      }
    }
    case Types.PROFILE_FOLLOW_FAILURE: {
      return {
        ...state,
        isChangingFollowing: false,
      }
    }
    case Types.PROFILE_UNFOLLOW: {
      return {
        ...state,
        isChangingFollowing: true,
      }
    }
    case Types.PROFILE_UNFOLLOW_SUCCESS: {
      const {index} = action
      let publicProfile = {
        ...state.publicProfile,
        userIsFollowing: false,
      }
      if (typeof index !== 'undefined') {
        publicProfile = {
          ...publicProfile,
          following: arrayRemoveAt(publicProfile.following, index),
        }
      }
      return {
        ...state,
        publicProfile,
        isChangingFollowing: false,
      }
    }
    case Types.PROFILE_UNFOLLOW_FAILURE: {
      return {
        ...state,
        isChangingFollowing: false,
      }
    }
    case Types.PROFILE_UPDATE:
      return {
        ...state,
        profileEdit: {...state.profileEdit, ...action.payload},
      }
    case Types.PROFILE_SAVE:
    case Types.PROFILE_CHANGE_PASSWORD:
    case ChangeEmailTypes.CHANGE_EMAIL:
    case ChangeEmailTypes.CHANGE_EMAIL_CANCEL:
    case ChangeEmailTypes.CHANGE_EMAIL_RESEND_CONFIRM:
    case ChangeEmailTypes.CHANGE_EMAIL_RESEND_VERIFY:
    case ChangeEmailTypes.EMAIL_VERIFY:
      return {
        ...state,
        isSaving: true,
      }
    case Types.PROFILE_SAVE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        publicProfile: action.payload,
        isSaving: false,
      }
    case Types.PROFILE_SAVE_FAILURE:
      return {
        ...state,
        isSaving: false,
      }
    case ChangeEmailTypes.CHANGE_EMAIL_SUCCESS:
    case ChangeEmailTypes.CHANGE_EMAIL_CANCEL_SUCCESS:
    case ChangeEmailTypes.CHANGE_EMAIL_RESEND_CONFIRM_SUCCESS:
    case ChangeEmailTypes.CHANGE_EMAIL_RESEND_VERIFY_SUCCESS:
    case ChangeEmailTypes.EMAIL_VERIFY_SUCCESS:
      return {
        ...state,
        errors: {},
        isSaving: false,
      }
    case ChangeEmailTypes.CHANGE_EMAIL_FAILURE:
    case ChangeEmailTypes.CHANGE_EMAIL_CANCEL_FAILURE:
    case ChangeEmailTypes.CHANGE_EMAIL_RESEND_CONFIRM_FAILURE:
    case ChangeEmailTypes.CHANGE_EMAIL_RESEND_VERIFY_FAILURE:
    case ChangeEmailTypes.EMAIL_VERIFY_FAILURE:
      return {
        ...state,
        isSaving: false,
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.PROFILE_CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordEditErrors: {},
        passwordEdit: {
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        },
        isSaving: false,
      }
    case Types.PROFILE_CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isSaving: false,
        passwordEditErrors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.PROFILE_CHANGE_PASSWORD_UPDATE:
      return {
        ...state,
        passwordEdit: {...state.passwordEdit, ...action.update},
      }
    case Types.GET_CATEGORIES_OF_PROFILE:
      return {
        ...state,
        categories: {
          ...state.categories,
          isLoading: true,
        },
      }
    case Types.GET_CATEGORIES_OF_PROFILE_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          ...action.categories,
          errors: {},
          isLoading: false,
        },
      }
    case Types.GET_CATEGORIES_OF_PROFILE_FAILURE:
      return {
        ...state,
        categories: {
          ...state.categories,
          errors: ErrorUtils.getApiErrors(action.error),
          isLoading: false,
        },
      }
    case Types.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        profile: {
          ...state.profile,
          notificationsCount: 0,
        },
      }
  }
}

function getInitialState(profile = null) {
  return {
    isFetching: false,
    isSaving: false,
    errors: {},
    passwordEditErrors: {},
    profile,
    recimeeId: null,
    publicProfile: null,
    publicProfileNotFound: false,
    isFetchingPublic: false,
    isEditMode: false,
    profileEdit: null,
    passwordEdit: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    categories: {
      isLoading: false,
      errors: {},
      results: [],
    },
  }
}

const reducer = reduceReducers((state, action) => profile(state, action))

export default reducer

import React from 'react'
import PropTypes from 'prop-types'
import autobind from 'autobind-decorator'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/core/styles'
import {ShoppingCart} from '@material-ui/icons'
import ButtonWithLoginRequired from '../common/ButtonWithLoginRequired'
import {addAllShoppingItemsFromRecipe} from './redux/actions'

class AddAllIngredientsButton extends React.Component {
  @autobind
  onClick(e) {
    e.preventDefault()
    this.props.addAllShoppingItemsFromRecipe(this.props.recipe)
  }

  render() {
    const {isAddingAll, classes} = this.props
    const {cartIcon, ...buttonClasses} = classes
    return (
      <ButtonWithLoginRequired
        disabled={isAddingAll}
        onClick={this.onClick}
        data-test-name="all-ingredients-to-cart"
        classes={buttonClasses}
      >
        <ShoppingCart className={classes.cartIcon} />
        Add All Ingredients
      </ButtonWithLoginRequired>
    )
  }
}

AddAllIngredientsButton.displayName = 'AddAllIngredientsButton'

AddAllIngredientsButton.propTypes = {
  recipeId: PropTypes.number.isRequired,
}

function mapStateToProps(state) {
  return {
    ...state.shoppingList,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({addAllShoppingItemsFromRecipe}, dispatch)
}

const styles = theme => ({
  root: {
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    color: '#589a43',
    padding: 0,
    textTransform: 'none',
    height: '37.42px',
    width: '230px',
    fontSize: '18px',
    fontWeight: 'bold',
    lineHeight: '21px',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      fontSize: '12px',
    },
  },
  cartIcon: {
    color: '#D8D8D8',
    fontSize: '18px',
    lineHeight: '14px',
    marginRight: '5px',
  },
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AddAllIngredientsButton)

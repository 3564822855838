import React, {useState, useEffect, useCallback, useRef} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {withStyles} from '@material-ui/core/styles'
import ShoppingCalendarWidget from '../shoppingList/ShoppingCalendarWidget'
import HomeApi from './api'
import NewsfeedItem from './NewsfeedItem'
import {createNotificationFromError} from '../common/redux/actions.notifications'
import FeaturedRecipes from './FeaturedRecipes'
import {getRecimee} from '../profile/redux/actions'

const styles = theme => ({
  noResult: {
    padding: theme.spacing(3),
  },
  carouselContainer: {
    position: 'relative',
    height: 750,
    marginLeft: -64,
    marginRight: -64,
    marginTop: -16,
    overflow: 'hidden',
  },
  carouselImageContainer: {
    position: 'absolute',
    width: '100%',
    height: 600,
    overflow: 'hidden',
  },
  carouselImage: {
    width: '100%',
  },
  carouselInfo: {
    position: 'absolute',
    width: 418,
    height: 378,
    left: 75,
    top: 300,
    padding: '25px 35px 35px 35px',
    '& >p': {
      fontFamily: 'Lato',
      fontSize: '16px',
      fontWeight: '300',
      lineHeight: '24px',
    },
  },
  carouselInfoTitle: {
    textOverflow: 'ellipsis',
    /** Max 2 lines **/
    maxHeight: '124px',
    lineHeight: '62px',
    display: 'block',
    overflow: 'hidden',
    wordWrap: 'break-word',
    fontFamily: 'Literata',
    fontWeight: `600`,
    fontSize: '52px',
  },
  carouselInfoBlurb: {},
  carouselInfoBy: {
    marginTop: '2.5em',
    fontSize: '16px',
    lineHeight: '19px',
  },
  emptyMessage: {
    position: 'relative',
    left: '30%',
    top: '15%',
    width: '450px',
    fontSize: '30px',
    fontWeight: '500',
    textAlign: 'center',
  },
  emptyMessageLogIn: {
    position: 'relative',
    left: '20%',
    top: '15%',
    width: '450px',
    fontSize: '30px',
    fontWeight: '500',
    textAlign: 'center',
  },
  shoppingCalendarWidget: {
    gridArea: 'widget',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoRows: 'minmax(min-content, max-content)',
    gridTemplateAreas: "'first4 first4 widget' 'first4 first4 widget'",
    gap: '1em',
    marginBottom: '1em',
    width: '100%',
    height: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridAutoRows: 'minmax(min-content, max-content)',
      gridTemplateAreas: "'first4 widget' 'first4 widget'",
      padding: '0 1em',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
      gridAutoRows: 'minmax(min-content, max-content)',
      gridTemplateAreas: "'first4' 'first4'",
    },
  },
  carouselSwitcher: {},
})

const Home = ({profile, token, classes, recimeeId, getRecimee}) => {
  const [newsfeed, setNewsfeed] = useState([])
  const [hasMoreNewsfeed, setHasMoreNewsfeed] = useState(true)
  const [firstElements, setFirstElements] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getRecimee()
    getNewsFeed()
  }, [])

  const getNewsFeed = useCallback(async () => {
    try {
      setLoading(true)
      const page = currentPage + 1
      const response = await HomeApi.getNewsFeed(page)
      let allNewsfeed = [...newsfeed]

      if (response.results.length === 0) {
        setHasMoreNewsfeed(false)
      }

      if (page === 1 && token) {
        const first =
          response.results.length >= 4 ? response.results.splice(0, 4) : response.results
        allNewsfeed = allNewsfeed.concat(response.results)
        setFirstElements(first)
      } else {
        allNewsfeed = allNewsfeed.concat(response.results)
      }

      setNewsfeed(allNewsfeed)
      setCurrentPage(page)
    } catch (e) {
      createNotificationFromError(e)
      if (e.response && e.response.status === 404) {
        setHasMoreNewsfeed(false)
      }
    } finally {
      setLoading(false)
    }
  }, [currentPage, newsfeed, profile, token])

  const renderFirst4 = useCallback(() => {
    if (firstElements.length === 0) {
      return Array.from({length: 4}, (_, index) => <NewsfeedItem key={index} loading />)
    }
    return firstElements.map(item => (
      <NewsfeedItem item={item} recimeeId={recimeeId} key={item.id} />
    ))
  }, [firstElements, recimeeId, token, profile])

  const renderNewsfeed = useCallback(() => {
    if (newsfeed.length > 0) {
      return newsfeed.map((item, index) => (
        <NewsfeedItem item={item} recimeeId={recimeeId} key={`${index}-${item.id}`} />
      ))
    }
    if (!profile && newsfeed.length === 0) return <NewsfeedItem loading />
  }, [newsfeed, profile, recimeeId, token])

  const sentinelRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMoreNewsfeed && !loading) {
          getNewsFeed()
        }
      },
      {threshold: 1.0},
    )

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current)
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current)
      }
    }
  }, [getNewsFeed, hasMoreNewsfeed, loading])

  return (
    <React.Fragment>
      <FeaturedRecipes />
      {profile && (
        <div className={classes.container}>
          {renderFirst4()}
          <div className={classes.shoppingCalendarWidget}>
            <ShoppingCalendarWidget />
          </div>
        </div>
      )}
      <div className={classes.container}>
        {renderNewsfeed()}
        {loading && (
          <div style={{height: '20px', margin: '0 auto'}}>
            <p>Loading...</p>
          </div>
        )}
        {hasMoreNewsfeed && <div ref={sentinelRef} />}
      </div>
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {...state.home, ...state.profile, ...state.auth, recimeeId: state.profile.recimeeId}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getRecimee}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home))

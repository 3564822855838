import _ from 'lodash'
import * as Types from './actions.types'
import {arrayRemoveAt, arrayUpdateAt} from '../../utils/ReduxUtils'

function createInitialState() {
  return {
    isLoadingItems: false,
    isAddingAll: false,
    items: [],
  }
}

export default function shoppingList(state = createInitialState(), action) {
  switch (action.type) {
    case Types.GET_SHOPPING_ITEMS:
      return {
        ...state,
        isLoadingItems: true,
      }
    case Types.GET_SHOPPING_ITEMS_SUCCESS:
      return {
        ...state,
        isLoadingItems: false,
        items: action.response,
      }
    case Types.GET_SHOPPING_ITEMS_FAILURE:
      return {
        ...state,
        isLoadingItems: false,
      }

    case Types.UPDATE_SHOPPING_ITEM_SUCCESS: {
      const itemIndex = _.findIndex(state.items, {id: action.id})
      const updatedItem = {
        ...state.items[itemIndex],
        ...action.updatedItem,
      }
      return {
        ...state,
        items: arrayUpdateAt(state.items, itemIndex, updatedItem),
      }
    }

    case Types.DELETE_SHOPPING_ITEM_SUCCESS: {
      const itemIndex = _.findIndex(state.items, {id: action.id})
      return {
        ...state,
        items: arrayRemoveAt(state.items, itemIndex),
      }
    }

    case Types.ADD_ALL_SHOPPING_ITEMS_FROM_RECIPE:
      return {
        ...state,
        isAddingAll: true,
      }
    case Types.ADD_ALL_SHOPPING_ITEMS_FROM_RECIPE_SUCCESS:
    case Types.ADD_ALL_SHOPPING_ITEMS_FROM_RECIPE_FAILURE:
      return {
        ...state,
        isAddingAll: false,
      }

    default:
      return state
  }
}

import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Typography, Modal, Grid, Button, FormLabel, MenuItem} from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import Icon from '@material-ui/core/Icon'
import autobind from 'autobind-decorator'
import cx from 'classnames'
import CardActionArea from '@material-ui/core/CardActionArea/CardActionArea'
import CardContent from '@material-ui/core/CardContent/CardContent'
import Card from '@material-ui/core/Card/Card'
import {bindActionCreators, compose} from 'redux'
import connect from 'react-redux/es/connect/connect'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash} from '@fortawesome/free-solid-svg-icons'
import styles, {CustomOptionPopper, CustomOptionFab} from './MyKitchen.styles.js'
import {DetailTextField, Label} from '../recipe/CreateRecipeFlow/CreateRecipe.styles'
import MyKitchenApi from './api'
import {createSuccess, createNotificationFromError} from '../common/redux/actions.notifications.js'
import {updateCategorySuccess, getCategoriesSuccess, deleteCategorySuccess} from './redux/actions'
import validatedComponent from '../common/ValidatedComponent'
import InputValidator from '../common/InputValidator'
import {viewableByTypes} from '../utils/RecipeTypes'
import {imageFileRegex} from '../constants/Regex'
import Loading from '../common/Loading'
import RCESelect from '../common/styles/RCESelect'

class OptionsButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      anchorEl: null,
      open: false,
      placement: 'left-start',
      openModal: false,
      updatedCategory: {},
    }
  }

  componentDidMount() {
    if (this.props.category) {
      this.setState(state => ({
        updatedCategory: {...state.updatedCategory, name: this.props.category.name},
      }))
      this.setState(state => ({
        updatedCategory: {...state.updatedCategory, sharedWith: this.props.category.sharedWith},
      }))
    } else {
      return <Loading />
    }
  }

  @autobind
  handleClickButton(e) {
    e.preventDefault()
    const {currentTarget} = e
    const currentState = this.state.open

    this.setState({
      anchorEl: currentTarget,
      open: !currentState,
    })
  }

  @autobind
  handleOpen(e) {
    e.preventDefault()
    this.setState({openModal: true})
  }

  @autobind
  handleClose(e) {
    e.preventDefault()
    this.setState({openModal: false})
  }

  stopPropagation(e) {
    e.stopPropagation()
  }

  onChange(fieldName, e) {
    const {value} = e.target
    this.setState(state => ({updatedCategory: {...state.updatedCategory, [fieldName]: value}}))
  }

  handleChangeImage(e) {
    const file = e.target.files.item(0)
    if (!file || !imageFileRegex.test(file.type)) {
      return
    }

    const reader = new FileReader()
    reader.onload = e => {
      this.setState(state => ({
        updatedCategory: {...state.updatedCategory, image: e.target.result},
      }))
    }
    reader.readAsDataURL(file)
  }

  @autobind
  async updateCategory(e) {
    const {
      createNotificationFromError,
      createSuccess,
      isComponentValid,
      updateCategorySuccess,
      getCategoriesSuccess,
      category,
    } = this.props
    try {
      if (isComponentValid()) {
        const response = await MyKitchenApi.updateCategory(category.id, this.state.updatedCategory)
        updateCategorySuccess(response.data)
        createSuccess('Category updated successfully.')
        const categories = await MyKitchenApi.getCategories(this.props.profile.id)
        getCategoriesSuccess(categories)
        this.handleClose(e)
      }
    } catch (e) {
      createNotificationFromError(e)
    }
  }

  @autobind
  async deleteCategory(e) {
    const {
      createNotificationFromError,
      createSuccess,
      isComponentValid,
      deleteCategorySuccess,
      getCategoriesSuccess,
      category,
    } = this.props
    try {
      if (isComponentValid()) {
        const response = await MyKitchenApi.deleteCategory(category.id)
        deleteCategorySuccess(response)
        createSuccess('Category deleted successfully.')
        const categories = await MyKitchenApi.getCategories(this.props.profile.id)
        getCategoriesSuccess(categories)
        this.handleClose(e)
      }
    } catch (e) {
      createNotificationFromError(e)
    }
  }

  render() {
    const {classes, index, category} = this.props
    const {anchorEl, open, placement, openModal, updatedCategory, errors} = this.state
    return (
      <div>
        <CustomOptionFab
          disableRipple
          disableFocusRipple
          disableTouchRipple
          color="primary"
          onClick={this.handleClickButton}
          aria-label="Add"
        >
          <Icon
            className={
              open
                ? cx(classes.recipeIconOption, 'fa fa-times')
                : cx(classes.recipeIconOption, 'fa fa-edit')
            }
          />
        </CustomOptionFab>

        <CustomOptionPopper
          id={index}
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          transition
          className={`${classes.popper} ${classes.optionsPopper}`}
        >
          {({TransitionProps}) => (
            <Fade {...TransitionProps} timeout={350}>
              <Card className={classes.cardOption}>
                <CardActionArea onClick={this.handleOpen}>
                  <CardContent>
                    <Typography gutterBottom className={classes.typography}>
                      Edit Category
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Fade>
          )}
        </CustomOptionPopper>
        <div>
          <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openModal}
            onClick={this.stopPropagation}
            onClose={this.handleClose}
            className={classes.optionsModal}
          >
            <div className={classes.optionPaper}>
              <Grid item xs={12}>
                <Typography variant="h6" id="modal-title" className={classes.optionsTitle}>
                  Edit "{category.name}" category
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.optionsMargin}>
                <Label>Title</Label>
                <DetailTextField
                  className={classes.titleTextField}
                  variant="outlined"
                  name="title"
                  inputProps={{maxLength: 150}}
                  value={updatedCategory.name}
                  label="Title"
                  onChange={this.onChange.bind(this, 'name')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} className={classes.optionsMargin}>
                <Label>Visibility</Label>
                <InputValidator shouldValidateOnBlur={true} errors={errors.sharedWith}>
                  <RCESelect
                    value={updatedCategory.sharedWith}
                    onChange={this.onChange.bind(this, 'sharedWith')}
                    disableUnderline
                  >
                    {viewableByTypes.map(type => (
                      <MenuItem value={type.value} key={type.value}>
                        {type.label}
                      </MenuItem>
                    ))}
                  </RCESelect>
                </InputValidator>
              </Grid>
              <Grid item xs={12} className={classes.optionsMargin}>
                <FormLabel htmlFor="profile-image" className={classes.optionsFormLabel}>
                  Feature Image
                </FormLabel>
                <InputValidator
                  canShowLabel={false}
                  errors={errors.image}
                  shouldValidateOnBlur={false}
                >
                  <input
                    style={{
                      marginTop: `16px`,
                    }}
                    type="file"
                    name="profile-image"
                    accept="image/*"
                    onChange={this.handleChangeImage.bind(this)}
                  />
                </InputValidator>
              </Grid>

              <Grid item xs={12} style={{textAlign: 'center'}}>
                <div>
                  <Button
                    data-test-name="create-category-save"
                    onClick={this.updateCategory}
                    className={classes.saveBtn}
                  >
                    Save
                  </Button>
                </div>
              </Grid>

              <Grid item xs={12} className={classes.optionsBottom} style={{textAlign: 'center'}}>
                <Button
                  data-test-name="create-category-cancel"
                  onClick={this.handleClose}
                  className={classes.cancelBtn}
                  style={{marginRight: '-32px'}}
                >
                  Cancel
                </Button>
                <FontAwesomeIcon
                  icon={faTrash}
                  className={cx(classes.optionDeleteIcon)}
                  onClick={this.deleteCategory}
                />
              </Grid>
            </div>
          </Modal>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createSuccess,
      createNotificationFromError,
      updateCategorySuccess,
      getCategoriesSuccess,
      deleteCategorySuccess,
    },
    dispatch,
  )
}

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
  validatedComponent,
)(OptionsButton)

import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router'
import {Dialog, DialogTitle, DialogContent, DialogActions, Button} from '@material-ui/core'
import autobind from 'autobind-decorator'
import {connect} from 'react-redux'
import {compose} from 'redux'
import _ from 'lodash'
import ButtonWithProgress from './ButtonWithProgress'

class ButtonWithLoginRequired extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogOpen: false,
    }
  }

  @autobind
  onClick(e) {
    e.preventDefault()
    const {onClick, auth} = this.props
    const isLoggedIn = !!_.get(auth, 'token')

    if (isLoggedIn) {
      if (typeof onClick === 'function') {
        onClick(e)
      }
    } else {
      this.setState({dialogOpen: true})
    }
  }

  @autobind
  closeDialog() {
    this.setState({
      dialogOpen: false,
    })
  }

  render() {
    const {onClick, dispatch, auth, ...others} = this.props

    const Component = others.component || ButtonWithProgress

    return (
      <React.Fragment>
        <Component {...others} onClick={this.onClick} />
        {this.state.dialogOpen && this.renderDialog()}
      </React.Fragment>
    )
  }

  @autobind
  renderDialog() {
    return (
      <Dialog
        open={this.state.dialogOpen}
        onClose={this.closeDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Login Required</DialogTitle>
        <DialogContent>
          You need to be logged in to perform this action. Please <Link to="/login">login</Link>.
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

ButtonWithLoginRequired.displayName = 'ButtonWithLoginRequired'

ButtonWithLoginRequired.propTypes = {
  isLoading: PropTypes.bool,
}

ButtonWithLoginRequired.defaultProps = {
  isLoading: false,
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  }
}

export default compose(connect(mapStateToProps))(ButtonWithLoginRequired)

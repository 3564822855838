import React from 'react'
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  withStyles,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@material-ui/core'
import _ from 'lodash'
import autobind from 'autobind-decorator'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import {DetailTextField} from '../CreateRecipeFlow/CreateRecipe.styles'
import MyKitchenApi from '../../myKitchen/api'
import {createCategorySuccess} from '../../myKitchen/redux/actions'
import ButtonWithProgress from '../../common/ButtonWithProgress'
import {createNotificationFromError, createSuccess} from '../../common/redux/actions.notifications'

const styles = theme => ({
  title: {
    fontFamily: 'Lato',
    fontSize: '24px',
    lineHeight: '29px',
    fontWeight: '300',
    paddingBottom: '10px',
    borderBottom: '1.5px solid #589a43',
    paddingLeft: '10px',
  },
  contentContainer: {
    padding: '30px 30px 0 30px',
    [theme.breakpoints.down('xs')]: {
      padding: '12px 8px 0 8px',
    },
  },
  categoryName: {
    fontFamily: 'Lato',
    fontSize: '18px',
    lineHeight: '29px',
    fontWeight: '300',

    paddingBottom: '10px',
  },
  select: {
    height: '51px',
    width: '474px',
    border: '1.5px solid #D8D8D8',
    borderRadius: '3px',
    padding: '3px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  saveBtn: {
    height: '30px',
    width: '132px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    padding: 0,
    color: '#589a43',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '21px',
    [theme.breakpoints.down('xs')]: {
      width: '140px',
    },
    textTransform: 'none',
  },
  cancelBtn: {
    height: '33px',
    width: '107px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '14px',
    lineHeight: '16px',
    padding: 0,
    textTransform: 'none',
  },
  checkBox: {
    color: '#589a43 !important',
  },
  actionsContainer: {
    width: '100%',
    padding: '18px 30px 0 30px',
    [theme.breakpoints.down('xs')]: {
      padding: '5px 5px 0 5px',
    },
  },
  createCategoryBtn: {
    height: '33px',
    width: '154px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '16px',
    padding: 0,
    textTransform: 'none',
  },
  saveLongBtn: {
    height: '30px',
    width: '280px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    padding: 0,
    color: '#589a43',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '21px',
    [theme.breakpoints.down('xs')]: {
      width: '200px',
    },
    textTransform: 'none',
  },
  cancelLongBtn: {
    height: '23px',
    width: '176px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '14px',
    lineHeight: '16px',
    padding: 0,
    textTransform: 'none',
  },
  categoryNameText: {
    width: '474px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  saveCancelMobile: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  createMobile: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  selectCategoryDialog: {
    margin: 0,
  },
  cancelSaveMobile: {
    display: 'inline-block',
    marginLeft: '15px',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      display: 'block',
      marginTop: 10,
    },
  },
})

class CreateCategoryComponent extends React.PureComponent {
  render() {
    const {
      classes,
      saveCategory,
      cancelCreateCategory,
      updateCategoryName,
      categoryName,
      savingCategory,
    } = this.props

    const saveDisabled = savingCategory || categoryName === ''

    return (
      <React.Fragment>
        <DialogContent id="create-category-component">
          <div className={classes.contentContainer}>
            <Typography variant="body2" className={classes.categoryName}>
              Category Name
            </Typography>
            <DetailTextField
              placeholder="Enter category name"
              className={classes.categoryNameText}
              onChange={updateCategoryName}
              value={categoryName}
              disabled={savingCategory}
              id="category-name-text"
            />
          </div>
        </DialogContent>
        <DialogActions style={{marginBottom: '24px'}}>
          <Grid container>
            <Grid item xs={12} style={{textAlign: 'center', marginTop: '18px'}}>
              <ButtonWithProgress
                data-test-name="save-create-category-dialog"
                className={classes.saveLongBtn}
                onClick={saveCategory}
                disabled={saveDisabled}
                isLoading={savingCategory}
              >
                Save
              </ButtonWithProgress>
            </Grid>
            <Grid item xs={12} style={{textAlign: 'center', marginTop: '18px'}}>
              <Button
                data-test-name="cancel-create-category-dialog"
                className={classes.cancelLongBtn}
                onClick={cancelCreateCategory}
                disabled={savingCategory}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </React.Fragment>
    )
  }
}

class SelectCategoryComponent extends React.PureComponent {
  render() {
    const {
      classes,
      handleChange,
      renderSelectedCategories,
      selectedCategories,
      isCategorySelected,
      onSave,
      handleCancel,
      handleCreateCategory,
      categories,
    } = this.props

    return (
      <React.Fragment>
        <DialogContent id="select-category-component">
          <div className={classes.contentContainer}>
            <Typography variant="body2" className={classes.categoryName}>
              Category Name
            </Typography>
            <Select
              multiple
              disableUnderline
              className={classes.select}
              onChange={handleChange}
              renderValue={renderSelectedCategories}
              value={selectedCategories}
            >
              <MenuItem disabled value="all-id">
                <Checkbox classes={{checked: classes.checkBox}} checked={true} />
                <ListItemText primary="All" />
              </MenuItem>
              {categories.map(cat => (
                <MenuItem key={cat.id} value={cat}>
                  <Checkbox
                    classes={{checked: classes.checkBox}}
                    checked={isCategorySelected(cat)}
                  />
                  <ListItemText primary={cat.name} />
                </MenuItem>
              ))}
            </Select>
          </div>
        </DialogContent>
        <DialogActions style={{marginBottom: '24px'}}>
          <div className={classes.actionsContainer}>
            <Grid container>
              <Grid item xs={12} sm={8} md={8} className={classes.saveCancelMobile}>
                <Button
                  data-test-name="save-select-category-dialog"
                  className={classes.saveBtn}
                  onClick={onSave}
                >
                  Save
                </Button>
                <div className={classes.cancelSaveMobile}>
                  <Button
                    data-test-name="cancel-select-category-dialog"
                    className={classes.cancelBtn}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4} className={classes.createMobile}>
                <Button
                  id="createCategory"
                  className={classes.createCategoryBtn}
                  onClick={handleCreateCategory}
                >
                  Create Category
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogActions>
      </React.Fragment>
    )
  }
}

class SelectCategoryDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      categoryName: '',
      selectedCategories: [{name: 'All', id: ''}],
      currentComponent: 'selectCategories', // or createCategory
      savingCategory: false,
    }
  }

  componentDidUpdate(prevProps) {
    const {categories, categoryToAddRecipe} = this.props

    if (prevProps.categories !== categories) {
      if (categoryToAddRecipe && categoryToAddRecipe !== null) {
        const cat = _.find(categories, ['id', categoryToAddRecipe.id])
        if (cat) {
          this.setState({selectedCategories: [{name: 'All', id: ''}, cat]}) // eslint-disable-line react/no-did-update-set-state
        }
      }
    }
  }

  @autobind
  async saveCategory() {
    const {createSuccess, createNotificationFromError, createCategorySuccess} = this.props
    try {
      this.setState({savingCategory: true})
      const payload = {image: '', name: this.state.categoryName, sharedWith: 'private'}
      const cat = await MyKitchenApi.createCategory(payload)
      this.setState(state => {
        const selCats = [...state.selectedCategories, cat.data]
        return {selectedCategories: selCats, currentComponent: 'selectCategories', categoryName: ''}
      })
      createCategorySuccess(cat.data)
      createSuccess('Category created successfully.')
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      this.setState({savingCategory: false})
    }
  }

  @autobind
  updateCategoryName(e) {
    this.setState({categoryName: e.target.value})
  }

  @autobind
  cancelCreateCategory() {
    this.setState({currentComponent: 'selectCategories', categoryName: ''})
  }

  @autobind
  isCategorySelected(category) {
    const {selectedCategories} = this.state

    const index = _.findIndex(selectedCategories, ['id', category.id])
    return index !== -1
  }

  @autobind
  renderSelectedCategories(selectedCategories) {
    const selected = selectedCategories.map(cat => cat.name)
    return selected.join(', ')
  }

  @autobind
  handleChange(event) {
    this.setState({selectedCategories: event.target.value})
  }

  @autobind
  onSave() {
    const {handleSave} = this.props
    const {selectedCategories} = this.state

    handleSave(
      selectedCategories.filter(cat => cat !== 'all-id' && cat.name !== 'All').map(cat => cat.id),
    )
  }

  @autobind
  handleCreateCategory() {
    this.setState({currentComponent: 'createCategory'})
  }

  render() {
    const {open, handleCancel, classes, categories} = this.props
    const {savingCategory, selectedCategories, currentComponent, categoryName} = this.state

    return (
      <Dialog
        data-test-name="select-category-dialog"
        disableBackdropClick
        disableEscapeKeyDown
        open={open}
        onClose={handleCancel}
      >
        <DialogTitle disableTypography style={{paddingBottom: 0, margin: 0}}>
          <Typography variant="title" className={classes.title}>
            Select Category
          </Typography>
        </DialogTitle>
        {currentComponent === 'selectCategories' ? (
          <SelectCategoryComponent
            classes={classes}
            handleChange={this.handleChange}
            renderSelectedCategories={this.renderSelectedCategories}
            selectedCategories={selectedCategories}
            isCategorySelected={this.isCategorySelected}
            onSave={this.onSave}
            handleCancel={handleCancel}
            handleCreateCategory={this.handleCreateCategory}
            categories={categories}
          />
        ) : (
          <CreateCategoryComponent
            classes={classes}
            saveCategory={this.saveCategory}
            cancelCreateCategory={this.cancelCreateCategory}
            updateCategoryName={this.updateCategoryName}
            categoryName={categoryName}
            savingCategory={savingCategory}
          />
        )}
      </Dialog>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {createSuccess, createNotificationFromError, createCategorySuccess},
    dispatch,
  )
}

export default compose(connect(null, mapDispatchToProps), withStyles(styles))(SelectCategoryDialog)

import settings from '../../../config/settings'
import fileAxios from '../api/fileAxios'
import axios from '../api/axios'

export default class MyKitchenApi {
  static createCategory(params) {
    const payload = new FormData()
    payload.append('name', params.name)
    payload.append('image', params.image)
    payload.append('shared_with', params.sharedWith)
    return fileAxios.post(`${settings.apiBaseURL}/categories`, payload)
  }

  static getCategories(userId) {
    return axios.get(`${settings.apiBaseURL}/categories?user=${userId}`)
  }

  static deleteCategory(id) {
    return axios.delete(`${settings.apiBaseURL}/categories/${id}`)
  }

  static updateCategory(id, category) {
    return axios.patch(`${settings.apiBaseURL}/categories/${id}`, category)
  }

  static getRecipesOfCategory(categoryId) {
    return axios.get(`${settings.apiBaseURL}/recipes?categories=${categoryId}`)
  }

  static getCategory(categoryId) {
    return axios.get(`${settings.apiBaseURL}/categories/${categoryId}`)
  }

  static getAllRecipesOfUser(userId) {
    return axios.get(`${settings.apiBaseURL}/recipes?user=${userId}`)
  }
}

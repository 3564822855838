const styles = () => ({
  root: {
    zIndex: 1,
    width: 350,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 10,
    overflowX: 'scroll',
    maxHeight: 575,
  },
  typography: {
    padding: 10,
  },
  divider: {
    marginLeft: 10,
    marginRight: 10,
    width: '95%',
  },
  link: {
    fontWeight: 600,
    color: 'black',
  },
  followBtn: {
    color: 'white',
    fontSize: '14px',
    background: '#589A43',
    fontWeight: 500,
    lineHeight: '22px',
    borderRadius: '34px',
    textTransform: 'none',
  },
  gridItem: {
    paddingTop: 7,
    paddingBottom: 7,
    minHeight: '55px',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
})

export default styles

import {createTheme} from '@material-ui/core/styles'

export default createTheme({
  palette: {
    background: {
      default: '#E4F2E4',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440 + 24,
      xl: 1920,
    },
  },
  typography: {
    useNextVariants: true,
    subtitle1: {
      fontWeight: '300',
    },
    fontFamily: 'Lato, sans-serif',
    fontWeight: '300',
  },
  SvgIcon: {
    width: '2em',
  },
  overrides: {
    MuiPickersDay: {
      current: {
        color: '#589a43',
      },
      isSelected: {
        backgroundColor: '#589a43',
        '&:hover': {
          backgroundColor: '#589a43',
        },
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: '#589a43',
      },
    },
  },
})

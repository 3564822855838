import React from 'react'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import {Grid} from '@material-ui/core'

import Loading from '../../common/Loading'
import {getRecipe} from '../redux/actions'
import CreateRecipe from './CreateRecipe'

class EditRecipe extends React.Component {
  componentDidMount() {
    const {id} = this.props.params
    this.props.getRecipe(id, {
      expand: 'user,creator,ingredient_lists,materials,directions,tags,categories',
    })
  }

  render() {
    const {isFetching} = this.props.recipe

    if (isFetching) {
      return (
        <Grid container>
          <Loading />
        </Grid>
      )
    }

    return <CreateRecipe editing={true} />
  }
}

function mapStateToProps(state) {
  return {
    ...state.recipe,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getRecipe}, dispatch)
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(EditRecipe)

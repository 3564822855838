const styles = theme => ({
  page: {
    padding: '30px 0 0 0',
  },
  authPage: {
    margin: '0 auto',
    padding: '30px 0 0 0',
  },
  title: {
    textAlign: 'center',
    margin: '0 0 40px 0',
  },
  regtitle: {
    fontSize: '50px',
    textAlign: 'center',
    margin: '0 0 40px 0',
  },
  card: {
    maxWidth: '700px',
    margin: '0 auto',
  },
  cardCentered: {
    maxWidth: '700px',
    margin: '0 auto',
    justifyContent: 'center',
    textAlign: 'center',
  },
  cardActions: {
    marginTop: '-50px',
    borderTop: '1px solid #ccc',
    justifyContent: 'center',
  },
  cardContent: {
    padding: '100px',
    [theme.breakpoints.down('xs')]: {
      padding: 20,
    },
  },
  confirmButton: {
    marginTop: '20px',
  },
})

export default styles

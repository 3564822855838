import React from 'react'
import moment from 'moment'

class FromNow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {seconds: 0}
  }

  tick() {
    this.setState(prevState => ({
      seconds: prevState.seconds + 1,
    }))
  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    const {datetime} = this.props
    return <span>{moment(datetime).fromNow()}</span>
  }
}

export default FromNow

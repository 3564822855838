import * as Types from './actions.types'
import * as ErrorUtils from '../../utils/ErrorUtils'
import * as ReduxUtils from '../../utils/ReduxUtils'
import {isTagValid} from '../utils'

function emptyIngredientList() {
  return {
    name: '',
    ingredients: [emptyIngredient()],
  }
}

function emptyIngredient() {
  return {
    ingredient: {title: ''},
    quantity: '',
    unit: 'ounce',
    order: 0,
  }
}

function emptyMaterial() {
  return {
    material: {title: ''},
    quantity: '',
    order: 0,
  }
}

function emptyDirection() {
  return {
    direction: '',
    image: null,
    order: 0,
  }
}

function formattedTag(tag) {
  return {
    id: 0,
    tag: {
      id: 0,
      title: tag.trim(),
    },
  }
}

function createInitialState() {
  return {
    isAutoSaving: false,
    hasUpdatedLocal: false,
    oldRecipe: {},
    recipe: {
      isImportingURL: false,
      hasImportedURL: false,
      importUrl: '',
      isSaving: false,
      isDeleting: false,
      multiplyIngredients: '',
      errors: {},
      title: '',
      source: '',
      description: '',
      cookTime: '',
      prepTime: '',
      image: '',
      difficulty: 'easy',
      cost: '',
      sharedWith: 'public',
      servings: '',
      minServings: '',
      maxServings: '',
      notes: '',
      ingredientLists: [emptyIngredientList()],
      materials: [],
      directions: [emptyDirection()],
      tags: [],
      tagEntry: '',
      tagSuggestions: [],
      isFetching: false,
      shareUrl: '',
    },
  }
}

export default function recipe(state = createInitialState(), action) {
  switch (action.type) {
    case Types.RECIPE_CLEAR:
      return createInitialState()

    case Types.UPDATE_LOCAL:
      if (action.rootKey) {
        return {
          ...state,
          hasUpdatedLocal: true,
          [action.rootKey]: {
            ...state[action.rootKey],
            ...action.patch,
          },
        }
      }
      return {...state, ...action.patch}
    case Types.UPDATE_LOCAL_AT_INDEX: {
      const field = state[action.rootKey][action.fieldKey]
      const currentItem = field[action.index]
      const updatedItem = {...currentItem, ...action.patch}

      const updatedField = ReduxUtils.arrayUpdateAt(field, action.index, updatedItem)

      return {
        ...state,
        [action.rootKey]: {
          ...state[action.rootKey],
          [action.fieldKey]: updatedField,
        },
      }
    }
    case Types.UPDATE_INGREDIENT: {
      const ingredientList = state.recipe.ingredientLists[action.ingredientListIndex || 0]
      const ingredient = ingredientList.ingredients[action.ingredientIndex || 0]

      const updatedIngredient = {...ingredient, ...action.patch}

      const updatedIngredientList = {
        ...ingredientList,
        ingredients: ingredientList.ingredients.map((oldIngredient, index) =>
          index === action.ingredientIndex ? updatedIngredient : oldIngredient,
        ),
      }
      const updatedIngredientLists = state.recipe.ingredientLists.map((oldIngredientList, index) =>
        index === action.ingredientListIndex ? updatedIngredientList : oldIngredientList,
      )

      return {
        ...state,
        recipe: {
          ...state.recipe,
          ingredientLists: updatedIngredientLists,
        },
      }
    }
    case Types.DELETE_LOCAL_AT_INDEX: {
      const field = state[action.rootKey][action.fieldKey]
      return {
        ...state,
        [action.rootKey]: {
          ...state[action.rootKey],
          [action.fieldKey]: [...field.slice(0, action.index), ...field.slice(action.index + 1)],
        },
      }
    }
    case Types.DELETE_INGREDIENT: {
      const ingredientList = state.recipe.ingredientLists[action.ingredientListIndex || 0]

      const updatedIngredientList = {
        ...ingredientList,
        ingredients: ingredientList.ingredients.filter(
          (_, index) => action.ingredientIndex !== index,
        ),
      }

      const updatedIngredientLists = state.recipe.ingredientLists.map((oldIngredientList, index) =>
        index === action.ingredientListIndex ? updatedIngredientList : oldIngredientList,
      )

      return {
        ...state,
        recipe: {
          ...state.recipe,
          ingredientLists: updatedIngredientLists,
        },
      }
    }

    case Types.DELETE_INGREDIENT_LIST: {
      const updatedIngredientLists = state.recipe.ingredientLists.filter(
        (_, index) => action.ingredientListIndex !== index,
      )

      return {
        ...state,
        recipe: {
          ...state.recipe,
          ingredientLists: updatedIngredientLists,
        },
      }
    }

    case Types.CREATE_RECIPE:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          isSaving: true,
        },
      }
    case Types.CREATE_RECIPE_SUCCESS:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          errors: {},
          isSaving: false,
        },
      }
    case Types.CREATE_RECIPE_FAILURE:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          errors: ErrorUtils.getApiErrors(action.errors),
          isSaving: false,
        },
      }
    case Types.DELETE_RECIPE:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          isDeleting: true,
        },
      }
    case Types.DELETE_RECIPE_SUCCESS:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          errors: {},
          isDeleting: false,
        },
      }
    case Types.DELETE_RECIPE_FAILURE:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          errors: ErrorUtils.getApiErrors(action.errors),
          isDeleting: false,
        },
      }
    case Types.AUTO_SAVE_RECIPE:
      return {
        ...state,
        isAutoSaving: true,
      }
    case Types.AUTO_SAVE_RECIPE_SUCCESS:
      return {
        ...state,
        isAutoSaving: false,
        hasUpdatedLocal: false,
        recipe: {
          ...state.recipe,
          modified: action.recipe.modified,
          created: action.recipe.created,
        },
        oldRecipe: {
          ...state.recipe,
        },
      }
    case Types.AUTO_SAVE_RECIPE_FAILURE:
      return {
        ...state,
        isAutoSaving: false,
        hasUpdatedLocal: false,
        recipe: {
          ...state.recipe,
          errors: ErrorUtils.getApiErrors(action.errors),
        },
      }

    case Types.ADD_INGREDIENT_LIST:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          ingredientLists: [...state.recipe.ingredientLists, emptyIngredientList()],
        },
      }

    case Types.ADD_INGREDIENT:
      if (state.recipe.ingredientLists[action.ingredientListIndex]) {
        const updatedIngredientList = state.recipe.ingredientLists[action.ingredientListIndex]
        updatedIngredientList.ingredients = [
          ...updatedIngredientList.ingredients,
          emptyIngredient(),
        ]
        return {
          ...state,
          recipe: {
            ...state.recipe,
            ingredientLists: state.recipe.ingredientLists.map((ingredientList, index) =>
              action.ingredientListIndex === index ? updatedIngredientList : ingredientList,
            ),
          },
        }
      }

      return state

    case Types.ADD_MATERIAL:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          materials: [...state.recipe.materials, emptyMaterial()],
        },
      }

    case Types.ADD_DIRECTION:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          directions: [...state.recipe.directions, emptyDirection()],
        },
      }

    case Types.ADD_TAG:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          tags: isTagValid(action.tag, state.recipe.tags)
            ? [...state.recipe.tags, formattedTag(action.tag)]
            : [...state.recipe.tags],
          tagSuggestions: [],
          tagEntry: '',
        },
      }

    case Types.RECIPE_PUBLISH_FAILURE:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          errors: ErrorUtils.getApiErrors(action.error),
        },
      }
    case Types.GET_RECIPE_FROM_URL:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          ...action.recipe,
          isImportingURL: true,
          hasImportedURL: false,
        },
      }
    case Types.GET_RECIPE_FROM_URL_SUCCESS:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          ...action.recipe,
          servings: getServings(action.recipe),
          isImportingURL: false,
        },
      }
    case Types.GET_RECIPE_FROM_URL_FAILURE:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          errors: ErrorUtils.getApiErrors(action.error),
          isImportingURL: false,
        },
      }
    case Types.GET_RECIPE:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          isFetching: true,
          errors: {},
        },
      }
    case Types.GET_RECIPE_SUCCESS:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          ...action.recipe,
          isFetching: false,
          errors: {},
        },
      }
    case Types.GET_RECIPE_FAILURE:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          isFetching: false,
          errors: ErrorUtils.getApiErrors(action.error),
        },
      }
    case Types.GET_TAG_SUGGESTIONS:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          isFetchingTagSuggestions: true,
        },
      }
    case Types.GET_TAG_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          isFetchingTagSuggestions: false,
          tagSuggestions: action.suggestions,
        },
      }
    case Types.GET_TAG_SUGGESTIONS_FAILURE:
      return {
        ...state,
        recipe: {
          ...state.recipe,
          isFetchingTagSuggestions: false,
        },
      }

    default:
      return state
  }
}

function getServings(recipe) {
  if (recipe.maxServings) {
    return `${recipe.minServings}-${recipe.maxServings}`
  }
  return recipe.minServings
}

export const CHANGE_EMAIL = 'CHANGE_EMAIL'
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS'
export const CHANGE_EMAIL_FAILURE = 'CHANGE_EMAIL_FAILURE'

export const CHANGE_EMAIL_CONFIRM = 'CHANGE_EMAIL_CONFIRM'
export const CHANGE_EMAIL_CONFIRM_SUCCESS = 'CHANGE_EMAIL_CONFIRM_SUCCESS'
export const CHANGE_EMAIL_CONFIRM_FAILURE = 'CHANGE_EMAIL_CONFIRM_FAILURE'

export const CHANGE_EMAIL_VERIFY = 'CHANGE_EMAIL_VERIFY'
export const CHANGE_EMAIL_VERIFY_SUCCESS = 'CHANGE_EMAIL_VERIFY_SUCCESS'
export const CHANGE_EMAIL_VERIFY_FAILURE = 'CHANGE_EMAIL_VERIFY_FAILURE'

export const CHANGE_EMAIL_RESEND_CONFIRM = 'CHANGE_EMAIL_RESEND_CONFIRM'
export const CHANGE_EMAIL_RESEND_CONFIRM_SUCCESS = 'CHANGE_EMAIL_RESEND_CONFIRM_SUCCESS'
export const CHANGE_EMAIL_RESEND_CONFIRM_FAILURE = 'CHANGE_EMAIL_RESEND_CONFIRM_FAILURE'

export const CHANGE_EMAIL_RESEND_VERIFY = 'CHANGE_EMAIL_RESEND_VERIFY'
export const CHANGE_EMAIL_RESEND_VERIFY_SUCCESS = 'CHANGE_EMAIL_RESEND_VERIFY_SUCCESS'
export const CHANGE_EMAIL_RESEND_VERIFY_FAILURE = 'CHANGE_EMAIL_RESEND_VERIFY_FAILURE'

export const CHANGE_EMAIL_CANCEL = 'CHANGE_EMAIL_CANCEL'
export const CHANGE_EMAIL_CANCEL_SUCCESS = 'CHANGE_EMAIL_CANCEL_SUCCESS'
export const CHANGE_EMAIL_CANCEL_FAILURE = 'CHANGE_EMAIL_CANCEL_FAILURE'

export const EMAIL_VERIFY = 'EMAIL_VERIFY'
export const EMAIL_VERIFY_SUCCESS = 'EMAIL_VERIFY_SUCCESS'
export const EMAIL_VERIFY_FAILURE = 'EMAIL_VERIFY_FAILURE'

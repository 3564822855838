import * as Types from './actions.types'

export function updateLocal(patch, rootKey) {
  return {type: Types.UPDATE_LOCAL, patch, rootKey}
}

export function createCategory(params) {
  return {type: Types.CREATE_CATEGORY, params}
}

export function createCategorySuccess(category) {
  return {type: Types.CREATE_CATEGORY_SUCCESS, category}
}

export function createCategoryFailure(error) {
  return {type: Types.CREATE_CATEGORY_FAILURE, error}
}

export function getCategories(userId) {
  return {type: Types.GET_CATEGORIES, userId}
}

export function getCategoriesSuccess(categories) {
  return {type: Types.GET_CATEGORIES_SUCCESS, categories}
}

export function getCategoriesFailure(error) {
  return {type: Types.GET_CATEGORIES_FAILURE, error}
}

export function updateCategory(id) {
  return {type: Types.UPDATE_CATEGORY, id}
}

export function updateCategorySuccess(category) {
  return {type: Types.UPDATE_CATEGORY_SUCCESS, category}
}

export function updateCategoryFailure(error) {
  return {type: Types.UPDATE_CATEGORY_FAILURE, error}
}

export function deleteCategory(id, userId) {
  return {type: Types.DELETE_CATEGORY, id, userId}
}

export function deleteCategorySuccess(category) {
  return {type: Types.DELETE_CATEGORY_SUCCESS, category}
}

export function deleteCategoryFailure(error) {
  return {type: Types.DELETE_CATEGORY_FAILURE, error}
}

export function clearCategory() {
  return {type: Types.CATEGORY_CLEAR}
}

export function setCategoryToAddRecipe(category) {
  return {type: Types.SET_CATEGORY_TO_ADD_RECIPE, category}
}

export function clearCategoryToAddRecipe() {
  return {type: Types.CLEAR_CATEGORY_TO_ADD_RECIPE}
}

import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Button, Grid, withStyles} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import _ from 'lodash'
import ValidatedComponent from '../common/ValidatedComponent.jsx'
import Loading from '../common/Loading.jsx'
import ImageEditor from '../common/ImageEditor.jsx'
import UserUtils from '../utils/UserUtils'
import * as Actions from './redux/actions'
import ShareUtils from '../utils/ShareUtils'
import settings from '../../../config/settings'

import styles, {ProfilePaper, StyledButton, DetailTextField} from './ProfileInfo.styles'
// import RecipesFollowingBoxCategory from './RecipesFollowingBoxCategories'

class ProfileInfo extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showEmail: false,
      email: '',
      /*refLink:
        _.get(settings, 'appBaseURL') + '/signup?referral_code=' + this.props.profile.referralCode,*/
    }

    this.referralLinkInput = null
    this.setReferralRef = element => {
      this.referralLinkInput = element
    }
    this.onCopyClick = this.onCopyClick.bind(this)
    this.onSaveClick = this.onSaveClick.bind(this)
    this.onShareClick = this.onShareClick.bind(this)
    this.showEmail = this.showEmail.bind(this)
    this.getTwitterShareUrl = this.getTwitterShareUrl.bind(this)
    this.getFbShareUrl = this.getFbShareUrl.bind(this)
    this.getGooglePlusShareUrl = this.getGooglePlusShareUrl.bind(this)
    this.getLinkedinShareUrl = this.getLinkedinShareUrl.bind(this)
  }

  componentDidMount() {
    this.props.initProfileEdit()
  }

  onSaveClick() {
    const {profileEdit, saveProfile, isComponentValid} = this.props

    if (isComponentValid()) {
      saveProfile(profileEdit.id, profileEdit)
    }
  }

  onChange(propName, e) {
    this.setState({[propName]: e.target.value})
  }

  showEmail() {
    this.setState(state => ({
      showEmail: !state.showEmail,
    }))
  }

  render() {
    // const {showEmail} = this.state
    const {
      profileEdit,
      updateProfile,
      isEditMode,
      isFetching,
      isSaving,
      toggleProfileEdit,
      follow,
      unfollow,
      classes,
      publicProfile,
    } = this.props

    const profile = publicProfile

    const isLoggedIn = this.props.profile && this.props.profile.id

    const profileBelongsToUser = isLoggedIn && this.props.profile.id === publicProfile.id

    const fullName =
      profile.firstName || profile.lastName
        ? (profile.firstName ? profile.firstName + ' ' : '') +
          (profile.lastName ? profile.lastName : '')
        : profile.username
    const firstName = profile.firstName
      ? profile.firstName
      : profile.username
      ? profile.username
      : 'unnamed user'

    const bio = profile.bio ? profile.bio : 'This user has not yet written a bio.'

    const pageTitle = (profileBelongsToUser ? 'My' : fullName + "'s") + ' Profile'

    let content

    if (isEditMode) {
      content = (
        <React.Fragment>
          <div className={classes.pageTitle}>Edit Profile</div>
          <ProfilePaper elevation={1}>
            <Grid container>
              <Grid item xs={12} sm={4} md={3} className={classes.avatarContainer}>
                <ImageEditor
                  src={profileEdit.avatar ? profileEdit.avatar.fullSize : null}
                  fallbackImage={UserUtils.getAvatarUploadPlaceholderUrl()}
                  disableCrop={true}
                  onReset={() => updateProfile({avatarUpload: null, avatar: null})}
                  onComplete={avatarUpload => updateProfile({avatarUpload})}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={9} className={classes.infoContainer}>
                <Grid container className={classes.bioHeaderContainer}>
                  <Grid item xs={6} className={classes.editProfileHalfRowLeft}>
                    <div className={classes.label}>First Name</div>
                    <DetailTextField
                      variant="outlined"
                      fullWidth
                      value={profileEdit.firstName}
                      onChange={e => updateProfile({firstName: e.target.value})}
                      placeholder="First name"
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.editProfileHalfRowRight}>
                    <div className={classes.label}>Last Name</div>
                    <DetailTextField
                      variant="outlined"
                      fullWidth
                      value={profileEdit.lastName}
                      onChange={e => updateProfile({lastName: e.target.value})}
                      placeholder="Last name"
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.editProfileHalfRowLeft}>
                    <div className={classes.label}>Username*</div>
                    <DetailTextField
                      variant="outlined"
                      fullWidth
                      value={profileEdit.username}
                      placeholder="Username"
                      onChange={e => updateProfile({username: e.target.value})}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.editProfileHalfRowRight}>
                    <div className={classes.label}>Location</div>
                    <DetailTextField
                      variant="outlined"
                      fullWidth
                      value={profileEdit.location}
                      placeholder="Location"
                      onChange={e => updateProfile({location: e.target.value})}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.editProfileFullRow}>
                  <div className={classes.label}>About Me</div>
                  <DetailTextField
                    variant="outlined"
                    multiline
                    rows="7"
                    fullWidth
                    value={profileEdit.bio}
                    placeholder="About Me"
                    onChange={e => updateProfile({bio: e.target.value})}
                  />
                </Grid>
              </Grid>
            </Grid>
          </ProfilePaper>
          <Grid item xs={12} className={classes.saveControls}>
            <Button className={classes.cancelProfileButton} onClick={() => toggleProfileEdit()}>
              Cancel
            </Button>
            <Button className={classes.saveProfileButton} onClick={this.onSaveClick}>
              Update
            </Button>
          </Grid>
        </React.Fragment>
      )
    } else {
      let button
      if (profileBelongsToUser) {
        button = (
          <StyledButton className={classes.editButton} onClick={() => toggleProfileEdit()}>
            Edit
          </StyledButton>
        )
      } else if (isLoggedIn) {
        button = (
          <StyledButton
            className={classes.followButton}
            style={{
              marginBottom: '15px',
            }}
            onClick={() => (profile.userIsFollowing ? unfollow(profile.id) : follow(profile.id))}
          >
            {profile.userIsFollowing ? 'Unfollow' : 'Follow'}
          </StyledButton>
        )
      }
      content = (
        <React.Fragment>
          <div className={classes.pageTitle}>{pageTitle}</div>
          <Grid item xs={12} className={classes.editControls}>
            {button}
          </Grid>
          <ProfilePaper elevation={1}>
            <Grid container>
              <Grid item xs={4} md={3}>
                <div className={classes.avatarContainer}>
                  <img
                    className={classes.avatar}
                    src={UserUtils.getImageUrl(profile)}
                    onError={e => {
                      e.currentTarget.style.display = 'none'
                    }}
                    alt=""
                  />
                </div>
              </Grid>
              <Grid item xs={8} md={3} className={classes.nameLocationMobile}>
                <div className={classes.fullName}>{fullName}</div>
                {profile.location && <div className={classes.location}>{profile.location}</div>}
              </Grid>
              <Grid item xs={12} md={9} className={classes.infoContainer}>
                <Grid container className={classes.bioHeaderContainer}>
                  <Grid item xs={8}>
                    <div className={classes.bioHeader}>About {firstName}</div>
                  </Grid>
                </Grid>
                <p className={classes.bio}>{bio}</p>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4} md={3} className={classes.nameLocationDesktop}>
                <div className={classes.fullName}>{fullName}</div>
                {profile.location && <div className={classes.location}>{profile.location}</div>}
              </Grid>
              {this.props.children}
            </Grid>
          </ProfilePaper>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <div className="row" style={{width: '100%'}}>
          {isFetching || isSaving ? <Loading /> : null}
          {content}
        </div>
      </React.Fragment>
    )
  }

  onCopyClick() {
    this.referralLinkInput.select()
    document.execCommand('copy')
  }

  onShareClick(shareUrl) {
    ShareUtils.showShareWindow(shareUrl)
  }

  getPinterestShareUrl() {
    const shareImage = this.props.publicProfile.avatar.fullSize
    return ShareUtils.getPinterestShareUrl(
      this.state.refLink,
      shareImage,
      `Join me on ${_.get(settings, 'website.title')}! ${this.state.refLink}`,
    )
  }

  getTwitterShareUrl() {
    const tweetText = `Join me on ${_.get(settings, 'website.title')}!`
    return ShareUtils.getTwitterShareUrl(
      this.state.refLink,
      tweetText,
      _.get(settings, 'website.title'),
    )
  }

  getFbShareUrl() {
    return ShareUtils.getFbShareUrl(this.state.refLink)
  }

  getGooglePlusShareUrl() {
    return ShareUtils.getGooglePlusShareUrl(this.state.refLink)
  }

  getLinkedinShareUrl() {
    const title = `${_.get(settings, 'website.title')} - Awesome Apps by TSL`
    const summary = `Join me on ${_.get(settings, 'website.title')}!`
    return ShareUtils.getLinkedinShareUrl(this.state.refLink, title, summary, this.url)
  }

  getTumblrShareUrl() {
    const title = `${_.get(settings, 'website.title')} - Awesome Apps by TSL`
    const shareImage = this.props.publicProfile.avatar.fullSize
    return ShareUtils.getTumblrShareUrl(
      this.state.refLink,
      title,
      _.get(settings, 'website.title'),
      shareImage,
      `Join me on ${_.get(settings, 'website.title')}! ${this.state.refLink}`,
    )
  }

  getEmailShareUrl() {
    let {email} = this.state
    email = email || ''
    return (
      'mailto:' +
      email +
      '?subject=' +
      this.props.publicProfile.firstName +
      ' ' +
      this.props.publicProfile.lastName +
      ` has invited you to join ${_.get(
        settings,
        'website.title',
      )}!&body=Use my referral link to signup: ` +
      this.state.refLink
    )
  }
}

function mapStateToProps(state) {
  return {...state.profile}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

ProfileInfo.displayName = 'ProfileInfo'

ProfileInfo.propTypes = {
  onSave: PropTypes.func,
  validateOnBlur: PropTypes.bool,
  showEdit: PropTypes.bool,
}

ProfileInfo.contextTypes = {
  router: PropTypes.object.isRequired,
}

ProfileInfo.defaultProps = {
  validateOnBlur: true,
  showEdit: true,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  ValidatedComponent,
)(ProfileInfo)

import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import {Link} from 'react-router'
import {Card, CardContent, Typography, Input, Grid, Button, withStyles} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import settings from '../../../config/settings'
import InputValidator from '../common/InputValidator.jsx'
import validatedComponent from '../common/ValidatedComponent.jsx'
import * as Actions from './redux/actions'
import {FacebookAuthButton, TwitterAuthButton} from './SocialAuthButtons.jsx'
import styles from '../common/styles/CardLayout.styles'

class Login extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      canSubmit: false,
    }

    this.popup = null

    this.changeUser = this.changeUser.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.login = this.login.bind(this)
    this.redirectIfAuthed = this.redirectIfAuthed.bind(this)
  }

  login(e) {
    e.preventDefault()

    const {isComponentValid, login} = this.props
    if (isComponentValid()) {
      login(this.state.username, this.state.password)
    }
  }

  componentDidMount() {
    this.redirectIfAuthed(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.redirectIfAuthed(nextProps)
  }

  redirectIfAuthed(props) {
    const {location, token} = props
    if (token) {
      if (location.query.redirectTo) {
        this.context.router.push(location.query.redirectTo)
      } else {
        this.context.router.push('/')
      }
      return true
    }
    return false
  }

  render() {
    const {username, password, canSubmit} = this.state
    const {
      errors,
      socialLoginOAuth1Step1,
      socialLoginOAuth1Step2,
      socialLoginOAuth2,
      provider,
      oauthToken,
      isNoEmailProvidedError,
      oauthTokenSecret,
      classes,
    } = this.props

    return (
      <div className={classes.authPage}>
        <Helmet title="Log In" />
        <Card className={classes.cardCentered}>
          <CardContent className={classes.cardContent}>
            <Typography component="h2" variant="h2" className={classes.title} gutterBottom>
              Log In
            </Typography>
            <form noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <InputValidator
                    fieldName="Email"
                    errors={errors.email}
                    shouldValidateOnBlur={true}
                  >
                    <Input
                      type="email"
                      placeholder="Email*"
                      required
                      value={username}
                      onChange={this.changeUser}
                    />
                  </InputValidator>
                </Grid>

                <Grid item xs={12}>
                  <InputValidator
                    fieldName="Password"
                    errors={errors.password}
                    shouldValidateOnBlur={true}
                  >
                    <Input
                      type="password"
                      name="password"
                      placeholder="Password*"
                      required
                      value={password}
                      onChange={this.changePassword}
                    />
                  </InputValidator>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={this.login}
                    disabled={!canSubmit}
                  >
                    Submit
                  </Button>
                </Grid>

                {_.get(settings, 'website.socialLogins') ? (
                  <Grid item xs={12} hidden={true}>
                    <Typography
                      component="h3"
                      variant="subtitle1"
                      className={classes.title}
                      gutterBottom
                    >
                      Or log in with an existing account
                    </Typography>
                    <Grid container justifyContent="center" spacing={2}>
                      <Grid item xs="auto">
                        <FacebookAuthButton
                          fbAppId={settings.fbAppId}
                          onSendAuthRequest={socialLoginOAuth2.bind(this)}
                          isNoEmailProvidedError={isNoEmailProvidedError && provider === 'facebook'}
                        />
                      </Grid>
                      <Grid item xs="auto">
                        <TwitterAuthButton
                          oauthToken={oauthToken}
                          oauthTokenSecret={oauthTokenSecret}
                          onRetrieveAuthRequestToken={socialLoginOAuth1Step1.bind(this)}
                          onSendAuthRequest={socialLoginOAuth1Step2.bind(this)}
                          isNoEmailProvidedError={isNoEmailProvidedError && provider === 'twitter'}
                        />
                      </Grid>
                    </Grid>
                    {/*<GoogleplusAuthButton isNoEmailProvidedError={isNoEmailProvidedError && provider === 'googleplus'}/>
                    <LinkedinAuthButton isNoEmailProvidedError={isNoEmailProvidedError && provider === 'linkedin'}/>*/}
                  </Grid>
                ) : null}

                <Grid item xs={12}>
                  <div>
                    <Button component={Link} to="/signup">
                      Create an account
                    </Button>
                  </div>
                  <div>
                    <Button component={Link} to="/forgot">
                      Forgot password
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
        <div className="push" />
      </div>
    )
  }

  changeUser(event) {
    const username = event.target.value
    this.setState(prevState => {
      return {
        username,
        canSubmit: prevState.password && username,
      }
    })
  }

  changePassword(event) {
    const password = event.target.value
    this.setState(prevState => {
      return {
        password,
        canSubmit: prevState.username && password,
      }
    })
  }
}

Login.displayName = 'Login'

Login.propTypes = {}

Login.contextTypes = {
  router: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

function mapStateToProps(state) {
  return {...state.auth}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  validatedComponent,
)(Login)

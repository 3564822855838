import React from 'react'
import {Paper, Icon} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import autobind from 'autobind-decorator'
import {Tabs, Tab} from '../common/styles/Tabs'
import ShoppingListWidget from './ShoppingListWidget'
import CalendarWidget from '../calendar/CalendarWidget'

class ShoppingCalendarWidget extends React.Component {
  constructor(props) {
    super(props)
    let {tab} = props
    if (typeof tab === 'undefined') tab = 0
    this.state = {
      activeTab: tab,
    }
  }

  @autobind
  changeTab(e, index) {
    this.setState({activeTab: index})
  }

  render() {
    const {activeTab} = this.state
    const {classes} = this.props

    return (
      <Paper>
        <Tabs value={activeTab} onChange={this.changeTab} variant="fullWidth">
          <Tab
            className={classes.fontStyle}
            icon={
              <Icon className={activeTab === 0 ? classes.tabIconSelected : classes.tabIcon}>
                list
              </Icon>
            }
            label="Shopping List"
          />
          <Tab
            className={classes.fontStyle}
            icon={
              <Icon className={activeTab === 1 ? classes.tabIconSelected : classes.tabIcon}>
                calendar_today
              </Icon>
            }
            label="Calendar"
          />
        </Tabs>
        {activeTab === 0 && <ShoppingListWidget />}
        {activeTab === 1 && <CalendarWidget />}
        <div className={classes.root}>
          <div className={classes.hide}>
            <button className={classes.leftButton}>Share</button>
            <button className={classes.rightButton}>Export</button>
          </div>
        </div>
      </Paper>
    )
  }
}

export default withStyles(theme => ({
  fontStyle: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: '300 !important',
  },
  tabIconSelected: {
    color: `#589a43`,
    verticalAlign: `middle`,
    fontSize: 32,
  },
  tabIcon: {
    color: 'white',
    verticalAlign: `middle`,
    fontSize: 32,
  },
  hide: {
    visibility: 'hidden',
  },
  root: {
    padding: '0 28px 28px 28px',
  },
  rightButton: {
    float: 'right',
    fontSize: '14px',
    paddingLeft: '28px',
    paddingRight: '28px',
    paddingTop: '4px',
    paddingBottom: '4px',
    backgroundColor: '#FFFFFF',
    borderRadius: '34.5px',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    borderWidth: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: 15,
    },
  },
  leftButton: {
    fontSize: '14px',
    paddingLeft: '28px',
    paddingRight: '28px',
    paddingTop: '4px',
    paddingBottom: '4px',
    backgroundColor: '#FFFFFF',
    borderRadius: '34.5px',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    borderWidth: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))(ShoppingCalendarWidget)

import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import {Button, Grid, CardMedia, CircularProgress, Chip} from '@material-ui/core'
// import Rating from 'react-rating'
import autobind from 'autobind-decorator'
import cx from 'classnames'
import {Link} from 'react-router'
import {
  StyledButton,
  RecipeName,
  TitleDescPaper,
  SubTitle,
  Title,
  Desc,
  OverviewCard,
  OverviewCardContent,
  OverviewCardItemTitle,
  OverviewCardItem,
} from './RecipeView.styles'
import {getCost, getDifficulty} from '../utils'
import SelectCategoryDialog from './SelectCategoryDialog'
import SelectCategoryTooltip from './SelectCategoryTooltip'
import AddToCalendarButton from '../../calendar/AddToCalendarButton'
import defaultFood from '../../../assets/defaultFood.png'
import {getCategories} from '../../myKitchen/redux/actions'
import {getRecimee} from '../../profile/redux/actions'
import ShareDialog from './ShareDialog'
import SharedPrivateDialog from './SharedPrivateDialog'
import ButtonWithLoginRequired from '../../common/ButtonWithLoginRequired'

class Overview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showCategorySelectDialogPublish: false,
      showCategorySelectTooltip: false,
      showCategorySelectTooltipMobile: false,
      showShareDialog: false,
      showPrivateShareDialog: false,
    }
  }

  componentDidMount() {
    const {profile, getCategories, getRecimee} = this.props

    if (profile !== null) {
      getCategories(profile.id)
      getRecimee()
    }
  }

  @autobind
  closePrivateShareDialog() {
    this.setState({showPrivateShareDialog: false})
  }

  @autobind
  closeShareDialog() {
    this.setState({showShareDialog: false})
  }

  @autobind
  showShareDialog() {
    const {recipe} = this.props

    if (recipe.sharedWith === 'public') {
      this.setState({showShareDialog: true})
    } else {
      this.setState({showPrivateShareDialog: true})
    }
  }

  @autobind
  closeCategorySelectTooltip() {
    this.setState({showCategorySelectTooltip: false})
  }

  @autobind
  closeCategorySelectTooltipMobile() {
    this.setState({showCategorySelectTooltipMobile: false})
  }

  @autobind
  showCategorySelectTooltip() {
    this.setState({showCategorySelectTooltip: true})
  }

  @autobind
  showCategorySelectTooltipMobile() {
    this.setState({showCategorySelectTooltipMobile: true})
  }

  @autobind
  showCategorySelectDialogPublish() {
    this.setState({showCategorySelectDialogPublish: true})
  }

  @autobind
  handleSaveCategorySelectPublish(selectedCategories) {
    const {onPublish} = this.props
    this.setState({showCategorySelectDialogPublish: false})
    onPublish(selectedCategories)
  }

  @autobind
  handleCancelCategorySelectPublish() {
    this.setState({showCategorySelectDialogPublish: false})
  }

  @autobind
  handleSaveCategorySelectTooltip(selectedCategories) {
    const {onAddToMyRecipes} = this.props
    this.setState({showCategorySelectTooltip: false})
    this.setState({showCategorySelectTooltipMobile: false})
    onAddToMyRecipes(selectedCategories)
  }

  renderAddMyRecipeText() {
    const {alreadyAdded} = this.props

    if (alreadyAdded) {
      return 'Added!'
    }

    return 'Add to My Recipes'
  }

  @autobind
  onEdit() {
    const {recipe} = this.props

    this.context.router.push({pathname: `/edit-recipe/${recipe.id}`})
  }

  render() {
    const {
      alreadyAdded,
      checkingAlreadyAdded,
      classes,
      recipe,
      isPublishing,
      profile,
      isAddingToMyRecipes,
      categoryToAddRecipe,
      categories,
      recimeeId,
    } = this.props
    const {
      showShareDialog,
      showCategorySelectTooltip,
      showCategorySelectTooltipMobile,
      showPrivateShareDialog,
    } = this.state
    const isPublished = recipe.status === 'published'
    const isMine = recipe.user && profile && recipe.user.id === profile.id
    const showPublishButton = !isPublished && isMine
    const disableAddToRecipeButton = alreadyAdded || checkingAlreadyAdded || isAddingToMyRecipes
    const byUser = recipe.creator ? recipe.creator : recipe.user
    const sourceName = recipe.source
    const byName = byUser.firstName + ' ' + byUser.lastName
    const isCreatorDifferentFromSource = sourceName.trim() !== byName.trim()

    const multiply = recipe.multiplyIngredients || 1
    let {prepTime} = recipe
    if (prepTime) prepTime += ' min'

    let {cookTime} = recipe
    if (cookTime) cookTime += ' min'

    const difficulty = getDifficulty(recipe)

    let servings
    if (!!recipe.minServings && !!recipe.maxServings) {
      servings = `${recipe.minServings * multiply}–${recipe.maxServings * multiply} People`
    } else if (recipe.minServings) {
      servings =
        `${recipe.minServings * multiply} ` + (recipe.minServings === 1 ? 'Person' : 'People')
    }

    const cost = getCost(recipe)

    const alwaysShowEverything = true // if false, hide unset fields in Overview area
    return (
      <Grid container className={classes.overview}>
        <Grid container item xs={12}>
          <Grid item xs={12} className={cx(classes.alignRight, classes.recipeActionBar)}>
            {isMine && (
              <Grid item xs={12} className={classes.overviewButtonContainer}>
                <StyledButton
                  className={classes.editShareBtn}
                  onClick={this.onEdit}
                  id="edit-recipe"
                >
                  Edit
                </StyledButton>
              </Grid>
            )}
            {isPublished ? (
              <Grid item xs={4} md={6} className={cx(classes.overviewButtonContainer)}>
                <ButtonWithLoginRequired
                  component={StyledButton}
                  className={classes.editShareBtn}
                  onClick={this.showShareDialog}
                  data-test-name="share"
                  id="share-btn"
                >
                  Share
                </ButtonWithLoginRequired>
              </Grid>
            ) : null}
            {!isMine && (
              <Grid item xs={4} md={6} className={classes.overviewButtonContainer}>
                <SelectCategoryTooltip
                  open={showCategorySelectTooltip}
                  handleTooltipClose={this.closeCategorySelectTooltip}
                  categories={categories}
                  onSave={this.handleSaveCategorySelectTooltip}
                >
                  <ButtonWithLoginRequired
                    component={Button}
                    classes={{
                      root: classes.greenTextButton,
                      disabled: classes.greenTextButtonDisabled,
                    }}
                    disabled={disableAddToRecipeButton}
                    onClick={this.showCategorySelectTooltip}
                    data-test-name="add-to-my-recipe"
                  >
                    {this.renderAddMyRecipeText()}
                  </ButtonWithLoginRequired>
                </SelectCategoryTooltip>
              </Grid>
            )}
            <Grid item xs={4} className={classes.overviewButtonContainer}>
              <AddToCalendarButton className={classes.editShareBtn} recipe={recipe} />
            </Grid>
            {showPublishButton && (
              <div className={classes.overviewButtonContainer}>
                <Button
                  className={classes.greenTextButton}
                  onClick={this.showCategorySelectDialogPublish}
                  id="publishButton"
                >
                  {isPublishing && <CircularProgress size={24} />} Publish
                </Button>
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.titleDesc}>
              <TitleDescPaper elevation={1} className={classes.overflowTitle}>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    {recipe?.titleLink ? (
                      <a href={recipe?.titleLink} target="_blank" rel="noopener noreferrer">
                        <RecipeName variant="h3">{recipe.title}</RecipeName>
                      </a>
                    ) : (
                      <RecipeName variant="h3">{recipe.title}</RecipeName>
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <SubTitle variant="body2" style={{wordBreak: 'break-all'}}>
                      by:{' '}
                      {
                        <Link
                          to={`/users/${byUser.id}`}
                          style={{textDecoration: 'none', color: 'inherit'}}
                        >
                          <span className={classes.recipeBy}>{byName}</span>
                        </Link>
                      }
                    </SubTitle>
                    {isCreatorDifferentFromSource && (
                      <SubTitle variant="body2" style={{wordBreak: 'break-all', fontSize: 12}}>
                        Source:{' '}
                        {recipe.sourceLink ? (
                          <a
                            href={recipe.sourceLink}
                            style={{textDecoration: 'none', color: 'inherit'}}
                          >
                            <span className={classes.recipeSource}>{sourceName}</span>
                          </a>
                        ) : (
                          <span
                            className={classes.recipeSource}
                            style={{textDecoration: 'none', color: 'inherit'}}
                          >
                            {sourceName}
                          </span>
                        )}
                      </SubTitle>
                    )}
                  </Grid>
                  {/*                   <Grid item xs={12} sm={4} className={classes.ratingBox}> */}
                  {/*                     div className={classes.ratingContainer}> */}
                  {/*                       <Rating */}
                  {/*                         className={classes.rating} */}
                  {/*                         emptySymbol="fa fa-star-o fa-2x" */}
                  {/*                         fullSymbol="fa fa-star fa-2x" */}
                  {/*                         initialRating={4} */}
                  {/*                         readonly={true} */}
                  {/*                       /> */}
                  {/*                     </div> */}
                  {/*                   </Grid> */}
                  <Grid item xs={12}>
                    <div className={cx(classes.tagChipAlign, classes.titleTagHeight)}>
                      {recipe.tags.map(tag => (
                        <Link to={`/search?q=${tag.tag.title}`} style={{textDecoration: 'none'}}>
                          <Chip
                            clickable
                            key={tag.id}
                            label={tag.tag.title}
                            className={classes.tagChip}
                          />
                        </Link>
                      ))}
                    </div>
                  </Grid>
                </Grid>
              </TitleDescPaper>
            </div>
            <div className={classes.descContainer}>
              <TitleDescPaper elevation={1}>
                <Title variant="h2">Description</Title>
                <Desc variant="body2" dangerouslySetInnerHTML={{__html: recipe.description}} />
              </TitleDescPaper>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.overviewCard}>
              <OverviewCard>
                <CardMedia
                  component="img"
                  alt={recipe.title}
                  image={recipe.image === null ? defaultFood : recipe.image}
                  title={recipe.title}
                  className={classes.overviewCardMedia}
                  id="overview-recipe-image"
                />
                {(prepTime ||
                  cookTime ||
                  difficulty ||
                  servings ||
                  cost ||
                  alwaysShowEverything) && (
                  <OverviewCardContent>
                    <Grid container>
                      {(prepTime || cookTime || alwaysShowEverything) && (
                        <Grid item sm={3} xs={3}>
                          <OverviewCardItemTitle gutterBottom variant="h4">
                            Time
                          </OverviewCardItemTitle>
                          {(prepTime || alwaysShowEverything) && (
                            <OverviewCardItem variant="body2">
                              Prep: {recipe.prepTime || 'N/A'}
                            </OverviewCardItem>
                          )}
                          {(cookTime || alwaysShowEverything) && (
                            <OverviewCardItem variant="body2">
                              Cook: {recipe.cookTime || 'N/A'}
                            </OverviewCardItem>
                          )}
                        </Grid>
                      )}
                      {(difficulty || alwaysShowEverything) && (
                        <Grid item sm={3} xs={3}>
                          <OverviewCardItemTitle gutterBottom variant="h4">
                            Difficulty
                          </OverviewCardItemTitle>
                          <OverviewCardItem variant="body2">{difficulty || 'N/A'}</OverviewCardItem>
                        </Grid>
                      )}
                      {(servings || alwaysShowEverything) && (
                        <Grid item sm={3} xs={3}>
                          <OverviewCardItemTitle gutterBottom variant="h4">
                            Servings
                          </OverviewCardItemTitle>
                          <OverviewCardItem variant="body2">{servings || 'N/A'}</OverviewCardItem>
                        </Grid>
                      )}
                      {(cost || alwaysShowEverything) && (
                        <Grid item sm={3} xs={3}>
                          <OverviewCardItemTitle gutterBottom variant="h4">
                            Cost
                          </OverviewCardItemTitle>
                          <OverviewCardItem variant="body2">{cost || 'N/A'}</OverviewCardItem>
                        </Grid>
                      )}
                    </Grid>
                  </OverviewCardContent>
                )}
              </OverviewCard>
            </div>
          </Grid>
          <Grid
            item
            justifyContent="center"
            xs={12}
            className={cx(classes.alignRight, classes.recipeActionBarMobile)}
          >
            {isMine && (
              <Grid item xs={6} className={classes.overviewButtonContainer}>
                <StyledButton
                  className={classes.editShareBtn}
                  onClick={this.onEdit}
                  id="edit-recipe"
                >
                  Edit
                </StyledButton>
              </Grid>
            )}
            {isPublished ? (
              <Grid item xs={6} md={6} className={classes.overviewButtonContainer}>
                <ButtonWithLoginRequired
                  component={StyledButton}
                  className={classes.editShareBtn}
                  onClick={this.showShareDialog}
                  data-test-name="share"
                  id="share-btn"
                >
                  Share
                </ButtonWithLoginRequired>
              </Grid>
            ) : null}
            {!isMine && (
              <Grid item xs={6} md={6} className={classes.overviewButtonContainer}>
                <SelectCategoryTooltip
                  open={showCategorySelectTooltipMobile}
                  handleTooltipClose={this.closeCategorySelectTooltipMobile}
                  categories={categories}
                  onSave={this.handleSaveCategorySelectTooltip}
                  className={classes.selectCategoryTooltipMobile}
                >
                  <ButtonWithLoginRequired
                    component={Button}
                    classes={{
                      root: classes.greenTextButton,
                      disabled: classes.greenTextButtonDisabled,
                    }}
                    disabled={disableAddToRecipeButton}
                    onClick={this.showCategorySelectTooltip}
                    data-test-name="add-to-my-recipe"
                  >
                    {this.renderAddMyRecipeText()}
                  </ButtonWithLoginRequired>
                </SelectCategoryTooltip>
              </Grid>
            )}
            <Grid item xs={6} className={classes.overviewButtonContainer}>
              <AddToCalendarButton className={classes.editShareBtn} recipe={recipe} />
            </Grid>
            {showPublishButton && (
              <div className={classes.overviewButtonContainer}>
                <Button
                  className={classes.greenTextButton}
                  onClick={this.showCategorySelectDialogPublish}
                  id="publishButton"
                >
                  {isPublishing && <CircularProgress size={24} />} Publish
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.descContainerMobile}>
          <TitleDescPaper elevation={1}>
            <Title variant="h2">Description</Title>
            <Desc variant="body2" dangerouslySetInnerHTML={{__html: recipe.description}} />
          </TitleDescPaper>
        </Grid>
        <SelectCategoryDialog
          open={this.state.showCategorySelectDialogPublish}
          handleCancel={this.handleCancelCategorySelectPublish}
          handleSave={this.handleSaveCategorySelectPublish}
          categories={categories}
          categoryToAddRecipe={categoryToAddRecipe}
        />
        <ShareDialog
          onClose={this.closeShareDialog}
          recipe={recipe}
          open={showShareDialog}
          handleCancel={this.closeShareDialog}
        />
        <SharedPrivateDialog
          open={showPrivateShareDialog}
          handleClose={this.closePrivateShareDialog}
        />
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  return {
    ...state.profile,
    categories: state.myKitchen.categories.results,
    recimeeId: state.profile.recimeeId,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getCategories, getRecimee}, dispatch)
}

Overview.contextTypes = {
  router: PropTypes.object.isRequired,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Overview)

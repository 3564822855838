import * as Types from './actions.types'
import * as ErrorUtils from '../../utils/ErrorUtils'

function createInitialState() {
  return {
    categories: {
      isLoading: false,
      errors: {},
      results: [],
    },
    category: {
      isSaving: false,
      errors: {},
      name: '',
      image: '',
      sharedWith: '',
    },
    categoryToAddRecipe: null,
  }
}

export default function myKitchen(state = createInitialState(), action) {
  switch (action.type) {
    case Types.CATEGORY_CLEAR:
      return createInitialState()

    case Types.UPDATE_LOCAL:
      if (action.rootKey) {
        return {
          ...state,
          [action.rootKey]: {
            ...state[action.rootKey],
            ...action.patch,
          },
        }
      }
      return {...state, ...action.patch}

    case Types.CREATE_CATEGORY:
      return {
        ...state,
        category: {
          ...state.category,
          isSaving: true,
        },
      }
    case Types.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          results: [...state.categories.results, action.category],
        },
      }
    case Types.CREATE_CATEGORY_FAILURE:
      return {
        ...state,
        category: {
          ...state.category,
          errors: ErrorUtils.getApiErrors(action.error),
          isSaving: false,
        },
      }

    case Types.GET_CATEGORIES:
      return {
        ...state,
        categories: {
          ...state.categories,
          isLoading: true,
        },
      }
    case Types.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: {
          ...state.categories,
          ...action.categories,
          errors: {},
          isLoading: false,
        },
      }
    case Types.GET_CATEGORIES_FAILURE:
      return {
        ...state,
        categories: {
          ...state.categories,
          errors: ErrorUtils.getApiErrors(action.error),
          isLoading: false,
        },
      }

    case Types.UPDATE_CATEGORY:
      return {
        ...state,
        category: {
          ...state.category,
          isSaving: true,
        },
      }
    case Types.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        category: {
          ...state.category,
          errors: {},
          isSaving: false,
        },
      }
    case Types.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        category: {
          ...state.category,
          errors: ErrorUtils.getApiErrors(action.error),
          isSaving: false,
        },
      }

    case Types.DELETE_CATEGORY:
      return {
        ...state,
        category: {
          ...state.category,
          isSaving: true,
        },
      }
    case Types.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        category: {
          ...state.category,
          errors: {},
          isSaving: false,
        },
      }
    case Types.DELETE_CATEGORY_FAILURE:
      return {
        ...state,
        category: {
          ...state.category,
          errors: ErrorUtils.getApiErrors(action.error),
          isSaving: false,
        },
      }
    case Types.SET_CATEGORY_TO_ADD_RECIPE:
      return {
        ...state,
        categoryToAddRecipe: action.category,
      }
    case Types.CLEAR_CATEGORY_TO_ADD_RECIPE:
      return {
        ...state,
        categoryToAddRecipe: null,
      }

    default:
      return state
  }
}

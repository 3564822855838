import React from 'react'
import PropTypes from 'prop-types'
import autobind from 'autobind-decorator'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {IconButton} from '@material-ui/core'
import ButtonWithLoginRequired from '../common/ButtonWithLoginRequired'
import {addShoppingItem} from './redux/actions'

class AddIngredientButton extends React.Component {
  @autobind
  onClick(e) {
    e.preventDefault()
    const {ingredientId, quantity, unit, index} = this.props
    this.props.addShoppingItem(ingredientId, quantity, unit, index)
  }

  render() {
    const {isAdding, ...others} = this.props
    return (
      <ButtonWithLoginRequired
        component={IconButton}
        isLoading={isAdding}
        onClick={this.onClick}
        data-test-name="add-ingredient-to-cart"
        {...others}
      >
        <i className="fa fa-cart-plus fa-fw" />
      </ButtonWithLoginRequired>
    )
  }
}

AddIngredientButton.displayName = 'AddIngredientButton'

AddIngredientButton.propTypes = {
  ingredientId: PropTypes.number.isRequired,
  isAdding: PropTypes.bool.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({addShoppingItem}, dispatch)
}

export default compose(connect(null, mapDispatchToProps))(AddIngredientButton)

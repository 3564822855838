import {Tooltip, IconButton} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useState} from 'react'
import Cropper from 'react-easy-crop'
import {faCheck, faWindowClose} from '@fortawesome/free-solid-svg-icons'

function ImageCropper({image, onCropDone, onCropCancel}) {
  const [crop, setCrop] = useState({x: 0, y: 0})
  const [zoom, setZoom] = useState(1)
  const [croppedArea, setCroppedArea] = useState(null)

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels)
  }

  return (
    <div>
      <div style={{position: 'relative', height: 300}}>
        <Cropper
          image={image}
          aspect={16 / 9}
          crop={crop}
          zoom={zoom}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
        />
      </div>

      <div style={{display: 'flex', justifyContent: 'flex-end', gap: 8, marginTop: 4}}>
        <Tooltip title="Cancel">
          <IconButton disableRipple size="small" onClick={onCropCancel}>
            <FontAwesomeIcon color="red" icon={faWindowClose} />
          </IconButton>
        </Tooltip>

        <Tooltip title="Confirm">
          <IconButton
            disableRipple
            size="small"
            onClick={() => {
              onCropDone(croppedArea)
            }}
          >
            <FontAwesomeIcon color="green" icon={faCheck} />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  )
}

export default ImageCropper

import parse from 'url-parse'
import settings from '../../../../config/settings'

const pageSize = settings.apiPageSize

const initialState = {
  pageCount: 0,
  count: 0,
  page: 0,
}

export default function paginate({actionTypes}) {
  if (!Array.isArray(actionTypes) || actionTypes.length !== 4) {
    throw new Error('Expected actionTypes to be an array of four elements.')
  }
  if (!actionTypes.every(t => typeof t === 'string')) {
    throw new Error('Expected actionTypes to be strings.')
  }

  const [requestType, successType, failureType, resetType] = actionTypes

  return function updatePagination(state = {...initialState}, action) {
    switch (action.type) {
      case requestType:
        return {
          ...state,
          isFetching: true,
        }
      case successType: {
        const {previous, next, count} = action.payload

        return {
          ...state,
          isFetching: false,
          count,
          page: getCurrentPage(previous, next),
          pageCount: Math.ceil(count / pageSize),
        }
      }
      case failureType:
        return {
          ...state,
          isFetching: false,
        }
      case resetType:
        return {...state, ...initialState}
      default:
        return state
    }
  }

  function getCurrentPage(prevUrl, nextUrl) {
    let page
    if (nextUrl) {
      const url = parse(nextUrl, true)
      page = Number(url.query.page) - 1
    } else if (prevUrl) {
      const url = parse(prevUrl, true)
      page = url.query.page ? Number(url.query.page) + 1 : 2
    }

    return page || 1
  }
}

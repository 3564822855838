import React from 'react'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import {Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import ShoppingCalendarWidget from '../shoppingList/ShoppingCalendarWidget'

const styles = theme => ({
  noResult: {
    padding: theme.spacing(3),
  },
  root: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 11,
      paddingRight: 11,
    },
  },
})

class ShoppingListMobile extends React.Component {
  render() {
    const {profile, classes} = this.props

    const userView = (
      <React.Fragment>
        <Grid container className={classes.root}>
          <Grid item xs={12} md={4}>
            {profile && <ShoppingCalendarWidget tab={0} />}
          </Grid>
        </Grid>
      </React.Fragment>
    )

    return userView
  }
}

ShoppingListMobile.displayName = 'ShoppingListMobile'

function mapStateToProps(state) {
  return {...state.home, ...state.profile}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(ShoppingListMobile)

import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import autobind from 'autobind-decorator'

import {Link} from 'react-router'
import Card from '@material-ui/core/Card/Card'
import CardActionArea from '@material-ui/core/CardActionArea/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia/CardMedia'
import CardContent from '@material-ui/core/CardContent/CardContent'
import Loading from '../common/Loading'
import categoryDefault from '../../assets/categoryDefault.png'
import MyKitchenApi from '../myKitchen/api'
import styles, {RecipeFollowingBoxCard, DescNotePaper, NotesPaper} from './ProfileInfo.styles'
import {ListIcon, UsersIcon} from './RecipesFollowingBoxCategories'
import {createNotificationFromError} from '../common/redux/actions.notifications.js'
import {Tabs, Tab} from '../common/styles/Tabs'
import UsersFollowing from './UsersFollowing'
import {getCategoriesOfProfile, getPublicProfile} from './redux/actions'

class RecipesFollowingBoxCategory extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      recipes: [],
      isLoadingR: false,
      isLoadingC: false,
      category: {},
      value: 0,
    }
  }

  componentDidMount() {
    if (this.props.params.categoryId === 'all') {
      this.setState({category: {name: 'All'}})
      this.loadAllRecipes(this.props.params.id)
    } else {
      this.loadRecipes(this.props.params.categoryId)
      this.loadCategory(this.props.params.categoryId)
    }
    this.props.getCategoriesOfProfile(this.props.params.id)
    if (!this.props.publicProfile.following) this.props.getPublicProfile(this.props.params.id)
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.params.categoryId !== nextProps.params.categoryId) {
      if (this.props.params.categoryId === 'all') {
        this.setState({category: {name: 'All'}})
        this.loadAllRecipes(this.props.profile.id)
      } else {
        this.loadRecipes(this.props.params.categoryId)
        this.loadCategory(this.props.params.categoryId)
      }
    }
  }

  @autobind
  handleChange(event, value) {
    this.setState({value})
  }

  @autobind
  loadAllRecipes(userId) {
    const {createNotificationFromError} = this.props
    this.setState({isLoadingR: true}, async () => {
      try {
        const response = await MyKitchenApi.getAllRecipesOfUser(userId)
        this.setState({recipes: response, isLoadingR: false})
      } catch (e) {
        createNotificationFromError(e)
      }
    })
  }

  @autobind
  loadRecipes(categoryId) {
    const {createNotificationFromError} = this.props
    this.setState({isLoadingR: true}, async () => {
      try {
        const response = await MyKitchenApi.getRecipesOfCategory(categoryId)
        this.setState({recipes: response, isLoadingR: false})
      } catch (e) {
        createNotificationFromError(e)
      }
    })
  }

  @autobind
  loadCategory(categoryId) {
    const {createNotificationFromError} = this.props
    this.setState({isLoadingC: true}, async () => {
      try {
        const response = await MyKitchenApi.getCategory(categoryId)
        this.setState({category: response, isLoadingC: false})
      } catch (e) {
        createNotificationFromError(e)
      }
    })
  }

  render() {
    const {recipes, isLoadingR, isLoadingC, category} = this.state
    const {classes, params, publicProfile, profile} = this.props
    if (!recipes || isLoadingR || isLoadingC) {
      return <Loading />
    }
    return (
      <Grid xs={12} md={9} item>
        <div className={classes.recipeFollowingBoxCard}>
          <RecipeFollowingBoxCard>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              variant="fullWidth"
              textColor={this.state.value === 0 ? 'inherit' : '#000000'}
              className={classes.tabs}
            >
              <Tab
                fullWidth={false}
                icon={
                  <ListIcon
                    className={
                      this.state.value === 0 ? classes.tabListIconSelected : classes.tabListIcon
                    }
                  />
                }
                label="Recipes"
              />
              <Tab
                fullWidth={false}
                icon={
                  <UsersIcon
                    className={
                      this.state.value === 0 ? classes.tabUsersIcon : classes.tabUsersIconSelected
                    }
                  />
                }
                label="Following"
              />
            </Tabs>
          </RecipeFollowingBoxCard>
          {this.state.value === 0 ? (
            <DescNotePaper>
              <div className={classes.overFlow}>
                <Typography variant="h5" component="h3" className={classes.titleCategories}>
                  Category - {category.name}
                </Typography>
                {recipes.length === 0 ? <p> User has no Recipes</p> : null}
                <Grid container>
                  {recipes.map((data, id) => {
                    return (
                      <Grid item xs={6} md={4} key={id} className={classes.paddingGridSubPaper}>
                        <Card>
                          <CardActionArea
                            className={classes.card}
                            component={Link}
                            to={`/recipe/${data.id}/${data.slug}`}
                          >
                            {data.image !== null ? (
                              <CardMedia
                                component="img"
                                className={classes.media}
                                height="140"
                                image={data.image}
                              />
                            ) : (
                              <CardMedia
                                component="img"
                                className={classes.media}
                                height="140"
                                src={categoryDefault}
                              />
                            )}
                            <CardContent className={classes.cardContent}>
                              <Typography gutterBottom className={classes.cardTitle}>
                                {data.title}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    )
                  })}
                </Grid>
              </div>
            </DescNotePaper>
          ) : (
            <NotesPaper className={classes.cardFollowingRoot}>
              <div className={classes.overFlow}>
                <UsersFollowing
                  classes={classes}
                  users={publicProfile.following}
                  shouldShowFollow={profile && publicProfile.id === profile.id}
                  params={params}
                />
              </div>
            </NotesPaper>
          )}
        </div>
      </Grid>
    )
  }
}

RecipesFollowingBoxCategory.displayName = 'RecipesFollowingBoxCategory'

function mapStateToProps(state) {
  return {
    ...state.profile,
    categories: state.profile.categories,
  }
}

RecipesFollowingBoxCategory.propTypes = {
  classes: PropTypes.object.isRequired,
}

RecipesFollowingBoxCategory.contextTypes = {
  router: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {getCategoriesOfProfile, getPublicProfile, createNotificationFromError},
    dispatch,
  )
}
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(RecipesFollowingBoxCategory)

import settings from '../../../config/settings'
import axios from '../api/axios'

const {apiBaseURL} = settings

export default class ArticleApi {
  static getArticle(id, params) {
    return axios.get(`${apiBaseURL}/articles/${id}`, {params})
  }
}

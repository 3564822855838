import React from 'react'
import {Button, Paper, Input, Card} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'

const styles = theme => ({
  avatarContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  avatar: {
    width: '100%',
  },
  editControls: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      marginBottom: 20,
    },
  },
  saveControls: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      justifyContent: 'center',
    },
  },
  infoContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px 20px',
    },
  },
  infoField: {
    width: '100%',
    margin: theme.spacing(1),
  },
  bioHeaderContainer: {
    borderTop: '2px solid #589a43',
    paddingTop: 10,
    [theme.breakpoints.down('xs')]: {
      width: '98%',
    },
  },
  bioHeader: {
    fontFamily: 'Literata',
    fontSize: 24,
    fontWeight: '400',

    lineHeight: `32px`,
  },
  bio: {
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: 16,
    lineHeight: '24px',
    whiteSpace: 'pre-wrap',
  },
  nameLocationMobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginTop: 12,
      wordWrap: 'break-word',
      paddingBottom: 20,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: 12,
      wordWrap: 'break-word',
      paddingBottom: 20,
    },
  },
  nameLocationDesktop: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  fullName: {
    fontFamily: 'Literata',
    fontSize: 27,
    fontWeight: '400',
    lineHeight: `36px`,
    paddingLeft: 24,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
      width: '98%',
      paddingLeft: 0,
    },
    [`${theme.breakpoints.down('xs')} and (orientation: landscape)`]: {
      marginTop: '-190',
    },
  },
  location: {
    fontFamily: 'Lato',
    fontSize: 12,
    fontWeight: '300',

    lineHeight: '14px',
    marginTop: 14,
    marginBottom: 14,
    paddingLeft: 24,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      paddingLeft: 0,
    },
  },
  buttonEdit: {
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      float: 'none',
    },
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      float: 'none',
    },
  },
  page: {
    margin: '0 auto',
    width: `100%`,
  },
  pageTitle: {
    fontFamily: 'Literata',
    fontSize: 34,
    fontWeight: '400',

    lineHeight: `48px`,
    marginTop: 20,
    marginBottom: 36,
    [theme.breakpoints.down('xs')]: {
      fontSize: 30,
      marginBottom: 16,
      marginTop: 0,
      margin: '0 auto',
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
      marginBottom: 16,
      marginTop: 0,
      margin: '0 auto',
      textAlign: 'center',
    },
  },
  labelSubMobile: {
    display: 'none',
    height: 21,
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: 18,
    lineHeight: '21px',
    marginBottom: 12,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  labelSub: {
    height: 21,
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: 18,
    lineHeight: '21px',
    marginBottom: 12,
  },
  label: {
    height: 21,
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: 18,
    lineHeight: '21px',
    marginBottom: 12,
  },
  editProfileHalfRowLeft: {
    marginTop: 12,
    paddingRight: 12,
    marginBottom: 6,
  },
  editProfileHalfRowRight: {
    marginTop: 12,
    paddingLeft: 12,
    marginBottom: 6,
  },
  editProfileFullRow: {
    marginTop: 12,
    marginBottom: 6,
  },
  saveProfileButton: {
    color: '#FFF',
    fontFamily: 'Lato',
    fontSize: '24px',
    fontWeight: '300',

    lineHeight: '29px',
    textAlign: 'center',
    textTransform: 'none',
    borderRadius: '34.5px',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    backgroundColor: '#589a43',
    width: '280px',
    height: '43px',
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      width: '202px',
      height: '35px',
      fontSize: '18px',
      '&:hover': {
        background: '#589A43',
        color: '#FFFFFF',
      },
    },
  },
  cancelProfileButton: {
    color: '#589a43',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '24px',
    lineHeight: '29px',
    textAlign: 'center',
    textTransform: 'none',
    borderRadius: '34.5px',
    borderColor: '#589a43',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    backgroundColor: '#FFF',
    width: '140px',
    height: '43px',
    padding: 0,
    marginRight: theme.spacing.unit,
    [theme.breakpoints.down('xs')]: {
      width: '202px',
      height: '35px',
      fontSize: '18px',
    },
  },
  accountInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  subscriptionMobile: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      height: 51,
      border: '1.5px solid #589a43',
      borderRadius: '3px',
      backgroundColor: '#589a43',
      boxShadow: '0 1px 10px 0 rgba(0,0,0,0.19)',
      color: '#FFFFFF',
      fontFamily: 'Lato',
      fontSize: 24,
      fontWeight: '300',
      lineHeight: '49px',
      textAlign: 'center',
      marginBottom: 12,
    },
  },
  subscription: {
    height: 51,
    border: '1.5px solid #589a43',
    borderRadius: '3px',
    backgroundColor: '#589a43',
    boxShadow: '0 1px 10px 0 rgba(0,0,0,0.19)',
    color: '#FFFFFF',
    fontFamily: 'Lato',
    fontSize: 24,
    fontWeight: '300',
    lineHeight: '49px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
    },
    [theme.breakpoints.down(900)]: {
      marginBottom: 12,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
  smallButtonContainer: {
    width: '100%',
    textAlign: 'center',
  },
  editButtonContainer: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  accountContainer: {
    margin: 16,
  },
  inputValidator: {
    marginBottom: 12,
  },
  recipeFollowingBoxCard: {
    margin: '0',
  },
  listIcon: {
    height: `24px`,
    width: `21px`,
    color: `#D8D8D8`,
    fontSize: `24px`,
    top: `18px`,
    left: `200px`,
    position: 'relative',
    cursor: `pointer`,
  },
  tabUsersIcon: {
    color: `white`,
    fontSize: '32px',
    verticalAlign: `middle`,
  },
  tabListIcon: {
    color: `white`,
    width: `32px`,
    verticalAlign: `middle`,
  },
  tabUsersIconSelected: {
    color: `#589a43`,
    fontSize: '32px',

    verticalAlign: `middle`,
  },
  tabListIconSelected: {
    color: `#589a43`,
    width: `32px`,
    verticalAlign: `middle`,
  },
  followingLink: {
    textDecoration: 'none',
    width: '70%',
  },
  paddingGridSubPaper: {
    padding: `15px 5px`,
  },
  media: {
    height: `62%`,
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      height: '65%',
    },
  },
  followingAvatar: {
    height: '72px !important',
    width: '72px !important',
    borderRadius: '50%',
    [theme.breakpoints.down('xs')]: {
      height: '59px !important',
      width: '59px !important',
      marginTop: 14,
    },
  },
  card: {
    height: `235px`,
    [theme.breakpoints.down('xs')]: {
      height: 200,
    },
  },
  cardFollowing: {
    height: `80px`,
    width: '100%',
  },
  cardContent: {
    height: `38%`,
    padding: '10px',
  },
  cardTitle: {
    color: `#000000`,
    fontFamily: 'Literata',
    fontSize: `27px`,
    lineHeight: `36px`,
    fontWeight: '400',
    textOverflow: `ellipsis`,
    maxHeight: `100%`,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      maxWidth: '155px',
      fontSize: '20px',
    },
  },
  cardName: {
    color: `#000000`,
    fontFamily: 'Literata',
    fontSize: `21px`,
    letterSpacing: `1px`,
    lineHeight: `32px`,
    fontWeight: '400',
    marginLeft: '16px',
    overflow: `hidden`,
    textOverflow: `ellipsis`,
    maxHeight: `100%`,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  overFlow: {overflow: `auto`, maxHeight: `400px`},
  noOverFlow: {overflow: `hidden`},
  cardFollowingRoot: {width: '100% !important', overflow: `hidden`},
  followButton: {
    background: '#FFFFFF',
    color: '#589A43',
    fontSize: '18px',
    lineHeight: '22px',
    height: '34px',
    '&:hover': {
      background: '#589A43',
      color: '#FFFFFF',
    },
  },
  unfollowButton: {
    color: '#FFFFFF',
    background: '#589A43',
    fontSize: '18px',
    lineHeight: '22px',
    height: '34px',
    minWidth: '150px',
    width: 'auto',
    margin: 'auto',
    '&:hover': {
      background: '#589A43',
      color: '#FFFFFF',
    },
  },
  unfollowUserPadding: {
    padding: 20,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  padding20: {
    padding: 20,
  },
  padding10Name: {
    padding: 10,
  },
  padding5: {
    padding: 5,
  },
  titleCategories: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: `24px`,
    fontFamily: 'Literata',
    fontWeight: 400,
    lineHeight: `32px`,
  },
  editButton: {
    width: 192,
    marginBottom: 20,
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto',
      marginBottom: 20,
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      marginBottom: 20,
    },
  },
  updateButton: {
    float: 'right',
    marginTop: 16,
    [theme.breakpoints.down('xs')]: {
      float: 'none',
      marginRight: '10px',
    },
    [theme.breakpoints.down('sm')]: {
      float: 'none',
      marginRight: '10px',
    },
  },
  updateButtonContainer: {
    width: '100%',
    textAlign: 'center',
  },
})

export const ProfilePaper = withStyles(theme => ({
  root: {
    padding: '20px',
    borderRadius: '3px',
    boxShadow: '0 1px 10px 0 rgba(0,0,0,0.19)',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
}))(Paper)

export const StyledButton = withStyles({
  root: {
    color: '#000',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    textTransform: 'none',
    borderRadius: '34.5px',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    backgroundColor: '#fff',
    width: '176px',
    height: '23px',
    padding: 0,
    marginTop: 8,
  },
})(Button)

export const SmallButton = withStyles({
  root: {
    color: '#000',
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    textTransform: 'none',
    borderRadius: '34.5px',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    backgroundColor: '#fff',
    width: '192px',
    height: '23px',
    padding: 0,
    marginTop: 4,
    marginBottom: 24,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'inline-block',
  },
})(Button)

export const UpdateButton = withStyles({
  root: {
    width: 280,
    height: 43,
    backgroundColor: '#589A43',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.12)',
    borderRadius: '34.5px',
    color: 'white',
    fontFamily: 'Lato',
    fontSize: '24px',
    fontWeight: 300,
    lineHeight: '29px',
    textTransform: 'none',
  },
})(Button)

export const DetailTextField = withStyles({
  root: {
    marginTop: 0,
    border: '1px solid #D8D8D8',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    color: '#000',
    padding: '9px 11px',
  },
})(({component, ...others}) => {
  let Component = Input
  if (component) {
    Component = component
  }
  return <Component disableUnderline {...others} />
})

export const AccountTextField = withStyles({
  root: {
    marginTop: 0,
    marginBottom: 0,
    border: '1px solid #D8D8D8',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    color: '#000',
    padding: '9px 11px',
  },
})(({component, ...others}) => {
  let Component = Input
  if (component) {
    Component = component
  }
  return <Component disableUnderline {...others} />
})

export const RecipeFollowingBoxCard = withStyles({
  root: {
    borderRadius: '3px 3px 0 0',
    boxShadow: '0 1px 10px 0 rgba(0,0,0,0.19)',
  },
})(Card)

export const DescNotePaper = withStyles({
  root: {
    padding: '15px',
    borderRadius: '0 0px 3px 3px',
    boxShadow: '0 1px 10px 0 rgba(0,0,0,0.19)',
  },
})(Paper)

export const NotesPaper = withStyles({
  root: {
    maxHeight: 400,
    whiteSpace: 'pre-wrap',
    overflow: 'auto',
  },
})(DescNotePaper)

export default styles

import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import validatedComponent from '../common/ValidatedComponent.jsx'
import * as Actions from './redux/actions.changeEmail'

class ChangeEmailVerify extends React.Component {
  componentDidMount() {
    const {id, token} = this.props.params
    this.props.verifyChangeEmail(id, token)
  }

  render() {
    return (
      <div className="panel">
        <div className="row">
          <div className="large-12 end columns">
            <h2>Change Email Verification</h2>
          </div>
        </div>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

ChangeEmailVerify.displayName = 'ChangeEmailVerify'

export default connect(null, mapDispatchToProps)(validatedComponent(ChangeEmailVerify))

import React from 'react'
import {
  Button,
  Typography,
  Tooltip,
  Select,
  Grid,
  MenuItem,
  Checkbox,
  ListItemText,
  withStyles,
} from '@material-ui/core'
import _ from 'lodash'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import autobind from 'autobind-decorator'
import {createCategorySuccess} from '../../myKitchen/redux/actions'
import {createNotificationFromError, createSuccess} from '../../common/redux/actions.notifications'
import MyKitchenApi from '../../myKitchen/api'
import {DetailTextField} from '../CreateRecipeFlow/CreateRecipe.styles'

import ButtonWithProgress from '../../common/ButtonWithProgress'

function arrowGen(theme) {
  return {
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      position: 'absolute',
      marginLeft: 10,
      marginRight: 10,
    },

    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: '200px',
      marginTop: '-0.95em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent #fff transparent`,
      },
    },
    opacity: 1,
    zIndex: 1,
  }
}

const styles = theme => ({
  contentContainer: {
    boxShadow: `0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)`,
    padding: '19px 19px 30px',
    [theme.breakpoints.down('xs')]: {
      padding: '5px 5px 0 5px',
    },
  },
  select: {
    height: '51px',
    width: '100%',
    marginTop: `10px`,
    border: '1.5px solid #D8D8D8',
    borderRadius: '3px',
    padding: '3px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  createCategoryBtn: {
    height: '33px',
    width: '154px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '16px',
    padding: 0,
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '10px',
    },
  },
  title: {
    fontFamily: 'Lato',
    fontSize: '24px',
    lineHeight: '29px',
    fontWeight: '300',
    paddingBottom: '10px',
    borderBottom: '1.5px solid #589a43',
    paddingLeft: '10px',
  },
  categoryName: {
    fontFamily: 'Lato',
    fontSize: '18px',
    lineHeight: '29px',
    fontWeight: '300',
    marginTop: '20px',
    paddingBottom: '10px',
  },
  actionsContainer: {
    width: '100%',
    textAlign: `center`,
    margin: `0 auto`,
    padding: '20px 0 0 0',
    [theme.breakpoints.down('xs')]: {
      padding: '5px 5px 0 5px',
    },
  },
  tooltip: {
    maxWidth: 'none',
    backgroundColor: '#FFFFFF',
    padding: 0,
    boxShadow: '0 1px 10px 0 345pxrgba(0,0,0,0.19)',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '15px',
    },
  },
  saveCancelMobile: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      margin: `10px 0`,
    },
  },
  cancelSaveMobile: {
    display: 'inline-block',
    marginLeft: '15px',
    marginRight: '25px',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
  saveBtn: {
    height: '30px',
    width: '158px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    padding: 0,
    color: '#589a43',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '21px',
    [theme.breakpoints.down('xs')]: {
      width: '140px',
    },
    textTransform: 'none',
  },
  cancelBtn: {
    height: '33px',
    width: '107px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '14px',
    lineHeight: '16px',
    padding: 0,
    textTransform: 'none',
  },
  categoryNameText: {
    width: '474px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cancelGrid: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      marginBottom: 10,
    },
  },
  addGrid: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  saveLongBtn: {
    height: '30px',
    width: '280px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    padding: 0,
    color: '#589a43',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '21px',
    [theme.breakpoints.down('xs')]: {
      width: '200px',
    },
    textTransform: 'none',
  },
  cancelLongBtn: {
    height: '23px',
    width: '176px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '14px',
    lineHeight: '16px',
    padding: 0,
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '10px',
    },
  },
  arrowPopper: arrowGen(theme),
  arrow: {
    position: 'absolute',
    fontSize: 6,
    width: '5em',
    height: '5em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  checkBox: {
    color: '#589a43 !important',
  },
})
class CreateCategoryComponent extends React.PureComponent {
  render() {
    const {
      classes,
      saveCategory,
      cancelCreateCategory,
      updateCategoryName,
      categoryName,
      savingCategory,
    } = this.props

    const saveDisabled = savingCategory || categoryName === ''

    return (
      <React.Fragment>
        <div className={classes.contentContainer}>
          <Typography variant="title" className={classes.title}>
            Select Category
          </Typography>
          <Typography variant="body2" className={classes.categoryName}>
            Category Name
          </Typography>
          <DetailTextField
            placeholder="Enter category name"
            className={classes.categoryNameText}
            onChange={updateCategoryName}
            value={categoryName}
            disabled={savingCategory}
            id="category-name-text"
          />
          <Grid container className={classes.actionsContainer}>
            <Grid container>
              <Grid item xs={12} style={{textAlign: 'center', marginTop: '18px'}}>
                <ButtonWithProgress
                  data-test-name="save-create-category-dialog"
                  className={classes.saveLongBtn}
                  onClick={saveCategory}
                  disabled={saveDisabled}
                  isLoading={savingCategory}
                >
                  Save
                </ButtonWithProgress>
              </Grid>
              <Grid item xs={12} style={{textAlign: 'center', marginTop: '18px'}}>
                <Button
                  data-test-name="cancel-create-category-dialog"
                  className={classes.cancelLongBtn}
                  onClick={cancelCreateCategory}
                  disabled={savingCategory}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}

class SelectCategoryComponent extends React.PureComponent {
  render() {
    const {
      classes,
      handleChange,
      renderSelectedCategories,
      selectedCategories,
      categories,
      isCategorySelected,
      onSave,
      handleCancel,
      handleCreateCategory,
    } = this.props

    return (
      <React.Fragment>
        <div data-test-name="select-category" className={classes.contentContainer}>
          <Typography variant="title" className={classes.title}>
            Select Category
          </Typography>
          <Typography variant="body2" className={classes.categoryName}>
            Category Name
          </Typography>
          <Select
            multiple
            disableUnderline
            className={classes.select}
            onChange={handleChange}
            renderValue={renderSelectedCategories}
            value={selectedCategories}
          >
            <MenuItem value="all-id" disabled>
              <Checkbox classes={{checked: classes.checkBox}} checked={true} />
              <ListItemText primary="All" />
            </MenuItem>
            {categories.map(cat => (
              <MenuItem key={cat.id} value={cat}>
                <Checkbox classes={{checked: classes.checkBox}} checked={isCategorySelected(cat)} />
                <ListItemText primary={cat.name} />
              </MenuItem>
            ))}
          </Select>
          <Grid container className={classes.actionsContainer}>
            <Grid item xs={6} sm={4} md={4} className={classes.saveCancelMobile}>
              <Button
                data-test-name="save-select-category-dialog"
                className={classes.saveBtn}
                onClick={onSave}
              >
                Add
              </Button>
            </Grid>
            <Grid item xs={6} sm={4} md={4} className={classes.saveCancelMobile}>
              <div className={classes.cancelSaveMobile}>
                <Button
                  data-test-name="cancel-select-category-dialog"
                  className={classes.cancelBtn}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={4} md={4} className={classes.createMobile}>
              <Button
                id="createCategory"
                className={classes.createCategoryBtn}
                onClick={handleCreateCategory}
              >
                Create Category
              </Button>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}

class SelectCategoryTooltip extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      categoryName: '',
      selectedCategories: [{name: 'All', id: ''}],
      currentComponent: 'selectCategories', // or createCategory
      savingCategory: false,
    }

    this.arrowRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    const {categories, categoryToAddRecipe} = this.props

    if (prevProps.categories !== categories) {
      if (categoryToAddRecipe && categoryToAddRecipe !== null) {
        const cat = _.find(categories, ['id', categoryToAddRecipe.id])
        if (cat) {
          this.setState({selectedCategories: [{name: 'All', id: ''}, cat]}) // eslint-disable-line react/no-did-update-set-state
        }
      }
    }
  }

  @autobind
  async saveCategory() {
    const {createSuccess, createNotificationFromError, createCategorySuccess} = this.props
    try {
      this.setState({savingCategory: true})
      const payload = {image: '', name: this.state.categoryName, sharedWith: 'private'}
      const cat = await MyKitchenApi.createCategory(payload)
      this.setState(state => {
        const selCats = [...state.selectedCategories, cat.data]
        return {selectedCategories: selCats, currentComponent: 'selectCategories', categoryName: ''}
      })
      createCategorySuccess(cat.data)
      createSuccess('Category created successfully.')
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      this.setState({savingCategory: false})
    }
  }

  @autobind
  onSave() {
    const {onSave} = this.props
    const {selectedCategories} = this.state

    onSave(
      selectedCategories.filter(cat => cat !== 'all-id' && cat.name !== 'All').map(cat => cat.id),
    )
  }

  @autobind
  updateCategoryName(e) {
    this.setState({categoryName: e.target.value})
  }

  @autobind
  cancelCreateCategory() {
    this.setState({currentComponent: 'selectCategories', categoryName: ''})
  }

  @autobind
  isCategorySelected(category) {
    const {selectedCategories} = this.state

    const index = _.findIndex(selectedCategories, ['id', category.id])
    return index !== -1
  }

  @autobind
  renderSelectedCategories(selectedCategories) {
    const selected = selectedCategories.map(cat => cat.name)
    return selected.join(', ')
  }

  @autobind
  handleChange(event) {
    this.setState({selectedCategories: event.target.value})
  }

  @autobind
  handleCreateCategory() {
    this.setState({currentComponent: 'createCategory'})
  }

  render() {
    const {handleTooltipClose, open, children, classes, categories} = this.props
    const {savingCategory, selectedCategories, currentComponent, categoryName} = this.state
    return (
      <div>
        <Tooltip
          classes={{tooltip: classes.tooltip, popper: classes.arrowPopper}}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          interactive
          PopperProps={{
            popperOptions: {
              modifiers: {
                arrow: {
                  enabled: true,
                  element: this.arrowRef.current,
                },
              },
            },
          }}
          title={
            currentComponent === 'selectCategories' ? (
              <SelectCategoryComponent
                handleChange={this.handleChange}
                renderSelectedCategories={this.renderSelectedCategories}
                selectedCategories={selectedCategories}
                categories={categories}
                classes={classes}
                isCategorySelected={this.isCategorySelected}
                handleCancel={handleTooltipClose}
                handleCreateCategory={this.handleCreateCategory}
                onSave={this.onSave}
                arrowRef={this.arrowRef}
              />
            ) : (
              <CreateCategoryComponent
                classes={classes}
                saveCategory={this.saveCategory}
                cancelCreateCategory={this.cancelCreateCategory}
                updateCategoryName={this.updateCategoryName}
                categoryName={categoryName}
                savingCategory={savingCategory}
              />
            )
          }
        >
          {children}
        </Tooltip>
      </div>
    )
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {createSuccess, createNotificationFromError, createCategorySuccess},
    dispatch,
  )
}

export default compose(connect(null, mapDispatchToProps), withStyles(styles))(SelectCategoryTooltip)

import {call, put, takeEvery} from 'redux-saga/effects'
import MyKitchenApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as NotificationActions from '../../common/redux/actions.notifications.js'

export default function* myKitchenFlow() {
  yield [takeEvery(Types.CREATE_CATEGORY, createCategory)]
  yield [takeEvery(Types.GET_CATEGORIES, getCategories)]
  yield [takeEvery(Types.UPDATE_CATEGORY, updateCategory)]
  yield [takeEvery(Types.DELETE_CATEGORY, deleteCategory)]
}

function* createCategory(action) {
  const {params} = action
  try {
    const response = yield call(MyKitchenApi.createCategory, params)
    yield put(Actions.createCategorySuccess(response))
    yield put(NotificationActions.createSuccess('Successfully Created Category.'))
  } catch (error) {
    yield put(Actions.createCategoryFailure(error))
  }
}

function* getCategories(action) {
  const {userId} = action
  try {
    const response = yield call(MyKitchenApi.getCategories, userId)
    yield put(Actions.getCategoriesSuccess(response))
  } catch (error) {
    yield put(Actions.getCategoriesFailure(error))
  }
}

function* updateCategory(action) {
  const {id} = action
  try {
    const response = yield call(MyKitchenApi.updateCategory, id)
    yield put(Actions.updateCategorySuccess(response))
    yield put(NotificationActions.createSuccess('Successfully Updated Category.'))
  } catch (error) {
    yield put(Actions.updateCategoryFailure(error))
  }
}
function* deleteCategory(action) {
  const {id, userId} = action
  try {
    const response = yield call(MyKitchenApi.deleteCategory, id)
    const responseGet = yield call(MyKitchenApi.getCategories, userId)
    yield put(Actions.getCategoriesSuccess(responseGet))
    yield put(Actions.deleteCategorySuccess(response))
    yield put(NotificationActions.createSuccess('Successfully Deleted Category.'))
  } catch (error) {
    yield put(Actions.deleteCategoryFailure(error))
  }
}

import * as Types from './actions.changeEmail.types'

export function changeEmail(newEmail) {
  return {type: Types.CHANGE_EMAIL, newEmail}
}

export function changeEmailSuccess() {
  return {type: Types.CHANGE_EMAIL_SUCCESS}
}

export function changeEmailFailure(error) {
  return {type: Types.CHANGE_EMAIL_FAILURE, error}
}

export function confirmChangeEmail(id, token) {
  return {type: Types.CHANGE_EMAIL_CONFIRM, id, token}
}

export function confirmChangeEmailSuccess() {
  return {type: Types.CHANGE_EMAIL_CONFIRM_SUCCESS}
}

export function confirmChangeEmailFailure(error) {
  return {type: Types.CHANGE_EMAIL_CONFIRM_FAILURE, error}
}

export function verifyChangeEmail(id, token) {
  return {type: Types.CHANGE_EMAIL_VERIFY, id, token}
}

export function verifyChangeEmailSuccess() {
  return {type: Types.CHANGE_EMAIL_VERIFY_SUCCESS}
}

export function verifyChangeEmailFailure(error) {
  return {type: Types.CHANGE_EMAIL_VERIFY_FAILURE, error}
}

export function resendConfirmEmail() {
  return {type: Types.CHANGE_EMAIL_RESEND_CONFIRM}
}

export function resendConfirmEmailSuccess() {
  return {type: Types.CHANGE_EMAIL_RESEND_CONFIRM_SUCCESS}
}

export function resendConfirmEmailFailure(error) {
  return {type: Types.CHANGE_EMAIL_RESEND_CONFIRM_FAILURE, error}
}

export function resendVerifyEmail() {
  return {type: Types.CHANGE_EMAIL_RESEND_VERIFY}
}

export function resendVerifyEmailSuccess() {
  return {type: Types.CHANGE_EMAIL_RESEND_VERIFY_SUCCESS}
}

export function resendVerifyEmailFailure(error) {
  return {type: Types.CHANGE_EMAIL_RESEND_VERIFY_FAILURE, error}
}

export function cancelChangeEmail() {
  return {type: Types.CHANGE_EMAIL_CANCEL}
}

export function cancelChangeEmailSuccess() {
  return {type: Types.CHANGE_EMAIL_CANCEL_SUCCESS}
}

export function cancelChangeEmailFailure(error) {
  return {type: Types.CHANGE_EMAIL_CANCEL_FAILURE, error}
}

export function verifyEmail(id, token) {
  return {type: Types.EMAIL_VERIFY, id, token}
}

export function verifyEmailSuccess() {
  return {type: Types.EMAIL_VERIFY_SUCCESS}
}

export function verifyEmailFailure(error) {
  return {type: Types.EMAIL_VERIFY_FAILURE, error}
}

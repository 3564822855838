export const NotificationActionTypes = {
  CREATE_NOTIFICATION: 'CREATE_NOTIFICATION',
  DISMISS_NOTIFICATION: 'DISMISS_NOTIFICATION',
}

export const NotificationType = {
  error: 'error',
  success: 'success',
  information: 'info',
  warning: 'warning',
  secondary: 'secondary',
}

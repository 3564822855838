import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {withStyles} from '@material-ui/styles'
import Notification from './Notification.jsx'
import * as NotificationActions from './redux/actions.notifications'

const NotificationContainer = ({dismiss, notifications, classes}) => {
  return (
    <div className={classes.messagesWrapper}>
      {notifications.map(notification => (
        <Notification key={notification.id} notification={notification} dismiss={dismiss} />
      ))}
    </div>
  )
}

NotificationContainer.displayName = 'NotificationContainer'

function mapStateToProps(state) {
  return {
    notifications: state.notifications,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(NotificationActions, dispatch)
}

const styles = {
  messagesWrapper: {
    position: 'fixed',
    left: 0,
    right: 'auto',
    bottom: 0,
    zIndex: 5000,
  },
}

// Wrap the component to inject dispatch and state into it
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(NotificationContainer))

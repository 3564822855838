import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import {Button, Grid, Modal, Typography, Icon} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import _ from 'lodash'
import autoBind from 'react-autobind'
import {withRouter} from 'react-router'

import cx from 'classnames'
import ButtonWithProgress from '../../common/ButtonWithProgress'
import FromNow from '../../common/FromNow'
import {
  clearRecipe,
  createRecipe,
  deleteRecipe,
  updateLocal,
  getRecipe,
  autoSaveRecipe,
  addIngredientList,
} from '../redux/actions'
import RecipeImport from './RecipeImport'
import RecipeDetails from './RecipeDetails'
import RecipeDirections from './RecipeDirections'
import RecipeIngredients from './RecipeIngredients'
import RecipeMaterials from './RecipeMaterials'
import RecipeNotes from './RecipeNotes'
import styles, {SectionExpandable, ButtonFilled} from './CreateRecipe.styles.js'

class CreateRecipe extends React.Component {
  constructor(props) {
    super(props)
    autoBind(this)
    this.state = {
      openModal: false,
    }
  }

  componentWillMount() {
    const {location} = this.props
    let isCreating = false
    const pathname = location.pathname.replace('/create-recipe/', '')

    if (pathname !== '/create-recipe') {
      isCreating = true
    }
    if (this.props.editing === false && isCreating === false) {
      this.props.clearRecipe()
    }
    if (this.props.editing === false && isCreating === true) {
      const {id} = this.props.params
      this.props.getRecipe(id, {
        expand: 'user,creator,ingredient_lists,materials,directions,tags,categories',
      })
    }
  }

  onCreateRecipe(fieldName, e) {
    this.props.createRecipe({[fieldName]: e.target.value}, 'recipe')
  }

  handleCreateRecipe(e) {
    e.preventDefault()
    const {recipe, editing, oldRecipe} = this.props
    let id
    if (editing === true) {
      id = recipe.id
    } else {
      id = this.props.params.id
    }
    recipe.materials = recipe.materials.filter(mat => mat.material.title !== '')
    const partialRecipe = recipe

    if (oldRecipe.image) {
      delete partialRecipe.image
    }

    this.props.createRecipe(partialRecipe, id)
  }

  handleDeleteRecipe(e) {
    e.preventDefault()
    const {recipe} = this.props
    this.props.deleteRecipe(recipe.id)
  }

  handleOnBlur() {
    const {recipe, editing, oldRecipe, isAutoSaving} = this.props

    if (!isAutoSaving) {
      let id
      const partialRecipe = recipe
      if (oldRecipe.image === partialRecipe.image) {
        delete partialRecipe.image
      }

      if (editing === true) {
        id = recipe.id
      } else {
        id = this.props.params.id
      }
      this.props.autoSaveRecipe(partialRecipe, id, editing)
    }
  }

  handleOpen(e) {
    e.preventDefault()
    this.setState({openModal: true})
  }

  handleClose(e) {
    e.preventDefault()
    this.setState({openModal: false})
  }

  stopPropagation(e) {
    e.stopPropagation()
  }

  render() {
    const {classes, recipe, editing, hasUpdatedLocal, addIngredientList} = this.props
    const {openModal} = this.state
    return (
      <Grid container>
        {editing ? null : (
          <React.Fragment>
            <RecipeImport />

            <Grid item xs={12} className={classes.greenLine} />
          </React.Fragment>
        )}

        <RecipeDetails onBlur={this.handleOnBlur} editing={editing} />

        <Grid item xs={12} className={classes.greenLine} />

        <Grid item xs={12}>
          <SectionExpandable title="Ingredients & Materials" defaultExpanded={true}>
            <Grid container spacing={10}>
              <Grid item xs={12} md={6}>
                {recipe.ingredientLists.map((ingredientList, index) => (
                  <RecipeIngredients
                    onBlur={this.handleOnBlur}
                    ingredientList={ingredientList}
                    ingredientListIndex={index}
                  />
                ))}
                <ButtonFilled
                  style={{marginTop: 16, backgroundColor: '#589a43', color: '#fff'}}
                  fullWidth
                  onClick={addIngredientList}
                  variant="outlined"
                >
                  <Icon style={{marginRight: 12, color: '#fff'}}>playlist_add_outlined</Icon> Create
                  new ingredient list
                </ButtonFilled>
              </Grid>
              <RecipeMaterials onBlur={this.handleOnBlur} />
            </Grid>
          </SectionExpandable>
        </Grid>

        <Grid item xs={12} className={classes.greenLine} />

        <Grid item xs={12}>
          <SectionExpandable title="Directions" defaultExpanded={true}>
            <Grid container spacing={10}>
              <RecipeDirections onBlur={this.handleOnBlur} />
            </Grid>
          </SectionExpandable>
        </Grid>

        <Grid item xs={12} className={classes.greenLine} />

        <Grid item xs={12}>
          <SectionExpandable title="Notes" defaultExpanded={true}>
            <Grid container spacing={10}>
              <RecipeNotes onBlur={this.handleOnBlur} />
            </Grid>
          </SectionExpandable>
        </Grid>

        <Grid container direction="row" justifyContent="space-between">
          {editing ? <Grid item xs={12} className={classes.greenLine} /> : null}
          {editing ? (
            <React.Fragment>
              <Grid item xs={12} sm={12} md={6} className={classes.textLeft}>
                <ButtonWithProgress
                  variant="outlined"
                  handleOpen
                  onClick={this.handleOpen}
                  isLoading={recipe.isDeleting}
                  className={classes.deleteButton}
                >
                  Delete Recipe
                </ButtonWithProgress>
              </Grid>
              <div>
                <Modal
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  open={openModal}
                  onClick={this.stopPropagation}
                  BackdropProps={{style: {background: 'none'}}}
                  onClose={this.handleClose}
                >
                  <div className={cx(classes.optionPaper, classes.deletePopUp)}>
                    <Grid item xs={12}>
                      <Typography variant="h6" id="modal-title" className={classes.optionsTitle}>
                        Confirm Delete
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      className={classes.optionsBottom}
                      style={{textAlign: 'center'}}
                    >
                      <div>
                        <Button
                          data-test-name="delete-recipe-confirm"
                          onClick={this.handleDeleteRecipe}
                          className={classes.saveBtn}
                        >
                          Confirm
                        </Button>
                      </div>

                      <Button
                        data-test-name="delete-recipe-cancel"
                        onClick={this.handleClose}
                        className={classes.cancelBtn}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </div>
                </Modal>
              </div>
            </React.Fragment>
          ) : (
            <Grid item xs={12} sm={12} md={6} className={classes.textLeft} />
          )}
          <Grid item xs={12} sm={12} md={6} className={classes.saveButton}>
            {recipe && recipe.modified && (
              <span className={classes.lastSaved}>
                Saved <FromNow datetime={recipe.modified} />
              </span>
            )}
            <ButtonWithProgress
              className={classes.savedBtn}
              variant="outlined"
              onClick={this.handleCreateRecipe}
              isLoading={recipe.isSaving || this.props.isAutoSaving}
            >
              {editing || hasUpdatedLocal ? 'Save' : 'Preview'}
            </ButtonWithProgress>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

CreateRecipe.displayName = 'CreateRecipe'

function mapStateToProps(state) {
  return {
    ...state.recipe,
    profile: _.get(state.profile, 'profile', null),
  }
}

CreateRecipe.propTypes = {
  classes: PropTypes.object.isRequired,
  editing: PropTypes.bool,
}

CreateRecipe.defaultProps = {
  editing: false,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createRecipe,
      deleteRecipe,
      updateLocal,
      autoSaveRecipe,
      getRecipe,
      clearRecipe,
      addIngredientList,
    },
    dispatch,
  )
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(CreateRecipe)

import * as Types from './actions.types'

export function updateLocal(patch, rootKey) {
  return {type: Types.UPDATE_LOCAL, patch, rootKey}
}

export function updateLocalAtIndex(rootKey, fieldKey, index, patch) {
  return {type: Types.UPDATE_LOCAL_AT_INDEX, rootKey, fieldKey, index, patch}
}

export function updateIngredient(ingredientListIndex, ingredientIndex, patch) {
  return {type: Types.UPDATE_INGREDIENT, ingredientListIndex, ingredientIndex, patch}
}

export function deleteLocalAtIndex(rootKey, fieldKey, index) {
  return {type: Types.DELETE_LOCAL_AT_INDEX, rootKey, fieldKey, index}
}

export function deleteIngredient(ingredientListIndex, ingredientIndex) {
  return {type: Types.DELETE_INGREDIENT, ingredientListIndex, ingredientIndex}
}

export function deleteIngredientList(ingredientListIndex) {
  return {type: Types.DELETE_INGREDIENT_LIST, ingredientListIndex}
}

export function autoSaveRecipe(params, recipeId, editing = false) {
  return {type: Types.AUTO_SAVE_RECIPE, params, recipeId, editing}
}

export function autoSaveRecipeSuccess(recipe) {
  return {type: Types.AUTO_SAVE_RECIPE_SUCCESS, recipe}
}

export function autoSaveRecipeFailure(errors) {
  return {type: Types.AUTO_SAVE_RECIPE_FAILURE, errors}
}

export function createRecipe(params, recipeId) {
  return {type: Types.CREATE_RECIPE, params, recipeId}
}

export function createRecipeSuccess(recipe) {
  return {type: Types.CREATE_RECIPE_SUCCESS, recipe}
}

export function createRecipeFailure(errors) {
  return {type: Types.CREATE_RECIPE_FAILURE, errors}
}

export function deleteRecipe(recipeId) {
  return {type: Types.DELETE_RECIPE, recipeId}
}

export function deleteRecipeSuccess(response) {
  return {type: Types.DELETE_RECIPE_SUCCESS, response}
}

export function deleteRecipeFailure(errors) {
  return {type: Types.DELETE_RECIPE_FAILURE, errors}
}
export function clearRecipe() {
  return {type: Types.RECIPE_CLEAR}
}

export function addIngredientList() {
  return {type: Types.ADD_INGREDIENT_LIST}
}

export function addIngredient(ingredientListIndex) {
  return {type: Types.ADD_INGREDIENT, ingredientListIndex}
}

export function addMaterial() {
  return {type: Types.ADD_MATERIAL}
}

export function addDirection() {
  return {type: Types.ADD_DIRECTION}
}

export function addTag(tag) {
  return {type: Types.ADD_TAG, tag}
}

export function recipePublishFailure(error) {
  return {type: Types.RECIPE_PUBLISH_FAILURE, error}
}

export function getRecipeFromUrl(url) {
  return {type: Types.GET_RECIPE_FROM_URL, url}
}

export function getRecipeFromUrlSuccess(recipe) {
  return {type: Types.GET_RECIPE_FROM_URL_SUCCESS, recipe}
}

export function getRecipeFromUrlFailure(error) {
  return {type: Types.GET_RECIPE_FROM_URL_FAILURE, error}
}

export function getRecipe(id, params) {
  return {type: Types.GET_RECIPE, id, params}
}

export function getRecipeSuccess(recipe) {
  return {type: Types.GET_RECIPE_SUCCESS, recipe}
}

export function getRecipeFailure(error) {
  return {type: Types.GET_RECIPE_FAILURE, error}
}

export function getTagSuggestions(tag) {
  return {type: Types.GET_TAG_SUGGESTIONS, tag}
}

export function getTagSuggestionsSuccess(suggestions) {
  return {type: Types.GET_TAG_SUGGESTIONS_SUCCESS, suggestions}
}

export function getTagSuggestionsFailure(error) {
  return {type: Types.GET_TAG_SUGGESTIONS_FAILURE, error}
}

export const CREATE_CATEGORY = 'CREATE_CATEGORY'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE'

export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE'

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE'

export const DELETE_CATEGORY = 'DELETE_CATEGORY'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE'

export const UPDATE_LOCAL = 'UPDATE_LOCAL'

export const CATEGORY_CLEAR = 'CATEGORY_CLEAR'

export const SET_CATEGORY_TO_ADD_RECIPE = 'SET_CATEGORY_TO_ADD_RECIPE'
export const CLEAR_CATEGORY_TO_ADD_RECIPE = 'CLEAR_CATEGORY_TO_ADD_RECIPE'

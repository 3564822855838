import React from 'react'
import {Grid} from '@material-ui/core'
import ShoppingCalendarWidget from '../shoppingList/ShoppingCalendarWidget'

function MyKitchenSidebar(props) {
  return (
    <React.Fragment>
      <Grid item xs={12} md={8}>
        {props.children}
      </Grid>
      <Grid item xs={12} md={4}>
        <ShoppingCalendarWidget />
      </Grid>
    </React.Fragment>
  )
}

MyKitchenSidebar.displayName = 'MyKitchenSidebar'

export default MyKitchenSidebar

import React from 'react'
import {Grid, Typography, withStyles} from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'
import autobind from 'autobind-decorator'

import {bindActionCreators, compose} from 'redux'
import connect from 'react-redux/es/connect/connect'
import {Tabs, Tab} from '../common/styles/Tabs'
import styles, {RecipeFollowingBoxCard, DescNotePaper, NotesPaper} from './ProfileInfo.styles'
import {getCategoriesOfProfile, getPublicProfile} from './redux/actions'
import Loading from '../common/Loading'
import ProfileCategories from './ProfileCategories'
import UsersFollowing from './UsersFollowing'

export function ListIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M23.4,5.8h-17c-0.3,0-0.6-0.3-0.6-0.6V3.7c0-0.3,0.3-0.6,0.6-0.6h17c0.3,0,0.6,0.3,0.6,0.6v1.5C24,5.5,23.7,5.8,23.4,5.8z
	 M24,12.8v-1.5c0-0.3-0.3-0.6-0.6-0.6h-17c-0.3,0-0.6,0.3-0.6,0.6v1.5c0,0.3,0.3,0.6,0.6,0.6h17C23.7,13.3,24,13.1,24,12.8z
	 M24,20.3v-1.5c0-0.3-0.3-0.6-0.6-0.6h-17c-0.3,0-0.6,0.3-0.6,0.6v1.5c0,0.3,0.3,0.6,0.6,0.6h17C23.7,20.9,24,20.6,24,20.3z M4.2,6
	v-3c0-0.3-0.3-0.6-0.6-0.6h-3C0.3,2.4,0,2.6,0,2.9v3c0,0.3,0.3,0.6,0.6,0.6h3C3.9,6.5,4.2,6.3,4.2,6z M4.2,13.5v-3
	c0-0.3-0.3-0.6-0.6-0.6h-3C0.3,9.9,0,10.2,0,10.5v3c0,0.3,0.3,0.6,0.6,0.6h3C3.9,14.1,4.2,13.8,4.2,13.5z M4.2,21.1v-3
	c0-0.3-0.3-0.6-0.6-0.6h-3C0.3,17.5,0,17.7,0,18v3c0,0.3,0.3,0.6,0.6,0.6h3C3.9,21.6,4.2,21.4,4.2,21.1z"
      />
    </SvgIcon>
  )
}
export function UsersIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M3.6,10.8C4.9,10.8,6,9.7,6,8.4S4.9,6,3.6,6S1.2,7.1,1.2,8.4S2.3,10.8,3.6,10.8z M20.4,10.8c1.3,0,2.4-1.1,2.4-2.4
	S21.7,6,20.4,6S18,7.1,18,8.4S19.1,10.8,20.4,10.8z M21.6,12h-2.4c-0.7,0-1.3,0.3-1.7,0.7c1.5,0.8,2.6,2.3,2.8,4.1h2.5
	c0.7,0,1.2-0.5,1.2-1.2v-1.2C24,13.1,22.9,12,21.6,12z M12,12c2.3,0,4.2-1.9,4.2-4.2S14.3,3.6,12,3.6S7.8,5.5,7.8,7.8S9.7,12,12,12z
	 M14.9,13.2h-0.3c-0.8,0.4-1.6,0.6-2.6,0.6s-1.8-0.2-2.6-0.6H9.1c-2.4,0-4.3,1.9-4.3,4.3v1.1c0,1,0.8,1.8,1.8,1.8h10.8
	c1,0,1.8-0.8,1.8-1.8v-1.1C19.2,15.1,17.3,13.2,14.9,13.2z M6.5,12.7c-0.4-0.4-1-0.7-1.7-0.7H2.4C1.1,12,0,13.1,0,14.4v1.2
	c0,0.7,0.5,1.2,1.2,1.2h2.5C3.9,15,5,13.5,6.5,12.7L6.5,12.7z"
      />
    </SvgIcon>
  )
}

class RecipesFollowingBoxCategories extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: 0,
    }
  }

  @autobind
  componentDidMount() {
    this.props.getCategoriesOfProfile(this.props.params.id)
    if (!this.props.publicProfile.following) this.props.getPublicProfile(this.props.params.id)
  }

  @autobind
  handleChange(event, value) {
    this.setState({value})
  }

  render() {
    const {classes, categories, profile, publicProfile, params} = this.props
    if (!categories || categories.isLoading) {
      return <Loading />
    }
    return (
      <Grid xs={12} md={9} item>
        <div className={classes.recipeFollowingBoxCard}>
          <RecipeFollowingBoxCard>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              variant="fullWidth"
              textColor={this.state.value === 0 ? 'inherit' : '#000000'}
            >
              <Tab
                fullWidth={false}
                icon={
                  <ListIcon
                    className={
                      this.state.value === 0 ? classes.tabListIconSelected : classes.tabListIcon
                    }
                  />
                }
                label="Recipes"
              />
              <Tab
                fullWidth={false}
                icon={
                  <UsersIcon
                    className={
                      this.state.value === 0 ? classes.tabUsersIcon : classes.tabUsersIconSelected
                    }
                  />
                }
                label="Following"
              />
            </Tabs>
          </RecipeFollowingBoxCard>
          {this.state.value === 0 ? (
            <DescNotePaper>
              <div className={classes.overFlow}>
                <Typography variant="h5" component="h3" className={classes.titleCategories}>
                  Categories
                </Typography>
                <ProfileCategories
                  classes={classes}
                  categories={categories}
                  profile={profile}
                  showAll={profile && publicProfile.id === profile.id}
                  params={params}
                />
              </div>
            </DescNotePaper>
          ) : (
            <NotesPaper className={classes.cardFollowingRoot}>
              <div className={classes.overFlow}>
                <UsersFollowing
                  classes={classes}
                  users={publicProfile.following}
                  shouldShowFollow={profile && publicProfile.id === profile.id}
                  params={params}
                />
              </div>
            </NotesPaper>
          )}
        </div>
      </Grid>
    )
  }
}
RecipesFollowingBoxCategories.displayName = 'RecipesFollowingBoxCategories'

function mapStateToProps(state) {
  return {
    ...state.profile,
    categories: state.profile.categories,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({getCategoriesOfProfile, getPublicProfile}, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(RecipesFollowingBoxCategories)

import React from 'react'
import {DragSource} from 'react-dnd'
import {compose} from 'redux'

import DragIcon from '../../assets/icons/drag-small.svg'
import ItemTypes from '../constants/DnD'
import withDragDropContext from '../DnDContext'

const dragSource = {
  beginDrag(props) {
    const {recipe, beginDrag} = props
    beginDrag()
    return {id: recipe.id}
  },

  endDrag(props) {
    const {endDrag} = props
    endDrag()
  },
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }
}

function CalendarRecipe(props) {
  const {classes, recipe, connectDragSource} = props

  return connectDragSource(
    <div className={classes.calendarRecipe} key={recipe.id}>
      <span className={classes.calendarRecipeDrag}>
        <img src={DragIcon} />
      </span>
      <span className={classes.calendarRecipeTitle}>{recipe.title}</span>
    </div>,
  )
}

export default compose(
  withDragDropContext,
  DragSource(ItemTypes.CALENDAR_RECIPE, dragSource, collect),
)(CalendarRecipe)

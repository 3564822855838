import settings from '../../../config/settings'
import axios from '../api/axios'

export default class ShoppingItemApi {
  static addAllFromRecipe(recipeId) {
    return axios.post(`${settings.apiBaseURL}/shopping-items/add-all-from-recipe`, {
      recipe: recipeId,
    })
  }

  static addShoppingItemByTitle(payload, params) {
    return axios.post(`${settings.apiBaseURL}/shopping-items/create-by-ingredient-title`, payload, {
      params,
    })
  }

  static updateShoppingItemByTitle(id, payload, params) {
    return axios.patch(
      `${settings.apiBaseURL}/shopping-items/${id}/update-by-ingredient-title`,
      payload,
      {
        params,
      },
    )
  }

  static addShoppingItem(payload, params) {
    return axios.post(`${settings.apiBaseURL}/shopping-items`, payload, {
      params,
    })
  }

  static getShoppingItems(params) {
    return axios.get(`${settings.apiBaseURL}/shopping-items`, {params})
  }

  static deleteAllShoppingItems() {
    return axios.delete(`${settings.apiBaseURL}/shopping-items/delete-all`)
  }

  static deleteShoppingItem(id) {
    return axios.delete(`${settings.apiBaseURL}/shopping-items/${id}`)
  }

  static updateShoppingItem(id, patch, params) {
    return axios.patch(`${settings.apiBaseURL}/shopping-items/${id}`, patch, {
      params,
    })
  }
}

export const PUBLICPROFILE_GET = 'PUBLICPROFILE_GET'
export const PUBLICPROFILE_GET_SUCCESS = 'PUBLICPROFILE_GET_SUCCESS'
export const PUBLICPROFILE_GET_FAILURE = 'PUBLICPROFILE_GET_FAILURE'

export const PROFILE_GET = 'PROFILE_GET'
export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS'
export const PROFILE_GET_FAILURE = 'PROFILE_GET_FAILURE'

export const RECIMEE_GET = 'RECIMEE_GET'
export const RECIMEE_GET_SUCCESS = 'RECIMEE_GET_SUCCESS'
export const RECIMEE_GET_FAILURE = 'RECIMEE_GET_FAILURE'

export const PROFILE_CHANGE_PASSWORD = 'PROFILE_CHANGE_PASSWORD'
export const PROFILE_CHANGE_PASSWORD_SUCCESS = 'PROFILE_CHANGE_PASSWORD_SUCCESS'
export const PROFILE_CHANGE_PASSWORD_FAILURE = 'PROFILE_CHANGE_PASSWORD_FAILURE'

export const PROFILE_CHANGE_PASSWORD_UPDATE = 'PROFILE_CHANGE_PASSWORD_UPDATE'

export const PROFILE_CREATE = 'PROFILE_CREATE'
export const PROFILE_CREATE_SUCCESS = 'PROFILE_CREATE_SUCCESS'
export const PROFILE_CREATE_FAILURE = 'PROFILE_CREATE_FAILURE'
export const PROFILE_CREATE_INIT = 'PROFILE_CREATE_INIT'

export const PROFILE_EDIT_INIT = 'PROFILE_EDIT_INIT'
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const PROFILE_SAVE = 'PROFILE_SAVE'
export const PROFILE_SAVE_SUCCESS = 'PROFILE_SAVE_SUCCESS'
export const PROFILE_SAVE_FAILURE = 'PROFILE_SAVE_FAILURE'

export const PROFILE_TOGGLE_EDIT = 'PROFILE_TOGGLE_EDIT'

export const PROFILE_FOLLOW = 'PROFILE_FOLLOW'
export const PROFILE_FOLLOW_SUCCESS = 'PROFILE_FOLLOW_SUCCESS'
export const PROFILE_FOLLOW_FAILURE = 'PROFILE_FOLLOW_FAILURE'
export const PROFILE_UNFOLLOW = 'PROFILE_UNFOLLOW'
export const PROFILE_UNFOLLOW_SUCCESS = 'PROFILE_UNFOLLOW_SUCCESS'
export const PROFILE_UNFOLLOW_FAILURE = 'PROFILE_UNFOLLOW_FAILURE'
export const PROFILE_UNFOLLOW_FOLLOWER = 'PROFILE_UNFOLLOW_FOLLOWER'
export const PROFILE_UNFOLLOW_FOLLOWER_SUCCESS = 'PROFILE_UNFOLLOW_FOLLOWER_SUCCESS'
export const PROFILE_UNFOLLOW_FOLLOWER_FAILURE = 'PROFILE_UNFOLLOW_FOLLOWER_FAILURE'

export const GET_CATEGORIES_OF_PROFILE = 'GET_CATEGORIES_OF_PROFILE'
export const GET_CATEGORIES_OF_PROFILE_SUCCESS = 'GET_CATEGORIES_OF_PROFILE_SUCCESS'
export const GET_CATEGORIES_OF_PROFILE_FAILURE = 'GET_CATEGORIES_OF_PROFILE_FAILURE'

export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'

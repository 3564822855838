import React from 'react'
import {Grid, Typography, Card, CardMedia, withStyles} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import * as Actions from './redux/actions'
import styles, {StyledButton} from './ProfileInfo.styles'
import ValidatedComponent from '../common/ValidatedComponent'

class UsersFollowing extends React.Component {
  render() {
    const {users, classes, unfollowFollower, shouldShowFollow} = this.props
    return (
      <Grid container>
        {users &&
          users.map((user, index) => {
            return (
              <Grid
                item
                xs={12}
                md={12}
                container
                key={index}
                className={classes.paddingGridSubPaper}
              >
                <Card
                  className={classes.cardFollowingRootUsers}
                  style={{
                    boxShadow: 'none',
                    width: '100%',
                    borderBottom: '1px solid #F2F2F2',
                    borderRadius: 'unset',
                  }}
                >
                  <Grid item xs={12} md={12} container>
                    <a href={`/users/${user.following.id}`} className={classes.followingLink}>
                      <Grid item xs={12} md={12} container>
                        <Grid item xs={2} className={classes.padding5Image}>
                          <CardMedia
                            component="img"
                            className={classes.followingAvatar}
                            image={user.following.avatar.fullSize}
                          />
                        </Grid>

                        <Grid item xs={10} className={classes.padding10Name}>
                          <Typography gutterBottom className={classes.cardName}>
                            {user.following.firstName + ' ' + user.following.lastName}
                          </Typography>
                        </Grid>
                      </Grid>
                    </a>
                    <Grid item xs={3} className={classes.unfollowUserPadding}>
                      {shouldShowFollow && (
                        <StyledButton
                          className={classes.unfollowButton}
                          style={{
                            minWidth: 92,
                            background: 'white',
                            color: '#589A43',
                            marginLeft: '-15px',
                          }}
                          onClick={() => unfollowFollower(user.following, index)}
                        >
                          Unfollow
                        </StyledButton>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )
          })}
        {!users || users.length === 0 ? (
          <Grid item xs={12} md={12}>
            <Typography gutterBottom>This profile has not followed any user yet.</Typography>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    )
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
  ValidatedComponent,
)(UsersFollowing)

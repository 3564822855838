import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  FormControl,
  withStyles,
  MenuItem,
  Input,
  TextField,
  Button,
} from '@material-ui/core'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import cx from 'classnames'

import autobind from 'autobind-decorator'
import InputValidator from './InputValidator.jsx'
import ValidatedComponent from './ValidatedComponent.jsx'
import RecipeApi from '../recipe/api'
import ButtonWithProgress from './ButtonWithProgress'
import {createNotificationFromError, createSuccess} from './redux/actions.notifications'
import {subjectsTypes} from '../utils/SubjectTypes.js'

const styles = theme => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: 10,
    },
  },
  dialogTitle: {
    padding: '22px',
  },
  menu: {
    width: `100%`,
  },
  title: {
    color: '#000000',
    fontFamily: 'Lato',
    fontSize: '24px',
    fontWeight: 300,
    lineHeight: '29px',
  },
  dialogContent: {
    padding: '0 22px',
  },
  dialogContentContainer: {
    padding: '41px 8px',
    borderTop: '1.5px solid #589A43',
    borderBottom: '1.5px solid #589A43',
    [theme.breakpoints.down('xs')]: {
      borderBottom: 'none',
      padding: '10px 0px',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      borderBottom: 'none',
    },
  },
  mailInput: {
    width: '100%',
    height: '51px',
    border: '1.5px solid #D8D8D8',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    padding: '16px',
  },

  multilineMailInput: {
    width: '100%',
    border: '1.5px solid #D8D8D8',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    padding: '16px',
  },

  mailLabel: {
    color: '#000000',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: 300,
    lineHeight: '22px',
    marginBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
  },
  mailSubjectLabel: {
    color: '#000000',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: 300,
    lineHeight: '22px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
  },
  mailHelperText: {
    color: '#589A43',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '17px',
    textAlign: 'right',
    marginTop: '8px',
  },
  btnContainer: {
    marginTop: '30px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      borderTop: '1.5px solid #589A43',
      paddingTop: 20,
      marginRight: 23,
      marginLeft: 23,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      borderTop: '1.5px solid #589A43',
      paddingTop: 20,
      marginRight: 23,
      marginLeft: 23,
    },
  },

  formControl: {
    width: '100%',
    padding: `0 5px`,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  cancelContainer: {
    marginTop: '18px',
  },
  bodyContainer: {
    margin: '30px 0 0 30px',
  },
  actions: {},
  marginTopFrom: {marginTop: `10px`},
})

const CustomLabel = withStyles(styles)(props => {
  const {classes, ...others} = props

  return <Typography variant="body2" className={classes.mailLabel} {...others} />
})

const CustomMailLabel = withStyles(styles)(props => {
  const {classes, ...others} = props

  return <Typography variant="body2" className={classes.mailSubjectLabel} {...others} />
})
const CustomInput = withStyles(styles)(props => {
  const {classes, ...others} = props

  return <Input disableUnderline className={classes.mailInput} {...others} />
})

const CustomMultilineInput = withStyles(styles)(props => {
  const {classes, ...others} = props

  return <Input disableUnderline className={classes.multilineMailInput} {...others} />
})
const CustomTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      },
    },
  },
})(TextField)

const CustomDialog = withStyles(theme => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: 10,
    },
  },
  paperScrollPaper: {
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      maxHeight: 335,
    },
    [`${theme.breakpoints.down('xs')} and (orientation: landscape)`]: {
      maxHeight: 300,
    },
  },
}))(Dialog)

const CustomBtn = withStyles(theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      width: '165px',
    },
    width: '233px',
    height: '30px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    padding: 0,
  },
  label: {
    color: '#589A43',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: 300,
    lineHeight: '22px',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
}))(ButtonWithProgress)

const CancelBtn = withStyles({
  root: {
    height: '23px',
    width: '147px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    padding: 0,
  },
  label: {
    height: '17px',
    width: '130.3px',
    color: '#000000',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '17px',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
})(Button)

class ContactUsDialog extends Component {
  constructor(props) {
    super(props)

    const {name, email, subject, customSubject, message} = this.getDefaultMailValues()

    this.state = {
      name,
      email,
      subject,
      message,
      customSubject,
      sending: false,
    }
  }

  @autobind
  resetFields() {
    this.setState(this.getDefaultMailValues())
  }

  getDefaultMailValues() {
    const name = ''
    const email = ''
    const subject = ''
    const message = ''
    const customSubject = ''

    return {name, email, subject, customSubject, message}
  }

  handleChange(fieldName, e) {
    this.setState({[fieldName]: e.target.value})
  }

  onChangeSelect(fieldName, value) {
    this.setState({[fieldName]: value})
  }

  onChangeSubject(value) {
    this.setState({subject: value.target.value})
  }

  componentWillUnmount() {
    this.resetFields()
  }

  @autobind
  handleSubmit() {
    const {isComponentValid} = this.props

    if (isComponentValid()) {
      this.contactUs()
    }
  }

  @autobind
  async contactUs() {
    const {name, email, subject, customSubject, message} = this.state
    const {createNotificationFromError, createSuccess, onClose} = this.props
    const subjectText = !customSubject ? subject : customSubject

    try {
      this.setState({sending: true})
      await RecipeApi.contactUs({name, email, subject: subjectText, message})
      createSuccess('Feedback sent successfully.')
      onClose()
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      this.setState({sending: false})
    }
  }

  @autobind
  handleExited() {
    this.resetFields()
  }

  render() {
    const {open, classes, onClose} = this.props

    return (
      <CustomDialog open={open} fullWidth maxWidth="md" onClose={() => this.handleExited()}>
        <DialogTitle className={classes.dialogTitle}>
          <Typography className={classes.title}>Contact Recimee</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} id="contact-us-dialog">
          <div className={classes.dialogContentContainer}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <FormControl className={cx(classes.formControl)}>
                  <CustomLabel>Name:</CustomLabel>
                  <InputValidator shouldValidateOnBlur={true}>
                    <CustomInput
                      id="contact-us-name"
                      value={this.state.name}
                      required
                      onChange={this.handleChange.bind(this, 'name')}
                    />
                  </InputValidator>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl className={cx(classes.formControl)}>
                  <CustomLabel>Email:</CustomLabel>
                  <InputValidator shouldValidateOnBlur={true}>
                    <CustomInput
                      id="contact-us-email"
                      value={this.state.email}
                      required
                      onChange={this.handleChange.bind(this, 'email')}
                    />
                  </InputValidator>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl className={cx(classes.formControl, classes.marginTopFrom)}>
                  <CustomMailLabel>Subject:</CustomMailLabel>
                  <CustomTextField
                    id="standard-select-currency"
                    select
                    required
                    label="Select Subject"
                    variant="outlined"
                    margin="normal"
                    className={classes.textField}
                    value={this.state.subject}
                    onChange={this.onChangeSubject.bind(this)}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                      // MuiInputLabel: {
                      //   className: classes.menu,
                      // },
                    }}
                  >
                    {subjectsTypes.map(option => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </FormControl>
              </Grid>
              {this.state.subject === 'Something else entirely...' ? (
                <Grid item xs={12}>
                  <FormControl className={cx(classes.formControl)}>
                    <CustomLabel>Enter Subject Here:</CustomLabel>
                    <InputValidator shouldValidateOnBlur={true}>
                      <CustomInput
                        value={this.state.customSubject}
                        required
                        onChange={this.handleChange.bind(this, 'customSubject')}
                      />
                    </InputValidator>
                  </FormControl>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                <FormControl className={cx(classes.formControl, classes.marginTopFrom)}>
                  <CustomLabel>Message:</CustomLabel>
                  <InputValidator shouldValidateOnBlur={true}>
                    <CustomMultilineInput
                      id="contact-us-message"
                      multiline
                      rows="4"
                      value={this.state.message}
                      required
                      onChange={this.handleChange.bind(this, 'message')}
                    />
                  </InputValidator>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <Grid item xs={12} style={{maxHeight: 145}}>
          <div className={classes.btnContainer}>
            <CustomBtn
              disabled={this.state.sending}
              onClick={this.handleSubmit}
              isLoading={this.state.sending}
              id="contact-us-dialog-contact-us-btn"
            >
              Submit
            </CustomBtn>
            <div className={classes.cancelContainer}>
              <CancelBtn
                disabled={this.state.sending}
                onClick={onClose}
                id="contact-us-dialog-cancel-btn"
              >
                Cancel
              </CancelBtn>
            </div>
          </div>
          <DialogActions className={classes.actions} />
        </Grid>
      </CustomDialog>
    )
  }

  @autobind
  renderSubject() {
    const {classes} = this.props
    return (
      <FormControl className={cx(classes.formControl, classes.marginTopFrom)}>
        <CustomMailLabel>Subject:</CustomMailLabel>
        <CustomTextField
          id="standard-select-currency"
          select
          required
          label="Select Subject"
          variant="outlined"
          margin="normal"
          className={classes.textField}
          value={this.state.subject}
          onChange={this.onChangeSubject.bind(this)}
          SelectProps={{
            MenuProps: {
              className: classes.menu,
            },
            MuiInputLabel: {
              className: classes.menu,
            },
          }}
        >
          {subjectsTypes.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </CustomTextField>
      </FormControl>
    )
  }
}

ContactUsDialog.displayName = 'ContactUsDialog'

ContactUsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({createSuccess, createNotificationFromError}, dispatch)
}

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
  ValidatedComponent,
)(ContactUsDialog)

import settings from '../../../config/settings'
import axios from '../api/axios'

const {apiBaseURL} = settings

export default class RecipeApi {
  static createRecipe(payload) {
    return axios.post(`${apiBaseURL}/recipes`, payload)
  }

  static deleteRecipe(id) {
    return axios.delete(`${apiBaseURL}/recipes/${id}`)
  }

  static updateRecipe(recipeId, payload) {
    return axios.patch(`${apiBaseURL}/recipes/${recipeId}`, payload)
  }

  static getRecipe(id, params) {
    return axios.get(`${apiBaseURL}/recipes/${id}`, {params})
  }

  static publishRecipe(id, params, data = {}) {
    return axios.post(`${apiBaseURL}/recipes/${id}/publish`, data, {params})
  }

  static addToMyRecipes(data) {
    return axios.post(`${apiBaseURL}/recipes/add-to-my-recipes`, data)
  }

  static alreadyAdded(id) {
    return axios.get(`${apiBaseURL}/recipes/${id}/already-added`)
  }

  static getRecipes(params) {
    return axios.get(`${apiBaseURL}/recipes`, {params})
  }

  static getRecipeFromUrl(url) {
    return axios.post(`${settings.apiBaseURL}/recipes/import-recipe-url`, {url})
  }

  static shareRecipe(id, email) {
    return axios.post(`${apiBaseURL}/recipes/${id}/share`, {email})
  }

  static getTagSuggestions(q) {
    return axios.get(`${apiBaseURL}/tag-search?q=${q}`)
  }

  static inviteFriends(payload) {
    return axios.post(`${apiBaseURL}/recipes/invite-friends`, payload)
  }

  static contactUs(payload) {
    return axios.post(`${apiBaseURL}/contact-us`, payload)
  }

  static getFeaturedRecipes() {
    return axios.get(`${apiBaseURL}/featured-recipes`)
  }
}

import React from 'react'
import {Button, IconButton, Typography, withStyles, Paper, Grid} from '@material-ui/core'
import autobind from 'autobind-decorator'
import Linkify from 'react-linkify'
import {Link, withRouter} from 'react-router'
import Pagination from 'rc-pagination'
import {ChevronLeft, ChevronRight} from '@material-ui/icons'
import PropTypes from 'prop-types'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import _ from 'lodash'
import SearchApi from './api'
import ProfileApi from '../profile/api'
import Loading from '../common/Loading'
import defaultFood from '../../assets/defaultFood.png'
import {getCost, getDifficulty} from '../recipe/utils'
import {createSuccess, createNotificationFromError} from '../common/redux/actions.notifications'
//import * as ProfileActions from '../profile/redux/actions'

const styles = theme => ({
  fixedContent: {
    height: `fit-content`,
    paddingLeft: 20,
    paddingRight: 20,
  },
  title: {
    fontFamily: 'Lato',
    fontSize: '24px',
    lineHeight: '29px',
    fontWeight: '300',

    marginTop: '21px',
    marginBottom: '24px',
  },
  imageContainer: {
    aspectRatio: '1',
    display: 'flex',
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
  imageContainerFood: {
    aspectRatio: '1',
    display: 'flex',
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
  body: {
    padding: '20px',
  },
  recipeTitle: {
    fontFamily: 'Literata',
    fontSize: '32px',
    fontWeight: '400',
    lineHeight: '36px',
    textDecoration: 'none',
    color: '#000',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      lineHeight: '20px',
      textDecoration: 'break-word',
    },
  },
  source: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
    textAlign: 'right',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      textAlign: 'left',
    },
  },
  description: {
    lineHeight: '24px',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '16px',
    marginTop: '10px',
    overflowY: 'auto',
    height: 160,
    whiteSpace: 'normal',
    textOverflow: 'ellipsis',
  },
  footer: {
    marginTop: '15px',
  },
  recipeExtra: {
    marginTop: '15px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      marginTop: '5px',
    },
  },
  lastExtra: {
    marginTop: '15px',
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
      marginTop: '5px',
    },
  },
  labelValue: {
    display: 'inline-block',
  },
  label: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '19px',
    display: 'inline-block',
    marginRight: '10px',
  },
  item: {
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '19px',
    textTransform: 'capitalize',
    display: 'inline-block',
  },
  pagination: {
    marginTop: '30px',
    '& li': {
      display: 'inline-block',
    },
  },
  pageNumbersContainer: {
    margin: 0,
    textAlign: 'center',
    padding: 0,
  },
  tabActive: {
    background: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    color: '#589A43',
    cursor: 'default',
  },
  tabBadge: {
    fontFamily: 'Literata, sans-serif',
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '32px',
    textAlign: 'center',
    color: 'white',
    borderRadius: '50%',
    position: 'absolute',
    top: -8,
    right: -8,
    width: 33,
    height: 33,
    backgroundColor: '#F89521',
  },
  followContainer: {
    textAlign: 'right',
  },
  unfollow: {
    background: 'white',
    color: '#589A43',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.12)',
  },
  userResult: {},
})

const SearchPaper = withStyles({
  root: {
    width: `100%`,
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 10px 0 rgba(0,0,0,0.19)',
    marginBottom: '19px',
  },
})(Paper)

const NavBtn = withStyles({
  root: {
    color: '#000',
    fontFamily: 'Lato',

    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '21px',
    borderRadius: '17.5px',
    padding: 'unset',
    width: '35px',
    height: '35px',
    minWidth: 'unset',
  },
})(Button)

const TabBtn = withStyles({
  root: {
    color: 'white',
    fontFamily: 'Lato, sans-serif',
    fontSize: 20,
    lineHeight: '24px',
    textAlign: 'center',
    fontWeight: 300,
    width: 127,
    height: 62,
    borderRadius: '21px',
    background: '#589A43',
    textTransform: 'none',
    marginRight: 24,
    marginBottom: 24,
    position: 'relative',
  },
})(Button)

const FollowBtn = withStyles({
  root: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: 300,
    fontSize: 18,
    lineHeight: '22px',
    color: 'white',
    width: 167,
    height: 35,
    background: '#589A43',
    borderRadius: '34.5px',
    textTransform: 'none',
  },
})(Button)

class SearchResults extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      recipes: [],
      totalRecipes: 0,
      users: [],
      totalUsers: 0,
      articles: [],
      totalArticles: 0,
      tab: props.location.query.type ? props.location.query.type : 'recipes',
      searching: false,
    }
  }

  getCurrentTabTotal() {
    switch (this.state.tab) {
      case 'users':
        return this.state.totalUsers
      case 'articles':
        return this.state.totalArticles
      default:
      case 'recipes':
        return this.state.totalRecipes
    }
  }

  componentDidMount() {
    this.doSearch()
  }

  componentDidUpdate(prevProps) {
    const {location} = this.props
    const {location: prevLocation} = prevProps

    const qEqual = location.query.q === prevLocation.query.q
    const pageEqual = location.query.page === prevLocation.query.page

    if (!qEqual || !pageEqual) {
      this.doSearch()
    }
  }

  @autobind
  switchTab(newTab) {
    const {location} = this.props
    const {tab} = this.state
    if (tab === newTab) return
    const query = {q: location.query.q, page: 1, type: newTab}
    this.context.router.push({pathname: '/search', query})
    this.setState({tab: newTab})
  }

  @autobind
  async doSearch() {
    const {location, createNotificationFromError} = this.props
    const {tab} = this.state

    try {
      this.setState({searching: true})
      const resRecipes = await SearchApi.search({
        q: location.query.q,
        page: tab === 'recipes' && location.query.page ? location.query.page : 1,
      })
      const resUsers = await SearchApi.searchUser({
        q: location.query.q,
        page: tab === 'users' && location.query.page ? location.query.page : 1,
      })
      const resArticles = await SearchApi.searchArticle({
        q: location.query.q,
        page: tab === 'articles' && location.query.page ? location.query.page : 1,
      })
      this.setState({
        recipes: resRecipes.results,
        totalRecipes: resRecipes.count,
        users: resUsers.results,
        totalUsers: resUsers.count,
        articles: resArticles.results,
        totalArticles: resArticles.count,
      })
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      this.setState({searching: false})
    }
  }

  @autobind
  canGoPrev() {
    const {location} = this.props
    const current = location.query.page || 1
    const prev = Number(current) - 1

    if (prev > 0) {
      return true
    }

    return false
  }

  @autobind
  canGoNext() {
    const {location} = this.props
    const current = location.query.page || 1
    const next = Number(current) + 1

    if (next <= Math.ceil(this.getCurrentTabTotal() / 6)) {
      return true
    }

    return false
  }

  @autobind
  goPrev() {
    const {location} = this.props
    const {tab} = this.state
    const current = location.query.page || 1
    const prev = Number(current) - 1

    if (prev > 0) {
      const query = {q: location.query.q, page: prev, type: tab}
      this.context.router.push({pathname: '/search', query})
    }
  }

  @autobind
  goNext() {
    const {location} = this.props
    const {tab} = this.state
    const current = location.query.page || 1
    const next = Number(current) + 1

    if (next <= Math.ceil(this.getCurrentTabTotal() / 6)) {
      const query = {q: location.query.q, page: next, type: tab}
      this.context.router.push({pathname: '/search', query})
    }
  }

  getServings(recipe) {
    let servings

    if (!!recipe.minServings && !!recipe.maxServings) {
      servings = `${recipe.minServings}–${recipe.maxServings} People`
    } else if (recipe.minServings) {
      servings = `${recipe.minServings} ` + (recipe.minServings === 1 ? 'Person' : 'People')
    }

    return servings
  }

  getPageItem(current, type, element) {
    if (type === 'page') {
      return <NavBtn>{current}</NavBtn>
    }
    return element
  }

  @autobind
  onPageChange(current) {
    const {location} = this.props
    const {tab} = this.state
    const query = {q: location.query.q, page: current, type: tab}
    this.context.router.push({pathname: '/search', query})
  }

  @autobind
  showPagination() {
    const {searching} = this.state

    if (searching || this.getCurrentTabTotal() <= 6) {
      return false
    }

    return true
  }

  @autobind
  clickRecipes(e) {
    e.preventDefault()
    this.switchTab('recipes')
  }

  @autobind
  clickArticles(e) {
    e.preventDefault()
    this.switchTab('articles')
  }

  async followUser(id) {
    const {users} = this.state
    const {createSuccess} = this.props
    const index = _.findIndex(users, {id})
    try {
      ProfileApi.followUser(id)
      if (index !== -1) {
        const user = this.state.users[index]
        const fullName =
          user.firstName || user.lastName
            ? (user.firstName ? user.firstName + ' ' : '') + (user.lastName ? user.lastName : '')
            : user.username
        createSuccess('Now following ' + fullName)
        this.setState(({users}) => ({
          users: [
            ...users.slice(0, index),
            {
              ...users[index],
              userIsFollowing: true,
            },
            ...users.slice(index + 1),
          ],
        }))
      }
    } catch (e) {
      createNotificationFromError(e)
    }
  }

  async unfollowUser(id) {
    const {users} = this.state
    const {createSuccess} = this.props
    const index = _.findIndex(users, {id})
    try {
      ProfileApi.unfollowUser(id)
      if (index !== -1) {
        const user = this.state.users[index]
        const fullName =
          user.firstName || user.lastName
            ? (user.firstName ? user.firstName + ' ' : '') + (user.lastName ? user.lastName : '')
            : user.username
        createSuccess('No longer following ' + fullName)
        this.setState(({users}) => ({
          users: [
            ...users.slice(0, index),
            {
              ...users[index],
              userIsFollowing: false,
            },
            ...users.slice(index + 1),
          ],
        }))
      }
    } catch (e) {
      createNotificationFromError(e)
    }
  }

  @autobind
  clickUsers(e) {
    e.preventDefault()
    this.switchTab('users')
  }

  @autobind
  renderTabs() {
    const {classes} = this.props
    const {tab, totalRecipes, totalUsers, totalArticles} = this.state
    return (
      <Grid container item>
        <Grid item xs={12}>
          <TabBtn
            disableRipple={tab === 'recipes'}
            className={tab === 'recipes' ? classes.tabActive : ''}
            onClick={this.clickRecipes}
          >
            Recipes
            <div className={classes.tabBadge}>{totalRecipes}</div>
          </TabBtn>
          <TabBtn
            disableRipple={tab === 'users'}
            className={tab === 'users' ? classes.tabActive : ''}
            onClick={this.clickUsers}
          >
            People
            <div className={classes.tabBadge}>{totalUsers}</div>
          </TabBtn>
          <TabBtn
            disableRipple={tab === 'articles'}
            className={tab === 'articles' ? classes.tabActive : ''}
            onClick={this.clickArticles}
          >
            Articles
            <div className={classes.tabBadge}>{totalArticles}</div>
          </TabBtn>
        </Grid>
      </Grid>
    )
  }

  @autobind
  renderSearchResultsAndTabs() {
    return (
      <React.Fragment>
        {this.renderTabs()}
        {this.renderSearchResults()}
      </React.Fragment>
    )
  }

  @autobind
  renderSearchResults() {
    const {tab, recipes, users, articles} = this.state
    const {classes, profile} = this.props
    switch (tab) {
      case 'users':
        if (users.length <= 0) {
          return <Typography variant="body2">No Users found.</Typography>
        }
        return users.map(user => {
          const fullName =
            user.firstName || user.lastName
              ? (user.firstName ? user.firstName + ' ' : '') + (user.lastName ? user.lastName : '')
              : user.username
          const bio = user.bio ? user.bio : 'This user has not yet written a bio.'
          const showFollowButton =
            typeof user.userIsFollowing !== 'undefined' &&
            profile.profile &&
            profile.profile.id !== user.id
          return (
            <SearchPaper key={user.id} className={classes.userResult}>
              <Grid container>
                <Grid
                  item
                  xs={3}
                  style={{backgroundImage: `url(${user.avatar.fullSize})`}}
                  className={classes.imageContainer}
                  data-test-name="user-image"
                />
                <Grid item xs={9}>
                  <div className={classes.body}>
                    <Grid container>
                      <Grid item xs={8}>
                        <Link
                          data-test-name="user-username"
                          to={`/users/${user.id}`}
                          className={classes.recipeTitle}
                        >
                          {fullName}
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={9}>
                        <Typography className={classes.description} variant="body2">
                          {bio}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} />
                      <Grid item xs={12} className={classes.followContainer}>
                        {showFollowButton && (
                          <FollowBtn
                            className={user.userIsFollowing ? classes.unfollow : null}
                            onClick={() =>
                              user.userIsFollowing
                                ? this.unfollowUser(user.id)
                                : this.followUser(user.id)
                            }
                          >
                            {user.userIsFollowing ? 'Unfollow' : 'Follow'}
                          </FollowBtn>
                        )}
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </SearchPaper>
          )
        })
      case 'articles':
        if (articles.length <= 0) {
          return <Typography variant="body2">No Articles found.</Typography>
        }
        return articles.map(article => {
          return (
            <SearchPaper key={article.id}>
              <Grid container>
                <Grid container xs={12} sm={3} md={3}>
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    {article.image === null ? (
                      <div
                        style={{backgroundImage: `url(${defaultFood})`}}
                        className={classes.imageContainerFood}
                        data-test-name="default-image"
                      />
                    ) : (
                      <div
                        style={{backgroundImage: `url(${article.image})`}}
                        className={classes.imageContainerFood}
                        data-test-name="article-image"
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={9} md={9}>
                  <div className={classes.body}>
                    <Grid container>
                      <Grid item xs={12} sm={8} md={8} lg={8}>
                        <Link
                          data-test-name="article-title"
                          to={`/article/${article.id}/${article.slug}`}
                          className={classes.recipeTitle}
                        >
                          {article.title}
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Typography className={classes.source} variant="body2">
                          by:{' '}
                          <Linkify
                            componentDecorator={(href, text, key) => (
                              <a href={href} key={key} target="_blank" rel="noopener noreferrer">
                                {text}
                              </a>
                            )}
                          >
                            {article.author}
                          </Linkify>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={9}>
                        <Typography
                          className={classes.description}
                          variant="body2"
                          dangerouslySetInnerHTML={{__html: article.description}}
                        />
                      </Grid>
                      <Grid item xs={3} />
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </SearchPaper>
          )
        })
      default:
      case 'recipes':
        if (recipes.length <= 0) {
          return <Typography variant="body2">No Recipes found.</Typography>
        }
        return recipes.map(recipe => {
          return (
            <SearchPaper key={recipe.id}>
              <Grid container>
                <Grid container xs={12} sm={3} md={3}>
                  <div
                    style={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    {recipe.image === null ? (
                      <div
                        style={{backgroundImage: `url(${defaultFood})`}}
                        className={classes.imageContainerFood}
                        data-test-name="default-image"
                      />
                    ) : (
                      <div
                        style={{backgroundImage: `url(${recipe.image})`}}
                        className={classes.imageContainerFood}
                        data-test-name="recipe-image"
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} sm={9} md={9}>
                  <div className={classes.body}>
                    <Grid container>
                      <Grid item xs={12} sm={8} md={8} lg={8}>
                        <Link
                          data-test-name="recipe-title"
                          to={`/recipe/${recipe.id}/${recipe.slug}`}
                          className={classes.recipeTitle}
                        >
                          {recipe.title}
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Typography className={classes.source} variant="body2">
                          by:{' '}
                          <Linkify
                            componentDecorator={(href, text, key) => (
                              <a href={href} key={key} target="_blank" rel="noopener noreferrer">
                                {text}
                              </a>
                            )}
                          >
                            {recipe.source}
                          </Linkify>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={9}>
                        <Typography
                          className={classes.description}
                          variant="body2"
                          dangerouslySetInnerHTML={{__html: recipe.description}}
                        />
                      </Grid>
                      <Grid item xs={3} />
                    </Grid>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        md={3}
                        lg={3}
                        className={classes.recipeExtra}
                        style={{textAlign: 'left'}}
                      >
                        <Typography className={classes.label}>Total Time</Typography>
                        <Typography className={classes.labelValue}>
                          {recipe.prepTime && recipe.cookTime
                            ? `${recipe.prepTime + recipe.cookTime} min`
                            : ''}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3} lg={3} className={classes.recipeExtra}>
                        <Typography className={classes.label}>Difficulty</Typography>

                        <Typography className={classes.labelValue}>
                          {getDifficulty(recipe) || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3} lg={3} className={classes.recipeExtra}>
                        <Typography className={classes.label}>Serving Size</Typography>
                        <Typography className={classes.item}>
                          {this.getServings(recipe) || 'N/A'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3} lg={3} className={classes.lastExtra}>
                        <Typography className={classes.label}>Cost</Typography>
                        <Typography className={classes.labelValue}>
                          {getCost(recipe) || 'N/A'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </SearchPaper>
          )
        })
    }
  }

  render() {
    const {classes, location} = this.props
    const {searching} = this.state

    const current = location.query.page || 1
    const total = this.getCurrentTabTotal()

    return (
      <React.Fragment>
        <Grid container item className={classes.fixedContent}>
          <Grid item xs={12}>
            <div id="search-title" className={classes.title}>
              Search Results for "{location.query.q}"
            </div>
            {searching ? <Loading /> : this.renderSearchResultsAndTabs()}
          </Grid>
          {!this.showPagination() ? null : (
            <Grid container item className={classes.pagination}>
              <Grid item xs={1}>
                <IconButton onClick={this.goPrev} disabled={!this.canGoPrev()}>
                  <ChevronLeft />
                </IconButton>
              </Grid>
              <Grid item xs={10}>
                <Pagination
                  total={total}
                  pageSize={6}
                  className={classes.pageNumbersContainer}
                  itemRender={this.getPageItem}
                  onChange={this.onPageChange}
                  current={Number(current)}
                />
              </Grid>
              <Grid item xs={1} style={{textAlign: 'right'}}>
                <IconButton onClick={this.goNext} disabled={!this.canGoNext()}>
                  <ChevronRight />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    )
  }
}

SearchResults.displayName = 'SearchResults'

SearchResults.contextTypes = {
  router: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({createSuccess, createNotificationFromError}, dispatch)
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(SearchResults)

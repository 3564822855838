import {call, put, takeEvery, all} from 'redux-saga/effects'
import UserApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as ResponseActions from '../../common/redux/actions.responseStatus'

export default function* publicProfileFlow() {
  yield all([takeEvery(Types.PUBLIC_PROFILE_GET, getPublicProfile)])
}

function* getPublicProfile(action) {
  const {id} = action

  try {
    const user = yield call(UserApi.getUser, id)
    yield put(Actions.getPublicProfileSuccess(user))
  } catch (error) {
    yield put(Actions.getPublicProfileFailure(error))
    yield put(ResponseActions.setResponseStatus(404))
  }
}

import axios from 'axios'
import UserSession from '../auth/UserSession'

const fileAxios = axios.create()

fileAxios.interceptors.request.use(request => {
  const authToken = UserSession.getToken()
  if (authToken) {
    if (request.headers && !request.headers.Authorization) {
      request.headers.Authorization = `Token ${authToken}`
    }
  }
  return request
})

export default fileAxios

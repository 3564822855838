export const ADD_SHOPPING_ITEM_BY_TITLE = 'ADD_SHOPPING_ITEM_BY_TITLE'
export const ADD_SHOPPING_ITEM_BY_TITLE_SUCCESS = 'ADD_SHOPPING_ITEM_BY_TITLE_SUCCESS'
export const ADD_SHOPPING_ITEM_BY_TITLE_FAILURE = 'ADD_SHOPPING_ITEM_BY_TITLE_FAILURE'

export const UPDATE_SHOPPING_ITEM_BY_TITLE = 'UPDATE_SHOPPING_ITEM_BY_TITLE'
export const UPDATE_SHOPPING_ITEM_BY_TITLE_SUCCESS = 'UPDATE_SHOPPING_ITEM_BY_TITLE_SUCCESS'
export const UPDATE_SHOPPING_ITEM_BY_TITLE_FAILURE = 'UPDATE_SHOPPING_ITEM_BY_TITLE_FAILURE'

export const ADD_SHOPPING_ITEM = 'ADD_SHOPPING_ITEM'
export const ADD_SHOPPING_ITEM_SUCCESS = 'ADD_SHOPPING_ITEM_SUCCESS'
export const ADD_SHOPPING_ITEM_FAILURE = 'ADD_SHOPPING_ITEM_FAILURE'

export const GET_SHOPPING_ITEMS = 'GET_SHOPPING_ITEMS'
export const GET_SHOPPING_ITEMS_SUCCESS = 'GET_SHOPPING_ITEMS_SUCCESS'
export const GET_SHOPPING_ITEMS_FAILURE = 'GET_SHOPPING_ITEMS_FAILURE'

export const DELETE_SHOPPING_ITEM = 'DELETE_SHOPPING_ITEM'
export const DELETE_SHOPPING_ITEM_SUCCESS = 'DELETE_SHOPPING_ITEM_SUCCESS'
export const DELETE_SHOPPING_ITEM_FAILURE = 'DELETE_SHOPPING_ITEM_FAILURE'

export const UPDATE_SHOPPING_ITEM = 'UPDATE_SHOPPING_ITEM'
export const UPDATE_SHOPPING_ITEM_SUCCESS = 'UPDATE_SHOPPING_ITEM_SUCCESS'
export const UPDATE_SHOPPING_ITEM_FAILURE = 'UPDATE_SHOPPING_ITEM_FAILURE'

export const ADD_ALL_SHOPPING_ITEMS_FROM_RECIPE = 'ADD_ALL_SHOPPING_ITEMS_FROM_RECIPE'
export const ADD_ALL_SHOPPING_ITEMS_FROM_RECIPE_SUCCESS =
  'ADD_ALL_SHOPPING_ITEMS_FROM_RECIPE_SUCCESS'
export const ADD_ALL_SHOPPING_ITEMS_FROM_RECIPE_FAILURE =
  'ADD_ALL_SHOPPING_ITEMS_FROM_RECIPE_FAILURE'

export const DELETE_ALL_SHOPPING_ITEMS = 'DELETE_ALL_SHOPPING_ITEMS'
export const DELETE_ALL_SHOPPING_ITEMS_SUCCESS = 'DELETE_ALL_SHOPPING_ITEMS_SUCCESS'
export const DELETE_ALL_SHOPPING_ITEMS_FAILURE = 'DELETE_ALL_SHOPPING_ITEMS_FAILURE'

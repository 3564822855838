import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import {
  Grid,
  Typography,
  Paper,
  IconButton,
  InputAdornment,
  CircularProgress,
  MenuItem,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import {withStyles} from '@material-ui/core/styles'
import autobind from 'autobind-decorator'
import cx from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import URLSearchParams from 'url-search-params'
import _ from 'lodash'
import MyKitchenStyles from '../myKitchen/MyKitchen.styles.js'
import {DetailTextField} from '../recipe/CreateRecipeFlow/CreateRecipe.styles.js'
import {getCalendarItems, deleteCalendarItem, calendarItemMoveLocal} from './redux/actions'
import RecipeApi from '../recipe/api'
import MyKitchenApi from '../myKitchen/api'
import CalendarRecipe from './CalendarRecipe'
import CalendarDay from './CalendarDay'
import {styles as CalendarItemStyles} from './CalendarItem'
import CalendarItemApi from './api'
import {createSuccess, createNotificationFromError} from '../common/redux/actions.notifications'
import RCESelect from '../common/styles/RCESelect'
import PageUtils from '../utils/PageUtils'

class MyRecipes extends React.Component {
  componentDidMount() {
    PageUtils.scrollToTop()
  }

  constructor(props) {
    super(props)

    this.state = {searchParams: '', category: 'rce-all'}
  }

  handleChange(name, event) {
    this.setState({[name]: event.target.value})
  }

  @autobind
  handleOnSearch(evt) {
    evt.preventDefault()
    this.doSearch()
  }

  @autobind
  handleOnKeyPress(evt) {
    if (evt.key === 'Enter') {
      evt.preventDefault()
      this.doSearch()
    }
  }

  @autobind
  handleCategoryChange(evt) {
    this.setState({category: evt.target.value}, () => {
      this.doSearch()
    })
  }

  @autobind
  doSearch() {
    const {handleSearch} = this.props
    const {searchParams, category} = this.state
    const params = {q: searchParams}
    if (category !== 'rce-all') {
      params.categories = category
    }
    handleSearch(params)
  }

  render() {
    const {classes, loading, categories} = this.props

    return (
      <Grid item xs={12} md={3}>
        <Typography variant="subtitle1" component="h5" className={classes.sidebarTitle}>
          All My Recipes
        </Typography>
        <DetailTextField
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={this.handleOnSearch} id="recipeSearchSubmit">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          className={classes.sidebarSearchInput}
          placeholder="Search"
          onChange={this.handleChange.bind(this, 'searchParams')}
          onKeyPress={this.handleOnKeyPress}
          disabled={loading}
          id="recipeSearch"
        />
        <RCESelect
          disabled={loading}
          fullWidth
          onChange={this.handleCategoryChange}
          id="recipeCategories"
          disableUnderline
          value={this.state.category}
        >
          <MenuItem value="rce-all">All Categories</MenuItem>
          {categories.map(cat => {
            return (
              <MenuItem key={cat.id} value={cat.id}>
                <Typography variant="inherit" noWrap>
                  {cat.name}
                </Typography>
              </MenuItem>
            )
          })}
        </RCESelect>

        <div className={classes.greenLineSeparator} />

        {loading ? (
          <div style={{textAlign: 'center'}}>
            <CircularProgress />
          </div>
        ) : (
          this.renderCalendarRecipes()
        )}
      </Grid>
    )
  }

  renderCalendarRecipes() {
    const {classes, recipes, beginDrag, endDrag} = this.props

    if (recipes.length === 0) {
      return <Typography>No recipes to display.</Typography>
    }

    return (
      <React.Fragment>
        {recipes.map(recipe => {
          return (
            <CalendarRecipe
              key={recipe.id}
              classes={classes}
              recipe={recipe}
              beginDrag={beginDrag}
              endDrag={endDrag}
            />
          )
        })}
      </React.Fragment>
    )
  }
}

class CalendarPage extends React.Component {
  constructor(props) {
    super(props)
    let startOfWeek = moment().day(0)
    let endOfWeek = moment().day(6)

    this.params = new URLSearchParams(props.location.search)
    const dateStart = this.params.get('date_start')
    if (dateStart) {
      startOfWeek = moment(dateStart, 'YYYY-MM-DD').day(0)
      endOfWeek = moment(dateStart, 'YYYY-MM-DD').day(6)
    }

    this.state = {
      startOfWeek,
      endOfWeek,
      recipes: [],
      isFetchingRecipes: false,
      isFetchingCategories: false,
      categories: [],
      isDraggingItem: false,
      daysWithMoving: new Set(),
    }
  }

  @autobind
  beginDraggingCalendarItem() {
    this.setState({isDraggingItem: true})
  }

  @autobind
  stopDraggingCalendarItem() {
    this.setState({isDraggingItem: false})
  }

  componentDidMount() {
    this.getCalendarItems()
    this.getRecipes()
    this.getCategories()
  }

  @autobind
  handleSearch(params) {
    this.getRecipes(params)
  }

  @autobind
  async getRecipes(params = {}) {
    const {profile} = this.props
    if (profile && profile.profile) {
      const _params = {...params, user: profile.profile.id, status: 'published'}
      try {
        this.setState({isFetchingRecipes: true})
        const response = await RecipeApi.getRecipes(_params)
        this.setState({recipes: response})
      } catch (e) {
        console.log(e)
      } finally {
        this.setState({isFetchingRecipes: false})
      }
    }
  }

  @autobind
  async getCategories() {
    const {profile} = this.props
    if (profile && profile.profile) {
      try {
        this.setState({isFetchingCategories: true})
        const response = await MyKitchenApi.getCategories(profile.profile.id)
        this.setState({categories: response.results})
      } catch (e) {
        console.log(e)
      } finally {
        this.setState({isFetchingCategories: false})
      }
    }
  }

  @autobind
  setupQueryParams() {
    this.params = new URLSearchParams(this.props.location.search)
    const dateStart = this.params.get('date_start')
    if (dateStart) {
      this.setState(
        {
          startOfWeek: moment(dateStart, 'YYYY-MM-DD').day(0),
          endOfWeek: moment(dateStart, 'YYYY-MM-DD').day(6),
        },
        () => {
          this.getCalendarItems()
        },
      )
    } else {
      this.getCalendarItems()
    }
  }

  @autobind
  setQueryToRouter() {
    const search = `?date_start=${this.state.startOfWeek.format('YYYY-MM-DD')}`
    this.props.router.push({
      pathname: this.props.location.pathname,
      search,
    })
  }

  @autobind
  getCalendarItems() {
    this.setQueryToRouter()
    this.props.getCalendarItems({
      date_start: this.state.startOfWeek.format('YYYY-MM-DD'),
      date_end: this.state.endOfWeek.format('YYYY-MM-DD'),
      expand: 'recipe',
    })
  }

  @autobind
  handlePrevWeek(e) {
    e.preventDefault()
    this.setState(
      state => ({
        startOfWeek: state.startOfWeek.day(-7),
        endOfWeek: state.endOfWeek.day(-1),
      }),
      () => {
        this.getCalendarItems()
      },
    )
  }

  @autobind
  handleNextWeek(e) {
    e.preventDefault()
    this.setState(
      state => ({
        startOfWeek: state.startOfWeek.day(7),
        endOfWeek: state.endOfWeek.day(13),
      }),
      () => {
        this.getCalendarItems()
      },
    )
  }

  @autobind
  addDayIndexToMoving(dayIndices) {
    const {daysWithMoving} = this.state

    const newDaysMoving = new Set(daysWithMoving.values())
    dayIndices.forEach(index => newDaysMoving.add(index))
    this.setState({daysWithMoving: newDaysMoving})
  }

  removeDayIndexFromMoving(dayIndices) {
    const {daysWithMoving} = this.state

    const newDaysMoving = new Set(daysWithMoving.values())
    dayIndices.forEach(index => newDaysMoving.delete(index))
    this.setState({daysWithMoving: newDaysMoving})
  }

  @autobind
  async moveCalItem(id, fromDate, toDate, fromDayIndex, toDayIndex) {
    const {calendarItemMoveLocal, createSuccess, createNotificationFromError} = this.props
    const dayIndices = [fromDayIndex, toDayIndex]
    try {
      this.addDayIndexToMoving(dayIndices)
      const payload = {calItem: id, toDate}
      const item = await CalendarItemApi.move(payload)
      calendarItemMoveLocal(item, fromDate, toDate)
      createSuccess('Calendar Item moved successfully.')
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      this.removeDayIndexFromMoving(dayIndices)
    }
  }

  @autobind
  hasCalItemMoving(dayIndex) {
    const {daysWithMoving} = this.state

    return daysWithMoving.has(dayIndex)
  }

  getDayItems(items, date) {
    return _.get(items, date.format('YYYYMMDD'), [])
  }

  render() {
    const {classes, items, isLoadingItems} = this.props
    const {
      startOfWeek,
      endOfWeek,
      categories,
      recipes,
      isFetchingRecipes,
      isFetchingCategories,
      isDraggingItem,
    } = this.state

    const loading = isFetchingCategories || isFetchingRecipes

    return (
      <Grid item xs={12}>
        <Paper className={classes.root} elevation={1}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={9} className={classes.calendarContainer}>
              <Typography variant="h5" component="h3" className={classes.calendarTitle}>
                <IconButton
                  onClick={this.handlePrevWeek}
                  className={cx(classes.navButton, classes.navButtonLeft)}
                  id="prevWeek"
                >
                  <FontAwesomeIcon icon={faChevronLeft} color="#589a43" />
                </IconButton>
                {`${startOfWeek.format('MMMM D')} - ${endOfWeek.format('MMMM D, YYYY')}`}
                <IconButton
                  onClick={this.handleNextWeek}
                  className={cx(classes.navButton, classes.navButtonRight)}
                  id="nextWeek"
                >
                  <FontAwesomeIcon icon={faChevronRight} color="#589a43" />
                </IconButton>
              </Typography>

              <div className={classes.calendar}>
                {[0, 1, 2, 3, 4, 5, 6].map(dayIndex => {
                  const date = moment(this.state.startOfWeek).day(dayIndex)
                  return (
                    <CalendarDay
                      dayIndex={dayIndex}
                      date={date}
                      isLoadingItems={isLoadingItems}
                      items={this.getDayItems(items, date)}
                      classes={classes}
                      key={dayIndex}
                      isDraggingItem={isDraggingItem}
                      moveCalItem={this.moveCalItem}
                      hasCalItemMoving={this.hasCalItemMoving(dayIndex)}
                    />
                  )
                })}
              </div>
            </Grid>
            <MyRecipes
              classes={classes}
              handleSearch={this.handleSearch}
              loading={loading}
              recipes={recipes}
              categories={categories}
              beginDrag={this.beginDraggingCalendarItem}
              endDrag={this.stopDraggingCalendarItem}
            />
          </Grid>
        </Paper>
      </Grid>
    )
  }
}

CalendarPage.displayName = 'CalendarPage'

function mapStateToProps(state) {
  return {
    ...state.calendar,
    profile: state.profile,
  }
}

CalendarPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createNotificationFromError,
      createSuccess,
      getCalendarItems,
      deleteCalendarItem,
      calendarItemMoveLocal,
    },
    dispatch,
  )
}

const styles = theme => ({
  ...CalendarItemStyles,
  ...MyKitchenStyles(theme),
  root: {
    padding: '25px 30px',
    backgroundColor: '#fff',
  },
  calendarContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: '0 !important',
    },
    minHeight: '100%',
  },
  calendar: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    minHeight: '100%',
    paddingBottom: 73,
  },
  calendarTitle: {
    textAlign: 'center',
    fontFamily: 'Literata',
    fontWeight: '400',

    fontSize: 24,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  calendarDay: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderRight: '2px solid #EFEFEF',
    minWidth: 80,
    padding: '8px',
    '&:last-child': {
      borderRight: 'none',
    },
  },
  calendarDayTitle: {
    borderBottom: '2px solid #589a43',
    padding: 8,
    margin: '-9px -9px 10px -9px',
    fontWeight: 'normal',
    fontSize: 12,
    textAlign: 'center',
  },
  navButton: {
    verticalAlign: 'middle',
    fontSize: 18,
  },
  navButtonLeft: {
    marginRight: 70,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  navButtonRight: {
    marginLeft: 70,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  calendarDayList: {
    textAlign: 'center',
    height: '100%',
  },
  sidebarTitle: {
    fontWeight: 'bold',
    marginTop: 62,
    marginBottom: 8,
  },
  sidebarSearchInput: {
    paddingRight: 1,
    marginBottom: 10,
  },
  greenLineSeparator: {
    backgroundColor: '#589a43',
    height: 3,
    width: 'calc(100% + 20px)',
    margin: '20px -10px',
  },
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(CalendarPage)

import React from 'react'
import Helmet from 'react-helmet'
import {Card, CardContent, Typography, Input, Grid, Button, withStyles} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import InputValidator from '../common/InputValidator.jsx'
import validatedComponent from '../common/ValidatedComponent.jsx'
import * as Actions from './redux/actions.forgotPw'
import styles from '../common/styles/CardLayout.styles'

class ForgotPasswordRequest extends React.Component {
  constructor() {
    super()

    this.state = {
      email: '',
    }
  }

  componentDidMount() {
    this.props.initForgotPw()
  }

  onChange(e) {
    this.setState({email: e.target.value})
  }

  onResetClick(e) {
    e.preventDefault()

    const {isComponentValid, requestForgotPw} = this.props
    if (isComponentValid()) {
      requestForgotPw(this.state.email)
    }
  }

  render() {
    const {email} = this.state
    const {isSent, errors, classes} = this.props
    return (
      <div className={classes.authPage}>
        <Helmet title="Reset password" />
        <Card className={classes.cardCentered}>
          <CardContent className={classes.cardContent}>
            <Typography component="h2" variant="h2" className={classes.title} gutterBottom>
              Reset Password
            </Typography>
            {!isSent ? (
              <form noValidate>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <InputValidator fieldName="Email" errors={errors.email}>
                      <Input
                        type="email"
                        required
                        placeholder="Forgot Password Email*"
                        value={email}
                        onChange={e => this.setState({email: e.target.value})}
                      />
                    </InputValidator>
                  </Grid>

                  <Grid item xs={12}>
                    <Button onClick={this.onResetClick.bind(this)} variant="contained">
                      Send reset password email
                    </Button>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <p>
                    Reset password email has been sent to <b>{email}</b>. Please follow the
                    instructions from the email.
                  </p>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch)
}

function mapStateToProps(state) {
  return {...state.forgotPw}
}

ForgotPasswordRequest.displayName = 'ForgotPasswordRequest'

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  validatedComponent,
)(ForgotPasswordRequest)

import {combineReducers} from 'redux'
import {routerReducer} from 'react-router-redux'
import responseStatus from './common/redux/reducers.responseStatus'
import auth from './auth/redux/reducers'
import forgotPw from './auth/redux/reducers.forgotPw'
import home from './home/redux/reducers'
import profile from './profile/redux/reducers'
import publicProfile from './publicUser/redux/reducers'
import notifications from './common/redux/reducers.notifications'
import signup from './auth/redux/reducers.signup'
import recipe from './recipe/redux/reducers'
import myKitchen from './myKitchen/redux/reducers'
import shoppingList from './shoppingList/redux/reducers'
import calendar from './calendar/redux/reducers'

const rootReducer = combineReducers({
  routing: routerReducer,
  responseStatus,
  auth,
  forgotPw,
  home,
  profile,
  notifications,
  signup,
  publicProfile,
  recipe,
  myKitchen,
  shoppingList,
  calendar,
})

export default rootReducer

export default class ShareUtils {
  static showShareWindow(url) {
    if (window && (typeof ShareUtils.shareWinRef === 'undefined' || this.shareWinRef.closed)) {
      ShareUtils.shareWinRef = window.open(
        url,
        'sharewin',
        'left=20,top=20,width=500,height=400,toolbar=1,resizable=0',
      )
    } else {
      ShareUtils.shareWinRef.location.href = url
      ShareUtils.shareWinRef.focus()
    }
  }

  static getPinterestShareUrl(url, imageUrl, description) {
    return `//www.pinterest.com/pin/create/button/?url=${url}&media=${imageUrl}&description=${description}`
  }

  static getTwitterShareUrl(url, text, hashtags) {
    return `//twitter.com/intent/tweet?text=${text}&hashtags=${hashtags}&url=${url}`
  }

  static getFbShareUrl(url) {
    return '//www.facebook.com/sharer/sharer.php?u=' + url
  }

  static getGooglePlusShareUrl(url) {
    return '//plus.google.com/share?url=' + url
  }

  static getLinkedinShareUrl(url, title, summary, source) {
    return `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${summary}&source=${source}`
  }

  static getTumblrShareUrl(url, title, tags, image, caption) {
    return `https://tumblr.com/share/?title=${title}&tags=${tags}&content=${image}&caption=${caption}&show-via=FundThis&canonicalUrl=${url}`
  }
}

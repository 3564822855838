import settings from '../../../config/settings'
import axios from '../api/axios'

const {apiBaseURL} = settings

export default class SearchApi {
  static search(q) {
    return axios.get(`${apiBaseURL}/recipe-search`, {params: q})
  }

  static searchUser(q) {
    return axios.get(`${apiBaseURL}/user-search`, {params: q})
  }

  static searchArticle(q) {
    return axios.get(`${apiBaseURL}/article-search`, {
      params: {...q, expand: 'author,tags,tag'},
    })
  }
}

import _ from 'lodash'

export const UNITS = [
  {value: 'other', label: 'Other'},
  {value: 'bag', label: 'Bag'},
  {value: 'bar', label: 'Bar'},
  {value: 'block', label: 'Block'},
  {value: 'bottle', label: 'Bottle'},
  {value: 'box', label: 'Box'},
  {value: 'bulb', label: 'Bulb'},
  {value: 'bunch', label: 'Bunch'},
  {value: 'can', label: 'Can'},
  {value: 'clove', label: 'Clove'},
  {value: 'container', label: 'Container'},
  {value: 'cube', label: 'Cube'},
  {value: 'cup', label: 'Cup'},
  {value: 'dash', label: 'Dash'},
  {value: 'dozen', label: 'Dozen'},
  {value: 'drop', label: 'Drop'},
  {value: 'envelope', label: 'Envelope'},
  {value: 'fillet', label: 'Fillet'},
  {value: 'fluid_ounce', label: 'Fluid Ounce'},
  {value: 'gallon', label: 'Gallon'},
  {value: 'gram', label: 'Gram'},
  {value: 'handful', label: 'Handful'},
  {value: 'head', label: 'Head'},
  {value: 'item', label: 'Item'},
  {value: 'jar', label: 'Jar'},
  {value: 'kilogram', label: 'Kilogram'},
  {value: 'large', label: 'Large'},
  {value: 'leaf', label: 'Leaf'},
  {value: 'liter', label: 'Liter'},
  {value: 'medium', label: 'Medium'},
  {value: 'milliliter', label: 'Milliliter'},
  {value: 'ounce', label: 'Ounce'},
  {value: 'pack', label: 'Pack'},
  {value: 'package', label: 'Package'},
  {value: 'pinch', label: 'Pinch'},
  {value: 'pint', label: 'Pint'},
  {value: 'pound', label: 'Pound'},
  {value: 'portion', label: 'Portion'},
  {value: 'quart', label: 'Quart'},
  {value: 'sheet', label: 'Sheet'},
  {value: 'shot', label: 'Shot'},
  {value: 'slab', label: 'Slab'},
  {value: 'slice', label: 'Slice'},
  {value: 'small', label: 'Small'},
  {value: 'sprig', label: 'Sprig'},
  {value: 'stalk', label: 'Stalk'},
  {value: 'stick', label: 'Stick'},
  {value: 'strip', label: 'Strip'},
  {value: 'tablespoon', label: 'Tablespoon'},
  {value: 'teaspoon', label: 'Teaspoon'},
  {value: 'thigh', label: 'Thigh'},
  {value: 'to taste', label: 'To taste'},
  {value: 'unit', label: 'Unit'},
  {value: 'wedge', label: 'Wedge'},
  {value: 'wheel', label: 'Wheel'},
  {value: 'whole', label: 'Whole'},
  {value: 'piece', label: 'Piece'},
]

export function getUnitLabel(value) {
  return _.get(_.find(UNITS, {value}), 'label', 'Other')
}

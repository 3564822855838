import React from 'react'
import {Grid, Typography, Card, CardContent, CardActionArea, CardMedia} from '@material-ui/core'
import {Link} from 'react-router'
import categoryDefault from '../../assets/categoryDefault.png'

function AllCategory(props) {
  const {classes, params} = props

  return (
    <Grid item xs={12} md={4} className={classes.paddingGridSubPaper}>
      <Card>
        <CardActionArea
          className={classes.card}
          component={Link}
          to={`/users/${params.id}/category/all`}
        >
          <CardMedia component="img" className={classes.media} height="140" src={categoryDefault} />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom className={classes.cardTitle}>
              All Recipes
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

export default function ProfileCategories(props) {
  const {categories, params, classes, showAll} = props
  return (
    <Grid container>
      <AllCategory {...props} />
      {categories.results.map((category, index) => {
        if (category.numPublicRecipes > 0 || showAll) {
          return (
            <Grid item xs={12} md={4} key={index} className={classes.paddingGridSubPaper}>
              <Card>
                <CardActionArea
                  className={classes.card}
                  component={Link}
                  to={`/users/${params.id}/category/${category.id}`}
                >
                  {category.featureImage !== null ? (
                    <CardMedia
                      component="img"
                      className={classes.media}
                      height="140"
                      image={category.featureImage}
                    />
                  ) : (
                    <CardMedia
                      component="img"
                      className={classes.media}
                      height="140"
                      src={categoryDefault}
                    />
                  )}
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom className={classes.cardTitle}>
                      {category.name}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )
        }

        return null
      })}
    </Grid>
  )
}

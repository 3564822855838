import React from 'react'
import {Grid, Paper, withStyles} from '@material-ui/core'
import {compose} from 'redux'
import {Link} from 'react-router'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import defaultImage from '../../assets/recipe_default_image.png'
import RecipeItem from './RecipeItem'
import ArticleItem from './ArticleItem'
import AdItem from './AdItem'

const styles = theme => ({
  recipeDefaultImageContainer: {
    backgroundColor: '#ccc',
    width: '100%',
    paddingLeft: '180px',
    paddingTop: '90px',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      paddingTop: '100px',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      paddingTop: '35%',
      textAlign: 'center',
    },
  },
  defaultImage: {
    backgroundColor: '#ccc',
    width: '57px',
    height: '63px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      position: 'absolute',
      right: '50%',
      transform: 'translate(50%,-50%)',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      position: 'relative',
      right: 0,
      left: '-125%',
      transform: 'none',
    },
    [`${theme.breakpoints.down('md')} and (orientation: landscape)`]: {
      left: '50%',
      top: '50%',
      transform: 'translate(-36%, -50%)',
    },
  },
  recipeImage: {
    width: '100%',
    objectFit: 'cover',
    height: '100%',
    position: 'relative',
  },

  recipeTitleContainer: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  recipeInfoTitle: {
    textOverflow: 'ellipsis',
    maxHeight: '77px',
    overflow: 'hidden',
    wordWrap: 'break-word',
    fontFamily: 'Literata',
    fontWeight: `500`,
    lineHeight: '36px',
    fontSize: '31px',
    textDecoration: 'none',
    color: '#000',
    paddingBottom: 5,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  recipeInfoBy: {
    marginTop: '0.5em',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontSize: '16px',
    lineHeight: '19px',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    '& p': {
      marginTop: 0,
    },
  },
  paper: {
    height: '100%',
    minHeight: '380px',
    maxHeight: '390px',
    overflow: 'hidden',
  },
  recipeUserName: {
    fontWeight: 500,
    textDecoration: 'underline',
  },
  contentTypeIcon: {
    position: 'absolute',
    height: 18,
    width: 18,
    bottom: 16,
    right: 16,
    background: '#fff',
    color: 'rgba(0, 0, 0, 0.6)',
    borderRadius: '50%',
    padding: 15,
    boxSizing: 'content-box',
  },
})

function NewsfeedLoading({item, recimeeId, classes, loading = false}) {
  if (loading) {
    return (
      <Grid item sm={12}>
        <Paper className={classes.paper}>
          <div className={classes.recipeImageContainer}>
            <Link data-test-name="recipe-image-blank" to="/" className={classes.recipeImageLink}>
              <div className={classes.recipeDefaultImageContainer}>
                <img className={classes.defaultImage} src={defaultImage} />{' '}
              </div>
            </Link>
          </div>
          <div className={classes.recipeTitleContainer}>
            <Link data-test-name="recipe-title-blank" to="/" className={classes.recipeInfoTitle} />
          </div>
          <Skeleton
            className={classes.recipeInfoBy}
            variant="rect"
            width="90%"
            height={40}
            margin="10px 0"
          />
          <Skeleton className={classes.recipeInfoBy} variant="rect" width="90%" height={20} />
        </Paper>
      </Grid>
    )
  }

  if (item.contentTypeName === 'recipe') {
    return <RecipeItem item={item} recimeeId={recimeeId} classes={classes} />
  }

  if (item.contentTypeName === 'article') {
    return <ArticleItem item={item} classes={classes} />
  }

  if (item.contentTypeName === 'ad') {
    return <AdItem item={item} classes={classes} />
  }

  return null
}

export default compose(withStyles(styles))(NewsfeedLoading)

import isNode from 'detect-node'
import Cookies from 'cookies-js'

class UserSession {
  constructor() {
    this.token = null
  }

  setToken(token) {
    this.token = token

    if (!isNode) {
      if (token) {
        window.localStorage.setItem('token', token)
        Cookies.set('Authorization', token)
      } else {
        window.localStorage.removeItem('token')
        Cookies.expire('Authorization')
      }
    }
  }

  setClientAuthCookie() {
    if (!isNode) {
      const token = window.localStorage.getItem('token')
      if (token) {
        Cookies.set('Authorization', token)
      } else {
        Cookies.expire('Authorization')
      }
    }
  }

  getToken() {
    if (!isNode) {
      return window.localStorage.getItem('token')
    }

    return this.token
  }
}

const userSession = new UserSession()
export default userSession

import React from 'react'
import Provider from 'react-redux/es/components/Provider'
import {Router} from 'react-router'
import {ThemeProvider} from '@material-ui/styles'
import routing from './shared/routing'

export default function Root({store, history, theme}) {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router
          history={history}
          onUpdate={(a, b) => {
            if (!window.location.pathname.startsWith('/create-recipe')) {
              window.scrollTo(0, 0)
            }
          }}
        >
          {routing(store)}
        </Router>
      </ThemeProvider>
    </Provider>
  )
}

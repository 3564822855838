import React from 'react'
import {withStyles} from '@material-ui/core/styles'

class DeleteButton extends React.Component {
  render() {
    const {classes, ...others} = this.props
    return (
      <a className={classes.deleteButton} {...others}>
        &times;
      </a>
    )
  }
}

const styles = () => ({
  deleteButton: {
    color: '#aaa',
    cursor: 'pointer',
    fontSize: 28,
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
    display: 'block',
    lineHeight: '46px',
    '&:hover': {
      color: '#bbb',
    },
  },
})

export default withStyles(styles)(DeleteButton)

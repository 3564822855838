import React from 'react'
import PropTypes from 'prop-types'
import autobind from 'autobind-decorator'

import {Grid, Typography} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import connect from 'react-redux/es/connect/connect'
import {withStyles} from '@material-ui/core/styles'
import ButtonWithProgress from '../../common/ButtonWithProgress'
import {updateLocal, getRecipeFromUrl} from '../redux/actions'
import styles, {DetailTextField} from './CreateRecipe.styles'

class ImportFlow extends React.Component {
  onChange(fieldName, e) {
    this.props.updateLocal({[fieldName]: e.target.value}, 'recipe')
  }

  @autobind
  handleCreateRecipe() {
    const {recipe, getRecipeFromUrl} = this.props
    getRecipeFromUrl(recipe.importUrl)
  }

  render() {
    const {classes, recipe, onBlur} = this.props
    return (
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.importerTitle}>
              Import Recipe from URL
            </Typography>
          </Grid>
          <Grid item md={6} xs={10} className={classes.center}>
            <DetailTextField
              className={classes.importerTextField}
              variant="outlined"
              name="title"
              value={recipe.importUrl}
              placeholder="Import Recipe from URL"
              label="Title"
              onChange={this.onChange.bind(this, 'importUrl')}
              onBlur={onBlur}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={9} className={classes.center}>
            <p className={classes.importerP}>
              Have a great recipe from another site? <br />
              Simply copy the recipe's URL into the box above to import it into Recimee. <br />
              <em>(Currently supporting foodnetwork.com)</em>
            </p>
          </Grid>

          <Grid container direction="row" justifyContent="space-between" className={classes.center}>
            <Grid item xs={12}>
              <ButtonWithProgress
                variant="outlined"
                className={classes.buttonImport}
                onClick={this.handleCreateRecipe}
                isLoading={recipe.isImportingURL}
              >
                Import
              </ButtonWithProgress>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

ImportFlow.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({updateLocal, getRecipeFromUrl}, dispatch)
}

function mapStateToProps(state) {
  return {...state.recipe}
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(ImportFlow)

import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {Grid, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import _ from 'lodash'
import {Link} from 'react-router'
import styles from './MyKitchen.styles.js'
import Loading from '../common/Loading'

class MyKitchen extends React.Component {
  render() {
    const {classes, children, profile} = this.props

    if (!profile) {
      return <Loading />
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography className={classes.title} component="h2">
            <Link to="/my-kitchen" className={classes.title} style={{textDecoration: 'none'}}>
              My Kitchen
            </Link>
          </Typography>
        </Grid>
        {children}
      </Grid>
    )
  }
}
MyKitchen.displayName = 'MyKitchen'

function mapStateToProps(state) {
  return {
    ...state.myKitchen,
    profile: _.get(state.profile, 'profile', null),
  }
}

MyKitchen.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default compose(connect(mapStateToProps), withStyles(styles))(MyKitchen)

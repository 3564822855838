import React from 'react'
import Helmet from 'react-helmet'
import NotFoundView from './NotFound.Component.jsx'

export default function NotFound() {
  return (
    <div>
      <Helmet title="Page Not Found" />
      <NotFoundView />
    </div>
  )
}

NotFound.displayName = 'NotFound'

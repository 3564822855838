import React from 'react'
import PropTypes from 'prop-types'
import {Grid, withStyles} from '@material-ui/core'
import {compose} from 'redux'
import InputValidator from '../common/InputValidator.jsx'
import ValidatedComponent from '../common/ValidatedComponent.jsx'

import styles, {AccountTextField, SmallButton} from './ProfileInfo.styles'

class ChangeEmail extends React.Component {
  constructor() {
    super()

    this.state = {
      newEmail: '',
    }
  }

  render() {
    const {newEmail} = this.state
    const {email, errors, savedNewEmail, isNewEmailConfirmed, classes} = this.props

    const isConfirmationPending = !!savedNewEmail && !isNewEmailConfirmed
    const isVerificationPending = !!savedNewEmail && isNewEmailConfirmed
    const isChangeRequested = !!savedNewEmail

    return (
      <React.Fragment>
        {!isChangeRequested ? (
          <Grid item xs={12}>
            <div className={classes.label}>Email</div>
            <InputValidator errors={errors.newEmail} className={classes.inputValidator}>
              <AccountTextField
                variant="outlined"
                fullWidth
                type="email"
                value={newEmail}
                required
                placeholder={email}
                onChange={e => this.setState({newEmail: e.target.value})}
              />
            </InputValidator>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          {!isChangeRequested ? (
            <div className={classes.smallButtonContainer}>
              <SmallButton onClick={this.onChangeEmailClick.bind(this)}>Update email</SmallButton>
            </div>
          ) : null}
          {isConfirmationPending ? (
            <div>
              <p>
                You requested to change email to <b>{savedNewEmail}</b>. Please continue with the
                confirmation email sent to <b>{email}</b>.
              </p>
              <div className={classes.smallButtonContainer}>
                <SmallButton onClick={this.props.onResendConfirmationEmail}>
                  Resend confirmation email
                </SmallButton>
              </div>
            </div>
          ) : null}
          {isVerificationPending ? (
            <div>
              <p>
                A verification email has been sent to the new email address (<b>{savedNewEmail}</b>
                ).
              </p>
              <div className={classes.smallButtonContainer}>
                <SmallButton onClick={this.props.onResendVerificationEmail}>
                  Resend verification email
                </SmallButton>
              </div>
            </div>
          ) : null}
          {isChangeRequested ? (
            <div>
              <p>
                Your current email is <b>{email}</b>
              </p>
              <div className={classes.smallButtonContainer}>
                <SmallButton onClick={this.props.onCancelChangeEmail}>
                  Cancel change request
                </SmallButton>
              </div>
            </div>
          ) : null}
        </Grid>
      </React.Fragment>
    )
  }

  onChangeEmailClick() {
    const {isComponentValid, onChangeEmail} = this.props
    if (isComponentValid()) {
      onChangeEmail(this.state.newEmail)
    }
  }
}

ChangeEmail.displayName = 'ChangeEmail'
ChangeEmail.propTypes = {
  errors: PropTypes.object,
  email: PropTypes.string.isRequired,
  savedNewEmail: PropTypes.string,
  isNewEmailConfirmed: PropTypes.bool,
  onChangeEmail: PropTypes.func.isRequired,
  onCancelChangeEmail: PropTypes.func.isRequired,
  onResendConfirmationEmail: PropTypes.func.isRequired,
  onResendVerificationEmail: PropTypes.func.isRequired,
}

export default compose(withStyles(styles), ValidatedComponent)(ChangeEmail)

import React from 'react'

import {Button, Dialog, DialogContent, DialogActions, Typography} from '@material-ui/core'

export default function SharedPrivateDialog(props) {
  const {open, handleClose} = props

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Typography variant="title">
          To share this recipe, you must change recipe shared with setting to public.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

import * as Types from './actions.types'

export function addRecipeToCalendar(recipeId, date, callback) {
  return {type: Types.ADD_RECIPE_TO_CALENDAR, recipeId, date, callback}
}

export function addRecipeToCalendarSuccess(item) {
  return {type: Types.ADD_RECIPE_TO_CALENDAR_SUCCESS, item}
}

export function addRecipeToCalendarFailure(error) {
  return {type: Types.ADD_RECIPE_TO_CALENDAR_FAILURE, error}
}

export function getCalendarItems(params) {
  return {type: Types.GET_CALENDAR_ITEMS, params}
}

export function getCalendarItemsSuccess(response) {
  return {type: Types.GET_CALENDAR_ITEMS_SUCCESS, response}
}

export function getCalendarItemsFailure(error) {
  return {type: Types.GET_CALENDAR_ITEMS_FAILURE, error}
}

export function deleteCalendarItem(id, dayIndex, itemIndex) {
  return {type: Types.DELETE_CALENDAR_ITEM, id, dayIndex, itemIndex}
}

export function deleteCalendarItemSuccess(id, dayIndex, itemIndex) {
  return {type: Types.DELETE_CALENDAR_ITEM_SUCCESS, id, dayIndex, itemIndex}
}

export function deleteCalendarItemFailure(error) {
  return {type: Types.DELETE_CALENDAR_ITEM_FAILURE, error}
}

export function addCalendarItemSuccess(calItem) {
  return {type: Types.ADD_CALENDAR_ITEM_SUCCESS, calItem}
}

export function updateOrderCalendarItems(items) {
  return {type: Types.CALENDAR_ITEM_UPDATE_ORDER_LOCAL, items}
}

export function calendarItemMoveLocal(calItem, fromDate, toDate) {
  return {type: Types.CALENDAR_ITEM_MOVE_LOCAL, calItem, fromDate, toDate}
}

import _ from 'lodash'
import settings from '../../../config/settings'

export default class PageUtils {
  static getTitle() {
    return _.get(settings, 'website.title')
  }

  static scrollToTop() {
    const cosParameter = window.scrollY / 2

    let scrollCount = 0

    let oldTimestamp = performance.now()

    function step(newTimestamp) {
      scrollCount += Math.PI / (1000 / (newTimestamp - oldTimestamp))
      if (scrollCount >= Math.PI) window.scrollTo(0, 0)
      if (window.scrollY === 0) return
      window.scrollTo(0, Math.round(cosParameter + cosParameter * Math.cos(scrollCount)))
      oldTimestamp = newTimestamp
      window.requestAnimationFrame(step)
    }

    window.requestAnimationFrame(step)
  }
}

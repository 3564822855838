import {call, put, all, takeEvery} from 'redux-saga/effects'
import UserApi from '../../publicUser/api'
import * as Actions from '../../publicUser/redux/actions.userList'
import * as Types from '../../publicUser/redux/actions.userList.types'

export default function* homeFlow() {
  yield all([takeEvery(Types.USERS_LIST_GET, getUserList)])
}

function* getUserList(action) {
  const {payload} = action
  try {
    const response = yield call(UserApi.getUsers, payload)
    yield put(Actions.getUsersSuccess(response))
  } catch (error) {
    yield put(Actions.getUsersFailure(error))
  }
}

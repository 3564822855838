import React from 'react'
import {connect} from 'react-redux'
import {Grid, Typography} from '@material-ui/core'
import {initResponseStatus, setResponseStatus} from './redux/actions.responseStatus'
import img404 from '../../assets/404.svg'

class NotFoundComponent extends React.Component {
  componentDidMount() {
    this.props.dispatch(setResponseStatus(404))
  }

  componentWillUnmount() {
    this.props.dispatch(initResponseStatus())
  }

  render() {
    return (
      <Grid container justifyContent="center">
        <Grid item xs="auto">
          <br /> <br />
          <img src={img404} alt="" />
          <Typography variant="h1" component="h1" align="center">
            Ooops.. Something went wrong!
          </Typography>
          <Typography variant="h5" align="center">
            How did you get here? Please share your feedback with Chris at{' '}
            <a href="mailto:cj@tsl.io">cj@tsl.io</a>
          </Typography>
          <br /> <br />
        </Grid>
      </Grid>
    )
  }
}

NotFoundComponent.displayName = 'NotFoundComponent'

export default connect()(NotFoundComponent)

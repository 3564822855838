import {Button, Typography, Paper, Card, CardContent} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'

export const styles = theme => ({
  recipeViewContainer: {
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },

    '& a': {
      color: '#000',
    },
  },
  overview: {
    marginTop: '40px',
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
    },
  },
  overviewButtonContainer: {
    marginLeft: '25px',
    display: 'inline-block',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
      width: '100%',
      marginLeft: 0,
      fontSize: 14,
    },
  },
  recipeActionBar: {
    paddingBottom: 10,

    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0,
      display: 'none',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      paddingBottom: 0,
      display: 'none',
    },
  },
  recipeActionBarMobile: {
    paddingBottom: 10,
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0,
      display: 'block',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      paddingBottom: 0,
      display: 'block',
    },
  },
  alignRight: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginTop: 20,
    },
    tagChipAlign: {
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        marginTop: 20,
        width: '100%',
      },
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      marginTop: 50,
    },
  },
  titleTagHeight: {
    height: '37px',
    [theme.breakpoints.down('xs')]: {
      height: '100%',
    },
  },
  titleDesc: {
    margin: '18px 28px 0 0',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginTop: 0,
    },
  },
  overflowTitle: {
    height: '215px',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      wordBreak: 'break-word',
    },
  },
  ratingBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  ratingContainer: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
  },
  selectCategoryTooltipMobile: {
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
  },
  tagChip: {
    backgroundColor: '#BBBBBB',
    color: '#fff',
    fontFamily: `Lato`,
    fontSize: `16px`,
    marginTop: '5px',
    marginLeft: `5px`,
    fontWeight: '300',
    lineHeight: `19px`,
    textAlign: `center`,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 10,
      marginRight: 5,
      marginLeft: 0,
    },
  },
  rating: {
    color: '#F9D757',
  },
  descContainerMobile: {
    marginTop: '37px',
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      marginTop: 10,
    },
  },
  descContainer: {
    marginTop: '37px',
    margin: '18px 28px 0 0',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      justifyContent: 'center',
      margin: '18px 0px 0 0',
    },
  },
  overviewCard: {
    margin: '18px 0 0 28px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  overviewCardMedia: {
    height: '381',
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      height: '203px',
    },
  },
  shareButtonMobile: {
    [theme.breakpoints.down('xs')]: {
      marginRight: 10,
    },
  },
  editShareBtn: {
    width: '200',
    height: '35px',
    [theme.breakpoints.down('xs')]: {
      width: '140px',
      height: '33px',
      fontSize: '14px',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      width: '250px',
      height: '33px',
      fontSize: '14px',
    },
  },
  greenTextButton: {
    color: '#589a43',
    fontFamily: 'Lato',
    fontSize: '21px',
    fontWeight: '600',
    lineHeight: '33px',
    textAlign: 'center',
    textTransform: 'none',
    borderRadius: '34.5px',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    backgroundColor: '#fff',
    width: '200px',
    height: '43px',
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      width: '140px',
      height: '33px',
      fontSize: '14px',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      width: '250px',
      height: '33px',
      fontSize: '14px',
    },
  },
  greenTextButtonDisabled: {
    color: '#589a43 !important',
  },
  overviewDirectionsNotesCard: {
    margin: '40px 0 0 0', //28.5px
    [theme.breakpoints.up('xs')]: {
      wordBreak: 'break-word',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      wordBreak: 'break-word',
    },
  },
  tabIconDirection: {
    color: `white`,
    width: `26px`,
    verticalAlign: `middle`,
  },
  tabIconNote: {
    color: `white`,
    width: `21px`,
    verticalAlign: `middle`,
  },
  tabIconDirectionSelected: {
    color: `#589a43`,
    width: `26px`,
    verticalAlign: `middle`,
  },
  tabIconNoteSelected: {
    color: `#589a43`,
    width: `21px`,
    verticalAlign: `middle`,
  },
  directionNumberIcon: {
    display: 'inline-block',
    verticalAlign: `top`,
    fontWeight: '900',
  },
  directionNumber: {
    marginBottom: `20px`,
    display: 'flex',
    width: '100%',
  },
  directionInner: {
    paddingLeft: 0,
    display: 'inline-block',
  },
  directionNumberData: {
    padding: '0 18px',
    wordBreak: 'break-word',
    width: '100%',
    display: 'flex',
    '& > p': {
      margin: 0,
    },
    '& a': {
      fontWeight: '500',
      color: '#000',
    },
  },
  directionImageContainer: {
    minHeight: '100px ',
    minWidth: '100px ',
  },
  recipeUserName: {
    fontWeight: 500,
    textDecoration: 'underline',
  },
  recipeBy: {
    fontWeight: 400,
    textDecoration: 'underline',
    fontSize: '16px',
    lineHeight: `24px`,
  },
  recipeSource: {
    fontWeight: 400,
    textDecoration: 'underline',
    fontSize: '12px',
    lineHeight: `16px`,
    marginTop: '8px',
  },
})

export const StyledButton = withStyles(theme => ({
  root: {
    color: '#000',
    fontFamily: 'Lato',
    fontSize: '21px',
    lineHeight: '29px',
    textAlign: 'center',
    fontWeight: '400',
    textTransform: 'none',
    borderRadius: '34.5px',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    backgroundColor: '#fff',
    width: '150px',
    height: '29px',
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}))(Button)

export const StyledButtonGreen = withStyles(theme => ({
  root: {
    color: '#589a43',
    width: '200px',
    height: '33px',
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      width: '140px',
      height: '33px',
      fontSize: '14px',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      width: '250px',
      height: '33px',
      fontSize: '14px',
    },
  },
}))(StyledButton)

export const StyledButtonGreenSmall = withStyles(theme => ({
  root: {
    fontSize: '18px',
    lineHeight: '21px',
    padding: '5px 40px',
    width: 'auto',
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      height: 'auto',
    },
  },
}))(StyledButtonGreen)

export const RecipeName = withStyles({
  root: {
    color: '#000',
    fontFamily: 'Literata',
    fontSize: '24px',
    lineHeight: '32px',
    marginBottom: '5px',
    fontWeight: '400',
  },
})(Typography)

export const TitleDescPaper = withStyles(theme => ({
  root: {
    padding: '20px',
    borderRadius: '3px',
    boxShadow: '0 1px 10px 0 rgba(0,0,0,0.19)',
    [theme.breakpoints.down('xs')]: {
      // paddingBottom: 0,
    },
  },
}))(Paper)

export const SubTitle = withStyles({
  root: {
    color: '#000',
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: '18px',
  },
})(Typography)

export const Title = withStyles({
  root: {
    color: '#000',
    fontFamily: 'Literata',
    fontSize: '24px',
    fontWeight: '400',
    lineHeight: '32px',
  },
})(Typography)

export const Desc = withStyles({
  root: {
    color: '#000',
    fontFamily: 'Lato',
    fontWeight: '300',
    fontSize: '18px',
    lineHeight: '32px',
    height: '151px',
    overflowY: 'auto',
    marginTop: '5px',

    '& a': {
      fontWeight: '500',
      color: '#000',
    },
  },
})(Typography)

export const OverviewCard = withStyles({
  root: {
    borderRadius: '3px',
    boxShadow: '0 1px 10px 0 rgba(0,0,0,0.19)',
  },
})(Card)

export const OverviewCardContent = withStyles({
  root: {
    height: '99.5px',
    display: 'flex',
    alignItems: 'center',
    '&:last-child': {
      paddingBottom: 16,
    },
    '& >div': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
})(CardContent)

export const OverviewCardItemTitle = withStyles(theme => ({
  root: {
    color: '#000',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
}))(Typography)

export const OverviewCardItem = withStyles({
  root: {
    color: '#000',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    paddingBottom: '3px',
  },
})(Typography)

export const OverviewDirectionsNotesCard = withStyles({
  root: {
    borderRadius: '3px 3px 0 0',
    boxShadow: '0 1px 10px 0 rgba(0,0,0,0.19)',
  },
})(Card)

export const DescNotePaper = withStyles({
  root: {
    padding: '31px',
    borderRadius: '0 0px 3px 3px',
    boxShadow: '0 1px 10px 0 rgba(0,0,0,0.19)',
  },
})(Paper)

export const NotesPaper = withStyles({
  root: {
    maxHeight: 400,
    whiteSpace: 'pre-wrap',
    overflow: 'auto',
    '& a': {
      fontWeight: '500',
      color: '#000',
    },
  },
})(DescNotePaper)

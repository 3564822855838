import React from 'react'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import {Popper, Paper, withStyles} from '@material-ui/core'
import autobind from 'autobind-decorator'
import {BasePicker, Calendar} from 'material-ui-pickers'
import moment from 'moment'
import {StyledButtonGreen} from '../recipe/RecipeView/RecipeView.styles'
import PickersProvider from '../utils/PickersProvider'
import {addRecipeToCalendar} from './redux/actions'
import ButtonWithLoginRequired from '../common/ButtonWithLoginRequired'

class AddToCalendarButton extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
      arrowRef: null,
      open: false,
      selectedDate: new Date(),
    }
  }

  @autobind
  handleClick(e) {
    const {currentTarget} = e
    this.setState(
      state => ({
        anchorEl: currentTarget,
        open: !state.open,
      }),
      () => {
        if (this.state.open) {
          this.setState({selectedDate: new Date()})
        }
      },
    )
  }

  @autobind
  handleArrowRef(node) {
    this.setState({
      arrowRef: node,
    })
  }

  @autobind
  handleChange(date) {
    this.setState({selectedDate: date})
  }

  @autobind
  handleAdd() {
    this.props.addRecipeToCalendar(
      this.props.recipe.id,
      moment(this.state.selectedDate).format('YYYY-MM-DD'),
      () => {
        this.setState({open: false})
      },
    )
  }

  render() {
    const {classes, isAdding} = this.props
    const {anchorEl, open, arrowRef, selectedDate} = this.state
    return (
      <React.Fragment>
        <ButtonWithLoginRequired component={StyledButtonGreen} onClick={this.handleClick}>
          Add to Calendar
        </ButtonWithLoginRequired>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement="bottom"
          disablePortal={false}
          className={classes.popper}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: 'scrollParent',
            },
            arrow: {
              enabled: true,
              element: arrowRef,
            },
            offset: {
              enabled: false,
              offset: -80,
            },
          }}
        >
          <PickersProvider>
            <span className={classes.arrow} ref={this.handleArrowRef} />
            <Paper className={classes.paper}>
              <BasePicker value={selectedDate} onChange={this.handleChange} autoOk>
                {({date, handleChange}) => (
                  <div className={classes.calendar}>
                    <Calendar date={date} onChange={handleChange} />
                  </div>
                )}
              </BasePicker>

              <StyledButtonGreen
                className={classes.addButton}
                disabled={isAdding}
                onClick={this.handleAdd}
              >
                Add
              </StyledButtonGreen>
            </Paper>
          </PickersProvider>
        </Popper>
      </React.Fragment>
    )
  }
}

const styles = theme => ({
  paper: {
    padding: '10px 20px 20px 20px',
  },
  calendar: {
    overflow: 'hidden',
  },
  addButton: {
    fontSize: 18,
    height: 'auto',
    width: '100%',
    marginTop: 10,
  },
  popper: {
    zIndex: 1,
    marginTop: 18 + 26,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',

    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-26px',
      '&::before': {
        borderWidth: '0 23px 26px 23px',
        borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '46px',
    height: '26px',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
      zIndex: 2,
    },
  },
})

AddToCalendarButton.displayName = 'AddToCalendarButton'

function mapStateToProps(state) {
  return {
    isAdding: state.calendar.isAdding,
  }
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({addRecipeToCalendar}, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(AddToCalendarButton)

import {call, put, takeLatest} from 'redux-saga/effects'
import {push, replace} from 'react-router-redux'
import CreateRecipe from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as NotificationActions from '../../common/redux/actions.notifications.js'

export default function* recipeFlow() {
  yield [
    takeLatest(Types.CREATE_RECIPE, createRecipe),
    takeLatest(Types.DELETE_RECIPE, deleteRecipe),
    takeLatest(Types.AUTO_SAVE_RECIPE, autoSaveRecipe),
    takeLatest(Types.GET_RECIPE_FROM_URL, getRecipeFromUrl),
    takeLatest(Types.GET_RECIPE, getRecipe),
    takeLatest(Types.GET_TAG_SUGGESTIONS, getTagSuggestions),
  ]
}

function* createRecipe(action) {
  const {params, recipeId} = action
  try {
    const recipeFormatted = formatRecipeToApi(params)
    let response
    let successMsg = 'Successfully Created Recipe.'
    if (recipeId) {
      response = yield call(CreateRecipe.updateRecipe, recipeId, recipeFormatted)
      successMsg = 'Successfully Updated Recipe.'
    } else {
      response = yield call(CreateRecipe.createRecipe, recipeFormatted)
    }
    yield put(Actions.createRecipeSuccess(response))
    yield put(NotificationActions.createSuccess(successMsg))
    yield put(push(`/recipe/${response.id}`))
  } catch (error) {
    yield put(Actions.createRecipeFailure(error))
    yield put(NotificationActions.createError('Error when saving'))
  }
}

function* deleteRecipe(action) {
  const {recipeId} = action
  try {
    const response = yield call(CreateRecipe.deleteRecipe, recipeId)
    yield put(Actions.deleteRecipeSuccess(response))
    yield put(NotificationActions.createSuccess('Successfully deleted recipe.'))
    yield put(push(`/my-kitchen`))
  } catch (error) {
    yield put(Actions.deleteRecipeFailure(error))
  }
}
function* autoSaveRecipe(action) {
  const {params, recipeId, editing} = action
  try {
    const recipeFormatted = formatRecipeToApi(params)
    let response
    if (recipeId) {
      response = yield call(CreateRecipe.updateRecipe, recipeId, recipeFormatted)
    } else {
      response = yield call(CreateRecipe.createRecipe, recipeFormatted)
    }
    yield put(Actions.autoSaveRecipeSuccess(response))
    yield put(NotificationActions.createSuccess('Saved.'))
    if (!recipeId) {
      yield put(replace(`/create-recipe/${response.id}`))
    }
  } catch (error) {
    yield put(Actions.autoSaveRecipeFailure(error))
  }
}

function formatRecipeToApi(recipe) {
  return recipe
}
function* getRecipeFromUrl(action) {
  const {url} = action
  try {
    const response = yield call(CreateRecipe.getRecipeFromUrl, url)
    yield put(Actions.getRecipeFromUrlSuccess(response))
    yield put(NotificationActions.createSuccess('URL import successful.'))
  } catch (error) {
    yield put(NotificationActions.createError('URL import failed.'))
    yield put(Actions.getRecipeFromUrlFailure(error))
  }
}

function* getRecipe({id, params}) {
  try {
    const response = yield call(CreateRecipe.getRecipe, id, params)
    yield put(Actions.getRecipeSuccess(response))
  } catch (e) {
    NotificationActions.createNotificationFromError(e)
    yield put(Actions.getRecipeFailure(e))
  }
}

function* getTagSuggestions(action) {
  const {tag} = action
  if (tag.trim() === '') {
    yield put(Actions.getTagSuggestionsSuccess([]))
  } else {
    try {
      const response = yield call(CreateRecipe.getTagSuggestions, tag)
      yield put(Actions.getTagSuggestionsSuccess(response.results))
    } catch (e) {
      yield put(Actions.getTagSuggestionsFailure(e))
    }
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import {Grid, Icon, MenuItem, Paper, Typography} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import connect from 'react-redux/es/connect/connect'
import autobind from 'autobind-decorator'
import deburr from 'lodash/deburr'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import {round, fraction, number} from 'mathjs'
import parse from 'autosuggest-highlight/parse'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import {
  updateLocal,
  updateIngredient,
  deleteIngredient,
  deleteIngredientList,
  addIngredient,
  updateLocalAtIndex,
} from '../redux/actions'
import {createError} from '../../common/redux/actions.notifications.js'
import styles, {DetailTextField, ButtonFilled} from './CreateRecipe.styles.js'
import {UNITS} from '../../constants/Units'
import DeleteButton from './DeleteButton'

import dragHandle from '../../../assets/icons/ellipsis-v.svg'
import {isNumeric} from '../utils'
import {ROUND_DIGITS} from '../../utils/constants'

function renderInputComponent(inputProps) {
  const {classes, inputRef = () => {}, ref, ...other} = inputProps

  return (
    <DetailTextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node)
          inputRef(node)
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  )
}

class RecipeIngredients extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      setSuggestions: '',
      ingredientErrors: {},
    }
  }

  onChange(index, patch) {
    this.props.updateIngredient(this.props.ingredientListIndex, index, patch)
  }

  @autobind
  addIngredient(e) {
    e.preventDefault()
    this.props.addIngredient(this.props.ingredientListIndex)
  }

  @autobind
  deleteIngredient(index) {
    this.props.deleteIngredient(this.props.ingredientListIndex, index)
  }

  onDragStart(e, index) {
    this.draggedIndex = index
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', e.target.parentNode.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode.parentNode, 20, 20)
  }

  onDragOver(index) {
    if (typeof this.draggedIndex === 'undefined') {
      return
    }
    const {recipe} = this.props
    if (this.draggedIndex === index) {
      return
    }
    const list = recipe?.ingredientLists[Number(this.props.ingredientListIndex)]?.ingredients
    const filteredList = list.filter((ingredient, ind) => ind !== this.draggedIndex)
    const draggedIngredient = list[this.draggedIndex]
    filteredList.splice(index, 0, draggedIngredient)
    this.props.updateLocalAtIndex('recipe', 'ingredientLists', this.props.ingredientListIndex, {
      ingredients: filteredList,
    })
    this.draggedIndex = index
  }

  onDragEnd() {
    this.draggedIndex = undefined
  }

  @autobind
  handleSuggestionsFetchRequested({value}) {
    this.setState({setSuggestions: this.getSuggestions(value)})
  }

  @autobind
  handleSuggestionsClearRequested() {
    this.setState({setSuggestions: []})
  }

  render() {
    const {
      onBlur,
      classes,
      ingredientList,
      ingredientListIndex,
      updateLocalAtIndex,
      deleteIngredientList,
    } = this.props
    const {setSuggestions} = this.state
    const autoSuggestProps = {
      renderInputComponent,
      suggestions: setSuggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion: this.renderSuggestion,
    }

    return (
      <>
        <DetailTextField
          value={ingredientList.name}
          onChange={e =>
            updateLocalAtIndex('recipe', 'ingredientLists', ingredientListIndex, {
              name: e.target.value,
            })
          }
          fullWidth
          onBlur={onBlur}
          style={{marginTop: ingredientListIndex > 0 ? 24 : 0, marginBottom: 24}}
          placeholder={
            ingredientListIndex > 0 ? "Additional ingredient list's name" : "Ingredient list's name"
          }
        />
        <Grid item container spacing={3}>
          <Grid item md={5} className={classes.ingredientMaterialTitleDesktop}>
            Ingredient
          </Grid>
          <Grid item md={3} className={classes.ingredientMaterialTitleDesktop}>
            Quantity
          </Grid>
          <Grid item md={4} className={classes.ingredientMaterialTitleDesktop}>
            Unit
          </Grid>
          {ingredientList.ingredients?.map((ingredient, index) => {
            return (
              <Grid
                key={index}
                item
                xs={12}
                className={classes.ingredients}
                onDragOver={() => this.onDragOver(index)}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={5} className={classes.ingredientMobile}>
                    <Grid item xs={12} className={classes.ingredientMaterialTitleMobile}>
                      Ingredient
                    </Grid>
                    <img
                      src={dragHandle}
                      alt="drag handle"
                      draggable
                      onDragStart={e => this.onDragStart(e, index)}
                      onDragEnd={e => {
                        this.onDragEnd(e)
                        onBlur()
                      }}
                      className={classes.dragImage}
                    />
                    <DetailTextField
                      value={ingredient.ingredient.title}
                      fullWidth
                      name="title"
                      onChange={e => this.onChange(index, {ingredient: {title: e.target.value}})}
                      onBlur={onBlur}
                      className={classes.draggableInput}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} className={classes.ingredientMobile}>
                    <Grid item xs={12} className={classes.ingredientMaterialTitleMobile}>
                      Quantity
                    </Grid>
                    <FormControl error={!!this.state.ingredientErrors[index]}>
                      <DetailTextField
                        aria-describedby="quantity-error-text"
                        value={
                          isNumeric(ingredient.quantity)
                            ? fraction(ingredient.quantity).toFraction(true)
                            : ingredient.quantity
                        }
                        fullWidth
                        name="quantity"
                        onChange={e => this.onChange(index, {quantity: e.target.value})}
                        onBlur={() => {
                          let qty
                          try {
                            qty = round(number(fraction(ingredient.quantity)), ROUND_DIGITS)
                            this.setState(prevState => ({
                              ingredientErrors: {...prevState.ingredientErrors, [index]: undefined},
                            }))
                          } catch (error) {
                            this.setState(prevState => ({
                              ingredientErrors: {
                                ...prevState.ingredientErrors,
                                [index]: 'Please enter a valid number',
                              },
                            }))
                            qty = ingredient.quantity
                          }
                          setTimeout(() => {
                            this.onChange(index, {quantity: qty})
                            onBlur()
                          }, 100)
                        }}
                      />
                      {this.state.ingredientErrors[index] && (
                        <FormHelperText id={`quantity-${index}-error-text`}>
                          {this.state.ingredientErrors[index]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} sm={4} md={3} className={classes.ingredientMobile}>
                    <Grid item xs={12} className={classes.ingredientMaterialTitleMobile}>
                      Unit
                    </Grid>
                    <Autosuggest
                      {...autoSuggestProps}
                      inputProps={{
                        classes,
                        id: 'react-autosuggest-simple',
                        label: 'Unit',
                        value: ingredient.unit,
                        onChange: (e, {newValue}) => {
                          this.onChange(index, {unit: newValue})
                        },
                      }}
                      theme={{
                        container: classes.container,
                        suggestionsContainerOpen: classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                      }}
                      renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps} square>
                          {options.children}
                        </Paper>
                      )}
                    />
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} className={classes.ingredientMobile}>
                    <DeleteButton
                      className={classes.deleteButtonIngredients}
                      onClick={() => {
                        this.deleteIngredient(index)
                        setTimeout(onBlur, 50)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )
          })}

          <Grid item container xs={12} spacing={1} className={classes.addButton}>
            {ingredientListIndex > 0 && (
              <Grid item xs={6}>
                <ButtonFilled
                  fullWidth
                  onClick={() => deleteIngredientList(ingredientListIndex)}
                  style={{borderColor: '#f44336', color: '#f44336'}}
                  variant="outlined"
                >
                  <Icon style={{marginRight: 12, color: '#f44336'}}>playlist_remove_outlined</Icon>{' '}
                  Remove ingredient list
                </ButtonFilled>
              </Grid>
            )}
            <Grid item xs={ingredientListIndex > 0 ? 6 : 12}>
              <ButtonFilled fullWidth onClick={this.addIngredient} variant="outlined">
                <Icon style={{marginRight: 12}} color="action">
                  add_circle_outline
                </Icon>{' '}
                Add new ingredient
              </ButtonFilled>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  @autobind
  renderSuggestion(suggestion, {query, isHighlighted}) {
    const matches = match(suggestion.label, query)
    const parts = parse(suggestion.label, matches)
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map(part => (
            <span key={part.text} style={{fontWeight: part.highlight ? 500 : 400}}>
              {part.text}
            </span>
          ))}
        </div>
      </MenuItem>
    )
  }

  getSuggestions(value) {
    const inputValue = deburr(value.trim())?.toLowerCase()
    const inputLength = inputValue.length
    let count = 0

    return inputLength === 0
      ? []
      : UNITS.filter(suggestion => {
          const keep =
            count < 5 && suggestion.label.slice(0, inputLength)?.toLowerCase() === inputValue

          if (keep) {
            count += 1
          }

          return keep
        })
  }

  getSuggestionValue(suggestion) {
    return suggestion.label
  }
}

RecipeIngredients.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateIngredient,
      deleteIngredient,
      deleteIngredientList,
      updateLocal,
      addIngredient,
      createError,
      updateLocalAtIndex,
    },
    dispatch,
  )
}

function mapStateToProps(state) {
  return {...state.recipe}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(RecipeIngredients)

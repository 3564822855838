import React from 'react'
import {NativeSelect, Grid, Paper, Typography, withStyles, Collapse} from '@material-ui/core'
import autobind from 'autobind-decorator'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import pluralize from 'pluralize'
import {fraction, number} from 'mathjs'
import {Link} from 'react-router'
import AddIngredientButton from '../../shoppingList/AddIngredientButton'
import AddAllIngredientsButton from '../../shoppingList/AddAllIngredientsButton'
import {updateLocal} from '../redux/actions'

const styles = theme => ({
  ingredientsMat: {
    margin: '40px 28.5px 0 0',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  ingredientsListIcon: {
    color: '#589a43',
    fontSize: '24px',
    lineHeight: '24px',
    [theme.breakpoints.down('md')]: {
      fontSize: '19px',
      lineHeight: '19px',
    },
  },
  ingredientsTitle: {
    marginLeft: '10px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '2px',
    },
  },
  items: {
    margin: '23px 0 50px 0',
  },
  item: {
    marginBottom: '23px',
    minHeight: '77.59px',
    border: '1.5px solid #D8D8D8',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '20px',
  },
  quantity: {
    marginLeft: '41px',
  },
  itemIcon: {
    marginLeft: '20px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '4px',
      marginRight: 5,
    },
  },
  itemIconButtonRoot: {
    margin: `-${theme.spacing(1)}px`,
    padding: theme.spacing(1),
    minWidth: 'unset',
  },
  itemIconButtonLabel: {
    fontSize: '1.5rem',
  },
  ingredientsMatInner: {
    padding: '24px 20px 46px',
    [theme.breakpoints.down('xs')]: {
      padding: '0px 20px 0px',
    },
  },
  hr: {
    borderTop: '1.5px solid #589a43',
  },
  materialsWrapper: {
    marginTop: '12px',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  showMaterial: {
    color: '#589a43',
    fontSize: '24px',
    lineHeight: '24px',
    marginLeft: '10px',
    verticalAlign: 'middle',
  },
  materialItems: {
    marginTop: '23px',
  },
  allIngredientsContainer: {
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      marginTop: 10,
      fontSize: 14,
    },
  },
  multiplyStyle: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'right',
      marginTop: 20,
    },
  },
  viewMaterialsText: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 10,
    },
  },
  addStyling: {
    [theme.breakpoints.down('xs')]: {
      fontWeight: 'normal',
      fontSize: 13,
    },
  },
  itemName: {
    textTransform: 'capitalize',
    [theme.breakpoints.down('xs')]: {
      paddingRight: 5,
    },
  },
  ingredientsTitleContainer: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
  },
})

const IngredientsMatPaper = withStyles({
  root: {
    borderRadius: '3px',
    boxShadow: '0 1px 10px 0 rgba(0,0,0,0.19)',
  },
})(Paper)

const IngredientsTitle = withStyles(theme => ({
  root: {
    color: '#000000',
    fontFamily: 'Literata',
    fontWeight: '400',

    fontSize: '24px',
    lineHeight: '32px',
    display: 'inline',
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
}))(Typography)

const MultiplySelect = withStyles(theme => ({
  root: {
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #EFEFEF',
    padding: '1px',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'right',
    },
  },
  select: {
    width: 'calc(100% - 26px)',
    paddingLeft: '8px',
    paddingRight: '18px',
  },
}))(NativeSelect)

const ItemText = withStyles(theme => ({
  root: {
    color: '#000000',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '16px',
    lineHeight: '19px',
    display: 'inline',
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
}))(Typography)

const ViewMaterials = withStyles({
  root: {
    color: '#000000',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: '300',

    lineHeight: '21px',
    cursor: 'pointer',
    display: 'inline',
  },
})(Typography)

function Item(props) {
  const {
    hideAdd,
    classes,
    ingredientId,
    name,
    quantity,
    unit,
    isAdding,
    index,
    ingredientLink,
  } = props
  return (
    <div className={classes.item}>
      <div style={{width: '100%'}}>
        <Grid container item xs={12}>
          <Grid item xs={5} className={classes.itemName}>
            <Link
              data-test-name="recipe-image-blank"
              to={ingredientLink}
              target="_blank"
              rel="noreferrer"
            >
              <ItemText data-test-name="item-name" className={classes.addStyling}>
                {name}
              </ItemText>
            </Link>
          </Grid>
          <Grid item xs={3} style={{textAlign: 'right'}}>
            <span className={classes.quantity}>
              <ItemText data-test-name="item-quantity">{quantity} </ItemText>
              <ItemText data-test-name="item-unit" style={{textTransform: 'capitalize'}}>
                {unit && pluralize(unit?.toLowerCase(), number(fraction(quantity)))}
              </ItemText>
            </span>
          </Grid>
          <Grid item xs={4} style={{textAlign: 'right'}}>
            {!hideAdd && (
              <span className={classes.itemIcon}>
                <AddIngredientButton
                  style={{color: '#D8D8D8'}}
                  ingredientId={ingredientId}
                  quantity={number(fraction(quantity))}
                  unit={unit}
                  index={index}
                  isAdding={isAdding}
                  classes={{root: classes.itemIconButtonRoot, label: classes.itemIconButtonLabel}}
                />
              </span>
            )}
            {/*             <span className={classes.itemIcon}> */}
            {/*               <IconButton */}
            {/*                 classes={{root: classes.itemIconButtonRoot, label: classes.itemIconButtonLabel}} */}
            {/*               > */}
            {/*                 <i className="fa fa-pencil-square-o" /> */}
            {/*               </IconButton> */}
            {/*             </span> */}
            {/*             <span className={classes.itemIcon}> */}
            {/*               <IconButton */}
            {/*                 classes={{root: classes.itemIconButtonRoot, label: classes.itemIconButtonLabel}} */}
            {/*               > */}
            {/*                 <i className="fa fa-trash" /> */}
            {/*               </IconButton> */}
            {/*             </span> */}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

const IngredientMatItem = withStyles(styles)(Item)

class IngredientsMaterials extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      viewMaterials: false,
    }
  }

  render() {
    const {classes, recipe} = this.props
    return (
      <Grid xs={12} md={6} item container>
        <div className={classes.ingredientsMat}>
          <IngredientsMatPaper>
            <div className={classes.ingredientsMatInner}>
              <Grid container item xs={12}>
                <Grid item xs={6} sm={4} className={classes.ingredientsTitleContainer}>
                  <span className={classes.ingredientsListIcon}>
                    <i className="fa fa-list" />
                  </span>
                  &nbsp;
                  <span className={classes.ingredientsTitle}>
                    <IngredientsTitle>Ingredients</IngredientsTitle>
                  </span>
                </Grid>

                <Grid item xs={6} sm={3} className={classes.multiplyStyle}>
                  <MultiplySelect
                    disableUnderline
                    onChange={this.onChangeMultiply}
                    value={recipe.multiplyIngredients}
                  >
                    <option value="">Multiply</option>
                    {[0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => {
                      return (
                        <option value={n} key={n}>
                          {n}x
                        </option>
                      )
                    })}
                  </MultiplySelect>
                </Grid>
                <Grid container xs={12} sm={5}>
                  <Grid item xs={6} />
                  <Grid item xs={6} sm={12} className={classes.allIngredientsContainer}>
                    <AddAllIngredientsButton recipe={recipe} />
                  </Grid>
                </Grid>
              </Grid>

              <div className={classes.items}>
                {recipe.ingredientLists
                  .filter(list => !!list.ingredients.length)
                  .map(list => {
                    return (
                      <>
                        {!!list.name && (
                          <IngredientsTitle style={{fontSize: 18, marginBottom: 12}}>
                            {list.name}
                          </IngredientsTitle>
                        )}

                        {list.ingredients.map((ingr, index) => {
                          return (
                            <IngredientMatItem
                              index={index}
                              ingredientId={ingr.ingredient.id}
                              key={ingr.id}
                              name={ingr.ingredient.title}
                              quantity={fraction(number(this.getItemQuantity(ingr))).toFraction(
                                true,
                              )}
                              ingredientLink={ingr.ingredientLink}
                              unit={ingr.unit}
                              isAdding={ingr.isAdding}
                            />
                          )
                        })}
                      </>
                    )
                  })}
              </div>
            </div>
            <hr className={classes.hr} />
            <div className={classes.ingredientsMatInner}>
              <div className={classes.materialsWrapper}>
                <div className={classes.viewMaterialsText}>
                  <ViewMaterials
                    data-test-name="view-materials"
                    onClick={this.handleViewMaterialsClicked}
                  >
                    {this.renderViewMaterialsText()}
                    <span className={classes.showMaterial}>
                      {this.state.viewMaterials ? (
                        <i className="fa fa-caret-up" />
                      ) : (
                        <i className="fa fa-caret-down" />
                      )}
                    </span>
                  </ViewMaterials>
                </div>
                <Collapse in={this.state.viewMaterials} timeout="auto" unmountOnExit={true}>
                  <div className={classes.materialItems}>
                    {recipe?.materials?.map((mat, index) => {
                      return (
                        <IngredientMatItem
                          index={'mat' + index}
                          hideAdd
                          key={mat.id}
                          name={mat.material.title}
                          ingredientLink={mat.materialLink}
                          quantity={mat.quantity}
                        />
                      )
                    })}
                  </div>
                </Collapse>
              </div>
            </div>
          </IngredientsMatPaper>
        </div>
      </Grid>
    )
  }

  renderViewMaterialsText() {
    if (this.state.viewMaterials) {
      return 'Hide Materials'
    }
    return 'View Materials'
  }

  @autobind
  getItemQuantity(item) {
    const {multiplyIngredients: multiply} = this.props.recipe
    if (!multiply) {
      return item.quantity
    }
    return item.quantity * multiply
  }

  @autobind
  onChangeMultiply(e) {
    e.preventDefault()
    this.props.updateLocal({multiplyIngredients: e.target.value}, 'recipe')
  }

  @autobind
  handleViewMaterialsClicked() {
    this.setState(({viewMaterials}) => {
      return {viewMaterials: !viewMaterials}
    })
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({updateLocal}, dispatch)
}

export default compose(connect(null, mapDispatchToProps), withStyles(styles))(IngredientsMaterials)

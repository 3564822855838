import * as Types from './actions.userList.types'

export function getUsers(params) {
  return {type: Types.USERS_LIST_GET, payload: {...params}}
}

export function getUsersSuccess(response) {
  return {type: Types.USERS_LIST_GET_SUCCESS, payload: response}
}

export function getUsersFailure(error) {
  return {type: Types.USERS_LIST_GET_FAILURE, error}
}

export function resetUserList() {
  return {type: Types.USERS_LIST_INIT}
}

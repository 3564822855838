import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import cx from 'classnames'

import {Grid, Icon} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import connect from 'react-redux/es/connect/connect'
import autobind from 'autobind-decorator'
import {updateLocal, updateLocalAtIndex, deleteLocalAtIndex, addDirection} from '../redux/actions'
import creationStyles, {
  DetailTextField,
  DirectionPhotoDropzoneAreaDetails,
  ButtonFilled,
} from './CreateRecipe.styles.js'
import DeleteButton from './DeleteButton'

import dragHandle from '../../../assets/icons/ellipsis-v.svg'

class RecipeDirections extends React.Component {
  onChange(index, patch) {
    this.props.updateLocalAtIndex('recipe', 'directions', index, patch)
  }

  @autobind
  deleteDirection(index) {
    this.props.deleteLocalAtIndex('recipe', 'directions', index)
  }

  @autobind
  handleChangeDocuments(index, files) {
    const reader = new FileReader()
    reader.onload = e => {
      this.props.updateLocalAtIndex('recipe', 'directions', index, {image: e.target.result})
    }
    reader.readAsDataURL(files[0])
  }

  onDragStart(e, index) {
    this.draggedIndex = index
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('text/html', e.target.parentNode.parentNode)
    e.dataTransfer.setDragImage(e.target.parentNode.parentNode, 20, 20)
  }

  onDragOver(index) {
    if (typeof this.draggedIndex === 'undefined') {
      return
    }
    const {recipe} = this.props
    if (this.draggedIndex === index) {
      return
    }
    const directions = recipe.directions.filter((direction, index) => index !== this.draggedIndex)
    directions.splice(index, 0, recipe.directions[this.draggedIndex])
    this.props.updateLocal({directions}, 'recipe')
    this.draggedIndex = index
  }

  onDragEnd() {
    this.draggedIndex = undefined
  }

  @autobind
  addDirection(e) {
    e.preventDefault()
    this.props.addDirection()
  }

  render() {
    const {classes, recipe, onBlur} = this.props
    return (
      <Grid item container spacing={3}>
        {recipe.directions.map((direction, index) => {
          return (
            <Grid
              key={index}
              item
              xs={12}
              className={classes.directions}
              onDragOver={() => this.onDragOver(index)}
            >
              <Grid container spacing={3}>
                <Grid item xs={7} sm={7} md={9}>
                  <img
                    src={dragHandle}
                    alt="drag handle"
                    draggable
                    onDragStart={e => this.onDragStart(e, index)}
                    onDragEnd={e => {
                      this.onDragEnd(e)
                      onBlur()
                    }}
                    className={classes.dragImage}
                  />
                  <DetailTextField
                    name="direction"
                    value={direction.direction}
                    className={classes.draggableInput}
                    onChange={e => this.onChange(index, {direction: e.target.value})}
                    rows="4"
                    multiline
                    onBlur={onBlur}
                  />
                </Grid>
                <Grid item xs={3} md={2}>
                  <DirectionPhotoDropzoneAreaDetails
                    dropZoneClass={classes.dropZoneDirectionPhoto}
                    filesLimit={1}
                    acceptedFiles={['image/jpg', 'image/jpeg', 'image/png']}
                    dropzoneText={
                      direction.image ? 'Photo Added' : <Icon color="disabled">add_a_photo</Icon>
                    }
                    onChange={e => {
                      this.handleChangeDocuments(index, e)
                      setTimeout(onBlur, 50)
                    }}
                    showPreviewsInDropzone={false}
                    showAlerts={false}
                    dropzoneParagraphClass={cx(classes.dropZoneText)}
                  />
                </Grid>
                <Grid item xs={1} md={1}>
                  <DeleteButton
                    onClick={() => {
                      this.deleteDirection(index)
                      setTimeout(onBlur, 50)
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )
        })}

        <Grid item xs={12} className={classes.directions}>
          <ButtonFilled fullWidth onClick={this.addDirection} variant="outlined">
            <Icon color="action">add_circle_outline</Icon>
          </ButtonFilled>
        </Grid>
      </Grid>
    )
  }
}

RecipeDirections.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {updateLocalAtIndex, deleteLocalAtIndex, updateLocal, addDirection},
    dispatch,
  )
}

function mapStateToProps(state) {
  return {...state.recipe}
}

const styles = theme => ({
  ...creationStyles,
  dropZoneDirectionPhoto: {
    minHeight: 52,
    maxHeight: 52,
    overflow: 'hidden',
  },
  directions: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
      marginLeft: 20,
    },
  },
  addButton: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
      marginLeft: 20,
    },
  },
  dragImage: {
    width: 8,
    height: 35,
    marginRight: 10,
    verticalAlign: 'middle',
    textAlign: 'center',
    cursor: 'move',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  draggableInput: {
    width: 'calc(100% - 18px)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(RecipeDirections)

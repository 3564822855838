export const costTypes = [
  {value: '', label: 'N/A'},
  {value: 'one', label: '$'},
  {value: 'two', label: '$$'},
  {value: 'three', label: '$$$'},
  {value: 'four', label: '$$$$'},
]

export const difficultyLevelTypes = [
  {value: '', label: 'N/A'},
  {value: 'easy', label: 'Easy'},
  {value: 'intermediate', label: 'Intermediate'},
  {value: 'difficult', label: 'Difficult'},
]

export const viewableByTypes = [
  {value: 'public', label: 'Public'},
  {value: 'private', label: 'Private'},
  {value: 'friends', label: 'Following'},
]

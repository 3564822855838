import _ from 'lodash'

export function authenticateOnEnter(store, nextState, replace, callback) {
  const storeState = store.getState()
  const {location} = nextState
  const isAuthed = !!_.get(storeState, 'auth.token')

  if (!isAuthed) {
    replace({
      pathname: '/login',
      query: {redirectTo: location.pathname + location.search},
    })
  }

  callback()
}

export default {authenticateOnEnter}

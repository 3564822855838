import * as Types from './actions.forgotPw.types'
import * as ErrorUtils from '../../utils/ErrorUtils'

export default function forgotPw(state = getInitialState(), action) {
  switch (action.type) {
    case Types.FORGOT_PW_INIT:
      return getInitialState()
    case Types.FORGOT_PW_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case Types.FORGOT_PW_REQUEST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isSent: true,
      }
    case Types.FORGOT_PW_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: ErrorUtils.getApiErrors(action.error),
      }
    case Types.FORGOT_PW_RESET:
      return {
        ...state,
        isFetching: true,
      }
    case Types.FORGOT_PW_RESET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isReset: true,
      }
    case Types.FORGOT_PW_RESET_FAILURE:
      return {
        ...state,
        isFetching: false,
        errors: ErrorUtils.getApiErrors(action.error),
      }
    default:
      return state
  }
}

export function getInitialState() {
  return {
    errors: {},
    isFetching: false,
    isSent: false,
    isReset: false,
  }
}

import {call, put, takeEvery} from 'redux-saga/effects'
import CalendarItemApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as NotificationActions from '../../common/redux/actions.notifications.js'

export default function* calendarFlow() {
  yield [takeEvery(Types.ADD_RECIPE_TO_CALENDAR, addCalendarItem)]
  yield [takeEvery(Types.GET_CALENDAR_ITEMS, getCalendarItems)]
  yield [takeEvery(Types.DELETE_CALENDAR_ITEM, deleteCalendarItem)]
}

function* addCalendarItem(action) {
  const {recipeId, date, callback} = action
  try {
    const response = yield call(CalendarItemApi.addCalendarItem, {
      recipe: recipeId,
      date,
    })
    yield put(Actions.addRecipeToCalendarSuccess(response))
    if (typeof callback === 'function') {
      callback()
    }
    yield put(NotificationActions.createSuccess('Successfully added to your calendar.'))
  } catch (error) {
    yield put(Actions.addRecipeToCalendarFailure(error))
  }
}

function* getCalendarItems(action) {
  const {params} = action
  try {
    const response = yield call(CalendarItemApi.getCalendarItems, params)
    yield put(Actions.getCalendarItemsSuccess(response))
  } catch (error) {
    yield put(Actions.getCalendarItemsFailure(error))
  }
}

function* deleteCalendarItem(action) {
  const {id, dayIndex, itemIndex} = action
  try {
    yield call(CalendarItemApi.deleteCalendarItem, id)
    yield put(Actions.deleteCalendarItemSuccess(id, dayIndex, itemIndex))
    yield put(NotificationActions.createSuccess('Successfully removed Calendar Item.'))
  } catch (error) {
    yield put(Actions.deleteCalendarItemFailure(error))
  }
}

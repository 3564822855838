import {withStyles} from '@material-ui/core/styles'
import {CardActionArea, Paper, Popper, Fab} from '@material-ui/core'

const styles = theme => ({
  title: {
    textAlign: 'left',
    color: `#000000`,
    fontFamily: 'Literata',
    fontSize: `34px`,
    fontWeight: '400',
    lineHeight: `48px`,
    marginBottom: 15,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 30,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      marginLeft: 10,
    },
  },
  containerSubPaper: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  categoriesPaper: {
    padding: `19px 15px`,
    boxShadow: `0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)`,
    [theme.breakpoints.down('xs')]: {
      margin: '0 auto',
      justifyContent: 'center',
    },
  },
  categoriesSubPaper: {
    height: `244px`,
    [theme.breakpoints.down('xs')]: {
      height: 145,
    },
  },
  titleCategories: {
    color: `#000000`,
    fontFamily: 'Literata',
    fontSize: `27px`,
    lineHeight: `32px`,
    fontWeight: '400',
    borderBottom: '1.5px solid #589a43',
    padding: `0 0 10px 10px`,
    [theme.breakpoints.down('xs')]: {
      fontFamily: 'lato-light',
      fontSize: 18,
      textAlign: 'center',
    },
  },
  paddingGridSubPaper: {
    padding: `15px 5px`,
    maxWidth: '261px',
    [theme.breakpoints.down(400)]: {
      minWidth: '172px !important',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '187px',
    },
    [`${theme.breakpoints.down(670)} and (orientation: landscape)`]: {
      maxWidth: 212,
    },
    [`${theme.breakpoints.down(750)} and (orientation: landscape)`]: {
      maxWidth: 228,
    },
  },
  card: {
    height: '227px',
    maxWidth: '257px',
    [theme.breakpoints.down('xs')]: {
      height: '150px',
    },
  },
  media: {
    height: `139px`,
    objectFit: 'cover',
    [theme.breakpoints.down('xs')]: {
      height: 83,
    },
  },
  cardTitle: {
    color: `#000000`,
    fontFamily: 'Literata',
    fontSize: `27px`,
    lineHeight: `36px`,
    fontWeight: '400',
    textOverflow: `ellipsis`,
    maxHeight: `100%`,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      maxWidth: '155px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  recipeIconOption: {
    color: 'white',
    width: '1.1em',
    height: '1.1em',
    fontSize: '28px',
  },
  createCategoryCard: {
    height: '227px',
    maxWidth: '257px',
    border: '3px dotted #EFEFEF',
    [theme.breakpoints.down('xs')]: {
      height: '150px',
    },
  },
  featureImageButton: {
    height: '24px',
    border: '0.5px solid #979797',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    color: '#979797',
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'center',
    fontFamily: 'Source Sans Pro',
    letterSpacing: '1px',
    textTransform: 'none',
  },
  featureImageLabel: {
    fontSize: '16px',
    lineHeight: '16px',
    color: 'rgba(0,0,0,0.87)',
    display: 'inline',
    marginLeft: '10px',
  },
  addIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#d8d8d8',
    fontSize: '32px',
    lineHeight: '32px',
  },
  typography: {
    fontFamily: `Lato`,
    fontWeight: '300',

    fontSize: `16px`,
    lineHeight: `21px`,
  },
  popper: {
    top: `2px !important`,
  },
  optionsModal: {
    overflow: 'scroll',
  },
  optionPaper: {
    width: `613px`,
    marginTop: `10%`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none',
    margin: `auto`,
    borderRadius: `3px`,
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
  cardContent: {
    height: `38%`,
    padding: '10px',
  },
  cardOption: {
    height: `53px`,
    width: `201px`,
    [theme.breakpoints.down('sm')]: {
      width: 143,
    },
  },
  optionsPopper: {
    left: '28px !important',
  },
  saveBtn: {
    height: '33px',
    width: '230px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    color: '#589a43',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '21px',
    textAlign: 'center',
    textTransform: 'none',
    marginTop: '50px',
    padding: 0,
  },
  cancelBtn: {
    height: '23px',
    width: '176px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    color: '#000000',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    marginTop: '26px',
    textTransform: 'none',
    padding: 0,
    borderRadius: `34.5px`,
  },
  optionsFormLabel: {
    color: `#000000`,
    fontFamily: `Lato`,
    fontWeight: '300',

    fontSize: `18px`,
    lineHeight: `21px`,
    marginBottom: '16px',
  },
  optionsMargin: {
    marginTop: '29px',
    padding: `0 69px`,
    [theme.breakpoints.down('xs')]: {
      padding: '0 20px',
    },
  },
  optionsTitle: {
    fontFamily: 'Literata',
    fontSize: `24px`,
    lineHeight: `32px`,
    fontWeight: '400',

    textAlign: `center`,
    paddingTop: '30.5px',
    padding: `0 20px`,
    [theme.breakpoints.down('xs')]: {
      fontFamily: 'Lato Light',
      lineHeight: '29px',
      textAlign: 'left',
    },
  },
  optionDeleteIcon: {
    height: `24px`,
    width: `21px`,
    color: `#D8D8D8`,
    fontSize: `24px`,
    top: `18px`,
    left: `200px`,
    position: 'relative',
    cursor: `pointer`,
  },
  optionsBottom: {
    paddingBottom: `31.5px`,
  },
})

export const CreateCategoryActionArea = withStyles({
  root: {
    height: '100%',
  },
})(CardActionArea)

export const CustomOptionPaper = withStyles({
  root: {
    height: `45px`,
    width: `150px`,
    borderRadius: `2px`,
    backgroundColor: `#FFFFFF`,
    boxShadow: `0 1px 6px 0 rgba(0,0,0,0.5)`,
  },
})(Paper)

export const CustomOptionPopper = withStyles({
  root: {
    right: 0,
  },
})(Popper)

export const CustomOptionFab = withStyles({
  root: {
    zIndex: 100,
    color: `#fff`,
    background: `transparent`,
    top: `5px`,
    right: `5px`,
    position: `absolute`,
    width: '31px',
    height: '31px',
    minHeight: `0`,
    minWidth: `0`,
    textShadow: `0px 1px 3px rgba(0, 0, 0, 0.5)`,
    display: 'block',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    '&:focus': {
      boxShadow: 'none',
    },
    boxShadow: 'none',
    transition: 'none',
  },
})(Fab)

export default styles

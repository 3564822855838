import _ from 'lodash'
import ImageUtils from './ImageUtils'
import imgUserDefault from '../../assets/user.png'
import imgAvataPlaceholder from '../../assets/avatar_upload_placeholder.png'

export default class UserUtils {
  static getImageUrl(user) {
    return _.get(user, 'avatar.fullSize') || imgUserDefault //TODO: change to regular size if available
  }

  static getSmallImageUrl(user) {
    return _.get(user, 'avatar.small') || UserUtils.getImageUrl(user)
  }

  static getEmptyImageUrl() {
    return ImageUtils.getAssetUrl('user.png')
  }

  static getAvatarUploadPlaceholderUrl() {
    return imgAvataPlaceholder
  }

  static getAvatarStyle(user) {
    return {background: `url(${this.getSmallImageUrl(user)}) center`}
  }

  static getFullName(user) {
    return `${user.firstName} ${user.lastName}` || _.get(user, ['name', 'email'], '')
  }
}

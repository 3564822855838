import React from 'react'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import {Grid, Paper, Icon, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import cx from 'classnames'
import clsx from 'clsx'
import {Link} from 'react-router'
import PageUtils from '../utils/PageUtils'
import {isMobile} from '../utils/BrowserUtils'

const styles = theme => ({
  paddingTitle: {
    padding: `20px`,
  },
  paddingSubPaper: {
    padding: '10px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0',
    },
  },
  subBody: {
    marginTop: 11,
    color: `#000000`,
    fontFamily: `Lato`,
    fontSize: `16px`,
    fontWeight: 300,
    lineHeight: `24px`,
    textAlign: 'left',
    padding: `0 10px`,
    [theme.breakpoints.down('xs')]: {
      fontSize: `14px`,
    },
  },
  paddingSubPaperPadding: {
    padding: `5px`,
  },
  paddingSubPaperPaddingLast: {
    padding: `5px`,
    marginBottom: `4.3%`,
    [theme.breakpoints.down('xs')]: {
      marginBottom: `1%`,
    },
  },
  aboutUsPaper: {
    padding: `20px`,
    color: `#000000`,
    fontFamily: `Literata`,
    fontSize: `34px`,
    lineHeight: `53px`,
    textAlign: `center`,
    borderRadius: `3px`,
    backgroundColor: `#FFFFFF`,
    boxShadow: `0 1px 10px 0 rgba(0,0,0,0.19)`,
  },
  carouselImageContainer: {
    position: 'absolute',
    width: '100%',
    height: 600,
    overflow: 'hidden',
  },
  carouselImage: {
    width: '100%',
    padding: theme.spacing(3),
  },
  carouselInfo: {
    position: 'absolute',
    width: 418,
    height: 378,
    left: 75,
    top: 300,
    padding: '25px 35px 35px 35px',
    '& >p': {
      fontFamily: 'Lato',
      fontSize: '16px',
      fontWeight: '300',
      lineHeight: '24px',
    },
  },
  carouselInfoTitle: {
    textOverflow: 'ellipsis',
    /** Max 2 lines **/
    maxHeight: '124px',
    lineHeight: '62px',
    display: 'block',
    overflow: 'hidden',
    wordWrap: 'break-word',
    fontFamily: 'Literata',
    fontWeight: `600`,
    fontSize: '52px',
  },
  Icon: {
    color: `#589a43`,
    fontSize: `25px`,
    marginTop: `7px`,
    width: `auto`,
    [theme.breakpoints.down('xs')]: {
      fontSize: `20px`,
      marginTop: `11.5px`,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      float: 'left',
      padding: '0 10',
    },
  },
  subTitle: {
    color: `#000000`,
    fontFamily: `Literata`,
    fontSize: `31px`,
    lineHeight: `34px`,
    textAlign: `left`,
    [theme.breakpoints.down('xs')]: {
      fontSize: `23px`,
    },
  },

  emptyMessage: {
    position: 'relative',
    left: '30%',
    top: '15%',
    width: '450px',
    fontSize: '30px',
    fontWeight: '500',
    textAlign: 'center',
  },
  emptyMessageLogIn: {
    position: 'relative',
    left: '20%',
    top: '15%',
    width: '450px',
    fontSize: '30px',
    fontWeight: '500',
    textAlign: 'center',
  },
  aboutLink: {
    textDecoration: `none`,
  },
  aboutUsHeader: {
    color: `#000000`,
    fontFamily: `Literata`,
    fontSize: `34px`,
    lineHeight: `53px`,
    marginBottom: '52px',
    [theme.breakpoints.down('xs')]: {
      fontSize: `30px`,
      marginBottom: `20px`,
      textAlign: `center`,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      textAlign: 'center',
      fontSize: `30px`,
      marginBottom: `20px`,
    },
  },
  aboutUs: {
    //     padding: `10px 0 20px`,
  },
})
export const CustomTypography = withStyles(theme => ({
  root: {
    padding: `25px 20px 45px`,
    color: `#000000`,
    fontFamily: `Literata`,
    fontSize: `34px`,
    lineHeight: `53px`,
    margin: `0 auto`,
    width: `95%`,
    textAlign: `center`,
    [theme.breakpoints.down('xs')]: {
      width: `100%`,
      margin: `0 auto`,
      padding: `5px 0px 20px`,
      fontSize: `16px`,
      lineHeight: `24px`,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      width: `100%`,
      margin: `0 auto`,
      padding: `5px 0px 20px`,
      fontSize: `16px`,
      lineHeight: `24px`,
    },
  },
}))(Typography)

class AboutUs extends React.Component {
  componentDidMount() {
    PageUtils.scrollToTop()
  }

  render() {
    const {classes} = this.props
    let calendar = '/my-kitchen/calendar'
    let shoppingList = '/my-kitchen'
    if (isMobile.resolution()) {
      calendar = '/calendar-mobile'
      shoppingList = '/shopping-list-mobile'
    }
    return (
      <React.Fragment>
        <Grid container className={classes.aboutUs}>
          <Grid item xs={12}>
            <Typography variant="h3" component="h3" className={classes.aboutUsHeader}>
              About Us
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Paper className={cx(classes.root, classes.aboutUsPaper)} elevation={1}>
              <Grid container>
                <Grid item xs={12}>
                  <CustomTypography variant="h5" component="h3">
                    Welcome to Recimee, a modern meal planning site where cooks of all levels can
                    find inspiration and simple tools to stay organized.
                  </CustomTypography>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={6} className={classes.paddingSubPaper}>
                    <a href="/my-kitchen" className={classes.aboutLink}>
                      <Paper className={cx(classes.root, classes.aboutUsPaper)} elevation={1}>
                        <Grid container className={classes.paddingSubPaperPadding}>
                          <Grid container item xs={12}>
                            <Grid item md={1} xs={2} sm={1}>
                              <Icon className={clsx(classes.Icon, 'fas fa-list')} />
                            </Grid>
                            <Grid item md={11} xs={10} sm={11}>
                              <Typography variant="h4" component="h4" className={classes.subTitle}>
                                Recipes
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className={classes.subBody}>
                              Search Recimee for curated recipes that are healthy(ish) and kitchen
                              tested. Have favorite recipes of your own? Upload or import your
                              favorites from select sites. Keep everything organized in your Recipe
                              Box for easy access.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </a>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.paddingSubPaper}>
                    <Link to={calendar} className={classes.aboutLink}>
                      <Paper className={cx(classes.root, classes.aboutUsPaper)} elevation={1}>
                        <Grid container className={classes.paddingSubPaperPadding}>
                          <Grid container item xs={12}>
                            <Grid item md={1} xs={2} sm={1}>
                              <Icon className={clsx(classes.Icon)}> calendar_today</Icon>
                            </Grid>
                            <Grid item md={11} xs={10} sm={11}>
                              <Typography variant="h4" component="h4" className={classes.subTitle}>
                                Calendar
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className={classes.subBody}>
                              The short view keeps you organized and focused. The long view is great
                              for quick meal planning. Search your recipes, then drag and drop into
                              the calendar. Voila, meal planning done.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.paddingSubPaper}>
                    <Link to={shoppingList} className={classes.aboutLink}>
                      <Paper className={cx(classes.root, classes.aboutUsPaper)} elevation={1}>
                        <Grid container className={classes.paddingSubPaperPadding}>
                          <Grid container item xs={12}>
                            <Grid item md={1} xs={2} sm={1}>
                              <Icon className={clsx(classes.Icon, 'fas fa-shopping-cart')} />
                            </Grid>
                            <Grid item md={11} xs={10} sm={11}>
                              <Typography variant="h4" component="h4" className={classes.subTitle}>
                                Shopping List
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className={classes.subBody}>
                              Always viewable, editable and shareable. Add ingredients from any
                              recipe or add one-off items as needed. Check off as you shop or share
                              the list and let someone else do the shopping.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Link>
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.paddingSubPaper}>
                    <a href="/" className={classes.aboutLink}>
                      <Paper className={cx(classes.root, classes.aboutUsPaper)} elevation={1}>
                        <Grid container className={classes.paddingSubPaperPaddingLast}>
                          <Grid container item xs={12}>
                            <Grid item md={1} xs={2} sm={1}>
                              <Icon className={clsx(classes.Icon, 'fas fa-users')} />
                            </Grid>
                            <Grid item md={11} xs={10} sm={11}>
                              <Typography variant="h4" component="h4" className={classes.subTitle}>
                                Community
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className={classes.subBody}>
                              Find inspiration in what your friends are making. See their latest
                              recipes, save them, make them. Share your favorite recipes to inspire
                              others.
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </a>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

AboutUs.displayName = 'AboutUs'

function mapStateToProps(state) {
  return {...state.home, ...state.profile}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(AboutUs)

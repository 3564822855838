import React from 'react'
import {Grid, Paper, withStyles} from '@material-ui/core'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasketOutlined'
import defaultImage from '../../assets/recipe_default_image.png'

function AdItem({classes, item}) {
  return (
    <Grid item>
      <Paper className={classes.paper}>
        <div className="recipeImageContainer">
          <a data-test-name="recipe-image" href={item.link} className={classes.recipeImage}>
            {item.image && <img className={classes.recipeImage} src={item.image} />}
            {!item.image && (
              <div className={classes.recipeDefaultImageContainer}>
                <img className={classes.defaultImage} src={defaultImage} />{' '}
              </div>
            )}
            <ShoppingBasketIcon className={classes.contentTypeIcon} />
          </a>
        </div>
        <div className={classes.recipeTitleContainer}>
          <a
            data-test-name="recipe-title"
            href={item.link}
            className={classes.recipeInfoTitle}
            target="_blank"
            rel="noreferrer"
          >
            {item.title}
          </a>
        </div>
        <div
          className={classes.recipeInfoBy}
          dangerouslySetInnerHTML={{__html: item.description}}
        />
      </Paper>
    </Grid>
  )
}

export default AdItem

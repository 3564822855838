import React, {useEffect, useState} from 'react'
import {
  Popper,
  Fade,
  Paper,
  Typography,
  Divider,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import {Link, withRouter} from 'react-router'
import cx from 'classnames'

import * as NotificationActions from '../common/redux/actions.notifications'
import NotificationsApi from './api'
import styles from './notifications.styles'
import ProfileApi from '../profile/api'

function NotificationsList({
  open = false,
  anchorEl,
  classes,
  arrowClass,
  notificationsArrowRef,
  handleNotificationsArrowRef,
  createError,
  createNotificationFromError,
  createSuccess,
  handleNotificationsOpen,
  router,
}) {
  const [isLoading, setLoading] = useState(false)
  const [notifications, updateNotifications] = useState([])
  const [count, setCount] = useState(0)
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (!open) {
      updateNotifications([])
      setCount(0)
      setPage(1)
      return
    }
    loadData(1)
  }, [open])

  useEffect(() => {
    if (open) handleNotificationsOpen()
  }, [router.location.pathname])

  const loadData = async (p = 1) => {
    setLoading(true)

    try {
      const response = await NotificationsApi.list(page)
      updateNotifications([...notifications, ...response?.results])
      setCount(response?.count)
      setPage(p + 1)
    } catch (e) {
      console.warn(e)
      if (e?.response?.status !== 400) {
        createError(`${e.response.status} - ${e.response.statusText}`)
        return
      }
      createNotificationFromError(e)
    } finally {
      setLoading(false)
    }
  }

  const onFollowSender = async (sender, notification) => {
    try {
      await ProfileApi.followUser(sender?.id)
      updateNotifications([
        ...notifications.map(n => {
          return {
            ...n,
            sender: {...n.sender, following: n.id === notification.id ? true : n.sender.following},
          }
        }),
      ])

      createSuccess(`Now following ${getSenderDisplayName(sender)}`)
    } catch (e) {
      console.warn(e)
      if (e.response.status !== 400) {
        createError(`${e.response.status} - ${e.response.statusText}`)
        return
      }
      createNotificationFromError(e)
    }
  }

  const handleScroll = e => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom && (page - 1) * 10 < count) {
      loadData(page)
    }
  }

  const getSenderDisplayName = sender => {
    if (!sender) return
    if (sender.firstName === '' || sender.lastName === '') {
      if (sender.username === '') return sender.email
      return sender.username
    }
    return `${sender.firstName} ${sender.lastName}`
  }

  const popperModifiers = {
    arrow: {
      enabled: true,
      element: notificationsArrowRef,
    },
    flip: {
      enabled: true,
    },
    offset: {
      enabled: true,
      offset: -40,
    },
  }

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      transition
      disablePortal
      placement="bottom"
      className={classes.root}
      modifiers={popperModifiers}
    >
      {({TransitionProps}) => (
        <>
          <span className={arrowClass} ref={handleNotificationsArrowRef} />
          <Fade {...TransitionProps} timeout={100}>
            <Paper className={classes.paper} onScroll={e => handleScroll(e)}>
              {notifications.map((notification, index) => {
                return (
                  <>
                    <Grid container key={`notification-${index}`}>
                      <Grid item xs={9} className={classes.gridItem}>
                        <Typography className={classes.typography}>
                          <Link className={classes.link} to={`/users/${notification?.sender?.id}`}>
                            {getSenderDisplayName(notification?.sender)}
                          </Link>{' '}
                          {notification?.type === 'user_followed'
                            ? 'is following you.'
                            : 'has saved your recipe '}
                          {notification?.type === 'recipe_saved' && (
                            <Link
                              className={classes.link}
                              to={`/recipe/${notification?.recipe?.id}`}
                            >
                              {notification?.recipe?.title}
                              {'.'}
                            </Link>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={3} className={cx(classes.gridItem, classes.center)}>
                        {notification?.type === 'user_followed' &&
                          !notification?.sender?.following && (
                            <Button
                              className={classes.followBtn}
                              onClick={() => onFollowSender(notification?.sender, notification)}
                            >
                              Follow
                            </Button>
                          )}
                      </Grid>
                    </Grid>
                    {index !== notifications.length - 1 && <Divider className={classes.divider} />}
                  </>
                )
              })}
              {isLoading && <CircularProgress />}
            </Paper>
          </Fade>
        </>
      )}
    </Popper>
  )
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...NotificationActions}, dispatch)
}

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(NotificationsList)

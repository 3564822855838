import React from 'react'
import {
  Grid,
  InputLabel,
  Button,
  Typography,
  Input,
  withStyles,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  FormControl,
  Select,
} from '@material-ui/core'
import autobind from 'autobind-decorator'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import humps from 'humps'

import PropTypes from 'prop-types'
import autoBind from 'react-autobind'
import MyKitchenApi from './api'
import {createNotificationFromError, createSuccess} from '../common/redux/actions.notifications.js'
import InputValidator from '../common/InputValidator.jsx'
import validatedComponent from '../common/ValidatedComponent.jsx'
import {createCategorySuccess} from './redux/actions'

const styles = theme => ({
  titleCreate: {
    fontFamily: 'lato-light',
    fontSize: '24px',
  },
  createCategory: {
    margin: '120px 0 188px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  createCategoryContainer: {
    padding: 20,
    backgroundColor: 'white',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      height: '90%',
      overflow: 'auto',
    },
  },
  createCategoryInputContainer: {
    marginBottom: '5px',
  },
  createCategoryInput: {
    width: '100%',
    border: '1.5px solid #D8D8D8',
    borderRadius: '3px',
    height: '51px',
    marginTop: 0,
    backgroundColor: '#FFFFFF',
    color: '#000',
    padding: '9px 11px',
  },
  featureImageLabel: {
    fontSize: '16px',
    lineHeight: '16px',
    color: 'rgba(0,0,0,0.87)',
    marginLeft: '10px',
    marginTop: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  featureImageButton: {
    height: '24px',
    border: '0.5px solid #979797',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    color: '#979797',
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'center',
    fontFamily: 'Source Sans Pro',
    letterSpacing: '1px',
    textTransform: 'none',
  },
  saveBtn: {
    height: '30px',
    width: '280px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    color: '#589a43',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '21px',
    textAlign: 'center',
    textTransform: 'none',
    marginTop: '30px',
    padding: 0,
  },
  cancelBtn: {
    height: '23px',
    width: '176px',
    bordeRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    color: '#000000',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    marginTop: '26px',
    textTransform: 'none',
    padding: 0,
  },
})

const DetailTextField = withStyles({
  root: {
    marginTop: 0,
    border: '1px solid #D8D8D8',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    color: '#000',
    padding: '9px 11px',
  },
})(({component, ...others}) => {
  let Component = Input
  if (component) {
    Component = component
  }
  return <Component disableUnderline {...others} />
})

class CreateCategoryMobile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      sharedWith: '',
      image: '',
      openModal: true,
    }
    autoBind(this)
  }

  closeModal() {
    this.props.onCancel()
  }

  render() {
    const {classes} = this.props

    return (
      <Modal
        open={this.props.openModal}
        onClose={this.closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{margin: '20px !important'}}
      >
        <div>
          <Grid
            container
            item
            md={6}
            lg={4}
            sm={9}
            xs={11}
            className={classes.createCategoryContainer}
          >
            <p className={classes.titleCreate}>Create Category</p>
            <Grid item xs={12}>
              <div className={classes.createCategoryInputContainer}>
                <InputValidator shouldValidateOnBlur={true}>
                  <DetailTextField
                    value={this.state.name}
                    placeholder="Category Name"
                    label="Category Name"
                    className={classes.createCategoryInput}
                    onChange={this.handleChange.bind(this, 'name')}
                    required
                    inputProps={{
                      'data-test-name': 'create-category-name',
                      id: 'categoryName',
                      maxLength: 150,
                    }}
                  />
                </InputValidator>
              </div>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={classes.createCategoryInputContainer}
                style={{width: '100%'}}
                variant="filled"
              >
                <InputLabel htmlFor="sharedWith" style={{marginTop: 8}}>
                  Who can see this?
                </InputLabel>
                <Select
                  value={this.state.sharedWith}
                  className={classes.createCategoryInput}
                  inputProps={{id: 'sharedWith'}}
                  onChange={this.handleChange.bind(this, 'sharedWith')}
                  disableUnderline
                >
                  <MenuItem value="" />
                  <MenuItem value="public">Public</MenuItem>
                  <MenuItem value="friends">Following</MenuItem>
                  <MenuItem value="private">Private</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel
                htmlFor="image"
                style={{display: 'block', marginBottom: '10px', marginTop: 20}}
              >
                Feature image
              </InputLabel>
              <Button variant="contained" component="label" className={classes.featureImageButton}>
                Choose File
                <input
                  style={{display: 'none'}}
                  type="file"
                  accept="image/*"
                  onChange={this.handleChangeImage.bind(this)}
                  id="image"
                />
              </Button>
              <Typography component="span" className={classes.featureImageLabel}>
                {this.renderImageName(this.state.image)}
              </Typography>
              {this.state.image !== '' ? (
                <span style={{marginLeft: '20px'}}>
                  <FormControlLabel
                    control={<Checkbox onChange={this.onClearImage} label="Clear Image" />}
                    label="Clear Image"
                  />
                </span>
              ) : null}
            </Grid>
            <Grid item xs={12} style={{textAlign: 'center'}}>
              <div>
                <Button
                  data-test-name="create-category-save"
                  onClick={this.createCategory}
                  className={classes.saveBtn}
                >
                  Save
                </Button>
              </div>
              <Button
                data-test-name="create-category-cancel"
                onClick={this.closeModal}
                className={classes.cancelBtn}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </Modal>
    )
  }

  renderImageName(value) {
    if (value === '') {
      return 'No file chosen'
    }
    return value.name
  }

  handleChange(name, event) {
    this.setState({[name]: event.target.value})
  }

  handleChangeImage(event) {
    if (event.target.files.length > 0) {
      this.setState({image: event.target.files[0]})
    }
  }

  @autobind
  onClearImage() {
    this.setState({image: ''})
  }

  clearFields() {
    this.setState({image: '', name: ''})
  }

  @autobind
  async createCategory() {
    const {
      createNotificationFromError,
      createSuccess,
      isComponentValid,
      onSaved,
      createCategorySuccess,
    } = this.props
    try {
      if (isComponentValid()) {
        const category = await MyKitchenApi.createCategory(this.state)
        createCategorySuccess(humps.camelizeKeys(category.data))
        this.clearFields()
        createSuccess('Category created successfully.')
        this.closeModal()
        if (typeof onSaved !== 'undefined') onSaved(category)
      }
    } catch (e) {
      createNotificationFromError(e)
    }
  }
}

CreateCategoryMobile.propTypes = {
  onCancel: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {createNotificationFromError, createSuccess, createCategorySuccess},
    dispatch,
  )
}

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
  validatedComponent,
)(CreateCategoryMobile)

import urlJoin from 'url-join'
import _ from 'lodash'
import settings from '../../../config/settings'

const appBaseUrl = _.get(settings, 'appBaseURL', '/')

export function getAbsoluteAssetUrl(localUrl) {
  return urlJoin(appBaseUrl, localUrl)
}

export default {getAbsoluteAssetUrl}

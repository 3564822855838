import * as Types from './actions.types'

export function addShoppingItemByTitle(payload, params, callback) {
  return {type: Types.ADD_SHOPPING_ITEM_BY_TITLE, payload, params, callback}
}

export function addShoppingItemByTitleSuccess(shoppingItem) {
  return {type: Types.ADD_SHOPPING_ITEM_BY_TITLE_SUCCESS, shoppingItem}
}

export function addShoppingItemByTitleFailure(error) {
  return {type: Types.ADD_SHOPPING_ITEM_BY_TITLE_FAILURE, error}
}

export function updateShoppingItemByTitle(id, payload, params, callback) {
  return {type: Types.UPDATE_SHOPPING_ITEM_BY_TITLE, id, payload, params, callback}
}

export function updateShoppingItemByTitleSuccess(shoppingItem) {
  return {type: Types.UPDATE_SHOPPING_ITEM_BY_TITLE_SUCCESS, shoppingItem}
}

export function updateShoppingItemByTitleFailure(error) {
  return {type: Types.UPDATE_SHOPPING_ITEM_BY_TITLE_FAILURE, error}
}

export function addShoppingItem(ingredient, quantity, unit, index, params, callback) {
  return {type: Types.ADD_SHOPPING_ITEM, ingredient, quantity, unit, index, params, callback}
}

export function addShoppingItemSuccess() {
  return {type: Types.ADD_SHOPPING_ITEM_SUCCESS}
}

export function addShoppingItemFailure(error) {
  return {type: Types.ADD_SHOPPING_ITEM_FAILURE, error}
}

export function getShoppingItems(params) {
  return {type: Types.GET_SHOPPING_ITEMS, params}
}

export function deleteAllShoppingItems() {
  return {type: Types.DELETE_ALL_SHOPPING_ITEMS}
}

export function getShoppingItemsSuccess(response) {
  return {type: Types.GET_SHOPPING_ITEMS_SUCCESS, response}
}

export function deleteAllShoppingItemsSuccess() {
  return {type: Types.DELETE_ALL_SHOPPING_ITEMS_SUCCESS}
}

export function getShoppingItemsFailure(error) {
  return {type: Types.GET_SHOPPING_ITEMS_FAILURE, error}
}

export function deleteAllShoppingItemsFailure(error) {
  return {type: Types.DELETE_ALL_SHOPPING_ITEMS_FAILURE, error}
}

export function deleteShoppingItem(id) {
  return {type: Types.DELETE_SHOPPING_ITEM, id}
}

export function deleteShoppingItemSuccess(id) {
  return {type: Types.DELETE_SHOPPING_ITEM_SUCCESS, id}
}

export function deleteShoppingItemFailure(error) {
  return {type: Types.DELETE_SHOPPING_ITEM_FAILURE, error}
}

export function updateShoppingItem(id, patch, params) {
  return {type: Types.UPDATE_SHOPPING_ITEM, id, patch, params}
}

export function updateShoppingItemSuccess(id, updatedItem) {
  return {type: Types.UPDATE_SHOPPING_ITEM_SUCCESS, id, updatedItem}
}

export function updateShoppingItemFailure(error) {
  return {type: Types.UPDATE_SHOPPING_ITEM_FAILURE, error}
}

export function addAllShoppingItemsFromRecipe(recipe) {
  return {type: Types.ADD_ALL_SHOPPING_ITEMS_FROM_RECIPE, recipe}
}

export function addAllShoppingItemsFromRecipeSuccess() {
  return {type: Types.ADD_ALL_SHOPPING_ITEMS_FROM_RECIPE_SUCCESS}
}

export function addAllShoppingItemsFromRecipeFailure(error) {
  return {type: Types.ADD_ALL_SHOPPING_ITEMS_FROM_RECIPE_FAILURE, error}
}

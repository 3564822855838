import React from 'react'
import {Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import {fraction} from 'mathjs'

const CostItem = withStyles({
  root: {
    color: '#000',
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: '300',

    lineHeight: '19px',
    display: 'inline-block',
  },
})(Typography)

const CostItemFaded = withStyles({
  root: {
    color: '#ACACAC',
    fontFamily: 'Lato',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '300',

    display: 'inline-block',
  },
})(Typography)

function CostOne() {
  return (
    <React.Fragment>
      <CostItem>$</CostItem>
      <CostItemFaded>$</CostItemFaded>
      <CostItemFaded>$</CostItemFaded>
      <CostItemFaded>$</CostItemFaded>
    </React.Fragment>
  )
}

function CostTwo() {
  return (
    <React.Fragment>
      <CostItem>$</CostItem>
      <CostItem>$</CostItem>
      <CostItemFaded>$</CostItemFaded>
      <CostItemFaded>$</CostItemFaded>
    </React.Fragment>
  )
}

function CostThree() {
  return (
    <React.Fragment>
      <CostItem>$</CostItem>
      <CostItem>$</CostItem>
      <CostItem>$</CostItem>
      <CostItemFaded>$</CostItemFaded>
    </React.Fragment>
  )
}

function CostFour() {
  return (
    <React.Fragment>
      <CostItem>$</CostItem>
      <CostItem>$</CostItem>
      <CostItem>$</CostItem>
      <CostItem>$</CostItem>
    </React.Fragment>
  )
}

export function getDifficulty(recipe) {
  if (recipe.difficulty === null || recipe.difficulty === undefined) {
    return null
  }
  const difficulties = {
    easy: 'Easy',
    intermediate: 'Intermediate',
    difficult: 'Difficult',
  }
  return difficulties[recipe.difficulty]
}

export function getCost(recipe) {
  switch (recipe.cost) {
    case 'one':
      return <CostOne />
    case 'two':
      return <CostTwo />
    case 'three':
      return <CostThree />
    case 'four':
      return <CostFour />
    default:
      return null
  }
}

export function isTagValid(tag, existingTags) {
  const trimmed = tag.trim()?.toLowerCase()
  return trimmed !== '' && !existingTags.map(t => t.tag.title?.toLowerCase()).includes(trimmed)
}

export function isNumeric(n) {
  if (typeof n !== 'string') return true
  if (n.includes('.')) return false
  try {
    fraction(n)
  } catch (error) {
    return false
  }
  return !Number.isNaN(parseFloat(n)) && !Number.isNaN(n) && n.charAt(n.length - 1) !== '/'
}

import {call, put, takeEvery} from 'redux-saga/effects'
import ShoppingItemApi from '../api'
import * as Actions from './actions'
import * as Types from './actions.types'
import * as NotificationActions from '../../common/redux/actions.notifications.js'
import {updateLocalAtIndex} from '../../recipe/redux/actions'

export default function* shoppingItemsFlow() {
  yield [takeEvery(Types.ADD_ALL_SHOPPING_ITEMS_FROM_RECIPE, addAllShoppingItemsFromRecipe)]
  yield [takeEvery(Types.ADD_SHOPPING_ITEM, addShoppingItem)]
  yield [takeEvery(Types.ADD_SHOPPING_ITEM_BY_TITLE, addShoppingItemByTitle)]
  yield [takeEvery(Types.UPDATE_SHOPPING_ITEM_BY_TITLE, updateShoppingItemByTitle)]
  yield [takeEvery(Types.GET_SHOPPING_ITEMS, getShoppingItems)]
  yield [takeEvery(Types.UPDATE_SHOPPING_ITEM, updateShoppingItem)]
  yield [takeEvery(Types.DELETE_SHOPPING_ITEM, deleteShoppingItem)]
  yield [takeEvery(Types.DELETE_ALL_SHOPPING_ITEMS, deleteAllShoppingItems)]
}

function* addAllShoppingItemsFromRecipe(action) {
  const {recipe} = action
  try {
    if (!recipe.multiplyIngredients) {
      yield call(ShoppingItemApi.addAllFromRecipe, recipe.id)
    } else {
      const ingreLength = recipe.ingredients.length
      for (let index = 0; index < ingreLength; index++) {
        const recipeIngredient = recipe.ingredients[index]
        const quantity = recipeIngredient.quantity * recipe.multiplyIngredients
        yield addShoppingItem({
          disableNotification: true,
          ingredient: recipeIngredient.ingredient.id,
          quantity: quantity.toFixed(2),
          unit: recipeIngredient.unit,
          index,
        })
      }
    }
    yield getShoppingItems({params: {expand: 'ingredient,category', done: false}})
    yield put(
      NotificationActions.createSuccess(
        'Successfully added all items from recipe to shopping list.',
      ),
    )
    yield put(Actions.addAllShoppingItemsFromRecipeSuccess())
  } catch (error) {
    yield put(Actions.addAllShoppingItemsFromRecipeFailure(error))
  }
}

function* addShoppingItemByTitle(action) {
  const {payload, params, callback} = action
  try {
    const response = yield call(ShoppingItemApi.addShoppingItemByTitle, payload, params)
    yield put(Actions.addShoppingItemByTitleSuccess(response))
    yield getShoppingItems({params: {expand: 'ingredient,category', done: false}})
    yield put(NotificationActions.createSuccess('Successfully added item to shopping list.'))
    if (typeof callback === 'function') {
      callback(true)
    }
  } catch (error) {
    yield put(Actions.addShoppingItemByTitleFailure(error))
    if (typeof callback === 'function') {
      callback(false)
    }
  }
}

function* addShoppingItem(action) {
  const {ingredient, quantity, unit, index, params, disableNotification} = action
  try {
    yield put(updateLocalAtIndex('recipe', 'ingredients', index, {isAdding: true}))
    yield call(ShoppingItemApi.addShoppingItem, {ingredient, quantity, unit}, params)
    yield put(updateLocalAtIndex('recipe', 'ingredients', index, {isAdding: false}))
    if (!disableNotification) {
      yield put(NotificationActions.createSuccess('Successfully added item to shopping list.'))
    }
  } catch (error) {
    yield put(Actions.addShoppingItemFailure(error))
  }
}

function* getShoppingItems(action) {
  const {params} = action
  try {
    const response = yield call(ShoppingItemApi.getShoppingItems, params)
    yield put(Actions.getShoppingItemsSuccess(response))
  } catch (error) {
    yield put(Actions.getShoppingItemsFailure(error))
  }
}

function* updateShoppingItem(action) {
  const {id, patch} = action
  try {
    yield call(ShoppingItemApi.updateShoppingItem, id, patch)
    yield put(Actions.updateShoppingItemSuccess(id, patch))
    yield put(NotificationActions.createSuccess('Shopping Item successfully updated.'))
  } catch (error) {
    yield put(Actions.updateShoppingItemFailure(error))
  }
}

function* deleteShoppingItem(action) {
  const {id} = action
  try {
    yield call(ShoppingItemApi.deleteShoppingItem, id)
    yield put(Actions.deleteShoppingItemSuccess(id))
    yield put(NotificationActions.createSuccess('Successfully removed Shopping Item.'))
  } catch (error) {
    yield put(Actions.deleteShoppingItemFailure(error))
  }
}

function* deleteAllShoppingItems(action) {
  try {
    yield call(ShoppingItemApi.deleteAllShoppingItems)
    yield put(Actions.deleteAllShoppingItemsSuccess())
    yield put(NotificationActions.createSuccess('Successfully removed all Shopping Items.'))
    yield getShoppingItems({params: {expand: 'ingredient,category', done: false}})
  } catch (error) {
    yield put(Actions.deleteAllShoppingItemsFailure(error))
  }
}

function* updateShoppingItemByTitle(action) {
  const {id, payload, params, callback} = action
  try {
    const response = yield call(ShoppingItemApi.updateShoppingItemByTitle, id, payload, params)
    yield put(Actions.updateShoppingItemByTitleSuccess(response))
    yield getShoppingItems({params: {expand: 'ingredient,category', done: false}})
    yield put(NotificationActions.createSuccess('Successfully updated shopping list item.'))
    if (typeof callback === 'function') {
      callback(true)
    }
  } catch (error) {
    yield put(Actions.updateShoppingItemByTitleFailure(error))
    if (typeof callback === 'function') {
      callback(false)
    }
  }
}

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'

import {Grid, MenuItem, Select} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import connect from 'react-redux/es/connect/connect'
import autobind from 'autobind-decorator'
import ButtonWithProgress from '../../common/ButtonWithProgress'
import {
  updateLocal,
  deleteLocalAtIndex,
  autoSaveRecipe,
  addTag,
  getTagSuggestions,
} from '../redux/actions'
import styles, {DescriptionTextField, DetailTextField, Label} from './CreateRecipe.styles.js'
import DeleteButton from './DeleteButton'
import {difficultyLevelTypes, costTypes, viewableByTypes} from '../../utils/RecipeTypes.js'
import {isTagValid} from '../utils'
import {createError} from '../../common/redux/actions.notifications.js'
import Categories from './Categories'
import Uploader from './Uploader'

const EN_DASH = '–'

class RecipeDetails extends React.Component {
  @autobind
  handleChangeDocuments(files) {
    const Compress = require('compress.js')

    // Initialization
    const compress = new Compress()
    compress
      .compress(files, {
        size: 2, // the max size in MB, defaults to 2MB
        quality: 0.9, // the quality of the image, max is 1,
        maxWidth: 1500, // the max width of the output image, defaults to 1920px
        maxHeight: 650, // the max height of the output image, defaults to 1920px
        resize: true, // defaults to true, set false if you do not want to resize the image width and height
      })
      .then(results => {
        const img1 = results[0]
        const dataUrl = img1.prefix + img1.data
        this.props.updateLocal({image: dataUrl}, 'recipe')
      })
  }

  onChange(fieldName, e) {
    if (fieldName === 'servings') {
      let val = e.target.value
      val = val.replace(EN_DASH, '-') // replace en-dash with hyphen
      val = val.replace(/[^\d-]/g, '') // remove all non-numeric non-hyphen characters
      const splitByHyphens = val.split('-') // count hyphens
      if (splitByHyphens.length > 2) {
        // remove everything after and including the second hyphen if there is one
        val = val.substring(0, splitByHyphens[0].length + splitByHyphens[1].length + 1)
      }
      val = val.replace('-', EN_DASH) // replace hyphen with en-dash

      const update = {servings: val}
      if (val.startsWith(EN_DASH)) {
        if (val === EN_DASH) {
          val = ''
        } else {
          val = val.substring(1)
        }
        update.servings = val
      }
      const split = val.split(EN_DASH) // split by en-dash
      if (split.length > 0) {
        update.minServings = split[0]
      }
      if (split.length > 1) {
        update.maxServings = split[1]
      }

      this.props.updateLocal(update, 'recipe')
    } else {
      if (fieldName === 'tagEntry') {
        e.target.value = e.target.value.trimStart()
        if (e.target.value.length > 0) {
          e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
        }
        this.props.getTagSuggestions(e.target.value)
      }
      this.props.updateLocal({[fieldName]: e.target.value}, 'recipe')
    }
  }

  onBlurServings(e) {
    let val = e.target.value
    const update = {servings: val}
    if (val.endsWith(EN_DASH)) {
      val = val.substring(0, val.length - 1)
      update.servings = val
    }
    const split = val.split(EN_DASH)

    if (split[1] !== '') {
      const first = parseInt(split[0], 10)
      const second = parseInt(split[1], 10)
      if (first > second) {
        update.minServings = second
        update.maxServings = first
        update.servings = update.minServings.toString() + EN_DASH + update.maxServings.toString()
      } else if (first === second) {
        update.minServings = first
        update.maxServings = first
        update.servings = first.toString()
      }
    }
    this.props.updateLocal(update, 'recipe')
    this.props.onBlur()
  }

  onChangeSelect(fieldName, e) {
    this.props.updateLocal({[fieldName]: e.target.value}, 'recipe')
  }

  @autobind
  addEnteredTag(e) {
    e.preventDefault()
    const tag = this.props.recipe.tagEntry
    if (isTagValid(tag, this.props.recipe.tags)) {
      this.props.addTag(tag)
      setTimeout(this.props.onBlur, 100)
    } else if (tag.trim() === '') {
      this.props.createError('Tag cannot be blank.')
      this.props.updateLocal({tagEntry: ''}, 'recipe')
    } else {
      this.props.createError('Duplicate tag.')
    }
  }

  addSuggestedTag(tag) {
    this.props.addTag(tag)
    setTimeout(this.props.onBlur, 100)
  }

  deleteTag(index) {
    this.props.deleteLocalAtIndex('recipe', 'tags', index)
  }

  @autobind
  onCategoryAdded(categories) {
    const catIds = []
    categories.forEach(item => {
      if (item.name !== 'All') catIds.push(item.id)
    })
    const {recipe, editing} = this.props
    let id
    if (editing === true) {
      id = recipe.id
    } else {
      id = this.props.params.id
    }
    recipe.categories = catIds
    this.props.autoSaveRecipe(recipe, id, editing)
  }

  render() {
    const {classes, recipe, onBlur, editing} = this.props
    const tags = recipe.tags.map(tag => tag.tag.title)
    const tagSuggestions = recipe.tagSuggestions
      .map(tagSuggestion => tagSuggestion.title)
      .filter(tagSuggestion => !tags.includes(tagSuggestion))

    return (
      <Grid item xs={12} className={classes.details}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} className={classes.leftDetails}>
            <Uploader
              image={recipe?.image}
              setImage={image => this.props.updateLocal({image}, 'recipe')}
            />
          </Grid>
          <Grid item xs={12} md={5} className={classes.middleDetails}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Label>Title</Label>
                <DetailTextField
                  className={classes.titleTextField}
                  variant="outlined"
                  name="title"
                  inputProps={{maxLength: 99}}
                  value={recipe.title}
                  label="Title"
                  onChange={this.onChange.bind(this, 'title')}
                  onBlur={onBlur}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Label>Source</Label>
                <DetailTextField
                  className={classes.titleTextField}
                  variant="outlined"
                  name="source"
                  value={recipe.source}
                  label="Source"
                  onChange={this.onChange.bind(this, 'source')}
                  onBlur={onBlur}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Label>Description</Label>
                <DescriptionTextField
                  multiline
                  className={classes.descriptionTextField}
                  variant="outlined"
                  rows="7"
                  name="description"
                  value={recipe.description}
                  onChange={this.onChange.bind(this, 'description')}
                  onBlur={onBlur}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={2} className={classes.rightDetails}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Label>Prep Time</Label>
                <DetailTextField
                  className={classes.halfTextField}
                  variant="outlined"
                  rows="1"
                  name="prepTime"
                  type="number"
                  value={recipe.prepTime}
                  onChange={this.onChange.bind(this, 'prepTime')}
                  onBlur={onBlur}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Label>Cost</Label>
                <Select
                  value={recipe.cost}
                  onChange={this.onChangeSelect.bind(this, 'cost')}
                  className={classes.halfTextField}
                  onBlur={onBlur}
                  disableUnderline
                >
                  {costTypes.map(type => (
                    <MenuItem value={type.value} key={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Label>Difficulty Level</Label>
                <Select
                  value={recipe.difficulty}
                  onChange={this.onChangeSelect.bind(this, 'difficulty')}
                  className={classes.halfTextField}
                  onBlur={onBlur}
                  disableUnderline
                >
                  {difficultyLevelTypes.map(type => (
                    <MenuItem value={type.value} key={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={2} className={classes.rightDetailsTwo}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Label>Cook Time</Label>
                <DetailTextField
                  className={classes.halfTextField}
                  variant="outlined"
                  rows="1"
                  type="number"
                  name="cookTime"
                  value={recipe.cookTime}
                  onChange={this.onChange.bind(this, 'cookTime')}
                  onBlur={onBlur}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Label>Servings</Label>
                <DetailTextField
                  className={classes.halfTextField}
                  variant="outlined"
                  rows="1"
                  name="servings"
                  value={recipe.servings}
                  onChange={this.onChange.bind(this, 'servings')}
                  onBlur={this.onBlurServings.bind(this)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Label>Shared With</Label>
                <Select
                  value={recipe.sharedWith}
                  onChange={this.onChangeSelect.bind(this, 'sharedWith')}
                  className={classes.halfTextField}
                  onBlur={onBlur}
                  disableUnderline
                >
                  {viewableByTypes.map(type => (
                    <MenuItem value={type.value} key={type.value}>
                      {type.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} />
          {editing && (
            <Grid item xs={12} md={5}>
              <Categories categories={recipe.categories} onCategoryAdded={this.onCategoryAdded} />
            </Grid>
          )}
          <Grid item xs={12} md={3} />
          <Grid item xs={12} md={3} />
          <Grid item xs={12} md={5} className={classes.tagEntryContainer}>
            <Label>Tags</Label>
            <DetailTextField
              className={classes.tagEntry}
              variant="outlined"
              rows="1"
              name="tagEntry"
              type="text"
              value={recipe.tagEntry}
              inputProps={{maxLength: 50}}
              onChange={this.onChange.bind(this, 'tagEntry')}
              onKeyDown={e => {
                const ENTER_KEY = 13
                if (e.keyCode === ENTER_KEY) {
                  this.addEnteredTag(e)
                }
              }}
              autoComplete="off"
            />
            &nbsp;&nbsp;
            <ButtonWithProgress
              variant="outlined"
              className={classes.addTagButton}
              onClick={this.addEnteredTag}
              isLoading={false}
            >
              Add
            </ButtonWithProgress>
            <ul className={classes.tagSuggestions}>
              {tagSuggestions.map(tagSuggestion => {
                return (
                  <li
                    onClick={e => {
                      e.preventDefault()
                      this.addSuggestedTag(tagSuggestion)
                    }}
                    key={tagSuggestion}
                  >
                    {tagSuggestion}
                  </li>
                )
              })}
            </ul>
          </Grid>
          <Grid item xs={12} md={5} className={classes.tagList}>
            {tags.map((tag, index) => {
              return (
                <div className={classes.tag}>
                  <div className={classes.tagTitle}>{tag}</div>
                  <DeleteButton
                    className={classes.tagDelete}
                    onClick={() => {
                      this.deleteTag(index)
                      setTimeout(onBlur, 50)
                    }}
                  />
                </div>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

RecipeDetails.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {updateLocal, autoSaveRecipe, deleteLocalAtIndex, addTag, getTagSuggestions, createError},
    dispatch,
  )
}

function mapStateToProps(state) {
  return {...state.recipe}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(RecipeDetails)

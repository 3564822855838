import {Select, withStyles} from '@material-ui/core'

const UnitSelect = withStyles({
  root: {
    marginTop: 0,
    border: '1px solid #D8D8D8',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    color: '#000',
    padding: '9px 11px',
  },
})(Select)

export default UnitSelect

import React from 'react'
import {Grid, Paper, withStyles} from '@material-ui/core'
import {compose} from 'redux'
import {Link} from 'react-router'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import RestaurantMenuOutlinedIcon from '@material-ui/icons/RestaurantMenuOutlined'
import defaultImage from '../../assets/recipe_default_image.png'

function RecipeItem({classes, item, recimeeId}) {
  return (
    <Grid item key={item?.id}>
      <Paper className={classes.paper}>
        <div className="recipeImageContainer">
          <Link
            data-test-name="recipe-image"
            to={`/recipe/${item?.id}/${item?.slug}`}
            className={classes.recipeImage}
          >
            {item?.image && <img className={classes.recipeImage} src={item?.image} />}
            {!item?.image && (
              <div className={classes.recipeDefaultImageContainer}>
                <img className={classes.defaultImage} src={defaultImage} />{' '}
              </div>
            )}
            <RestaurantMenuOutlinedIcon className={classes.contentTypeIcon} />
          </Link>
        </div>
        <div className={classes.recipeTitleContainer}>
          <Link
            data-test-name="recipe-title"
            to={`/recipe/${item?.id}/${item?.slug}`}
            className={classes.recipeInfoTitle}
          >
            {item?.title}
          </Link>
        </div>
        <div className={classes.recipeInfoBy}>
          Saved by:{' '}
          {item?.user?.isRecimee ? (
            <Link to={`/users/${recimeeId?.id}`} style={{textDecoration: 'none', color: 'inherit'}}>
              <span className={classes.recipeUserName}>Recimee</span>
            </Link>
          ) : item?.sourceLink ? (
            <Link
              to={`/users/${item?.user?.id}`}
              style={{textDecoration: 'none', color: 'inherit'}}
            >
              <span className={classes.recipeUserName}>
                {item?.user?.firstName + ' ' + item?.user?.lastName}
              </span>
            </Link>
          ) : (
            <Link
              to={`/users/${item?.user?.id}`}
              style={{textDecoration: 'none', color: 'inherit'}}
            >
              <span className={classes.recipeUserName}>
                {item?.user.firstName + ' ' + item?.user.lastName}
              </span>
            </Link>
          )}
        </div>
      </Paper>
    </Grid>
  )
}

export default RecipeItem

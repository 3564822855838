import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  FormControl,
  withStyles,
  Input,
  Button,
} from '@material-ui/core'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import autobind from 'autobind-decorator'
import InputValidator from './InputValidator.jsx'
import ValidatedComponent from './ValidatedComponent.jsx'
import RecipeApi from '../recipe/api'
import ButtonWithProgress from './ButtonWithProgress'
import {createNotificationFromError, createSuccess} from './redux/actions.notifications'

const styles = {
  dialogTitle: {
    padding: '22px',
  },
  title: {
    color: '#000000',
    fontFamily: 'Lato',
    fontSize: '24px',
    fontWeight: 300,
    lineHeight: '29px',
  },
  dialogContent: {
    padding: '0 22px',
  },
  dialogContentContainer: {
    padding: '41px 12px',
    borderTop: '1.5px solid #589A43',
    borderBottom: '1.5px solid #589A43',
  },
  mailInput: {
    width: '100%',
    height: '51px',
    border: '1.5px solid #D8D8D8',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    padding: '16px',
  },

  mailLabel: {
    color: '#000000',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: 300,
    lineHeight: '22px',
    marginBottom: '10px',
  },
  mailHelperText: {
    color: '#589A43',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '17px',
    textAlign: 'right',
    marginTop: '8px',
  },
  btnContainer: {
    marginTop: '30px',
    textAlign: 'center',
  },
  formControl: {
    width: '100%',
  },
  cancelContainer: {
    marginTop: '18px',
  },
  bodyContainer: {
    margin: '30px 0 0 30px',
  },
  actions: {
    height: '66px',
  },
}

const MailLabel = withStyles(styles)(props => {
  const {classes, ...others} = props

  return <Typography variant="body2" className={classes.mailLabel} {...others} />
})

const MailInput = withStyles(styles)(props => {
  const {classes, ...others} = props

  return <Input disableUnderline className={classes.mailInput} {...others} />
})

const InviteBtn = withStyles({
  root: {
    width: '233px',
    height: '30px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    padding: 0,
  },
  label: {
    color: '#589A43',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: 300,
    lineHeight: '22px',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
})(ButtonWithProgress)

const CancelBtn = withStyles({
  root: {
    height: '23px',
    width: '147px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    padding: 0,
  },
  label: {
    height: '17px',
    width: '130.3px',
    color: '#000000',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '17px',
    textAlign: 'center',
    textTransform: 'capitalize',
  },
})(Button)

class InviteRecimeeFriendsDialog extends Component {
  constructor(props) {
    super(props)

    const {subject, to} = this.getDefaultMailValues()

    this.state = {
      subject,
      to,
      sending: false,
    }
  }

  @autobind
  resetFields() {
    this.setState(this.getDefaultMailValues())
  }

  getDefaultMailValues() {
    const subject = "You'll love this new site I'm using, Recimee.com"
    const to = ''

    return {subject, to}
  }

  handleChange(fieldName, e) {
    this.setState({[fieldName]: e.target.value})
  }

  componentWillUnmount() {
    this.resetFields()
  }

  @autobind
  handleSubmit() {
    const {isComponentValid} = this.props

    if (isComponentValid()) {
      this.inviteFriends()
    }
  }

  @autobind
  async inviteFriends() {
    const {subject, to} = this.state
    const {createNotificationFromError, createSuccess, onClose} = this.props

    try {
      this.setState({sending: true})
      await RecipeApi.inviteFriends({subject, to})
      this.setState({to: ''})
      createSuccess('Invitation sent successfully.')
      onClose()
    } catch (e) {
      createNotificationFromError(e)
    } finally {
      this.setState({sending: false})
    }
  }

  @autobind
  handleExited() {
    this.resetFields()
  }

  render() {
    const {open, classes, onClose} = this.props

    return (
      <Dialog open={open} fullWidth maxWidth="md" onClose={this.handleExited}>
        <DialogTitle className={classes.dialogTitle}>
          <Typography className={classes.title}>Invite Friends to Recimee</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent} id="invite-dialog">
          <div className={classes.dialogContentContainer}>
            <Grid container>
              <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <MailLabel>Mail to:</MailLabel>
                  <InputValidator shouldValidateOnBlur={true}>
                    <MailInput
                      id="invite-email"
                      value={this.state.to}
                      required
                      onChange={this.handleChange.bind(this, 'to')}
                    />
                  </InputValidator>
                  <Typography variant="body2" className={classes.mailHelperText}>
                    Separate multiple emails with a semicolon
                  </Typography>
                </FormControl>
                <FormControl style={{marginTop: '10px'}} className={classes.formControl}>
                  <MailLabel>Subject:</MailLabel>
                  <InputValidator shouldValidateOnBlur={true}>
                    <MailInput
                      id="invite-subject"
                      value={this.state.subject}
                      required
                      onChange={this.handleChange.bind(this, 'subject')}
                    />
                  </InputValidator>
                </FormControl>
                <div className={classes.btnContainer}>
                  <InviteBtn
                    disabled={this.state.sending}
                    onClick={this.handleSubmit}
                    isLoading={this.state.sending}
                    id="invite-dialog-invite-btn"
                  >
                    Invite
                  </InviteBtn>
                  <div className={classes.cancelContainer}>
                    <CancelBtn
                      disabled={this.state.sending}
                      onClick={onClose}
                      id="invite-dialog-cancel-btn"
                    >
                      Cancel
                    </CancelBtn>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions className={classes.actions} />
      </Dialog>
    )
  }
}

InviteRecimeeFriendsDialog.displayName = 'InviteRecimeeUserDialog'

InviteRecimeeFriendsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({createSuccess, createNotificationFromError}, dispatch)
}

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
  ValidatedComponent,
)(InviteRecimeeFriendsDialog)

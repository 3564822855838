import React from 'react'
import {Grid, withStyles, Select, MenuItem} from '@material-ui/core'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import InputValidator from '../common/InputValidator.jsx'
import ValidatedComponent from '../common/ValidatedComponent.jsx'
import ChangeEmail from './ChangeEmail.jsx'
import Loading from '../common/Loading.jsx'
import * as ChangeEmailActions from './redux/actions.changeEmail'
import * as ProfileActions from './redux/actions'

import styles, {
  ProfilePaper,
  SmallButton,
  UpdateButton,
  AccountTextField,
} from './ProfileInfo.styles'

const ageTypes = [
  {value: '<18', label: '<18 years'},
  {value: '18-24', label: '18-24 years'},
  {value: '25-34', label: '25-34 years'},
  {value: '35-44', label: '35-44 years'},
  {value: '45-54', label: '45-54 years'},
  {value: '55-64', label: '55-64 years'},
  {value: '65+', label: '65+ years'},
]

const genderTypes = [
  {value: 'male', label: 'Male'},
  {value: 'female', label: 'Female'},
  {value: 'non-binary', label: 'Non-binary'},
  {value: 'not-answered', label: 'Prefer not to answer'},
]

class ProfileSecurity extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      editButton: true,
      address: '1225 SW 3rd Ave',
      addressTwo: '33444',
      city: 'Delray Beach',
      state: 'FL',
      zipCode: '',
      country: 'USA',
      cardNumber: '**** **** **** 1234',
      expirationDate: '12',
      expirationDateTwo: '21',
      cvvCode: '887',
      age: props?.profile?.age || '',
      gender: props?.profile?.gender || '',
    }
    this.handleButtonsClick = this.handleButtonsClick.bind(this)
  }

  handleButtonsClick() {
    if (this.state.show) {
      const {saveProfile, profile} = this.props
      const {age, gender} = this.state
      saveProfile(profile.id, {age, gender})
    }
    this.setState(prevState => ({editButton: !prevState.editButton, show: !prevState.show}))
  }

  onChange(e, fieldName) {
    e.persist()
    this.setState({[fieldName]: e.target.value})
  }

  render() {
    const {currentPassword, newPassword, confirmNewPassword} = this.props.passwordEdit
    const {
      profile,
      isSaving,
      passwordEditErrors,
      errors,
      updateChangePassword,
      changeEmail,
      cancelChangeEmail,
      resendConfirmEmail,
      resendVerifyEmail,
      classes,
    } = this.props

    return (
      <React.Fragment>
        <div className={classes.pageTitle}>Account Settings</div>
        {this.state.editButton ? (
          <div className={classes.editButtonContainer}>
            <SmallButton onClick={this.handleButtonsClick} className={classes.buttonEdit}>
              Edit
            </SmallButton>
          </div>
        ) : null}
        <div className={classes.page}>
          <ProfilePaper elevation={1}>
            <div className={classes.accountContainer}>
              <Grid container spacing={4}>
                {isSaving && (
                  <Grid item xs={12}>
                    <Loading />
                  </Grid>
                )}

                <Grid item xs={12} sm={12} md={4} className={classes.accountInnerContainer}>
                  <div>
                    <ChangeEmail
                      email={profile.email}
                      savedNewEmail={profile.newEmail}
                      isNewEmailConfirmed={profile.isNewEmailConfirmed}
                      errors={errors}
                      onChangeEmail={changeEmail.bind(this)}
                      onResendConfirmationEmail={resendConfirmEmail.bind(this)}
                      onResendVerificationEmail={resendVerifyEmail.bind(this)}
                      onCancelChangeEmail={cancelChangeEmail.bind(this)}
                    />
                  </div>
                  <div style={{marginTop: 12}}>
                    <div className={classes.label}>Current Password</div>
                    <InputValidator
                      errors={passwordEditErrors.currentPassword}
                      className={classes.inputValidator}
                    >
                      <AccountTextField
                        variant="outlined"
                        fullWidth
                        type="password"
                        value={currentPassword}
                        required
                        onChange={e => updateChangePassword({currentPassword: e.target.value})}
                      />
                    </InputValidator>
                    <div className={classes.label}>New Password</div>
                    <InputValidator
                      errors={passwordEditErrors.newPassword}
                      customValidation={() => {
                        return this.validatePasswords()
                      }}
                      className={classes.inputValidator}
                    >
                      <AccountTextField
                        variant="outlined"
                        fullWidth
                        type="password"
                        value={newPassword}
                        required
                        onChange={e => updateChangePassword({newPassword: e.target.value})}
                      />
                    </InputValidator>
                    <div className={classes.label}>Confirm Password</div>
                    <InputValidator
                      customValidation={() => {
                        return this.validatePasswords()
                      }}
                      className={classes.inputValidator}
                    >
                      <AccountTextField
                        variant="outlined"
                        fullWidth
                        type="password"
                        value={confirmNewPassword}
                        required
                        onChange={e => updateChangePassword({confirmNewPassword: e.target.value})}
                      />
                    </InputValidator>
                    <div className={classes.smallButtonContainer}>
                      <SmallButton onClick={this.onChangePasswordClick.bind(this)}>
                        Update password
                      </SmallButton>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5} className={classes.accountInnerContainer}>
                  <div>
                    <div className={classes.label}>Address</div>
                    <InputValidator className={classes.inputValidator}>
                      <AccountTextField
                        variant="outlined"
                        id="address"
                        fullWidth
                        disabled={this.state.editButton}
                        onChange={e => {
                          this.onChange(e, 'address')
                        }}
                        defaultValue={this.state.address}
                      />
                    </InputValidator>
                    <InputValidator className={classes.inputValidator}>
                      <AccountTextField
                        variant="outlined"
                        id="addressTwo"
                        fullWidth
                        disabled={this.state.editButton}
                        onChange={e => {
                          this.onChange(e, 'addressTwo')
                        }}
                        defaultValue={this.state.addressTwo}
                      />
                    </InputValidator>
                  </div>
                  <div>
                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} md={9}>
                        <div className={classes.label}>City</div>
                        <InputValidator className={classes.inputValidator}>
                          <AccountTextField
                            variant="outlined"
                            id="city"
                            fullWidth
                            disabled={this.state.editButton}
                            onChange={e => {
                              this.onChange(e, 'city')
                            }}
                            defaultValue={this.state.city}
                          />
                        </InputValidator>
                      </Grid>
                      <Grid item xs={6} sm={6} md={3}>
                        <div className={classes.label}>State</div>
                        <InputValidator className={classes.inputValidator}>
                          <AccountTextField
                            variant="outlined"
                            fullWidth
                            id="state"
                            disabled={this.state.editButton}
                            onChange={e => {
                              this.onChange(e, 'state')
                            }}
                            defaultValue={this.state.state}
                          />
                        </InputValidator>
                      </Grid>
                    </Grid>
                    <InputValidator className={classes.inputValidator}>
                      <div className={classes.label}>Zip Code</div>
                      <AccountTextField
                        variant="outlined"
                        fullWidth
                        id="zipCode"
                        disabled={this.state.editButton}
                        onChange={e => {
                          this.onChange(e, 'zipCode')
                        }}
                        defaultValue={this.state.zipCode}
                      />
                    </InputValidator>
                  </div>
                  <div>
                    <div className={classes.label}>Country</div>
                    <InputValidator className={classes.inputValidator}>
                      <AccountTextField
                        variant="outlined"
                        id="country"
                        fullWidth
                        disabled={this.state.editButton}
                        onChange={e => {
                          this.onChange(e, 'country')
                        }}
                        defaultValue={this.state.country}
                      />
                    </InputValidator>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={3} className={classes.accountInnerContainer}>
                  <div>
                    <div className={classes.labelSub}>Subscription</div>
                    <div className={classes.subscription}>Premium</div>
                  </div>

                  <div style={{marginTop: 76}}>
                    <div className={classes.label}>Age</div>

                    <Select
                      variant="outlined"
                      fullWidth
                      defaultValue={this.state.age}
                      value={this.state.age}
                      disabled={this.state.editButton}
                      onChange={e => this.onChange(e, 'age')}
                      displayEmpty
                    >
                      {ageTypes.map(type => (
                        <MenuItem value={type.value} key={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <div className={classes.label} style={{marginTop: 6}}>
                      Gender
                    </div>

                    <Select
                      variant="outlined"
                      fullWidth
                      defaultValue={this.state.gender}
                      value={this.state.gender}
                      disabled={this.state.editButton}
                      onChange={e => this.onChange(e, 'gender')}
                      displayEmpty
                    >
                      {genderTypes.map(type => (
                        <MenuItem value={type.value} key={type.value}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  {/* <div>
                    <div className={classes.label}>Card Number</div>
                    <InputValidator className={classes.inputValidator}>
                      <AccountTextField
                        variant="outlined"
                        id="cardNumber"
                        fullWidth
                        disabled={this.state.editButton}
                        onChange={e => {
                          this.onChange(e, 'cardNumber')
                        }}
                        defaultValue={this.state.cardNumber}
                      />
                    </InputValidator>
                    <div className={classes.label}>Expiration Date</div>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <InputValidator className={classes.inputValidator}>
                          <AccountTextField
                            variant="outlined"
                            id="expirationDate"
                            fullWidth
                            disabled={this.state.editButton}
                            onChange={e => {
                              this.onChange(e, 'expirationDate')
                            }}
                            defaultValue={this.state.expirationDate}
                          />
                        </InputValidator>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <InputValidator className={classes.inputValidator}>
                          <AccountTextField
                            variant="outlined"
                            id="expirationDateTwo"
                            fullWidth
                            disabled={this.state.editButton}
                            onChange={e => {
                              this.onChange(e, 'expirationDateTwo')
                            }}
                            defaultValue={this.state.expirationDateTwo}
                          />
                        </InputValidator>
                      </Grid>
                    </Grid>
                    <div className={classes.label}>CVV Code</div>
                    <InputValidator className={classes.inputValidator}>
                      <AccountTextField
                        variant="outlined"
                        id="cvvCode"
                        fullWidth
                        disabled={this.state.editButton}
                        onChange={e => {
                          this.onChange(e, 'cvvCode')
                        }}
                        defaultValue={this.state.cvvCode}
                      />
                    </InputValidator>
                  </div> */}
                </Grid>
              </Grid>
            </div>
          </ProfilePaper>
        </div>
        <div className={classes.updateButtonContainer}>
          {this.state.show ? (
            <UpdateButton className={classes.updateButton} onClick={this.handleButtonsClick}>
              Update
            </UpdateButton>
          ) : null}
        </div>
      </React.Fragment>
    )
  }

  onChangePasswordClick() {
    const {
      passwordEdit: {currentPassword, newPassword},
      isComponentValid,
      changePassword,
    } = this.props

    if (isComponentValid()) {
      changePassword(currentPassword, newPassword)
    }
  }

  validatePasswords() {
    const {newPassword, confirmNewPassword} = this.props.passwordEdit
    if (newPassword !== confirmNewPassword) {
      return 'Both passwords must be equal'
    }
  }
}

function mapStateToProps(state) {
  return {...state.profile}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...ChangeEmailActions,
      ...ProfileActions,
    },
    dispatch,
  )
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  ValidatedComponent,
)(ProfileSecurity)

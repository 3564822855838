import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = theme => ({
  dot: {},
  dots: {
    position: 'relative',
    padding: '20px 0 28px',
  },
  dotOuter: {
    width: 19,
    height: 19,
    padding: 8.5,
    float: 'right',
    position: 'relative',
  },
  dotDesktop: {
    width: 19,
    height: 19,
    background: '#589A43',
    transition: 'all 400ms cubic-bezier(0.4, 0.0, 0.2, 1)',
    borderRadius: 9.5,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      display: 'none',
    },
  },
  dotMobile: {
    width: 14,
    height: 14,
    background: '#589A43',
    transition: 'all 400ms cubic-bezier(0.4, 0.0, 0.2, 1)',
    borderRadius: 9.5,
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      display: 'block',
    },
  },
})

class Dots extends Component {
  constructor(props) {
    super(props)
    this.state = {
      previousIndex: props.index || 0,
    }
  }

  componentWillReceiveProps({index}) {
    if (index !== this.props.index) {
      this.setState({previousIndex: this.props.index})
      this.timeout = setTimeout(() => {
        this.timeout = null
        this.setState({previousIndex: index})
      }, 450)
    }
  }

  componentWillUnmount() {
    if (this.timeout != null) {
      clearTimeout(this.timeout)
    }
  }

  handleDotClick(index, event) {
    if (this.props.onDotClick != null) {
      this.props.onDotClick(index, event)
    }
  }

  render() {
    const {classes, count, index, style = {}, onDotClick, ...other} = this.props
    const {previousIndex} = this.state

    return (
      <div
        style={{
          ...style,
          width: count * 38,
          position: `relative`,
          float: `right`,
          bottom: `75px`,
        }}
        {...other}
      >
        <div className={classes.dots}>
          {[...Array(count).keys()].map(i => (
            <div
              key={i}
              className={classes.dotOuter}
              style={{
                left: i * 38,
                cursor: onDotClick != null ? 'pointer' : 'inherit',
              }}
              onClick={event => this.handleDotClick(i, event)}
            >
              <Paper
                elevation={0}
                className={classes.dot}
                style={{
                  opacity:
                    i >= Math.min(previousIndex, index) && i <= Math.max(previousIndex, index)
                      ? 0
                      : 0.5,
                }}
              />
            </div>
          ))}
          <Paper
            elevation={0}
            className={classes.dotDesktop}
            style={{
              position: 'relative',
              marginTop: 8.5,
              left: Math.min(previousIndex, index) * 38 + 8.5,
              width: Math.abs(previousIndex - index) * 38 + 19,
            }}
          />
          <Paper
            elevation={0}
            className={classes.dotMobile}
            style={{
              position: 'relative',
              marginTop: 8.5,
              left: Math.min(previousIndex, index) * 38 + 8.5,
              width: Math.abs(previousIndex - index) * 38 + 14,
            }}
          />
        </div>
      </div>
    )
  }
}

Dots.propTypes = {
  count: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.object,
  onDotClick: PropTypes.func,
}

export default withStyles(styles)(Dots)

import React from 'react'
import PropTypes from 'prop-types'
import Input from '@material-ui/core/Input'
import NumberFormat from './NumberFormat'

function NumberFormatCustom(props) {
  const {inputRef, onChange, ...other} = props

  return (
    <NumberFormat
      decimalScale={0}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      allowNegative={false}
      {...other}
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

class InputInteger extends React.Component {
  render() {
    const {...otherProps} = this.props
    return <Input inputComponent={NumberFormatCustom} {...otherProps} />
  }
}

export default InputInteger

import React from 'react'
import {Grid} from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'
import autobind from 'autobind-decorator'
import {OverviewDirectionsNotesCard, DescNotePaper, NotesPaper} from './RecipeView.styles'
import {Tabs, Tab} from '../../common/styles/Tabs'

function DirectionIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M21.71 11.29l-9-9c-.39-.39-1.02-.39-1.41 0l-9 9c-.39.39-.39 1.02 0 1.41l9 9c.39.39 1.02.39 1.41 0l9-9c.39-.38.39-1.01 0-1.41zM14 14.5V12h-4v3H8v-4c0-.55.45-1 1-1h5V7.5l3.5 3.5-3.5 3.5z" />
    </SvgIcon>
  )
}
function NotesIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="M16.7,15.4H24V1.3C24,0.6,23.4,0,22.7,0H1.3C0.6,0,0,0.6,0,1.3v21.4C0,23.4,0.6,24,1.3,24h14.1v-7.3 C15.4,16,16,15.4,16.7,15.4z M23.6,18.4l-5.2,5.2c-0.2,0.2-0.6,0.4-0.9,0.4h-0.3v-6.9H24v0.3C24,17.8,23.9,18.1,23.6,18.4z" />
    </SvgIcon>
  )
}

export default class DirectionsNotes extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      value: 0,
    }
  }

  @autobind
  handleChange(event, value) {
    this.setState({value})
  }

  render() {
    const {classes, recipe} = this.props

    return (
      <Grid xs={12} md={6} item>
        <div className={classes.overviewDirectionsNotesCard}>
          <OverviewDirectionsNotesCard>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              variant="fullWidth"
              textColor={this.state.value === 0 ? 'inherit' : '#000000'}
            >
              <Tab
                fullWidth={false}
                icon={
                  <DirectionIcon
                    className={
                      this.state.value === 0
                        ? classes.tabIconDirectionSelected
                        : classes.tabIconDirection
                    }
                  />
                }
                label="Directions"
              />
              <Tab
                fullWidth={false}
                icon={
                  <NotesIcon
                    className={
                      this.state.value === 1 ? classes.tabIconNoteSelected : classes.tabIconNote
                    }
                  />
                }
                label="Notes"
              />
            </Tabs>
          </OverviewDirectionsNotesCard>
          {this.state.value === 0 ? (
            <DescNotePaper>
              {recipe.directions.map((data, key) => {
                return (
                  <div className={classes.directionNumber}>
                    <div className={classes.directionInner}>
                      <svg width="25" height="25" className={classes.directionNumberIcon}>
                        <circle cx="12.5" cy="12.5" r="12.5" fill="#D8D8D8" />
                        <text
                          x="50%"
                          y="50%"
                          textAnchor="middle"
                          fill="white"
                          fontWeight="400"
                          fontSize="18px"
                          fontFamily="Literata"
                          dy=".3em"
                        >
                          {key + 1}
                        </text>
                        {key + 1}. &nbsp;
                      </svg>
                    </div>
                    <span
                      className={classes.directionNumberData}
                      key={key}
                      dangerouslySetInnerHTML={{__html: data.direction}}
                    />
                    {data.image && (
                      <div
                        className={classes.directionImageContainer}
                        style={{
                          backgroundImage: `url(${data.image})`,
                          width: '100px',
                          height: '100px',
                          backgroundSize: 'cover',
                          right: 0,
                          top: 0,
                        }}
                      />
                    )}
                  </div>
                )
              })}
            </DescNotePaper>
          ) : (
            <NotesPaper dangerouslySetInnerHTML={{__html: recipe.notes}} />
          )}
        </div>
      </Grid>
    )
  }
}

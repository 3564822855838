export const ADD_RECIPE_TO_CALENDAR = 'ADD_RECIPE_TO_CALENDAR'
export const ADD_RECIPE_TO_CALENDAR_SUCCESS = 'ADD_RECIPE_TO_CALENDAR_SUCCESS'
export const ADD_RECIPE_TO_CALENDAR_FAILURE = 'ADD_RECIPE_TO_CALENDAR_FAILURE'

export const GET_CALENDAR_ITEMS = 'GET_CALENDAR_ITEMS'
export const GET_CALENDAR_ITEMS_SUCCESS = 'GET_CALENDAR_ITEMS_SUCCESS'
export const GET_CALENDAR_ITEMS_FAILURE = 'GET_CALENDAR_ITEMS_FAILURE'
export const ADD_CALENDAR_ITEM_SUCCESS = 'ADD_CALENDAR_ITEM_SUCCESS'

export const DELETE_CALENDAR_ITEM = 'DELETE_CALENDAR_ITEM'
export const DELETE_CALENDAR_ITEM_SUCCESS = 'DELETE_CALENDAR_ITEM_SUCCESS'
export const DELETE_CALENDAR_ITEM_FAILURE = 'DELETE_CALENDAR_ITEM_FAILURE'

export const CALENDAR_ITEM_UPDATE_ORDER_LOCAL = 'CALENDAR_ITEM_UPDATE_ORDER_LOCAL'
export const CALENDAR_ITEM_MOVE_LOCAL = 'CALENDAR_ITEM_MOVE_LOCAL'

import React, {useRef, useState} from 'react'
import Compress from 'compress.js'
import {IconButton, Tooltip, Icon} from '@material-ui/core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {withStyles} from '@material-ui/core/styles'
import {faCheck, faUpload, faCrop} from '@fortawesome/free-solid-svg-icons'
import ImageCropper from './ImageCropper'
import styles, {DropzoneAreaDetails} from './CreateRecipe.styles.js'

function Uploader({image, setImage, classes}) {
  const [currentStep, setCurrentStep] = useState(image ? 'img-cropped' : 'choose-img')
  const [imageBeforeCrop, setImageBeforeCrop] = useState(image)
  const imageBeforeCropRef = useRef(null)

  const onImageSelected = async files => {
    const compressor = new Compress()
    const compressedFiles = await compressor.compress(files, {
      size: 2,
      quality: 0.9,
      maxWidth: 1500,
      maxHeight: 650,
      resize: true,
    })
    const img = compressedFiles[0]
    const dataUrl = img.prefix + img.data

    setImageBeforeCrop(dataUrl)
    setCurrentStep('crop-img')
  }

  const onCropDone = imgCroppedArea => {
    const canvasEle = document.createElement('canvas')
    canvasEle.width = imgCroppedArea.width
    canvasEle.height = imgCroppedArea.height
    const context = canvasEle.getContext('2d')
    const imageObj1 = imageBeforeCropRef.current
    imageObj1.src = imageBeforeCrop
    imageObj1.onload = function() {
      context.drawImage(
        imageObj1,
        imgCroppedArea.x,
        imgCroppedArea.y,
        imgCroppedArea.width,
        imgCroppedArea.height,
        0,
        0,
        imgCroppedArea.width,
        imgCroppedArea.height,
      )

      const dataURL = canvasEle.toDataURL('image/jpeg')

      setImage(dataURL)
      setCurrentStep('img-cropped')
    }
  }

  const onCropCancel = () => {
    setCurrentStep(image ? 'img-cropped' : 'choose-img')
  }

  return (
    <div>
      {currentStep === 'choose-img' ? (
        <DropzoneAreaDetails
          dropZoneClass={classes.dropZone}
          filesLimit={1}
          acceptedFiles={['image/jpg', 'image/jpeg', 'image/png']}
          dropzoneText={
            'Click or drag and drop the main photo of the dish' +
            '\n(Max Size: 10MB, Formats: jpg, png)'
          }
          onChange={onImageSelected}
          maxFileSize={10000000}
          showPreviewsInDropzone={false}
          showAlerts={true}
          dropzoneParagraphClass={classes.dropZoneText}
        />
      ) : currentStep === 'crop-img' ? (
        <>
          <ImageCropper
            image={imageBeforeCrop}
            onCropDone={onCropDone}
            onCropCancel={onCropCancel}
          />
          <img
            crossOrigin="anonymous"
            ref={imageBeforeCropRef}
            style={{display: 'none'}}
            src={imageBeforeCrop}
          />
        </>
      ) : (
        <div>
          <div
            style={{
              height: 300,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(0,0,0,0.5)',
              borderRadius: 4,
            }}
          >
            <img style={{maxWidth: 300}} src={image} className="cropped-img" />
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-end', gap: 8, marginTop: 4}}>
            <Tooltip title="Crop image">
              <IconButton
                disableRipple
                size="small"
                onClick={() => {
                  setCurrentStep('crop-img')
                }}
              >
                <FontAwesomeIcon icon={faCrop} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Upload new image">
              <IconButton
                disableRipple
                size="small"
                onClick={() => {
                  setCurrentStep('choose-img')
                  setImage('')
                }}
              >
                <Icon>add_a_photo</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(Uploader)

import settings from '../../../config/settings'
import axios from '../api/axios'

export default class CalendarItemApi {
  static addCalendarItem(payload, params) {
    return axios.post(`${settings.apiBaseURL}/calendar`, payload, {
      params,
    })
  }

  static getCalendarItems(params) {
    return axios.get(`${settings.apiBaseURL}/calendar`, {params})
  }

  static deleteCalendarItem(id) {
    return axios.delete(`${settings.apiBaseURL}/calendar/${id}`)
  }

  static updateOrder(payload) {
    return axios.post(`${settings.apiBaseURL}/calendar/update-order`, payload)
  }

  static move(payload) {
    return axios.post(`${settings.apiBaseURL}/calendar/move-cal-item`, payload)
  }
}

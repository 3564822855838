import settings from '../../../config/settings'
import axios from '../api/axios'

const {apiBaseURL} = settings

export default class HomeApi {
  static getNewsFeed(page) {
    return axios.get(`${apiBaseURL}/news-feed?page=` + page)
  }
}

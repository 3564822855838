export function arrayRemoveAt(array, index) {
  return [...array.slice(0, index), ...array.slice(index + 1)]
}

export function arrayAdd(array, item) {
  return [...array, item]
}

export function arrayUpdateAt(array, index, item) {
  return [...array.slice(0, index), item, ...array.slice(index + 1)]
}

import React from 'react'
import PropTypes from 'prop-types'
import {Grid, withStyles} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import connect from 'react-redux/es/connect/connect'
import {updateLocal} from '../redux/actions'
import {DescriptionTextField} from './CreateRecipe.styles.js'

const styles = theme => ({
  notes: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
      marginLeft: 20,
    },
  },
})

class RecipeNotes extends React.Component {
  onChange(fieldName, e) {
    this.props.updateLocal({[fieldName]: e.target.value}, 'recipe')
  }

  render() {
    const {classes, recipe, onBlur} = this.props
    return (
      <Grid item xs={12} className={classes.notes}>
        <DescriptionTextField
          multiline
          variant="outlined"
          rows="5"
          name="notes"
          placeholder="Notes here"
          value={recipe.notes}
          onChange={this.onChange.bind(this, 'notes')}
          onBlur={onBlur}
          fullWidth
        />
      </Grid>
    )
  }
}

RecipeNotes.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({updateLocal}, dispatch)
}

function mapStateToProps(state) {
  return {...state.recipe}
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(RecipeNotes)

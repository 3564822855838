import React from 'react'
import {Grid, Typography, Card, CardContent, CardActionArea, CardMedia} from '@material-ui/core'
import {Link} from 'react-router'
import categoryDefault from '../../assets/categoryDefault.png'
import {CreateCategoryActionArea} from './MyKitchen.styles'
import PlusCircle from '../../assets/icons/plusCircle.svg'
import OptionsButton from './OptionsButton'

function CreateCategoryCard(props) {
  const {classes, onCreateCategory} = props

  return (
    <Grid xs={4} sm={4} md={6} className={classes.paddingGridSubPaper}>
      <Card className={classes.createCategoryCard}>
        <CreateCategoryActionArea
          onClick={onCreateCategory}
          data-test-name="create-category-action-area"
        >
          <CardContent>
            <span className={classes.addIcon}>
              <img src={PlusCircle} alt="Add Category" />
            </span>
          </CardContent>
        </CreateCategoryActionArea>
      </Card>
    </Grid>
  )
}

function AllCategory(props) {
  const {classes} = props

  return (
    <Grid xs={4} sm={4} md={6} className={classes.paddingGridSubPaper}>
      <Card>
        <CardActionArea className={classes.card} component={Link} to="/my-kitchen/category/all">
          <CardMedia component="img" className={classes.media} height="140" src={categoryDefault} />
          <CardContent className={classes.cardContent}>
            <Typography gutterBottom className={classes.cardTitle}>
              All Recipes
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  )
}

export default function Categories(props) {
  const {categories, classes, profile} = props

  return (
    <Grid container>
      <CreateCategoryCard {...props} />
      <AllCategory {...props} />
      {categories.results.map((category, index) => {
        return (
          <Grid item xs={4} sm={4} md={6} key={index} className={classes.paddingGridSubPaper}>
            <Card>
              <CardActionArea
                className={classes.card}
                component={Link}
                to={`/my-kitchen/category/${category.id}`}
              >
                <OptionsButton
                  classes={classes}
                  index={index}
                  category={category}
                  profile={profile}
                />
                {category.featureImage !== null ? (
                  <CardMedia
                    component="img"
                    className={classes.media}
                    height="140"
                    image={category.featureImage}
                  />
                ) : (
                  <CardMedia
                    component="img"
                    className={classes.media}
                    height="140"
                    src={categoryDefault}
                  />
                )}
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom className={classes.cardTitle}>
                    {category.name}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        )
      })}
    </Grid>
  )
}

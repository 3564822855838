import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

class ButtonWithProgress extends React.Component {
  render() {
    const {isLoading, children, classes, component, ...others} = this.props
    const {buttonProgress, ...otherClasses} = classes
    const ButtonComponent = component || Button
    return (
      <ButtonComponent disabled={isLoading} {...others} classes={otherClasses}>
        {children}
        {isLoading && <CircularProgress size={24} className={buttonProgress} />}
      </ButtonComponent>
    )
  }
}

ButtonWithProgress.propTypes = {
  isLoading: PropTypes.bool.isRequired,
}

const styles = () => ({
  root: {},
  label: {},
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})

export default withStyles(styles)(ButtonWithProgress)

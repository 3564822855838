import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import {
  AppBar,
  Toolbar,
  List,
  MenuList,
  MenuItem,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  IconButton,
  Divider,
  InputAdornment,
  Input,
} from '@material-ui/core'
import autobind from 'autobind-decorator'
import {Link, withRouter} from 'react-router'
import Drawer from '@material-ui/core/Drawer'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItemText from '@material-ui/core/ListItemText'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import HamburgerMenu from '../../assets/icons/menu.svg'
import SearchIcon from '../../assets/icons/search.svg'
import SearchMenuIcon from '../../assets/icons/search-circle.svg'
import CreateRecipeIcon from '../../assets/icons/add-recipe.svg'
import Logo from '../../assets/RCE-Logo.svg'
import {logout as logoutAction} from '../auth/redux/actions'

import DrawerProfileIcon from '../../assets/icons/drawer-profile.svg'
import DrawerKitchenIcon from '../../assets/icons/drawer-kitchen.svg'
import DrawerShoppingListIcon from '../../assets/icons/drawer-shoppinglist.svg'
import DrawerAddRecipeIcon from '../../assets/icons/drawer-addrecipe.svg'
import DrawerCalendarIcon from '../../assets/icons/drawer-calendar.svg'
import DrawerSettingsIcon from '../../assets/icons/drawer-settings.svg'
import DrawerLogoutIcon from '../../assets/icons/drawer-logout.svg'
import NotificationsList from '../notifications/NotificationsList'

import {clearNotifications} from '../profile/redux/actions'

const drawerWidth = 240

const SearchField = withStyles({
  root: {
    marginTop: 0,
    border: '1px solid #dadada',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    color: '#000',
    padding: '9px 13px',
    height: 38,
    width: 250,
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '16px',
    lineHeight: '19px',
  },
})(Input)

const styles = theme => ({
  navbar: {
    backgroundColor: 'white',
    boxShadow: 'none',
  },
  navbarToolbar: {
    paddingLeft: 0,
    paddingRight: 48,
    [theme.breakpoints.down('sm')]: {
      boxShadow: '0 1px 10px 0 rgba(0,0,0,0.19)',
      paddingRight: 0,
    },
  },
  navbarTitle: {
    flex: 1,
  },
  navbarTitleLink: {
    color: 'black',
    fontSize: '32px',
    borderRadius: 0,
    justifyContent: `normal`,
    /*'&:hover': {
      background: 'none',
    },*/
  },
  navbarProfileButtonSize: {
    width: '48px',
    height: '48px',
    padding: '8',
    borderRadius: '50%',
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      width: '48px',
      height: '48px',
      padding: 8,
    },
  },
  navbarAddButtonSize: {
    width: '48px',
    height: '48px',
    padding: '8',
    borderRadius: '50%',
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      width: '48px',
      height: '48px',
      padding: 8,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      display: 'none',
    },
  },
  navbarSearchButtonSize: {
    display: 'none',
    width: '48px',
    height: '48px',
    padding: '8',
    borderRadius: '50%',
    marginTop: 0,
    [theme.breakpoints.down('sm')]: {
      width: '48px',
      height: '48px',
      padding: 8,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'inline',
    },
    [`${theme.breakpoints.down('xs')} and (orientation: landscape)`]: {
      display: 'inline',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      display: 'inline',
    },
  },
  mobileDrawerButtonSize: {
    width: '25px',
    height: '25px',
    padding: '8',
    marginTop: 0,
  },
  navbarProfileButtonSizeDesktop: {
    width: 48,
    height: 48,
    padding: 8,
    borderRadius: '50%',
    marginRight: 30,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      display: 'none',
    },
  },
  navbarProfileButtonSizeDesktopIcon: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  hamburgerMenu: {
    padding: 0,
    paddingRight: 4,
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },

    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      display: 'block',
    },
  },
  hamburgerMenuIcon: {
    width: '48px',
    height: '48px',
    marginTop: 0,
    padding: 10,
  },
  navbarDropdownDesktop: {
    top: 'inherit!important',
    left: 'inherit!important',
    transform: 'translate3d(-72px, 0, 0)!important',
    [theme.breakpoints.down('sm')]: {
      left: 'inherit',
      top: 'inherit',
      transform: 'translate3d(-95px, 0, 0)!important',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      left: 'inherit',
      top: 'inherit',
      transform: 'translate3d(-95px, 0, 0)!important',
    },
  },
  navbarDropdown: {
    zIndex: 1,
    marginTop: 26,
    borderRadius: 0,
    width: 180,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      marginTop: '0px',
      [theme.breakpoints.down('xs')]: {
        marginTop: -26,
        left: 0,
      },
    },
    [theme.breakpoints.down('xs')]: {
      zIndex: 1,
      marginTop: 26,
      borderRadius: 0,
      width: 180,
      float: 'right',
    },
  },
  navbarDropdownPaper: {
    paddingTop: 2,
    paddingRight: 14,
    paddingBottom: 2,
    paddingLeft: 14,
    marginRight: 36,
  },
  brandImageContainer: {
    flex: 1,
    paddingTop: 12,
    paddingRight: 14,
    paddingBottom: 12,
    paddingLeft: 60,
  },
  brandImage: {
    width: `205px`,
    [theme.breakpoints.down('sm')]: {
      width: 147,
      marginLeft: -41,
    },
  },
  loggedOutNav: {
    marginTop: 12,
    marginRight: 0,
    [theme.breakpoints.down('md')]: {
      marginRight: 15,
    },
  },
  loggedOutNavLink: {
    textDecoration: 'none',
    color: 'black',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  createRecipeLink: {
    padding: 0,
  },

  notificationButton: {
    padding: 8,
    '& div': {
      height: 32,
      width: 32,
      borderRadius: '25px',
      background: '#eb993f',
      fontSize: '16px',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },

  nav: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  navItem: {
    marginRight: 5,
    width: 48,
    height: 48,
    [theme.breakpoints.down('sm')]: {
      marginRight: 8,
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      marginRight: 12,
    },
  },
  searchBar: {
    marginRight: 20,
    marginTop: 4,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },

    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      display: 'none',
    },
  },
  searchBarMobileOpen: {
    display: 'none',
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      display: 'block',
      top: 50,
      margin: '15px auto',
      width: '100%',
      paddingRight: 25,
      paddingLeft: 25,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      top: 50,
      margin: '15px auto 0',
      width: '100%',
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  searchBarMobileClose: {
    display: 'none',
  },
  drawerContainer: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'inline',
    },

    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      display: 'inline',
    },
  },
  arrow: {
    position: 'relative',
    fontSize: 7,
    width: '88px',
    height: '35px',
    overflow: 'hidden',
    display: 'block',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 40,
      height: 50,
      position: 'absolute',
      top: 20,
      left: 20,
      backgroundColor: '#fff',
      transform: 'rotate(45deg)',
      boxShadow: '-1px -1px 10px -2px rgba(0, 0, 0, 0.2)',
      zIndex: 2,
    },
  },
  navbarDropdownItem: {
    fontWeight: 'bold',
    fontSize: '15',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 8,
  },
  navbarDropdownItemShoppingList: {
    fontWeight: 'bold',
    fontSize: '15',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 8,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      display: 'block',
    },
  },
  spacer: {
    marginTop: 8,
    marginBottom: 8,
  },
  searchButton: {
    padding: `8px`,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    flexShrink: 0,
    width: 0,
    [theme.breakpoints.down('xs')]: {
      width: drawerWidth,
    },

    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      width: drawerWidth,
    },
  },
  drawerPaper: {
    width: 0,
    [theme.breakpoints.down('xs')]: {
      width: drawerWidth,
    },

    [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
      width: drawerWidth,
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
})

export class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      searchText: '',
      profileMenuOpened: false,
      arrowRef: null,
      searchOpened: false,
      mobileDrawerOpen: false,
      notificationsOpened: false,
      notificationsArrowRef: null,
    }

    this.logout = this.logout.bind(this)
    this.handleProfileMenuOpen = this.handleProfileMenuOpen.bind(this)
    this.handleProfileMenuClose = this.handleProfileMenuClose.bind(this)
    this.handleSearchOpen = this.handleSearchOpen.bind(this)
    this.handleSearchClose = this.handleSearchClose.bind(this)
    this.handleMobileDrawerOpen = this.handleMobileDrawerOpen.bind(this)
    this.handleMobileDrawerClose = this.handleMobileDrawerClose.bind(this)
    this.handleNotificationsOpen = this.handleNotificationsOpen.bind(this)
  }

  handleProfileMenuOpen() {
    this.setState({profileMenuOpened: true})
  }

  handleNotificationsOpen() {
    this.setState(({notificationsOpened}) => ({
      notificationsOpened: !notificationsOpened,
    }))
    this.props.clearNotifications()
  }

  handleProfileMenuClose() {
    this.setState({profileMenuOpened: false})
  }

  handleSearchOpen() {
    this.setState({searchOpened: true})
  }

  handleSearchClose() {
    this.setState({searchOpened: false})
  }

  handleMobileDrawerOpen() {
    this.setState({mobileDrawerOpen: true})
  }

  handleMobileDrawerClose() {
    this.setState({mobileDrawerOpen: false})
  }

  @autobind
  onSearchChange(event) {
    this.setState({searchText: event.target.value})
  }

  @autobind
  onSearchKeyPress(event) {
    if (event.key === 'Enter') {
      const {searchText} = this.state
      if (searchText !== '') {
        const query = {q: searchText}
        this.context.router.push({pathname: '/search', query})
      }
    }
  }

  @autobind
  onSearch() {
    const {searchText} = this.state
    if (searchText !== '') {
      const query = {q: searchText}
      this.context.router.push({pathname: '/search', query})
    }
  }

  componentDidMount() {
    const {location} = this.props
    this.setState({searchText: location.query.q})
  }

  @autobind
  handleArrowRef(node) {
    this.setState({
      arrowRef: node,
    })
  }

  @autobind
  handleNotificationsArrowRef(node) {
    this.setState({
      notificationsArrowRef: node,
    })
  }

  render() {
    const {props} = this
    const {classes} = props
    const profile = _.get(props, 'profile')

    const {arrowRef} = this.state

    let profileText = ''
    if (profile) {
      profileText = profile.name ? profile.name : profile.email
    }

    const isLoggedIn = !!_.get(props, 'profile')

    return (
      <AppBar color="default" className={classes.navbar} position="static">
        <Toolbar className={classes.navbarToolbar}>
          <div className={classes.brandImageContainer}>
            <Link
              to="/"
              onClick={() => this.props.onHomeClick()}
              className={classes.navbarTitleLink}
            >
              <img src={Logo} className={classes.brandImage} />
            </Link>
          </div>
          <div className={classes.nav}>
            <div className={classes.searchBar}>
              <SearchField
                id="recipe-search"
                disableUnderline
                placeholder="Search"
                onKeyPress={this.onSearchKeyPress}
                onChange={this.onSearchChange}
                value={this.state.searchText}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={this.onSearch}
                      className={classes.searchButton}
                    >
                      <img src={SearchIcon} />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>
            {isLoggedIn ? (
              <React.Fragment>
                <IconButton
                  className={classes.notificationButton}
                  onClick={this.handleNotificationsOpen}
                  title="Notifications"
                  ref={n => {
                    this.notificationsButtonRef = n
                  }}
                >
                  {profile?.notificationsCount > 0 ? (
                    <div>{profile?.notificationsCount}</div>
                  ) : (
                    <NotificationsNoneIcon />
                  )}
                </IconButton>
                <div className={classes.navItem}>
                  <Link
                    className={this.state.searchOpened ? classes.searchBarMobileClose : {}}
                    to="/create-recipe"
                  >
                    <IconButton title="Create Recipe" className={classes.createRecipeLink}>
                      <img src={CreateRecipeIcon} className={classes.navbarAddButtonSize} />
                    </IconButton>
                  </Link>
                  <IconButton
                    onClick={
                      !this.state.searchOpened ? this.handleSearchOpen : this.handleSearchClose
                    }
                    className={classes.createRecipeLink}
                  >
                    <img src={SearchMenuIcon} className={classes.navbarSearchButtonSize} />
                  </IconButton>
                </div>
                <div className={classes.navItem}>
                  <IconButton
                    ref={n => {
                      this.profileButtonRef = n
                    }}
                    onClick={this.handleProfileMenuOpen}
                    title={profileText}
                    className={classes.navbarProfileButtonSizeDesktop}
                  >
                    {!!profile && !!profile.avatar && (
                      <img
                        src={profile.avatar.fullSize}
                        className={classes.navbarProfileButtonSizeDesktopIcon}
                      />
                    )}
                  </IconButton>

                  <IconButton
                    ref={n => {
                      this.profileButtonRef = n
                    }}
                    onClick={this.handleMobileDrawerOpen}
                    title={profileText}
                    className={classes.hamburgerMenu}
                  >
                    <img src={HamburgerMenu} className={classes.hamburgerMenuIcon} />
                  </IconButton>

                  <div>
                    <Drawer
                      className={classes.drawer}
                      variant="contained"
                      anchor="right"
                      open={this.state.mobileDrawerOpen}
                      classes={{
                        paper: classes.drawerPaper,
                      }}
                    >
                      <ClickAwayListener onClickAway={this.handleMobileDrawerClose}>
                        <div>
                          <div
                            className={classes.drawerHeader}
                            onClick={this.handleMobileDrawerClose}
                          >
                            <IconButton>
                              <ChevronRightIcon />
                            </IconButton>
                          </div>
                          <Divider />
                          <List>
                            {[
                              'My Profile',
                              'My Kitchen',
                              'Shopping List',
                              'Calendar',
                              'Add Recipe',
                            ].map((text, index) => (
                              <MenuItem
                                to={
                                  index === 0 && profile && profile.id
                                    ? '/users/' + profile.id.toString()
                                    : index === 1
                                    ? '/my-kitchen'
                                    : index === 2
                                    ? '/shopping-list-mobile'
                                    : index === 3
                                    ? '/calendar-mobile'
                                    : '/create-recipe'
                                }
                                className={classes.navbarDropdownItem}
                                button
                                component={Link}
                                onClick={this.handleMobileDrawerClose}
                                key={text}
                              >
                                <IconButton className={classes.createRecipeLink}>
                                  <img
                                    src={
                                      index === 0 && profile && profile.id
                                        ? DrawerProfileIcon
                                        : index === 1
                                        ? DrawerKitchenIcon
                                        : index === 2
                                        ? DrawerShoppingListIcon
                                        : index === 3
                                        ? DrawerCalendarIcon
                                        : DrawerAddRecipeIcon
                                    }
                                    className={classes.mobileDrawerButtonSize}
                                  />
                                </IconButton>
                                <ListItemText primary={text} />
                              </MenuItem>
                            ))}
                          </List>
                          <Divider />
                          <List>
                            {['Account Settings', 'Log out'].map((text, index) => (
                              <MenuItem
                                button
                                component={Link}
                                to={index === 0 ? '/profile/account' : ''}
                                onClick={index === 0 ? this.handleMobileDrawerClose : this.logout}
                                key={text}
                                className={classes.navbarDropdownItem}
                              >
                                <IconButton className={classes.createRecipeLink}>
                                  <img
                                    src={index === 0 ? DrawerSettingsIcon : DrawerLogoutIcon}
                                    className={classes.mobileDrawerButtonSize}
                                  />
                                </IconButton>
                                <ListItemText primary={text} />
                              </MenuItem>
                            ))}
                          </List>
                        </div>
                      </ClickAwayListener>
                    </Drawer>
                  </div>
                  <NotificationsList
                    open={this.state.notificationsOpened}
                    anchorEl={this.notificationsButtonRef}
                    notificationsArrowRef={this.state.notificationsArrowRef}
                    handleNotificationsOpen={this.handleNotificationsOpen}
                    handleNotificationsArrowRef={this.handleNotificationsArrowRef}
                    arrowClass={classes.arrow}
                  />
                  <Popper
                    anchorEl={this.profileButtonRef}
                    open={this.state.profileMenuOpened}
                    transition
                    disablePortal
                    className={`${classes.navbarDropdown} ${classes.navbarDropdownDesktop}`}
                    modifiers={{
                      arrow: {
                        enabled: true,
                        element: arrowRef,
                      },
                      flip: {
                        enabled: true,
                      },
                      offset: {
                        enabled: true,
                        offset: -40,
                      },
                    }}
                  >
                    {({TransitionProps, placement}) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                      >
                        <React.Fragment>
                          <span className={classes.arrow} ref={this.handleArrowRef} />
                          <Paper className={classes.navbarDropdownPaper}>
                            <ClickAwayListener onClickAway={this.handleProfileMenuClose}>
                              <MenuList>
                                {profile && profile.id && (
                                  <MenuItem
                                    onClick={this.handleProfileMenuClose}
                                    component={Link}
                                    to={'/users/' + profile.id.toString()}
                                    className={classes.navbarDropdownItem}
                                  >
                                    My Profile
                                  </MenuItem>
                                )}
                                <MenuItem
                                  onClick={this.handleProfileMenuClose}
                                  component={Link}
                                  to="/my-kitchen"
                                  className={classes.navbarDropdownItem}
                                >
                                  My Kitchen
                                </MenuItem>
                                <MenuItem
                                  onClick={this.handleProfileMenuClose}
                                  component={Link}
                                  to="/shopping-list-mobile"
                                  className={classes.navbarDropdownItemShoppingList}
                                >
                                  Shopping List
                                </MenuItem>
                                <Divider className={classes.spacer} />
                                <MenuItem
                                  onClick={this.handleProfileMenuClose}
                                  component={Link}
                                  to="/profile/account"
                                  className={classes.navbarDropdownItem}
                                >
                                  Settings
                                </MenuItem>
                                <MenuItem
                                  onClick={e => {
                                    this.handleProfileMenuClose(e)
                                    this.logout(e)
                                  }}
                                  className={classes.navbarDropdownItem}
                                >
                                  Logout
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </React.Fragment>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div>
                  <IconButton
                    onClick={
                      !this.state.searchOpened ? this.handleSearchOpen : this.handleSearchClose
                    }
                    className={classes.createRecipeLink}
                  >
                    <img src={SearchMenuIcon} className={classes.navbarSearchButtonSize} />
                  </IconButton>
                </div>
                <div className={classes.nav}>
                  <div className={classes.loggedOutNav}>
                    <Link component={Link} to="/login" className={classes.loggedOutNavLink}>
                      Login
                    </Link>
                    &nbsp;/&nbsp;
                    <Link
                      to="/signup"
                      variant="contained"
                      color="primary"
                      className={classes.loggedOutNavLink}
                    >
                      Signup
                    </Link>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </Toolbar>
        <div
          className={
            this.state.searchOpened ? classes.searchBarMobileOpen : classes.searchBarMobileClose
          }
        >
          <SearchField
            style={{width: '100%'}}
            id="recipe-search"
            disableUnderline
            placeholder="Search"
            onKeyPress={this.onSearchKeyPress}
            onChange={this.onSearchChange}
            value={this.state.searchText}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  aria-label="toggle password visibility"
                  onClick={this.onSearch}
                  className={classes.searchButton}
                >
                  <img src={SearchIcon} />
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
      </AppBar>
    )
  }

  logout() {
    this.props.logoutAction()
    this.handleMobileDrawerClose()
  }
}

Header.displayName = 'Header'
Header.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object,
  onHomeClick: PropTypes.func.isRequired,
}
Header.contextTypes = {
  router: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {searchText: _.get(state, 'home.query.q', '')}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({clearNotifications, logoutAction}, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(Header)

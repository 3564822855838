import React from 'react'
import autoBind from 'react-autobind'

import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import {Typography, Paper} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import _ from 'lodash'
import cx from 'classnames'
import styles from './MyKitchen.styles.js'
import {updateLocal, getCategories, deleteCategory} from './redux/actions'
import Loading from '../common/Loading'

import Categories from './Categories'
import CreateCategory from './CreateCategory'
import MyKitchenSidebar from './MyKitchenSidebar'
import CreateCategoryMobile from './CreateCategoryMobile.jsx'

//import CreateCategoryMobile from './CreateCategoryMobile.jsx'

class MyKitchenCategories extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      myRecipeBoxState: 'list',
      openModal: false,
    }
    autoBind(this)
  }

  componentDidMount() {
    this.props.getCategories(this.props.profile.id)
  }

  handleDeleteCategory(id, e) {
    e.preventDefault()
    this.props.deleteCategory(id, this.props.profile.id)
  }

  handleGoToRecipesOfCategory(id, e) {
    e.preventDefault()
    this.props.getRecipesOfCategory(id)
  }

  render() {
    const {classes, categories} = this.props
    const {openModal} = this.state

    if (!categories.results || categories.isLoading) {
      return <Loading />
    }
    return (
      <MyKitchenSidebar>
        <CreateCategoryMobile openModal={openModal} onCancel={this.handleOnCancel} />
        <Paper className={cx(classes.root, classes.categoriesPaper)} elevation={1}>
          <Typography variant="h5" component="h3" className={classes.titleCategories}>
            {this.renderRecipeBoxTitle()}
          </Typography>
          {this.renderMyRecipeBox()}
        </Paper>
      </MyKitchenSidebar>
    )
  }

  renderRecipeBoxTitle() {
    if (this.state.myRecipeBoxState === 'createCategory') {
      return 'Create Category'
    }
    return 'My Recipe Box - All Categories'
  }

  handleOnCreateCategory() {
    if (window.innerWidth < 600) {
      this.setState({myRecipeBoxState: 'list', openModal: true})
    } else {
      this.setState({myRecipeBoxState: 'createCategory'})
    }
  }

  handleOnCancel() {
    this.setState({myRecipeBoxState: 'list', openModal: false})
  }

  renderMyRecipeBox() {
    const {myRecipeBoxState} = this.state
    const {classes, categories, profile} = this.props

    switch (myRecipeBoxState) {
      case 'list':
        return (
          <Categories
            classes={classes}
            categories={categories}
            onCreateCategory={this.handleOnCreateCategory}
            profile={profile}
          />
        )
      case 'createCategory':
        return <CreateCategory onCancel={this.handleOnCancel} />

      default:
        return null
    }
  }
}

MyKitchenCategories.displayName = 'MyKitchenCategories'

function mapStateToProps(state) {
  return {
    ...state.myKitchen,
    profile: _.get(state.profile, 'profile', null),
  }
}

MyKitchenCategories.propTypes = {
  classes: PropTypes.object.isRequired,
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({updateLocal, getCategories, deleteCategory}, dispatch)
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(MyKitchenCategories)

import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/core/styles'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import connect from 'react-redux/es/connect/connect'
import autobind from 'autobind-decorator'
import {DragSource, DropTarget} from 'react-dnd'
import {Link} from 'react-router'
import {CustomOptionFab} from '../myKitchen/MyKitchen.styles.js'
import DragIcon from '../../assets/icons/drag-small.svg'
import {getCalendarItems, deleteCalendarItem} from './redux/actions'
import ItemTypes from '../constants/DnD'
import withDragDropContext from '../DnDContext'

const dropTarget = {
  canDrop(props, monitor) {
    const dragItem = monitor.getItem()
    const hoverItem = props.calendarItem

    if (dragItem.updatingOrder || props.updatingOrder) {
      return false
    }

    if (dragItem.calItem.date === hoverItem.date) {
      return true
    }

    return false
  },

  hover(props, monitor, component) {
    if (!component) {
      return null
    }
    // node = HTML Div element from imperative API
    const rawComponent = component.getDecoratedComponentInstance()
    const node = rawComponent.getNode()
    if (!node) {
      return null
    }
    if (!monitor.canDrop()) {
      return null
    }
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index
    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }
    // Determine rectangle on screen
    const hoverBoundingRect = node.getBoundingClientRect()
    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
    // Determine mouse position
    const clientOffset = monitor.getClientOffset()
    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top
    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%
    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }
    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }
    // Time to actually perform the action
    //props.moveCard(dragIndex, hoverIndex)
    props.reorderCalItems(dragIndex, hoverIndex)
    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  },
}

function dropCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }
}

const dragSource = {
  beginDrag(props) {
    // Return the data describing the dragged item
    return {
      calItem: props.calendarItem,
      index: props.index,
      updatingOrder: props.updatingOrder,
      dayIndex: props.dayIndex,
    }
  },
}

function dragCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }
}

class CalendarItem extends React.Component {
  constructor(props) {
    super(props)

    this.calItemRef = React.createRef()
  }

  @autobind
  getNode() {
    return this.calItemRef.current
  }

  @autobind
  deleteRecipeFromCalendar(e) {
    e.preventDefault()
    this.props.deleteCalendarItem(
      this.props.calendarItem.id,
      this.props.calendarItem.date.replace(/-/g, ''),
      this.props.index,
    )
  }

  render() {
    const {classes, calendarItem, connectDragSource, connectDropTarget} = this.props
    const output = connectDragSource(
      <div className={classes.calendarRecipe} ref={this.calItemRef}>
        <span className={classes.calendarRecipeDrag}>
          <img src={DragIcon} />
        </span>
        <Link to={`/recipe/${calendarItem.recipe.id}`} className={classes.calendarLink}>
          <span className={classes.calendarRecipeTitle}>{calendarItem.recipe.title}</span>
        </Link>
        <CustomOptionFab
          color="primary"
          className={classes.recipeRemove}
          onClick={this.deleteRecipeFromCalendar}
        >
          <FontAwesomeIcon icon={faTimesCircle} className={classes.recipeRemoveIcon} />
        </CustomOptionFab>
      </div>,
    )
    return connectDropTarget(output)
  }
}

CalendarItem.displayName = 'CalendarItem'

function mapStateToProps(state) {
  return {
    ...state.calendar,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getCalendarItems, deleteCalendarItem}, dispatch)
}

export const styles = {
  calendarRecipe: {
    backgroundColor: '#EFEFEF',
    borderRadius: '3px',
    padding: '7px 20px 7px 7px',
    position: 'relative',
    display: 'flex',
    fontSize: '15px',
    marginBottom: 10,
    textAlign: 'left',
  },
  calendarRecipeDrag: {
    display: 'flex',
    cursor: 'move',
    marginRight: 7,
  },
  calendarLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  recipeRemove: {
    top: -5,
    right: -5,
    height: 24,
    width: 24,
    '&:hover': {
      backgroundColor: '#878787',
      borderColor: '#878787',
    },
    '&:focus': {
      backgroundColor: '#878787',
      borderColor: '#878787',
    },
    '&:active': {
      backgroundColor: '#878787',
      borderColor: '#878787',
    },
  },
  recipeRemoveIcon: {
    height: '24px !important',
    width: '24px !important',
    filter: 'drop-shadow( -1px 1px 4px rgba(0, 0, 0, .3))',
  },
  calendarRecipeTitle: {
    overflow: `hidden`,
    textOverflow: `ellipsis`,
  },
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withDragDropContext,
  DropTarget(ItemTypes.CALENDAR_ITEM, dropTarget, dropCollect),
  DragSource(ItemTypes.CALENDAR_ITEM, dragSource, dragCollect),
)(CalendarItem)

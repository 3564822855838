import React from 'react'
import {
  Grid,
  InputLabel,
  Button,
  Typography,
  Input,
  withStyles,
  Checkbox,
  FormControlLabel,
  MenuItem,
} from '@material-ui/core'
import autobind from 'autobind-decorator'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import Helmet from 'react-helmet'
import humps from 'humps'

import MyKitchenApi from './api'
import {createNotificationFromError, createSuccess} from '../common/redux/actions.notifications.js'
import InputValidator from '../common/InputValidator.jsx'
import validatedComponent from '../common/ValidatedComponent.jsx'
import {createCategorySuccess} from './redux/actions'
import RCESelect from '../common/styles/RCESelect'
import CreateCategoryMobile from './CreateCategoryMobile'

const styles = {
  createCategory: {
    margin: '120px 0 188px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  createCategoryContainer: {
    width: '474px',
  },
  createCategoryInputContainer: {
    marginBottom: '49px',
  },
  createCategoryInput: {
    width: '100%',
    border: '1.5px solid #D8D8D8',
    borderRadius: '3px',
    boxShadow: '0 1px 10px 0 rgba(0,0,0,0.19)',
    height: '51px',
  },
  featureImageLabel: {
    fontSize: '16px',
    lineHeight: '16px',
    color: 'rgba(0,0,0,0.87)',
    display: 'inline',
    marginLeft: '10px',
  },
  featureImageButton: {
    height: '24px',
    border: '0.5px solid #979797',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    color: '#979797',
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'center',
    fontFamily: 'Source Sans Pro',
    letterSpacing: '1px',
    textTransform: 'none',
  },
  saveBtn: {
    height: '30px',
    width: '280px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    color: '#589a43',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '21px',
    textAlign: 'center',
    textTransform: 'none',
    marginTop: '110px',
    padding: 0,
  },
  cancelBtn: {
    height: '23px',
    width: '176px',
    bordeRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    color: '#000000',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    marginTop: '26px',
    textTransform: 'none',
    padding: 0,
  },
}

const DetailTextField = withStyles({
  root: {
    marginTop: 0,
    border: '1px solid #D8D8D8',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    color: '#000',
    padding: '9px 11px',
  },
})(({component, ...others}) => {
  let Component = Input
  if (component) {
    Component = component
  }
  return <Component disableUnderline {...others} />
})

class CreateCategory extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      sharedWith: '',
      image: '',
    }
  }

  render() {
    const {classes, onCancel} = this.props

    return (
      <>
        {window.innerWidth > 600 ? (
          <div className={classes.createCategory}>
            <Helmet title="Create Category" />
            <div className={classes.createCategoryContainer}>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <div className={classes.createCategoryInputContainer}>
                    <InputLabel
                      htmlFor="categoryName"
                      style={{display: 'block', marginBottom: '10px'}}
                    >
                      Category Name
                    </InputLabel>
                    <InputValidator shouldValidateOnBlur={true}>
                      <DetailTextField
                        value={this.state.name}
                        label="Category Name"
                        className={classes.createCategoryInput}
                        onChange={this.handleChange.bind(this, 'name')}
                        required
                        inputProps={{
                          'data-test-name': 'create-category-name',
                          id: 'categoryName',
                          maxLength: 150,
                        }}
                      />
                    </InputValidator>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.createCategoryInputContainer}>
                    <InputLabel
                      htmlFor="sharedWith"
                      style={{display: 'block', marginBottom: '10px'}}
                    >
                      Who can see this?
                    </InputLabel>
                    <RCESelect
                      value={this.state.sharedWith}
                      className={classes.createCategoryInput}
                      inputProps={{id: 'sharedWith'}}
                      onChange={this.handleChange.bind(this, 'sharedWith')}
                      disableUnderline
                    >
                      <MenuItem value="" />
                      <MenuItem value="public">Public</MenuItem>
                      <MenuItem value="friends">Following</MenuItem>
                      <MenuItem value="private">Private</MenuItem>
                    </RCESelect>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="image" style={{display: 'block', marginBottom: '10px'}}>
                    Feature image
                  </InputLabel>
                  <Button
                    variant="contained"
                    component="label"
                    className={classes.featureImageButton}
                  >
                    Choose File
                    <input
                      style={{display: 'none'}}
                      type="file"
                      accept="image/*"
                      onChange={this.handleChangeImage.bind(this)}
                      id="image"
                    />
                  </Button>
                  <Typography component="span" className={classes.featureImageLabel}>
                    {this.renderImageName(this.state.image)}
                  </Typography>
                  {this.state.image !== '' ? (
                    <span style={{marginLeft: '20px'}}>
                      <FormControlLabel
                        control={<Checkbox onChange={this.onClearImage} label="Clear Image" />}
                        label="Clear Image"
                      />
                    </span>
                  ) : null}
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center'}}>
                  <div>
                    <Button
                      data-test-name="create-category-save"
                      onClick={this.createCategory}
                      className={classes.saveBtn}
                    >
                      Save
                    </Button>
                  </div>
                  <Button
                    data-test-name="create-category-cancel"
                    onClick={onCancel}
                    className={classes.cancelBtn}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        ) : (
          <CreateCategoryMobile />
        )}
      </>
    )
  }

  renderImageName(value) {
    if (value === '') {
      return 'No file chosen'
    }
    return value.name
  }

  handleChange(name, event) {
    this.setState({[name]: event.target.value})
  }

  handleChangeImage(event) {
    if (event.target.files.length > 0) {
      this.setState({image: event.target.files[0]})
    }
  }

  @autobind
  onClearImage() {
    this.setState({image: ''})
  }

  clearFields() {
    this.setState({image: '', name: ''})
  }

  @autobind
  async createCategory() {
    const {
      createNotificationFromError,
      createSuccess,
      isComponentValid,
      createCategorySuccess,
      onCancel,
    } = this.props
    try {
      if (isComponentValid()) {
        const category = await MyKitchenApi.createCategory(this.state)
        createCategorySuccess(humps.camelizeKeys(category.data))
        this.clearFields()
        createSuccess('Category created successfully.')
      }
    } catch (e) {
      createNotificationFromError(e)
    }
    onCancel()
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {createNotificationFromError, createSuccess, createCategorySuccess},
    dispatch,
  )
}

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles),
  validatedComponent,
)(CreateCategory)

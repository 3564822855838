import React from 'react'
import {
  Input,
  NativeSelect,
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  Icon,
  Typography,
  ExpansionPanelDetails,
  withStyles,
} from '@material-ui/core'
import {DropzoneArea} from 'material-ui-dropzone'

const drawerWidth = 265
const inputBorder = '1.15px solid #D8D8D8'

const styles = theme => ({
  details: {
    marginTop: 30,
  },
  appFrame: {
    display: 'flex',
    height: '100%',
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    height: '100%',
    overflow: 'auto',
  },
  greenLine: {
    borderTop: '3px solid #589a43',
    marginTop: 20,
  },
  leftDetails: {
    paddingTop: `41px`,
    paddingLeft: `2px`,
    paddingBottom: `47px`,
  },
  middleDetails: {
    paddingTop: `41px`,
    paddingBottom: `47px`,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },
  rightDetails: {
    paddingTop: `41px`,
    paddingBottom: `47px`,

    paddingRight: `2px`,
    [theme.breakpoints.down('sm')]: {
      paddingRight: 20,
      paddingLeft: '32 !important',
    },
  },
  rightDetailsTwo: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: '32 !important',
      paddingLeft: 20,
    },
  },
  descriptionTextField: {
    minHeight: 160,
    border: inputBorder,
  },
  titleTextField: {
    marginBottom: 10,
    border: inputBorder,
    height: '48px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 4,
      height: '42px',
    },
  },
  importerTextField: {
    marginBottom: 0,
    borderColor: '#888',
    [theme.breakpoints.down('sm')]: {
      padding: '6px 10px',
    },
  },
  halfTextField: {
    marginBottom: 25,
    border: inputBorder,
    borderRadius: '3px',
    height: '48px',
    paddingLeft: '11px',
    backgroundColor: '#FFFFFF',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 4,
      height: '42px',
    },
  },
  dropZone: {
    height: 307,
    marginLeft: '0',
    width: '100%',
    border: '2px dashed #EFEFEF',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 40px)',
      paddingRight: 30,
      paddingLeft: 30,
      margin: '0 auto',
    },
  },
  rightButton: {
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  dropZoneText: {
    fontSize: 16,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  removeHeight: {
    height: '0',
  },

  center: {
    textAlign: 'center',
    margin: `0 auto`,
    padding: `0 !important`,
  },
  importerP: {
    color: `#000000`,
    fontFamily: 'Lato',
    fontSize: `16px`,
    fontWeight: '300',
    lineHeight: `21px`,
    textAlign: `center`,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      marginLeft: 33,
      marginRight: 33,
    },
  },
  importerTitle: {
    textAlign: 'center',
    color: `#000000`,
    fontFamily: `Lato`,
    fontWeight: '300',
    fontSize: `24px`,
    lineHeight: `29px`,
    marginTop: `45px`,
    [theme.breakpoints.down('sm')]: {
      marginTop: `21px`,
      display: 'none',
    },
  },
  buttonImport: {
    width: `280px`,
    marginTop: `20px`,
    marginBottom: `40px`,
    borderRadius: `34.5px`,
    backgroundColor: `#FFFFFF`,
    fontWeight: '600',
    boxShadow: `0 3px 10px -2px rgba(0,0,0,0.12)`,
    color: `#589a43`,
    fontFamily: `Lato`,
    fontSize: `21px`,
    lineHeight: `21px`,
    textAlign: `center`,
    textTransform: `capitalize`,
  },
  ingredients: {
    marginBottom: 18,
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
      marginLeft: 20,
    },
  },
  materials: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
      marginLeft: 20,
    },
  },
  materialMobileTitle: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 20,
      display: 'block',
      fontSize: '16px',
    },
  },
  dragImage: {
    width: 18,
    height: 35,
    paddingRight: 10,
    verticalAlign: 'middle',
    textAlign: 'center',
    cursor: 'move',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  draggableInput: {
    width: 'calc(100% - 18px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(2),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  tagList: {
    marginTop: '1.5em',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },
  tag: {
    background: 'white',
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #D8D8D8',
    padding: '0.5em',
    marginBottom: '0.5em',
    '&:not(:last-child)': {
      marginRight: '0.5em',
    },
  },

  tagDelete: {
    fontSize: '24px',
    marginTop: '-4px',
    cursor: 'pointer',
    marginLeft: 10,
    color: '#999',
    '&:hover': {
      color: 'black',
    },
  },
  textRight: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
      marginLeft: 20,
      textAlign: 'center',
      marginTop: 20,
    },
  },
  textLeft: {
    textAlign: 'left',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
      marginLeft: 20,
      textAlign: 'center',
      marginBottom: 20,
    },
  },
  saveButton: {
    textAlign: 'right',
    color: '#1b5e20',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  savedBtn: {
    marginTop: 20,
    color: '#1b5e20',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
      marginLeft: 20,
      marginTop: 10,
      textAlign: 'center',
      width: 'calc(100% - 40px)',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: '#1b5e20',
    },
  },
  lastSaved: {
    paddingRight: 0,
    marginTop: 15,
    color: '#1b5e20',
    fontWeight: 'bold',
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      paddingRight: 15,
      position: 'relative',
      top: 11,
    },
  },
  deleteButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover': {
      color: 'white',
      backgroundColor: 'red',
    },
  },
  addButton: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
      marginLeft: 20,
    },
  },
  ingredientMobile: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0 !important',
    },
  },
  ingredientMaterialTitleDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  ingredientMaterialTitleMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
      display: 'block',
    },
  },
  deleteButtonIngredients: {
    color: '#aaa',
    cursor: 'pointer',
    fontSize: 28,
    lineHeight: '48px',
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
    display: 'block',
    '&:hover': {
      color: '#bbb',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 28,
    },
  },
  optionsMargin: {
    marginTop: '29px',
    padding: `0 69px`,
  },
  optionsTitle: {
    fontFamily: 'Literata',
    fontSize: `24px`,
    lineHeight: `32px`,
    fontWeight: '400',

    textAlign: `center`,
    paddingTop: '30.5px',
    padding: `0 20px`,
  },
  optionDeleteIcon: {
    height: `24px`,
    width: `21px`,
    color: `#D8D8D8`,
    fontSize: `24px`,
    top: `18px`,
    left: `200px`,
    position: 'relative',
    cursor: `pointer`,
  },
  saveBtn: {
    height: '30px',
    width: '180px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    color: '#589a43',
    fontFamily: 'Lato',
    fontSize: '18px',
    fontWeight: '300',
    lineHeight: '21px',
    textAlign: 'center',
    textTransform: 'none',
    marginTop: '50px',
    padding: 0,
  },
  cancelBtn: {
    height: '23px',
    width: '124px',
    borderRadius: '34.5px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.12)',
    color: '#000000',
    fontFamily: 'Lato',
    fontWeight: '300',

    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    marginTop: '26px',
    textTransform: 'none',
    padding: 0,
  },
  quantityInput: {
    marginTop: 0,
    border: '1px solid #D8D8D8',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    width: '100%',
    color: '#000',
    padding: '9px 11px',
  },
  optionsBottom: {
    paddingBottom: `31.5px`,
  },
  tagEntryContainer: {
    position: 'relative',
    marginTop: '-0.65em',
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
      marginLeft: 20,
    },
  },
  tagEntry: {
    height: 48,
    width: '80%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  addTagButton: {
    height: 48,
    width: '16%',
    border: inputBorder,
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      width: '100%',
    },
  },
  optionsModal: {
    top: `50%`,
    left: '50%',
    transform: `translate(-50%, -50%)`,
  },

  optionPaper: {
    width: `613px`,
    marginTop: `10%`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none',
    overflow: 'auto',
    margin: `auto`,
    borderRadius: `3px`,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      justifyContent: 'center',
    },
  },
  tagSuggestions: {
    position: 'absolute',
    top: '5.75em',
    minWidth: 300,
    maxWidth: 800,
    listStyle: 'none',
    margin: 0,
    padding: 0,
    zIndex: 1000,
    '&>li': {
      background: 'white',
      lineHeight: '50px',
      padding: '0 15px',
      cursor: 'pointer',
      borderLeft: '1px solid #D8D8D8',
      borderRight: '1px solid #D8D8D8',
    },
    '&>li:hover': {
      background: '#eee',
    },
    '&>li:first-child': {
      borderTop: '1px dashed #D8D8D8',
    },
    '&>li:last-child': {
      borderBottomLeftRadius: '3px',
      borderBottomRightRadius: '3px',
      borderBottom: '1px solid #D8D8D8',
    },
  },
})
export default styles

export const Label = withStyles({
  root: {
    fontSize: 18,
    marginBottom: 10,
  },
})(props => {
  const {classes, ...others} = props
  return <div className={classes.root} {...others} />
})

export const DetailTextField = withStyles({
  root: {
    marginTop: 0,
    border: '1px solid #D8D8D8',
    borderRadius: '3px',
    backgroundColor: '#FFFFFF',
    color: '#000',
    padding: '9px 11px',
  },
})(({component, ...others}) => {
  let Component = Input
  if (component) {
    Component = component
  }
  return <Component disableUnderline {...others} />
})

export const DescriptionTextField = DetailTextField

export const SelectFilled = props => {
  return <NativeSelect input={<DetailTextField />} {...props} />
}

export const DropzoneAreaDetails = withStyles({
  dropzoneTextStyle: {
    padding: '10px',
    marginTop: '25%',
  },
})(DropzoneArea)

export const DirectionPhotoDropzoneAreaDetails = withStyles({
  dropzoneTextStyle: {
    padding: '10px',
    marginTop: '-1.35em',
    minHeight: 52,
    fontWeight: '400',
    maxHeight: 52,
    fontFamily: "'Literata', serif",
    fontSize: 18,
  },
})(DropzoneArea)

export const ButtonFilled = withStyles({
  root: {
    backgroundColor: '#FFF',
  },
  outlined: {
    borderColor: '#D8D8D8',
    padding: 13,
  },
})(Button)

export const SectionExpandable = withStyles(theme => ({
  panel: {
    backgroundColor: 'transparent',
  },
  summary: {
    padding: 0,
    display: 'block',
  },
  summaryContent: {
    display: 'inline-block',
    flexGrow: 0,
    '& > :last-child': {
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 20,
      },
    },
  },
  expandIcon: {
    right: 'unset',
  },
  sectionTitle: {
    fontFamily: "'Literata', serif",
    fontWeight: '400',

    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 21,
    },
  },
  details: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}))(({title, classes, children, ...others}) => (
  <ExpansionPanel elevation={0} classes={{root: classes.panel}} {...others}>
    <ExpansionPanelSummary
      expandIcon={<Icon>arrow_drop_down</Icon>}
      classes={{
        root: classes.summary,
        content: classes.summaryContent,
        expandIcon: classes.expandIcon,
      }}
    >
      <Typography className={classes.sectionTitle}>{title}</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails classes={{root: classes.details}}>{children}</ExpansionPanelDetails>
  </ExpansionPanel>
))

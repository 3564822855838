import * as Types from './actions.types'

export function initPublicProfile() {
  return {type: Types.PUBLIC_PROFILE_INIT}
}

export function getPublicProfile(id) {
  return {type: Types.PUBLIC_PROFILE_GET, id}
}

export function getPublicProfileSuccess(user) {
  return {type: Types.PUBLIC_PROFILE_GET_SUCCESS, user}
}

export function getPublicProfileFailure(error) {
  return {type: Types.PUBLIC_PROFILE_GET_FAILURE, error}
}

import React from 'react'
import {IndexRoute, Route} from 'react-router'
import {authenticateOnEnter} from './utils/RoutingUtils'
import App from './App.jsx'
import NotFound from './common/NotFound.Page.jsx'
import Home from './home/Home.jsx'
import Login from './auth/Login.jsx'
import Signup from './auth/Signup.jsx'
import ForgotPasswordRequest from './auth/ForgotPasswordRequest.jsx'
import ForgotPasswordConsume from './auth/ForgotPasswordConsume.jsx'
import Profile from './profile/Profile.jsx'
import AccountSettings from './profile/AccountSettings.jsx'
import ConfirmEmail from './profile/ConfirmEmail.jsx'
import ChangeEmailConfirm from './profile/ChangeEmailConfirm.jsx'
import ChangeEmailVerify from './profile/ChangeEmailVerify.jsx'
import CreateRecipe from './recipe/CreateRecipeFlow/CreateRecipe.jsx'
import RecipeView from './recipe/RecipeView/RecipeView'
import MyKitchen from './myKitchen/MyKitchen'
import ShoppingListMobile from './home/ShoppingListMobile'
import CalendarMobile from './home/CalendarMobile'
import MyKitchenCategory from './myKitchen/MyKitchenCategory'
import MyKitchenCategories from './myKitchen/MyKitchenCategories'
import CalendarPage from './calendar/CalendarPage'
import RecipesFollowingBoxCategories from './profile/RecipesFollowingBoxCategories'
import RecipesFollowingBoxCategory from './profile/RecipesFollowingBoxCategory'
import ProfileSettings from './profile/ProfileSettings'
import EditRecipe from './recipe/CreateRecipeFlow/EditRecipe'
import SearchResults from './search/SearchResults'
import AboutUs from './aboutUs/AboutUs'
import ArticleView from './article'

export default function routing(store) {
  const authenticate = authenticateOnEnter.bind(this, store)

  return (
    <Route path="/" component={App}>
      <IndexRoute component={Home} />
      <Route path="/about-us" component={AboutUs} />
      <Route path="/login" component={Login} />
      <Route path="/signup" component={Signup} />
      <Route path="/forgot" component={ForgotPasswordRequest} />
      <Route path="/shopping-list-mobile" component={ShoppingListMobile} />
      <Route path="/calendar-mobile" component={CalendarMobile} />
      <Route path="/forgot-password/:token" component={ForgotPasswordConsume} />
      <Route path="/profile" component={ProfileSettings}>
        <Route path="account" component={AccountSettings} onEnter={authenticate} />
      </Route>
      <Route path="/account" component={AccountSettings} onEnter={authenticate} />
      <Route path="/confirm-email/:id/:token" component={ConfirmEmail} />
      <Route path="/change-email/:id/:token" component={ChangeEmailConfirm} />
      <Route path="/change-email-verify/:id/:token" component={ChangeEmailVerify} />
      <Route path="/users/:id" component={Profile}>
        <IndexRoute component={RecipesFollowingBoxCategories} />
        <Route path="category/:categoryId" component={RecipesFollowingBoxCategory} />
      </Route>
      <Route path="/edit-recipe/:id" component={EditRecipe} onEnter={authenticate} />
      <Route path="/create-recipe" component={CreateRecipe} onEnter={authenticate} />
      <Route path="/create-recipe/:id" component={CreateRecipe} onEnter={authenticate} />
      <Route path="/recipe/:id(/:slug)" component={RecipeView} />
      <Route path="/article/:id(/:slug)" component={ArticleView} />
      <Route path="/my-kitchen" component={MyKitchen} onEnter={authenticate}>
        <IndexRoute component={MyKitchenCategories} />
        <Route path="category/:categoryId" component={MyKitchenCategory} />
        <Route path="calendar" component={CalendarPage} />
      </Route>
      <Route path="/search" component={SearchResults} />
      <Route path="/404" component={NotFound} />

      {/* should always be at the end */}
      <Route path="*" component={NotFound} />
    </Route>
  )
}

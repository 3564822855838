import React, {useState, useEffect} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Divider, Typography} from '@material-ui/core'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {Link, withRouter} from 'react-router'
import Loading from '../common/Loading'
import ArticleApi from './api'
import {createError} from '../common/redux/actions.notifications'
import NotFound from '../common/NotFound.Page'
import NewsFeedItem from '../home/NewsfeedItem'

function ArticleView({params, classes, recimeeId}) {
  const [isLoading, setIsLoading] = useState(false)
  const [articleData, setArticleData] = useState({})
  const [notFound, setNotFound] = useState(null)
  const {id} = params

  useEffect(() => {
    fetchArticle(id)
  }, [id])

  const fetchArticle = async id => {
    setIsLoading(true)
    setNotFound(null)
    try {
      const article = await ArticleApi.getArticle(id, {
        expand: 'tags,tag',
      })
      setArticleData(article)
    } catch (error) {
      setNotFound(true)
    }
    setIsLoading(false)
  }

  if (isLoading) {
    return <Loading />
  }

  if (notFound) {
    return <NotFound />
  }

  return (
    <div className={classes.container}>
      <div className={classes.titleGroup}>
        {articleData?.titleLink ? (
          <Link
            to={articleData?.titleLink}
            target="_blank"
            style={{textDecoration: 'none', color: 'inherit'}}
          >
            <Typography variant="h1" className={classes.title}>
              {articleData?.title}
            </Typography>
          </Link>
        ) : (
          <Typography variant="h1" className={classes.title}>
            {articleData?.title}
          </Typography>
        )}
        <Divider className={classes.divider} />
        <div className={classes.groupBy}>
          <Typography variant="body1" className={classes.articleBy}>
            Article by
          </Typography>
          {articleData?.authorLink ? (
            <Link
              to={articleData.authorLink}
              target="_blank"
              style={{textDecoration: 'none', color: 'inherit'}}
            >
              <Typography variant="body1">{articleData?.author}</Typography>
            </Link>
          ) : (
            <Typography variant="body1">{articleData?.author}</Typography>
          )}
        </div>
      </div>
      <img src={articleData.image} className={classes.image} alt={articleData?.description} />
      <section className={classes.section}>
        <div className={classes.wrapper}>
          <article
            className={classes.innerHtml}
            dangerouslySetInnerHTML={{__html: articleData?.text}}
          />
          <div className={classes.tagsGroup}>
            <div className={classes.tags}>
              {articleData?.tags?.map(({tag}) => (
                <div>{tag.title}</div>
              ))}
            </div>
            <Divider />
            <div className={classes.groupBy}>
              <Typography variant="body1" className={classes.articleByFooter}>
                Article by
              </Typography>
              <Typography variant="body1">{articleData?.author}</Typography>
            </div>
          </div>
        </div>
        <sidebar className={classes.sidebar}>
          {articleData?.sidebarContent?.map(item => (
            <div className={classes.adCard}>
              <NewsFeedItem item={item?.contentObject} recimeeId={recimeeId} />
            </div>
          ))}
        </sidebar>
      </section>
    </div>
  )
}

const styles = theme => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    display: 'grid',
    gridTemplateColumns: '66% 34%',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '100%',
      margin: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '100%',
    },
  },
  wrapper: {
    width: '100%',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  },
  adCard: {
    minHeight: '380px',
    maxHeight: '390px',
    width: '100%',
    backgroundColor: '#f5f5f5',
  },
  titleGroup: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '870px',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      padding: theme.spacing(0, 4),
    },
    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
      padding: theme.spacing(0, 2),
    },
  },
  title: {
    fontFamily: `Literata`,
    fontWeight: 600,
    fontSize: '52px',
    lineHeight: '110%',
    letterSpacing: '-0.5px',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '31px',
      lineHeight: '36px',
    },
  },
  groupBy: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(1),
  },
  articleBy: {
    color: '#388E3C',
    marginRight: theme.spacing(0.5),
  },
  articleByFooter: {
    marginRight: theme.spacing(0.5),
  },
  divider: {
    backgroundColor: '#388E3C',
  },
  image: {
    maxHeight: '810px',
    marginBottom: theme.spacing(8),
    aspectRatio: '16 / 9',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'auto',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'auto',
      width: '100%',
    },
  },
  innerHtml: {
    padding: '0 7.5%',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '170%',
    '& pre': {
      width: '100%',
      whiteSpace: 'pre-wrap',
    },
    '& h2': {
      fontFamily: 'Literata',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '0.18px',
    },
    '& img': {
      maxWidth: '100%',
    },
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      marginBottom: theme.spacing(8),
      width: '100%',
      padding: '0 12.5%',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
      marginBottom: theme.spacing(8),
      padding: theme.spacing(0, 2),
      width: '100%',
    },
  },
  tagsGroup: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 7.5%',
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      width: '100%',
      padding: '0 12.5%',
    },
    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
      padding: theme.spacing(0, 2),
      width: '100%',
    },
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(4),
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    '& div': {
      fontFamily: 'Lato',
      border: `1px solid rgba(0, 0, 0, 0.6)`,
      borderRadius: '50px',
      padding: theme.spacing(1, 2),
    },
  },
})

function mapStateToProps(state) {
  return {
    recimeeId: state.profile.recimeeId,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createError,
    },
    dispatch,
  )
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  withStyles(styles),
)(ArticleView)

import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import MuiTabs from '@material-ui/core/Tabs'
import MuiTab from '@material-ui/core/Tab'

export const Tabs = withStyles({
  indicator: {backgroundColor: 'none'},
  root: {
    background: '#EFEFEF',
  },
})(props => <MuiTabs {...props} />)

export const Tab = withStyles({
  root: {
    background: 'transparent',
    textTransform: 'capitalize',
    fontSize: 16,
    paddingTop: 9,
    paddingBottom: 9,
    minWidth: 150,
  },
  selected: {
    background: 'white',
  },
  wrapper: {
    display: `inline`,
  },
  labelContainer: {
    textTransform: `none`,
    padding: 0,
    verticalAlign: `middle`,
    marginLeft: `10px`,
  },
})(MuiTab)

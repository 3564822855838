import reduceReducers from 'reduce-reducers'
import * as Types from '../../publicUser/redux/actions.userList.types'
import paginate from '../../common/redux/reducers.paginate'

function home(state = createInitState(), action) {
  switch (action.type) {
    case Types.USERS_LIST_INIT:
      return createInitState()
    case Types.USERS_LIST_GET:
      return {
        ...state,
        query: {...action.payload},
        isFetching: true,
      }
    case Types.USERS_LIST_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        users: action.payload.results,
      }
    case Types.USERS_LIST_GET_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    default:
      return state
  }
}

function createInitState() {
  return {
    isFetching: false,
    query: createInitQuery(),
    users: [],
  }
}

function createInitQuery() {
  return {
    page: null,
  }
}

const homePagination = paginate({
  actionTypes: [
    Types.USERS_LIST_GET,
    Types.USERS_LIST_GET_SUCCESS,
    Types.USERS_LIST_GET_FAILURE,
    Types.USERS_LIST_INIT,
  ],
})

const reducer = reduceReducers(home, homePagination)

export default reducer
